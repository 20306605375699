import React, { useEffect, useState, useRef } from 'react';
import ListBoxContainer from './ListBox/ListBoxContainer';
import HomeMapContainer from '../HomeMap/HomeMapContainer';
import eventEmitter from '../../../utils/eventEmitter';
import { EventKey } from '../../../utils/constant';


const HomeListView = (props: any) => {
    const [isMapInfullView, setIsMapInfullView] = useState<boolean>(true);
    const [isListInfullView, setIsListInfullView] = useState<boolean>(false);
    const listBar = useRef<HTMLHeadingElement>(null)
    const mapBar = useRef<HTMLHeadingElement>(null)

    useEffect(() => {
        if(isMapInfullView === false) {
            let obj = mapBar.current ? mapBar.current.offsetWidth : 0
            eventEmitter.emit("eventGetWindowSize", obj)
        } else {
            let obj = null
            eventEmitter.emit("eventGetWindowSize", obj)
        }
    }, [isListInfullView, isMapInfullView])

    useEffect(() => {
        eventEmitter.on(EventKey.MapLayoutChange, (data: boolean) => setIsMapInfullView(data));
    }, [eventEmitter.on, setIsMapInfullView]);

    useEffect(() => {
        eventEmitter.on(EventKey.ListLayoutChange, (data: boolean) => setIsListInfullView(data));
    }, [eventEmitter.on, setIsListInfullView]);
    return (
        // <div className={!isListInfullView ? "row position-relative" : ""}>
        <div className={`
            ${isMapInfullView
            ? 'row row-map'
            : (`d-flex position-relative ${isListInfullView && !isMapInfullView ? "map-list-view-full" : "map-list-view"}`)
            }`
        }>
            <div ref={listBar} className={`map-list-view1 list-view ${!isMapInfullView ? 'col-lg-4' : 'd-none'} ${isListInfullView ? 'col-lg-12 list-view-full pe-0' : 'col-lg-8' }`}>
                <ListBoxContainer props={{ isListInfullView, isMapInfullView, setIsMapInfullView, setIsListInfullView, isVisible: isListInfullView || !isMapInfullView }} fromMapView={props.fromMapView} />
            </div>
            <div ref={mapBar} className={`${!isMapInfullView ? 'col-lg-4 ps-0 map-view col-md-4' : 'col-lg-12'} ${isListInfullView ? 'd-none' : 'col-lg-4'}`}>
                <HomeMapContainer props={{ isListInfullView, isMapInfullView, setIsMapInfullView, setIsListInfullView }} fromMapView={props.fromMapView} />
            </div>
        </div>
    );
};

export default HomeListView;

/* eslint-disable @typescript-eslint/no-array-constructor */
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Tooltip } from 'react-tooltip';
import swal from 'sweetalert';
import heartProgress from '../../../../assets/images/heart-progress.png';
import warning from '../../../../assets/images/warning.png';
import { RootState } from '../../../../store';
import { setOnboardingProgress } from '../../../../store/onboarding/onboardingProgressReducer';
import { OnboardingProgressType } from '../../../../utils/model';
import { ProgressFooterPropsType } from './ProgressFooterContainer';
import { useNavigate } from 'react-router-dom';
import { ROUTES_DATA } from '../../../../navigation/RoutesConstants';
import { setOnboardingProgressStages } from '../../../../store/onboarding/onboardingProgressStagesReducer';
import { setOnboardingIsPublished } from '../../../../store/onboarding/onboardingPublishedReducer';

// const ProgressFooterView = ({ isValidated, backActionHandler, nextActionHandler }: ProgressFooterPropsType) => {
const ProgressFooterView = ({ stageRef }: ProgressFooterPropsType) => {
    const { wardFacility } = useSelector((state: RootState) => state.onboardingWardFacility);
    const { isPageValid } = useSelector((state: RootState) => state.onboardingPageValidity);
    const { serviceId, serviceName } = useSelector((state: RootState) => state.onboardingService);
    const ONBOARDING_STAGES: OnboardingProgressType[] = [
        {
            stageNumber: 1,
            stageHeading: 'Identify Your Facility Type.',
            stageDescription: `You can select one or more categories that best describes the services for <span class="bolder">${serviceName}</span>.`
        },
        {
            stageNumber: 2,
            stageHeading: wardFacility === null ? 'Set Up Your Wards & Beds.' : wardFacility ? 'Set Up Your Wards & Beds.' : `Then Let's Add Beds.`,
            stageDescription: undefined
        },
        {
            stageNumber: 3,
            stageHeading: 'Name the Wards.',
            stageDescription: `This step allows you to personalize and organize the wards at <span class="bolder">${serviceName}</span>.`
        },
        {
            stageNumber: 4,
            stageHeading: 'Show Your Amenities.',
            stageDescription: undefined
        },
        {
            stageNumber: 5,
            stageHeading: 'Get Your Location Right.',
            stageDescription: `This section helps you provide essential location details and highlight nearby amenities to give a comprehensive view of <span class="bolder">${serviceName}</span> surroundings.`
        },
        {
            stageNumber: 6,
            stageHeading: 'Facility Overview.',
            stageDescription: `This section allows you to use our Ai generated description based on publicly available information to describe your facility, helping potential residents and their families understand the services and care you provide at <span class="bolder">${serviceName}.</span>`
        },
        {
            stageNumber: 7,
            stageHeading: 'Introduce Your Key Contact.',
            stageDescription:
                'This step allows you to add a personal touch to your service profile by including the details of your coordinator or key contact person. A friendly face and contact information help build trust and improve communication with prospective residents and their families.'
        },
        {
            stageNumber: 8,
            stageHeading: 'Your Services.',
            stageDescription: `This section allows you to seamlessly add & showcase services offered at <span class="bolder">${serviceName}</span>.`
        },
        {
            stageNumber: 9,
            stageHeading: 'Establish Credibility.',
            stageDescription: `We highly recommend adding all the accreditations of <span class="bolder">${serviceName}</span>, to elevate your property's profile and demonstrate your commitment to quality and safety.`
        },
        {
            stageNumber: 10,
            stageHeading: 'Your Photo Gallery.',
            stageDescription:
                'This section allows you to customize the way your gallery is displayed on your profile, showcasing the best aspects of your facility to prospective residents and their families.'
        },
        {
            stageNumber: 11,
            stageHeading: 'Questions & Answers.',
            stageDescription: 'This is <span class="bolder">Questions & Answers.</span>'
        }
    ];

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const onboardingProgress = useSelector((state: RootState) => state.onboardingProgress);
    const [totalSteps, setTotalSteps] = useState(ONBOARDING_STAGES.length);

    const nextOnClickHandler = (payload: OnboardingProgressType) => {
        dispatch(setOnboardingProgress(payload!));
    };

    useEffect(() => {
        const updatedArr = ONBOARDING_STAGES.map((item) => {
            if (item.stageNumber === 2) {
                const obj = { ...item, stageHeading: wardFacility === null ? 'Set Up Your Wards & Beds.' : wardFacility ? 'Set Up Your Wards & Beds.' : `Then Let's Add Beds.` };
                if (onboardingProgress.stageNumber === 2) {
                    dispatch(setOnboardingProgress(obj));
                }
                return obj;
            }
            return item;
        });
        dispatch(setOnboardingProgressStages({ onboardingStages: [...updatedArr] }));
    }, [wardFacility]);

    return (
        <div className="stepfooter">
            <div className="cursor-pointer" style={{ display: 'flex', background: 'linear-gradient(to left, #1a1a4a, #e0e0eb)' }}>
                {Array.from({ length: totalSteps }, (v, i) => i + 1).map((item: number, index: number) => {
                    if (!wardFacility && index !== 2) {
                        return (
                            <div
                                key={item}
                                style={{
                                    width: `${Math.ceil(100 / totalSteps)}%`,
                                    height: '5px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center'
                                    // borderRight: item === totalSteps ? '0px' : '1px solid #fff'
                                }}
                                onClick={async () => {
                                    if ((await stageRef.current.triggerValidation()) === false) {
                                        return;
                                    }

                                    if (stageRef.current.isValidated!) {
                                        if (item > onboardingProgress.stageNumber!) {
                                            stageRef.current.nextActionHandler!();
                                        }
                                        if (item < onboardingProgress.stageNumber!) {
                                            stageRef.current.backActionHandler!();
                                        }
                                        nextOnClickHandler(ONBOARDING_STAGES[item - 1]);
                                    } else {
                                        swal({
                                            title: '',
                                            text: `Data entered in this step will be lost. Are you sure you want continue?`,
                                            icon: warning,
                                            buttons: ['No', 'Yes'],
                                            dangerMode: true
                                        }).then(async (confirm: any) => {
                                            if (confirm) {
                                                nextOnClickHandler(ONBOARDING_STAGES[item - 1]);
                                            }
                                        });
                                    }
                                }}
                                data-tooltip-id={`my-tooltip-${item}`}
                                // data-tooltip-content={`This is item#${item}`}
                            >
                                {item === onboardingProgress.stageNumber && (
                                    <div className="progress-indicator cursor-pointer mb-2">
                                        <img src={heartProgress} alt="Progress Indicator" className="img-fluid" width="50" />
                                    </div>
                                )}
                                <Tooltip id={`my-tooltip-${item}`} className={`my-tooltip`} style={{ marginTop: item === onboardingProgress.stageNumber ? -10 : 0 }}>
                                    <p className="my-tooltip" dangerouslySetInnerHTML={{ __html: ONBOARDING_STAGES[item - 1].stageHeading! }}></p>
                                </Tooltip>
                            </div>
                        );
                    } else if (wardFacility) {
                        return (
                            <div
                                key={item}
                                style={{
                                    width: `${Math.ceil(100 / totalSteps)}%`,
                                    height: '5px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center'
                                    // borderRight: item === totalSteps ? '0px' : '1px solid #fff'
                                }}
                                onClick={async () => {
                                    if ((await stageRef.current.triggerValidation()) === false) {
                                        return;
                                    }

                                    if (stageRef.current.isValidated!) {
                                        if (item > onboardingProgress.stageNumber!) {
                                            stageRef.current.nextActionHandler!();
                                        }
                                        if (item < onboardingProgress.stageNumber!) {
                                            stageRef.current.backActionHandler!();
                                        }
                                        nextOnClickHandler(ONBOARDING_STAGES[item - 1]);
                                    } else {
                                        swal({
                                            title: '',
                                            text: `Data entered in this step will be lost. Are you sure you want continue?`,
                                            icon: warning,
                                            buttons: ['No', 'Yes'],
                                            dangerMode: true
                                        }).then(async (confirm: any) => {
                                            if (confirm) {
                                                nextOnClickHandler(ONBOARDING_STAGES[item - 1]);
                                            }
                                        });
                                    }
                                }}
                                data-tooltip-id={`my-tooltip-${item}`}
                                // data-tooltip-content={`This is item#${item}`}
                            >
                                {item === onboardingProgress.stageNumber && (
                                    <div className="progress-indicator cursor-pointer mb-2">
                                        <img src={heartProgress} alt="Progress Indicator" className="img-fluid" width="50" />
                                    </div>
                                )}
                                <Tooltip id={`my-tooltip-${item}`} className={`my-tooltip`} style={{ marginTop: item === onboardingProgress.stageNumber ? -10 : 0 }}>
                                    <p className="my-tooltip" dangerouslySetInnerHTML={{ __html: ONBOARDING_STAGES[item - 1].stageHeading! }}></p>
                                </Tooltip>
                            </div>
                        );
                    }
                })}
            </div>
            <div className="service-detail-padding">
                <div className="container-fluid p-0">
                    <div className="d-flex stepbuttons">
                        <button
                            className="classic-btn black-btn"
                            onClick={async () => {
                                if ((await stageRef.current.triggerValidation()) === false) {
                                    return;
                                }
                                if (stageRef.current.isValidated!) {
                                    if (onboardingProgress?.stageNumber! > 0) {
                                        if (onboardingProgress?.stageNumber === 4 && !wardFacility) {
                                            stageRef.current.backActionHandler!();
                                            nextOnClickHandler(ONBOARDING_STAGES[onboardingProgress?.stageNumber! - 3]);
                                        } else {
                                            stageRef.current.backActionHandler!();
                                            nextOnClickHandler(ONBOARDING_STAGES[onboardingProgress?.stageNumber! - 2]);
                                        }
                                    }
                                } else {
                                    swal({
                                        title: '',
                                        text: `Data entered in this step will be lost. Are you sure you want to go back?`,
                                        icon: warning,
                                        buttons: ['No', 'Yes'],
                                        dangerMode: true
                                    }).then(async (confirm: any) => {
                                        if (confirm) {
                                            if (onboardingProgress?.stageNumber! > 0) {
                                                stageRef.current.backActionHandler!();
                                                nextOnClickHandler(ONBOARDING_STAGES[onboardingProgress?.stageNumber! - 2]);
                                            }
                                        }
                                    });
                                }
                            }}
                            disabled={onboardingProgress?.stageNumber! > 1 ? false : true}
                            style={{ background: '#4e4851', visibility: onboardingProgress?.stageNumber! === 1 ? 'hidden' : 'visible' }}
                        >
                            Back
                        </button>
                        <button
                            className="classic-btn save-btn"
                            onClick={async () => {
                                // if ((await stageRef.current.triggerValidation()) === false) {
                                //     return;
                                // }

                                if (isPageValid) {
                                    swal({
                                        title: '',
                                        text: 'Do you want to save and exit the onboarding process?',
                                        icon: warning,
                                        buttons: ['No', 'Yes'],
                                        dangerMode: true
                                    }).then(async (confirm: any) => {
                                        if (confirm) {
                                            swal({
                                                title: 'Saved',
                                                text: 'Your progress has been saved. You can continue later.',
                                                icon: 'success',
                                                buttons: {
                                                    confirm: {
                                                        text: 'Ok',
                                                        value: true,
                                                        visible: true,
                                                        className: 'btn-info'
                                                    }
                                                }
                                            }).then(() => {
                                                stageRef.current.nextActionHandler!();
                                                navigate(ROUTES_DATA.ONBOARDINGDASHBOARD);
                                            });
                                        }
                                    });
                                } else {
                                    swal({
                                        title: '',
                                        text: 'If data is entered in this step, it will be lost. Are you sure you want to continue?',
                                        icon: warning,
                                        buttons: ['No', 'Yes'],
                                        dangerMode: true
                                    }).then(async (confirm: any) => {
                                        if (confirm) {
                                            swal({
                                                title: 'Saved',
                                                text: 'Your progress has been saved. You can continue later.',
                                                icon: 'success',
                                                buttons: {
                                                    confirm: {
                                                        text: 'Ok',
                                                        value: true,
                                                        visible: true,
                                                        className: 'btn-info'
                                                    }
                                                }
                                            }).then(() => {
                                                // stageRef.current.nextActionHandler!();
                                                navigate(ROUTES_DATA.ONBOARDINGDASHBOARD);
                                            });
                                        }
                                    });
                                }
                            }}
                        >
                            {isPageValid ? "Save & Continue Later": "Exit & Continue Later"}
                        </button>
                        {onboardingProgress.stageNumber! !== totalSteps ? (
                            <button
                                className="classic-btn"
                                onClick={async () => {
                                    if ((await stageRef.current.triggerValidation()) === false) {
                                        return;
                                    }
                                    if (stageRef.current.isValidated!) {
                                        if (onboardingProgress.stageNumber! < totalSteps) {
                                            if (onboardingProgress?.stageNumber === 2 && !wardFacility) {
                                                stageRef.current.nextActionHandler!();
                                                nextOnClickHandler(ONBOARDING_STAGES[onboardingProgress?.stageNumber! + 1]);
                                            } else {
                                                stageRef.current.nextActionHandler!();
                                                nextOnClickHandler(ONBOARDING_STAGES[onboardingProgress?.stageNumber!]);
                                            }
                                        }
                                    } else {
                                        swal({
                                            title: '',
                                            text: `Data entered in this step will be lost. Are you sure you want continue?`,
                                            icon: warning,
                                            buttons: ['No', 'Yes'],
                                            dangerMode: true
                                        }).then(async (confirm: any) => {
                                            if (confirm) {
                                                if (onboardingProgress.stageNumber! < totalSteps) {
                                                    stageRef.current.nextActionHandler!();
                                                    nextOnClickHandler(ONBOARDING_STAGES[onboardingProgress.stageNumber!]);
                                                }
                                            }
                                        });
                                    }
                                }}
                            >
                                Next
                            </button>
                        ) : (
                            <button
                                className="classic-btn"
                                onClick={async () => {
                                    dispatch(setOnboardingIsPublished(false));
                                    if ((await stageRef.current.triggerValidation()) === false) {
                                        return;
                                    }
                                    if (stageRef.current.isValidated!) {
                                        if (onboardingProgress.stageNumber! == totalSteps) {
                                            stageRef.current.nextActionHandler!();
                                            navigate(ROUTES_DATA.ONBOARDINGPREVIEW);
                                        }
                                    } else {
                                        swal({
                                            title: '',
                                            text: `Data entered in this step will be lost. Are you sure you want continue?`,
                                            icon: warning,
                                            buttons: ['No', 'Yes'],
                                            dangerMode: true
                                        }).then(async (confirm: any) => {
                                            if (confirm) {
                                                if (onboardingProgress.stageNumber! == totalSteps) {
                                                    stageRef.current.nextActionHandler!();
                                                    navigate(ROUTES_DATA.ONBOARDINGPREVIEW);
                                                }
                                            }
                                        });
                                    }
                                }}
                            >
                                Preview
                            </button>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ProgressFooterView;

    import React from 'react';
import OnboardingPreviewOverviewDetailsView from './OnboardingPreviewOverviewDetailsView';
    
    const OnboardingPreviewOverviewDetailsContainer = (props: any) => {
        return (
            <div>
                <OnboardingPreviewOverviewDetailsView {...props}/>
            </div>
        );
    };
    
    export default OnboardingPreviewOverviewDetailsContainer;
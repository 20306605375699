import { useEffect, useState } from 'react';
import { getServiceCoordinatorDetailsByServiceId } from '../../../../service/onboarding-preview';
import OnboardingPreviewAccreditationContainer from './OnboardingPreviewAccreditations/OnboardingPreviewAccreditationsContainer';
import OnboardingPreviewOverviewDetailsContainer from './OnboardingPreviewOverviewDetails/OnboardingPreviewOverviewDetailsContainer';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store';

const OnboardingPreviewOverviewView = () => {

    const { serviceId, serviceName } = useSelector((state: RootState) => state.onboardingService);

    const [result, setResult] = useState<any>(null);

    useEffect(() => {
        getAllServiceList();
    }, []);

    const getAllServiceList = async () => {
        const result = await getServiceCoordinatorDetailsByServiceId(serviceId!);
        setResult(result);
    };

    return (
        <div id="overview-section">
            <div className="service-detail-padding overview-section">
                <div className="container-fluid p-0">
                    <div className="row">
                        <div className="col-lg-12">
                            <OnboardingPreviewOverviewDetailsContainer serviceCoordinatorDetails={result?.data?.serviceCoordinatorDetails} serviceDescription={result?.data?.serviceDescription} getAllServiceList={getAllServiceList} />
                        </div>
                    </div>
                </div>
            </div>
            <div className="service-detail-padding preview-accreditation-section section-padding">
                <div className="container-fluid p-0">
                    <div className="row">
                        <div className="col-lg-12">
                            <OnboardingPreviewAccreditationContainer
                                serviceAccreditation={result?.data?.serviceAccreditation}
                                cqcRating={result?.data?.cqcRating}
                                cqcLocationId={result?.data?.cqcLocationId}
                                cqcLatestInspection={result?.data?.cqcLatestInspection}
                                cqcReportPublished={result?.data?.cqcReportPublished}
                                cqcLatestReview={result?.data?.cqcLatestReview}

                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default OnboardingPreviewOverviewView;

import { createSlice } from '@reduxjs/toolkit';
import { ProgressStagesReducerPayload, ProgressStagesReducerType } from '../../utils/model';

const initialState: ProgressStagesReducerType = {
    onboardingStages: [
        {
            stageNumber: 1,
            stageHeading: 'Identify Your Facility Type.',
            stageDescription: `You can select one or more categories that best describes the services for <span class="bolder">Service Name</span>.`
        },
        {
            stageNumber: 2,
            stageHeading: `Set Up Your Wards & Beds`,
            stageDescription: undefined
        },
        {
            stageNumber: 3,
            stageHeading: 'Name the Wards.',
            stageDescription: `This step allows you to personalize and organize the wards at <span class="bolder">Service Name</span>.`
        },
        {
            stageNumber: 4,
            stageHeading: 'Show Your Amenities.',
            stageDescription: undefined
        },
        {
            stageNumber: 5,
            stageHeading: 'Get Your Location Right.',
            stageDescription: `This section helps you provide essential location details and highlight nearby amenities to give a comprehensive view of <span class="bolder">Service Name</span> surroundings.`
        },
        {
            stageNumber: 6,
            stageHeading: 'Facility Overview.',
            stageDescription: `This section allows you to use our Ai generated description based on publicly available information to describe your facility, helping potential residents and their families understand the services and care you provide at <span class="bolder">Service Name.</span>`
        },
        {
            stageNumber: 7,
            stageHeading: 'Introduce Your Key Contact.',
            stageDescription:
                'This step allows you to add a personal touch to your service profile by including the details of your coordinator or key contact person. A friendly face and contact information help build trust and improve communication with prospective residents and their families.'
        },
        {
            stageNumber: 8,
            stageHeading: 'Your Services.',
            stageDescription: `This section allows you to seamlessly add & showcase services offered at <span class="bolder">Service Name</span>.`
        },
        {
            stageNumber: 9,
            stageHeading: 'Establish Credibility.',
            stageDescription: `We highly recommend adding all the accreditations of <span class="bolder">Service Name</span>, to elevate your property's profile and demonstrate your commitment to quality and safety.`
        },
        {
            stageNumber: 10,
            stageHeading: 'Your Photo Gallery.',
            stageDescription:
                'This section allows you to customize the way your gallery is displayed on your profile, showcasing the best aspects of your facility to prospective residents and their families.'
        },
        {
            stageNumber: 11,
            stageHeading: 'Questions & Answers.',
            stageDescription: 'This is <span class="bolder">Questions & Answers.</span>'
        }
    ]
};

export const onboardingProgressStagesSlice = createSlice({
    name: 'onboardingProgressStages',
    initialState,
    reducers: {
        setOnboardingProgressStages: (state, { payload }: ProgressStagesReducerPayload) => {
            state.onboardingStages = [...payload.onboardingStages];
        }
    }
});

export const { setOnboardingProgressStages } = onboardingProgressStagesSlice.actions;

export default onboardingProgressStagesSlice.reducer;

import { components } from 'react-select';

export const CustomDropdownIndicator = (props: any) => {
    return (
        <components.DropdownIndicator className='react-select-custom-down-indicator' {...props}>
            {/* <KeyboardArrowDownIcon className='react-select-custom-down'/> */}
            {/* <svg className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-i4bv87-MuiSvgIcon-root react-select-custom-down" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="KeyboardArrowDownIcon"><path d="M7.41 8.59 12 13.17l4.59-4.58L18 10l-6 6-6-6 1.41-1.41z"></path></svg> */}
            {/* <i className="fa fa-angle-down react-select-custom-down" aria-hidden="true"></i> */}
            <svg className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-i4bv87-MuiSvgIcon-root react-select-custom-down" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
	 viewBox="0 0 263.6 143.9"  enable-background="new 0 0 263.6 143.9" xmlSpace="preserve">
<path d="M132.2,115.7c1.3-1.8,2.1-3.1,3.1-4.2c35.4-35.5,70.9-71,106.4-106.3c2.2-2.2,5.2-4,8.1-4.8c4.6-1.3,9.6,1.4,12.1,5.5
	c2.5,4.2,2.2,9.5-0.8,13.4c-1,1.3-2.2,2.4-3.3,3.5c-38.5,38.5-76.9,76.9-115.4,115.4c-7.6,7.6-13.6,7.6-21.1,0.1
	C82.6,99.5,43.9,60.8,5.3,22c-2.1-2.1-3.9-4.9-4.8-7.7C-1,9.7,1.4,4.7,5.5,2c4.2-2.7,9.6-2.6,13.7,0.5c1.2,0.9,2.2,2,3.3,3.1
	c35.4,35.4,70.8,70.7,106.1,106.1C129.6,112.6,130.4,113.7,132.2,115.7z"/>
</svg>
        </components.DropdownIndicator>
    );
};
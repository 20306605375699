import React from 'react';
import OnboardingPreviewAccreditationView from './OnboardingPreviewAccreditationsView';

const OnboardingPreviewAccreditationContainer = (props: any) => {
    return (
        <div>
            <OnboardingPreviewAccreditationView {...props}/>
        </div>
    );
};

export default OnboardingPreviewAccreditationContainer;
import moment from "moment";
import { store } from "../store";
import { setLogoutAction } from "../store/Auth/loginReducer";
import { removeListtContractAction } from "../store/contract/listContractReducer"; 
import { removeBedInformationAction } from "../store/dashboard/bedInformationReducer"; 
import { removePatientDraftAction } from "../store/dashboard/draftPatientData"; 
import { removeCityInformationAction } from "../store/dashboard/getCityReducer"; 
import { removeFunderInformationAction } from "../store/dashboard/getFunderReducer"; 
import { removePatientInformationAction } from "../store/dashboard/getPatientTypeReducer"; 
import { removeNavigationAction } from "../store/dashboard/navigationReducer"; 
import { removePendingActivitiesAction } from "../store/dashboard/pendingActivitiesReducer"; 
import { removeWardBedInformationAction } from "../store/dashboard/wardBedinformationReducer"; 
import { removeWardInformationAction } from "../store/dashboard/wardinformationReducers"; 
import { removeListtReasonsAction } from "../store/discharge/listReasonsReducer"; 
import { removeListtPatientAction } from "../store/patient/listPatientReducer"; 
import { removePatientDetailAction } from "../store/patient/patientDetailsReducer"; 
import { removeListRealeaseReasonsAction } from "../store/patient/realeaseReasonReducer"; 
import { removeListTransferReasonsAction } from "../store/patient/transferReasonsReducer"; 
import { removeVacantBedAction } from "../store/patient/vacantBedReducer";
import { removeRelationshipInformationAction } from "../store/dashboard/getRelationshipReducer"; 
import { removeListMaintenanceReasonsAction } from "../store/maintenance/listMaintenanceReasonsReducer";
import { removeListMaintenanceBedsAction } from "../store/maintenance/listMaintenanceBedsReducer";
import { removeServiceDetailAction } from "../store/Auth/serviceDetailReducer";
import { removeRoleSelectedAction } from "../store/Auth/roleSelectedReducer";
import { removePageNavigationAction } from "../store/general/pageNavigationReducer";
import { removeInvoiceStatisticsAction } from "../store/invoice/invoiceStatisticsReducer";
import { removeAssessmentDetailsAction } from "../store/assessment/getAssessmentDetailsReducer";
import { removeListMessagesAction } from "../store/referral/listMessagesReducer";
import { removeIntigrationGridFilterStateAction } from "../store/general/integrationgridFilterStateReducer";
import {resetIsNhsReferralTracker} from "../store/NHS/nhsLoginReducer"
import {resetIsBellIconSwingable} from "../store/Notifications/notificationBellSwingReducer";
import { useState } from "react";

// let [result,setresult]=useState<any>();
export default {
    getText() {
        return 'This is demo.';
    },
    calculateAge (birthDate: any) {
        birthDate = new Date(birthDate);
        const otherDate: any = new Date();
    
        var years = (otherDate.getFullYear() - birthDate.getFullYear());
    
        if (otherDate.getMonth() < birthDate.getMonth() || 
            otherDate.getMonth() == birthDate.getMonth() && otherDate.getDate() < birthDate.getDate()) {
            years--;
        }
        return years;
    },
    dateFormatDDMMMYYYY(date: any) {
        return moment(date).format('DD MMM YYYY')
    },
    dateFormatDDMMYYYY(date:any){
        return moment(date).format('DD-MM-yyyy')
    },
    dateFormatDDMMYYYYSlash(date:any){
        return moment(date).format('DD/MM/yyyy')
    },
    dateFormatDDMMYYYYDB(date:any){
        return moment(date).format('yyyy-MM-DD')
    },
    dateFormat(date:any){
        return moment(date,'DD/MM/YYYY HH:mm').toDate();
    },
    datedashFormat(date:any){
        return (moment(date,'DD-MM-yyyy HH:mm').format('DD MMM YYYY'));
        // return moment(date,'DD-MM-yyyy HH:mm').toDate();
    },
    onKeypressValidateSplifunding(valueType:any, value: any){
        
        if(valueType === 'number') {
            // ^(?:[0-9]+(?:\.[0-9]{0,2})?)?$
            var regex=/^(100|\d?[0-9](?:\.[0-9]{0,2})?)?$/;
                if (!value.match(regex))
                {
                    return false;
                }
                else{
                    return true;
                }
            }
    },
    onKeypressValidateApplicable(valueType:any, value: any){
         
        if(valueType === 'number') {
            var regex=/^[0-9]*(\.[0-9]{0,2})?$/;
            if (regex.test(value) || value === '') {
                return true;
            } else{
                return false;
            }
        }
    },
    onKeypressValidateCosting(valueType:any, value: any){
        console.log(value);
       if(valueType === 'number') {
           var regex=/^[0-9]*(\.[0-9]{0,2})?$/;
           if (regex.test(value) || value === '') {
               return true;
           } else{
               return false;
           }
       }
   },
    signOutUser() {
        store.dispatch(setLogoutAction());
        store.dispatch(removeListtContractAction());
        store.dispatch(removeBedInformationAction());
        store.dispatch(removePatientDraftAction());
        store.dispatch(removeCityInformationAction());
        store.dispatch(removeFunderInformationAction());
        store.dispatch(removePatientInformationAction());
        store.dispatch(removeNavigationAction());
        store.dispatch(removePageNavigationAction());
        store.dispatch(removePendingActivitiesAction());
        store.dispatch(removeWardBedInformationAction());
        store.dispatch(removeWardInformationAction());
        store.dispatch(removeListtReasonsAction());
        store.dispatch(removeListtPatientAction());
        store.dispatch(removePatientDetailAction());
        store.dispatch(removeListRealeaseReasonsAction());
        store.dispatch(removeListTransferReasonsAction());
        store.dispatch(removeVacantBedAction());
        store.dispatch(removeRelationshipInformationAction());
        store.dispatch(removeListMaintenanceReasonsAction());
        store.dispatch(removeListMaintenanceBedsAction());
        store.dispatch(removeServiceDetailAction());
        store.dispatch(removeRoleSelectedAction());
        store.dispatch(removeInvoiceStatisticsAction());
        store.dispatch(removeAssessmentDetailsAction());
        store.dispatch(removeListMessagesAction());
        store.dispatch(removeIntigrationGridFilterStateAction());
        store.dispatch(resetIsNhsReferralTracker());
        store.dispatch(resetIsBellIconSwingable())
    },
    convertToPlain(htmlstr:any){
        const decodedString = document.createElement("textarea");
        decodedString.innerHTML = htmlstr;        
        return decodedString.value.replace(/<[^>]+>/g, '');
    },
    convertUtcToLocalTime(utcTime: any, timeZone: string) {
        if (!utcTime) {
            return undefined;
        }
    
        const value = moment.utc(utcTime, "hh:mm A").tz(timeZone);
        return value?.format("hh:mm A");
    },
    
     base64ToFile(base64String: string, filename: string) {
        // Split the base64 string into data type and actual base64 data
        const [header, data] = base64String.split(',');
        const mimeType = header?.match(/:(.*?);/)?.[1]; // Extract MIME type from the header
    
        // Decode base64 data to binary
        const binaryString = atob(data);
        const len = binaryString.length;
        const bytes = new Uint8Array(len);
    
        // Convert binary string to array of bytes
        for (let i = 0; i < len; i++) {
            bytes[i] = binaryString.charCodeAt(i);
        }
    
        // Create a Blob from the bytes
        const blob = new Blob([bytes], { type: mimeType });
    
        // Convert Blob to File
        return new File([blob], filename, { type: mimeType });
    }
    
};

 
'use client';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { useEffect, useState } from 'react';
import EditPencil from '../../../../assets/images/edit.svg';
import { getOnboardingServiceImages } from '../../../../service/onboarding-preview';
import eventEmitter from '../../../../utils/eventEmitter';
import ImageLightBoxContainer from '../../../NHSPage/NHSServiceDetail/ServiceImageCollage/ImageLightbox/ImageLightboxContainer';
import EditGalleryModalView from './EditGalleryModalView';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store';
import { ModulesAccessRights } from '../../../../utils/constant';
import swal from 'sweetalert';
import warning from '../../../../assets/images/warning.png';

const OnboardingPreviewGalleryView = () => {
    const { serviceId, serviceName } = useSelector((state: RootState) => state.onboardingService);
    const { isForPublished } = useSelector((state: RootState) => state.onboardingIsForPublished);
    const { StatusLogin, DataLogin } = useSelector((state: RootState) => state.login);

    const [showEditPage, setShowEditPage] = useState<any>(DataLogin?.userModel?.modules?.find((item:any)=>(item.moduleName === 'OnBoarding')))

    const [image1, setImage1] = useState<{ imagePath: string; imageName: string } | null>(null);
    const [image2, setImage2] = useState<{ imagePath: string; imageName: string } | null>(null);
    const [image3, setImage3] = useState<{ imagePath: string; imageName: string } | null>(null);
    const [image4, setImage4] = useState<{ imagePath: string; imageName: string } | null>(null);
    const [image5, setImage5] = useState<{ imagePath: string; imageName: string } | null>(null);

    const [showEditModal, setShowEditModal] = useState(false);

    const [serviceImages, setServiceImages] = useState<any[]>([]);

    const handleShowEditModal = () => {
        setShowEditModal((prevState) => !prevState);
    };
    useEffect(() => {
        if (showEditModal) {
            document.body.classList.add('modal-open');
        } else {
            document.body.classList.remove('modal-open');
        }
        return () => {
            document.body.classList.remove('modal-open');
        };
    }, [showEditModal]);
    useEffect(() => {
        getServiceImages();
    }, []);
    const getServiceImages = async () => {
        const result = await getOnboardingServiceImages(serviceId!);
        setServiceImages(result.data?.serviceDetailImageCategories);
        result.data?.topImages?.forEach((item: any, index: number) => {
            const img = {
                id: item.imageId,
                index: item.isTopImage ? item.topOrderIndex : item.order,
                imagePath: item.imagePath,
                imageName: item.imageName,
                topIndex: index + 1
            };

            const imageRefs = [setImage1, setImage2, setImage3, setImage4, setImage5];
            imageRefs[img.index]({ imagePath: img.imagePath, imageName: img.imageName });
        });
        setTimeout(() => {
            setShowEditModal(false);
        }, 1500);
    };
    const showImageLightBoxHandler = () => {
        eventEmitter.emit('showImageLightBox');
    };

    const handleSave = (callbackFn: () => Promise<() => void>) => {
        swal({
            title: '',
            text: `Do you want to save the changes done to the image gallery?`,
            icon: warning,
            buttons: ['No', 'Yes'],
            dangerMode: true
        }).then(async (confirm: any) => {
            if (confirm) {
                await callbackFn();
            }
        });
    };

    return (
        <div>
            <div className="service-detail-padding">
                <div className="container-fluid p-0">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="service-image-collage">
                                <div className="d-flex">
                                    <div className="placeholder-left">
                                        <div className="image-placeholder image-placeholder-1 position-relative" onClick={() => showImageLightBoxHandler()}>
                                            {image1 && <img src={image1.imagePath} alt={image1.imageName} className="img-fluid" />}
                                        </div>
                                    </div>
                                    <div className="placeholder-middle">
                                        <div className="image-placeholder image-placeholder-2 position-relative" onClick={() => showImageLightBoxHandler()}>
                                            {image2 && <img src={image2.imagePath} alt={image2.imageName} className="img-fluid" />}
                                        </div>
                                        <div className="image-placeholder image-placeholder-3 position-relative" onClick={() => showImageLightBoxHandler()}>
                                            {image3 && <img src={image3.imagePath} alt={image3.imageName} className="img-fluid" />}
                                        </div>
                                    </div>
                                    <div className="placeholder-right d-flex flex-column grid-gap">
                                        <div className="image-placeholder image-placeholder-2 " onClick={() => showImageLightBoxHandler()}>
                                            {image4 && <img src={image4.imagePath} alt={image4.imageName} className="img-fluid" />}
                                        </div>
                                        <div className="image-placeholder image-placeholder-3" onClick={() => showImageLightBoxHandler()}>
                                            {image5 && <img src={image5.imagePath} alt={image5.imageName} className="img-fluid" />}

                                            {isForPublished ? (
                                                showEditPage?.userAccess?.some((a: any) => a.accessCode === ModulesAccessRights.Onboarding.GalleryEditAccess.accessCode) ? (
                                                    <div
                                                        className="edit-icon-preview ms-2 cursor-pointer img-above-edit-icon-preview"
                                                        onClick={(event) => {
                                                            event.preventDefault();
                                                            event.stopPropagation();
                                                            handleShowEditModal();
                                                        }}
                                                    >
                                                        <img src={EditPencil} className="img-fluid" alt="edit" width={20} />
                                                    </div>
                                                ) : (
                                                    <></>
                                                )
                                            ) : (
                                                <div
                                                    className="edit-icon-preview ms-2 cursor-pointer img-above-edit-icon-preview"
                                                    onClick={(event) => {
                                                        event.preventDefault();
                                                        event.stopPropagation();
                                                        handleShowEditModal();
                                                    }}
                                                >
                                                    <img src={EditPencil} className="img-fluid" alt="edit" width={20} />
                                                </div>
                                            )}
                                        </div>
                                        <ImageLightBoxContainer serviceImages={serviceImages} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <EditGalleryModalView saveFn={handleSave} handleShowEditModal={handleShowEditModal} showEditModal={showEditModal} getServiceImages={getServiceImages} />
        </div>
    );
};

export default OnboardingPreviewGalleryView;

import React, { useEffect, useState } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../store';
import { ROUTES_DATA } from './RoutesConstants';
import { Roles, RolesArray, ModulesAccessRights, Modules } from '../utils/constant';
import { setRedirectToFromAction, removeRedirectToFromAction } from '../store/general/redirectToFromURLReducer';
import { setNavigationAction } from '../store/dashboard/navigationReducer';

const PrivateRoutes = () => {
    const { StatusLogin, DataLogin } = useSelector((state: RootState) => state.login);
    // const { ShowClass, FormName } = useSelector((state: RootState) => state.navigation);
    const { StatusRoleSelected, DataRoleSelected } = useSelector((state: RootState) => state.roleSelected);
    let auth: any = { token: DataLogin.jwtToken };
    const { DataServiceDetail } = useSelector((state: RootState) => state.serviceDetail);
    const dispatch = useDispatch();
    const location = useLocation();

    const fetchModule = DataLogin?.userModel?.modules?.filter((item: any) => item?.module === ModulesAccessRights.Administrator.moduleName);
    const viewIntegrationAccess =
        fetchModule !== undefined && fetchModule[0]?.userAccess.length > 0
            ? fetchModule[0]?.userAccess?.filter((item: any) => item?.accessCode === ModulesAccessRights.Administrator.viewAccess.accessCode)
            : undefined;

    useEffect(() => {
        if (DataLogin?.userModel?.userRoleId === Roles.NHS) {
            const bodyElem = document.getElementsByTagName("body");
            if (bodyElem.length) {
                bodyElem[0].classList.add("nhs-body");
            }
        } else if(DataLogin?.userModel?.userRoleId === Roles.ReferralManager && location.pathname.includes(ROUTES_DATA.SERVICEDETAIL)) {
            const bodyElem = document.getElementsByTagName("body");
            if (bodyElem.length) {
                bodyElem[0].classList.add("nhs-body");
            }
        } else if(DataLogin?.userModel?.userRoleId === Roles.ServiceManager && location.pathname.includes(ROUTES_DATA.SERVICEDETAIL)) {
            const bodyElem = document.getElementsByTagName("body");
            if (bodyElem.length) {
                bodyElem[0].classList.add("nhs-body");
            }
        } else {
            const bodyElem = document.getElementsByTagName("body");
            if (bodyElem.length) {
                bodyElem[0].classList.remove("nhs-body");
            }
        }
    }, [DataLogin?.userModel?.userRoleId]);

    useEffect(() => {
        if (StatusLogin === false && StatusRoleSelected === false && auth.token === undefined) {
            let obj: any = {
                fromPath: `${location.pathname}${location.search}`
            };
            dispatch(setRedirectToFromAction(obj));
        } else {
            dispatch(removeRedirectToFromAction());
        }
    }, [StatusLogin, StatusRoleSelected, DataRoleSelected]);
    if (StatusLogin === false && auth.token === undefined) {
        return <Navigate to={ROUTES_DATA.LOGIN} />;
    }

    let isIquviaEnabled = DataLogin?.userModel?.userRoleId === Roles.ServiceManager && location.pathname === ROUTES_DATA.BILLINGMANAGEMENT && DataServiceDetail?.iqviaServiceEnabled === true ? true : false;

    let userHasRequiredRole;
    if (DataLogin.userModel.userRoleId === Roles.NHS || DataLogin.userModel.userRoleId === Roles.OnBoarding || DataLogin.userModel.userRoleId === Roles.SiteUser) {
        userHasRequiredRole = StatusLogin === true && auth.token !== '' && RolesArray.includes(DataLogin.userModel.userRoleId) ? true : false;
    } else {
        userHasRequiredRole = StatusLogin === true && auth.token !== '' && RolesArray.includes(DataLogin.userModel.userRoleId) && DataLogin.userModel.modules?.length > 0 ? true : false;
    }
    if (!userHasRequiredRole) {
        return <Navigate to={ROUTES_DATA.AccessDenied} />;
    }
    if (DataLogin.userModel.userRoleId === Roles.ReferralManager && location.pathname !== ROUTES_DATA.REFERRALMANAGEMENT && location.pathname !== ROUTES_DATA.CHANGEPASSWORD && location.pathname !== ROUTES_DATA.REFERRALMANAGERVIEWMAP && location.pathname !== ROUTES_DATA.SERVICEMANAGERVIEWMAP && location.pathname !== ROUTES_DATA.NHSREFERRAL && !location.pathname.includes(ROUTES_DATA.SERVICEDETAIL) && !location.pathname.includes(ROUTES_DATA.PROVIDERSERVICEDETAIL)) {
        return <Navigate to={ROUTES_DATA.REFERRALMANAGEMENT} />;
    }

    if (DataLogin.userModel.userRoleId === Roles.NHS && location.pathname !== ROUTES_DATA.NHS && location.pathname !== ROUTES_DATA.CHANGEPASSWORD && location.pathname !== ROUTES_DATA.REFERRALMANAGERVIEWMAP && location.pathname !== ROUTES_DATA.SERVICEMANAGERVIEWMAP && location.pathname !== ROUTES_DATA.NHSREFERRAL && !location.pathname.includes(ROUTES_DATA.SERVICEDETAIL) && !location.pathname.includes(ROUTES_DATA.PROVIDERSERVICEDETAIL)) {
        return <Navigate to={ROUTES_DATA.NHS} />;
    }
    if (DataLogin?.userModel?.userRoleId === Roles.OnBoarding && location.pathname !== ROUTES_DATA.ONBOARDINGDASHBOARD && location.pathname !== ROUTES_DATA.CHANGEPASSWORD && location.pathname !== ROUTES_DATA.ONBOARDING && location.pathname !== ROUTES_DATA.ONBOARDINGPREVIEW && !location.pathname.includes(ROUTES_DATA.PROVIDERSERVICEDETAIL)) {
        return <Navigate to={ROUTES_DATA.ONBOARDINGDASHBOARD} />;
    }
    if (userHasRequiredRole && location.pathname == ROUTES_DATA.INTEGRATION && viewIntegrationAccess == undefined) {
        return <Navigate to={ROUTES_DATA.AccessDenied} />;
    }
    if(isIquviaEnabled){
        return <Navigate to={ROUTES_DATA.AccessDenied} />;
    }

    return <Outlet />;
};

export default PrivateRoutes;

"use client";
import React, { useEffect, useRef, useState } from "react";
import ClientPortal from "../../../../../../components/ClientPortal";
import profileimage from "../../../../../../assets/images/profile.png";
import { useTranslation } from 'react-i18next';
import EditPencil from "../../../../../../assets/images/edit.svg";
//@ts-ignore
import AOS from 'aos';
import 'aos/dist/aos.css';
import airobot from '../../../../../../assets/images/airobot.png';
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { generateOpenAiDescription, getOnboardingDescribeFacility, submitGenerateOpenAiDescription } from "../../../../../../service/onboarding-describeyourfacility-service";
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { useSelector } from "react-redux";
import { RootState } from "../../../../../../store";
import { ModulesAccessRights, OpenAienums, wordLimit } from "../../../../../../utils/constant";
import { DescribeFacilityType } from "../../../../../../utils/model";
import swal from 'sweetalert';
import warning from "../../../../../../assets/images/warning.png"
import LoaderContainer from "../../../../../../components/Loader/loaderContainer";
import OnboardingGenerateAnimationLoader from "../../../OnboardingGenerateAnimationLoder";
import { t } from "i18next";

const OnboardingPreviewAboutusView = (props: any) => {
    var settings = {
        dots: false,
        infinite: false,
        speed: 800,
        slidesToShow: 1,
        slidesToScroll: 1
    };

    const sliderRef = useRef<Slider>(null);
    const headingInputRefs = useRef<Array<HTMLTextAreaElement | null>>([]);

    const { serviceId, serviceName } = useSelector((state: RootState) => state.onboardingService);
    const { isForPublished } = useSelector((state: RootState) => state.onboardingIsForPublished);
    const { StatusLogin, DataLogin } = useSelector((state: RootState) => state.login);

    const [showEditPage, setShowEditPage] = useState<any>(DataLogin?.userModel?.modules?.find((item: any) => (item.moduleName === 'OnBoarding')))

    useEffect(() => {
        AOS.init();
    }, [])
    const [PreviewshowPortal4, PreviewsetShowPortal4] = useState(false);
    const [descriptionData, setDescriptionData]: any = useState([]);
    const [showFullMessage, setShowFullMessage] = useState<boolean>(false);
    const [showFullCoordinatorMessage, setShowFullCoordinatorMessage] = useState<boolean>(false);
    const [previewShowPortal, setPreviewShowPortal] = useState(false);
    const [isLoading, setIsLoading] = useState(false)
    const [isManualGenerate, setIsManualGenerate] = useState(false);

    const PreviewhandleModal4 = (event: any) => {
        event.preventDefault();
        PreviewsetShowPortal4((prevState) => !prevState);
    };
    useEffect(() => {
        if (PreviewshowPortal4) {
            document.body.classList.add("modal-open");
        } else {
            document.body.classList.remove("modal-open");
        }
        return () => {
            document.body.classList.remove("modal-open");
        };
    }, [PreviewshowPortal4]);

    useEffect(() => {
        if (previewShowPortal) {
            document.body.classList.add('modal-open');
        } else {
            document.body.classList.remove('modal-open');
        }
        return () => {
            document.body.classList.remove('modal-open');
        };
    }, [previewShowPortal]);
    useEffect(() => {
        if (showFullMessage) {
            document.body.classList.add('modal-open');
        } else {
            document.body.classList.remove('modal-open');
        }
        return () => {
            document.body.classList.remove('modal-open');
        };
    }, [showFullMessage]);
    useEffect(() => {
        if (showFullCoordinatorMessage) {
            document.body.classList.add('modal-open');
        } else {
            document.body.classList.remove('modal-open');
        }
        return () => {
            document.body.classList.remove('modal-open');
        };
    }, [showFullCoordinatorMessage]);

    useEffect(() => {
        const fetchFacilityData = async () => {
            // setIsLoading(true)
            const response = await getOnboardingDescribeFacility(serviceId!);
            if (response.status) {
                // setIsLoading(false)
                const descriptions = response?.data?.openairesponse;
                if (descriptions?.length > 0) {
                    const updatedArr = descriptions.map((item: any) => ({
                        ...item,
                        wordCount: calculateWordCount(item.generatedResponse)
                    }));
                    setDescriptionData(updatedArr);
                } else {
                    setDescriptionData(descriptions);
                }
                // setClickCount(response?.data?.clickCount)
            }
            // setIsLoading(false)
        };
        fetchFacilityData();
    }, []);

    const viewMore = () => {
        setShowFullMessage(!showFullMessage);
    };
    const viewMoreCoordinatorMessage = () => {
        setShowFullCoordinatorMessage(!showFullCoordinatorMessage);
    };

    const handlePreviewShowPortal = (event: any) => {
        event.preventDefault();
        PreviewsetShowPortal4((prevState) => !prevState);
    };

    const handleSeeMoreModal = (event: any) => {
        event.preventDefault();
        setShowFullMessage((prevState) => !prevState);
    };

    function calculateWordCount(text: string) {
        return text.trim().split(/\s+/).length;
    }

    const handleChangeDescription = (event: any, descriptionId: number) => {
        const newDescription = event.target.value;
        const wordCount = calculateWordCount(newDescription);

        if (wordCount <= wordLimit) {
            const updatedDescriptions = descriptionData.map((item: any) => {
                if (item.airesponseId === descriptionId) {
                    return { ...item, generatedResponse: newDescription, wordCount };
                }
                return item;
            });
            setDescriptionData(updatedDescriptions);
        }
    };

    const handleActiveDescription = (id: number) => {
        const updatedData = descriptionData.map((item: any) => ({
            ...item,
            isSelected: item.airesponseId === id
        }));
        setDescriptionData(updatedData);
    };



    const handleGenerateOpenAi = async () => {
        const payload: DescribeFacilityType = {
            serviceId: serviceId!,
            pageId: OpenAienums.FacilityOverview
        }
        //setIsLoading(true)
        setIsManualGenerate(true)
        const response = await generateOpenAiDescription(payload)
        if (response?.status) {
            //setIsLoading(false)
            setIsManualGenerate(false)
            const descriptionArr = response?.data
            const updatedArr = descriptionArr?.map((item: any) => ({
                ...item,
                wordCount: calculateWordCount(item?.generatedResponse)
            }))
            setDescriptionData(updatedArr)
            setTimeout(() => {
                sliderRef.current?.slickGoTo(updatedArr.length);
                setTimeout(() => {
                    focusHeadingAutocomplete(updatedArr.length - 1)
                }, 500);
            }, 100);
            // setClickCount((prev) => prev + 1)
        } else {
            //setIsLoading(false)
            setIsManualGenerate(false)
            swal({
                title: '',
                text: response.message,
                icon: "error",
                dangerMode: true
            })
        }

    }

    const submitOpenAiDescriptions = async () => {
        const finalAiValidation = descriptionData?.map(({ wordCount, ...rest }: any) => rest)
            .some((item: any) => !item.generatedResponse)

        const finalDesAiValidation = descriptionData?.map(({ wordCount, ...rest }: any) => rest)
            .some((item: any) => item.isSelected === true)

        if (descriptionData.length === 0 || finalAiValidation) {
            swal({
                title: '',
                text: "Please generate at least one description.",
                icon: warning,
                dangerMode: true
            })
            return
        }

        if (!finalDesAiValidation) {
            swal({
                title: '',
                text: "Please select at least one description.",
                icon: warning,
                dangerMode: true
            })
            return
        }

        const finalAiResponse = descriptionData?.map(({ wordCount, ...rest }: any) => rest)
        const payload = {
            Service_Id: serviceId!,
            openairesponse: finalAiResponse
        }
        // setIsLoading(true)

        swal({
            title: '',
            text: "Do you want to save the changes done to the facility overview?",
            icon: warning,
            buttons: ['No', 'Yes'],
            dangerMode: true
        }).then(async (confirm: boolean) => {
            if (confirm) {
                setIsLoading(true)
                const response = await submitGenerateOpenAiDescription(payload)
                if (response.status === true) {
                    setIsLoading(false)
                    swal({
                        title: '',
                        text: response.message,
                        icon: "success",
                        dangerMode: true
                    }).then(async (confirm: any) => {
                        PreviewsetShowPortal4((prevState) => !prevState);
                    });
                    props.getAllServices()
                } else {
                    setIsLoading(false)
                    swal({
                        title: '',
                        text: response.message,
                        icon: "error",
                        dangerMode: true
                    })
                }
            }

        })

    }

    const generateDisplayDescription = (str: string | undefined | null) => {
        if (str) {
            return (
                <p className="about-text justify" data-aos="fade-up" data-aos-duration="1500" data-aos-delay="400" data-aos-once="true">
                    {str?.substring(0, 630) + (str?.length > 630 ? '...' : '')}
                    {
                        str?.length > 630 && (
                            <a className="view-more-text bold hand-icon" onClick={viewMore}>
                                view more
                            </a>
                        )
                    }
                </p>
            )
        } else {
            return (
                <p className="about-text justify" data-aos="fade-up" data-aos-duration="1500" data-aos-delay="400" data-aos-once="true">
                    {""}
                </p>
            )
        }
    }

    const focusHeadingAutocomplete = (index: number) => {
        if (headingInputRefs && headingInputRefs?.current[index]) {
            headingInputRefs.current[index]!.focus();
            headingInputRefs.current[index]!.setSelectionRange(headingInputRefs.current[index]!.value.length, headingInputRefs.current[index]!.value.length);
            headingInputRefs.current[index]!.scrollTop = headingInputRefs.current[index]!.scrollHeight;
        }
    };

    // if (iseLoading) {
    //     return <LoaderContainer />
    // }
    return (
        <div>
            <div className="d-flex small-title align-items-center preview-small-title">
                <h4 className="indigo-text bolder mb-0" data-aos="fade-up" data-aos-duration="1500" data-aos-once="true">
                    About Us
                </h4>
                {/* <div className="edit-icon-preview ms-2 cursor-pointer" onClick={handlePreviewShowPortal} data-aos="fade-up" data-aos-duration="1500" data-aos-once="true">
                    <img src={EditPencil} className="img-fluid" alt="edit" width={20} />
                </div> */}
                {isForPublished ? showEditPage?.userAccess?.some((a: any) => a.accessCode === ModulesAccessRights.Onboarding.AiDescriptionAccess.accessCode) ?
                    <div className="edit-icon-preview ms-2 cursor-pointer" onClick={handlePreviewShowPortal} data-aos="fade-up" data-aos-duration="1500" data-aos-once="true">
                        <img src={EditPencil} className="img-fluid" alt="edit" width={20} />
                    </div>

                    :
                    <></>
                    :
                    <div className="edit-icon-preview ms-2 cursor-pointer" onClick={handlePreviewShowPortal} data-aos="fade-up" data-aos-duration="1500" data-aos-once="true">
                        <img src={EditPencil} className="img-fluid" alt="edit" width={20} />
                    </div>}
            </div>
            {generateDisplayDescription(props?.serviceDescription)}
            <div className="mt-4">
                <div className="d-flex small-title align-items-center preview-small-title">
                    <h4 className="indigo-text bolder mb-0" data-aos="fade-up" data-aos-duration="1500" data-aos-once="true">
                        Message from your Host
                    </h4>
                </div>
                {props?.serviceCoordinatorDetails?.serviceCoordinatorMessage && props?.serviceCoordinatorDetails?.serviceCoordinatorMessage !== null && (
                    <p>
                        {props?.serviceCoordinatorDetails?.serviceCoordinatorMessage.substring(0, 600) + (props?.serviceCoordinatorDetails?.serviceCoordinatorMessage?.length > 600 ? '...' : '')}
                        {props?.serviceCoordinatorDetails?.serviceCoordinatorMessage?.length > 600 && (
                            <a className="view-more-text bold hand-icon" onClick={viewMoreCoordinatorMessage}>
                                view more
                            </a>
                        )}
                    </p>
                )}

            </div>
            <ClientPortal selector="myportal" show={showFullMessage}>
                <div className="overlay" onClick={handleSeeMoreModal}></div>
                <div className="modal">
                    <div className="header mb-3">
                        <h4 className="mb-0">About {props?.serviceCoordinatorDetails?.serviceName}</h4>
                        <a onClick={viewMore} className="removeButton">
                            <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 384 512">
                                <path
                                    fill="#ffffff"
                                    d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"
                                />
                            </svg>
                        </a>
                    </div>
                    <div className="body text-popup">
                        <p>{props?.serviceDescription}</p>
                    </div>
                </div>
            </ClientPortal>
            <ClientPortal selector="myportal" show={showFullCoordinatorMessage}>
                <div className="overlay" onClick={handleSeeMoreModal}></div>
                <div className="modal">
                    <div className="header mb-3">
                        <h4 className="mb-0">{props?.serviceCoordinatorDetails?.name}'s Message</h4>
                        <a onClick={viewMoreCoordinatorMessage} className="removeButton">
                            <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 384 512">
                                <path
                                    fill="#ffffff"
                                    d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"
                                />
                            </svg>
                        </a>
                    </div>
                    <div className="body text-popup">
                        <p>{props?.serviceCoordinatorDetails?.serviceCoordinatorMessage}</p>
                    </div>
                </div>
            </ClientPortal>

            <ClientPortal selector="myportal" show={PreviewshowPortal4}>
                {isLoading && <LoaderContainer />}
                {isManualGenerate && <OnboardingGenerateAnimationLoader showModal={isManualGenerate} previewMessage={t('GenerateHeadings.Facility_Description')} />}
                <div className="overlay" onClick={PreviewhandleModal4}></div>
                <div className="modal modal-collage-image p-0 nhs-body">
                    <div className="header">
                        <a onClick={PreviewhandleModal4} className="d-flex align-items-center">
                            <i className="fa-solid fa-angle-left back-collage-btn"></i>
                            <h4 className="bolder mb-0 ms-2">Edit Facility Overview</h4>
                        </a>
                    </div>
                    <div className="body service-detail-padding preview-service-fieldset-text">
                        <div className="container-fluid p-0">
                            <div className='mt-4 row'>
                                <div className='col-md-10'>
                                    <div className='sec-frame'>
                                        <div className='sec-frame-title'>
                                            <p>Edit Overview</p>
                                        </div>

                                        <div className='gradient-bg describe-facility-content description-slider position-relative'>
                                            {descriptionData?.length > 0 ? (
                                                <Slider {...settings} ref={sliderRef}>
                                                    {descriptionData?.map((item: any, index: number) => (
                                                        <div className="position-relative" key={item?.airesponseId}>
                                                            <div className="description-item ">
                                                                <textarea
                                                                    ref={(ref) => (headingInputRefs.current[index] = ref)}
                                                                    className="text-justify desc-textarea form-control mb-3"
                                                                    name=""
                                                                    id=""
                                                                    value={item?.generatedResponse}
                                                                    onChange={(event) => handleChangeDescription(event, item?.airesponseId)}
                                                                />

                                                                <div className="d-flex justify-content-between">
                                                                    <div className="me-2">
                                                                        <div className="desc-count-btn">{item.wordCount}/{t('Words.facility')}</div>
                                                                    </div>
                                                                    <div className="position-relative use-this-btn">
                                                                        <input
                                                                            className=""
                                                                            type="radio"
                                                                            checked={item.isSelected}
                                                                            name="description"
                                                                            id=""
                                                                            onChange={() => handleActiveDescription(item?.airesponseId)}
                                                                        />
                                                                        <button className={`btn primary-btn ${item.isSelected ? "useThisBtn" : ""} `}>
                                                                            <span>Use this</span>
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </Slider>
                                            ) : (
                                                <div className="">
                                                    <p className="text-justify mb-3">Please click on Generate button to get the description</p>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-2 '>
                                    <div className='ms-3 robot-container d-flex align-item-center justify-content-center'>
                                        <div className='robot-animation mt-2'>
                                            <img src={airobot} className="img-fluid" alt="airboat" />
                                        </div>
                                    </div>
                                    <div className='ms-3'>
                                        <button className="toggle-button variation3" disabled={isLoading} onClick={handleGenerateOpenAi} >
                                            <span className="toggle-indicator"></span>
                                            <span className="label">Generate</span>

                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className="modal-footer--sticky p-2">
                        <div className="d-flex justify-content-center"><button type="submit" className="btn primary-btn mt-1 px-5" onClick={submitOpenAiDescriptions} >Save</button></div>
                    </div>
                </div>
            </ClientPortal>
        </div>
    );
};

export default OnboardingPreviewAboutusView;
import { createSlice } from '@reduxjs/toolkit';
import { OnboardingServicePayload, OnboardingServiceType } from '../../utils/model';

// Please set initial state to undefined once integrated with the dashboard page.
const initialState: OnboardingServiceType = {
    serviceId: undefined,
    serviceName: '',
    userName: '',
    slugName : null
};

export const onboardingServiceSlice = createSlice({
    name: 'onboardingService',
    initialState,
    reducers: {
        setOnboardingService: (state, { payload }: OnboardingServicePayload) => {
            state.serviceId = payload.serviceId;
            state.serviceName = payload.serviceName;
            state.userName = payload.userName;
            state.slugName = payload.slugName
        }
    }
});

export const { setOnboardingService } = onboardingServiceSlice.actions;

export default onboardingServiceSlice.reducer;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState : {wardFacility: boolean | null} = {
    wardFacility: false
};

export const onboardingWardFacilitySlice = createSlice({
    name: 'onboardingWardFacility',
    initialState,
    reducers: {
        setOnboardingWardFacility: (state, action: PayloadAction<boolean | null>) => {
            state.wardFacility = action.payload;
        }
    }
});

export const { setOnboardingWardFacility } = onboardingWardFacilitySlice.actions;

export default onboardingWardFacilitySlice.reducer;

"use client";
import React, { useEffect, useState } from 'react';
import profile_neu_desktop from "../../../assets/images/profile-neu-desktop.png";
//@ts-ignore
import AOS from 'aos';
import 'aos/dist/aos.css';
import { setOnboardingProgress } from '../../../store/onboarding/onboardingProgressReducer';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store';

const OnboardingProfileNameView = () => {

    const { userName, serviceName } = useSelector((state: RootState) => state.onboardingService);

    const dispatch = useDispatch();

    useEffect(() => {
        AOS.init();
    }, [])

    const nextOnClickHandler = () => {
        dispatch(setOnboardingProgress({
            stageNumber: 1,
            stageHeading: 'Identify Your Facility Type.',
            stageDescription: `You can select one or more categories that best describes the services for <span class="bolder">${serviceName}</span>.`,
        }))
    };

    return (
        <div className="getlocated-body service-detail-padding">
            <div className="container-fluid p-0">
                <div className="getlocated-section">
                    <div className="section-title-parent">
                        <h5 className="section-title"><span className="gradient-text">Let’s Get Located.</span></h5>
                    </div>
                    <div className="section-description">
                        <p>
                            <span className="bolder"> Hi {userName} !</span> You are about to embark on a journey with Locate.care to showcase <span className="bolder"> {serviceName} </span> like never before.
                        </p>
                        <p>Unlock our innovative design and bring unmatched clarity and appeal to both Care Recipients and Funders.</p>
                        <h5 className='lightblue mt-4 mb-4'>Just a few steps away !</h5>
                    </div>

                    <div className='profileInformation'>
                        <div className="row">
                            <div className="col-md-6 d-flex profileInfoChild">
                                <div className="box-details">
                                    <div className="d-flex mb-2">
                                        <div className="me-3">
                                            <div className="neumorphicOrderList" >1</div>
                                        </div>
                                        <div>
                                            <div className='mb-2'>
                                                <h6>Identify yourself.</h6>
                                                <p>Select the type of facility that applies to you. You can choose one or multiple based on your registration.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="d-flex mb-2">
                                        <div className="me-3">
                                            <div className="neumorphicOrderList" >2</div>
                                        </div>
                                        <div>
                                            <div className='mb-2'>
                                                <h6>Build your service.</h6>
                                                <p>Create your wards and beds as per your needs.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="d-flex mb-2">
                                        <div className="me-3">
                                            <div className="neumorphicOrderList" >3</div>
                                        </div>
                                        <div>
                                            <div className='mb-2'>
                                                <h6>Highlight Amenities</h6>
                                                <p>Mark the amenities and showcase them on your property page to make your site stand out.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="d-flex mb-2">
                                        <div className="me-3">
                                            <div className="neumorphicOrderList" >4</div>
                                        </div>
                                        <div>
                                            <div className='mb-2'>
                                                <h6>Pin Your Location</h6>
                                                <p>Accurately pin your location and confirm your address. Add nearby places of interest to enhance your profile.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="d-flex mb-2">
                                        <div className="me-3">
                                            <div className="neumorphicOrderList" >5</div>
                                        </div>
                                        <div>
                                            <div className='mb-2'>
                                                <h6>Showcase Expertise & Care</h6>
                                                <p>Select the services provided at your facility to give viewers comprehensive information.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="d-flex mb-2">
                                        <div className="me-3">
                                            <div className="neumorphicOrderList" >6</div>
                                        </div>
                                        <div>
                                            <div className='mb-2'>
                                                <h6>Establish Credibility</h6>
                                                <p>Highlight your accreditations and certifications to enhance your site’s profile.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 d-flex profileInfoChild">
                                <div className="box-details">
                                    <div className="text-center img-height-center">
                                        <img src={profile_neu_desktop} className="img-fluid" alt="list-image" data-aos="fade-up" data-aos-duration="1500" data-aos-once="true" />
                                    </div>
                                </div>
                            </div>
                            <div className='profileInfoChild mt-4'>
                                <div className="d-flex align-items-center justify-content-end">
                                    <h5 className='bold lightblue mb-0 me-3'>Let's begin setting up..</h5>
                                    <button className="btn primary-btn classic-btn letstart-btn" onClick={nextOnClickHandler}>
                                        <span>Next</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default OnboardingProfileNameView;
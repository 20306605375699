import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { useRef } from 'react';
import { Tooltip } from 'react-tooltip';
import ClientPortal from '../../../../components/ClientPortal';
import GalleryContainer from '../../Gallery/GalleryContainer';
import warning from '../../../../assets/images/warning.png';

const EditGalleryModalView = (props: any) => {
    const editGalleryRef = useRef<any>();
    const { showEditModal, handleShowEditModal, getServiceImages, saveFn } = props;

    const handleSave = async () => {
        saveFn(async () => {
            if ((await editGalleryRef.current.triggerValidation()) === false) {
                return;
            }
            editGalleryRef.current.nextActionHandler!(true);
            setTimeout(async () => {
                handleShowEditModal();
                await getServiceImages();
            }, 2000);
        });
    };

    return (
        <ClientPortal selector="myportal" show={showEditModal}>
            <div className="overlay" onClick={handleShowEditModal}></div>
            <div className="modal modal-collage-image p-0 nhs-body">
                <div className="header">
                    <a onClick={handleShowEditModal} className="d-flex align-items-center">
                        <i className="fa-solid fa-angle-left back-collage-btn"></i>
                        <h4 className="bolder mb-0 ms-2">Update Your Photo Gallery</h4>
                    </a>
                </div>
                <div className="body service-detail-padding">
                    <div className='container-fluid'>
                    <GalleryContainer ref={editGalleryRef} />
                    </div>
                </div>
                <div className="modal-footer--sticky p-2">
                    <div className="d-flex justify-content-center">
                        <button
                            type="submit"
                            className="btn primary-btn mt-1 px-5"
                            onClick={async (event) => {
                                event?.preventDefault();
                                event?.stopPropagation();
                                await handleSave();
                            }}
                        >
                            Save
                        </button>
                    </div>
                </div>
            </div>
            <Tooltip id="my-tooltip" className="my-tooltip" />
        </ClientPortal>
    );
};

export default EditGalleryModalView;

import { AxiosError, AxiosResponse } from 'axios';
import { DataResponseModel } from '../utils/model';
import { API_ENDPOINT, BASEURLDEV } from './ApiConstants';
import instance from './axiosinstance';

export const getOnboardingServiceCards = (serviceId: number) => {
    return instance
        .get(`${BASEURLDEV}${API_ENDPOINT.GetserviceCardsWithFactsheets}?serviceId=${serviceId}`)
        .then((response: AxiosResponse) => {
            const resultData: any = response.data as DataResponseModel;
            return resultData;
        })
        .catch((error: AxiosError) => {
            if (error.code === 'ERR_NETWORK') {
                const errorResponse = {
                    status: false,
                    data: [],
                    message: error.message,
                    totalCount: 0
                };
                const errorData: any = errorResponse as DataResponseModel;
                return errorData;
            } else {
                const errorResponse = {
                    status: false,
                    data: [],
                    message: 'Something went wrong!',
                    totalCount: 0
                };
                const errorData: any = error.response ? (error.response.data as DataResponseModel) : (errorResponse as DataResponseModel);
                return errorData;
            }
        });
};

export const generateServiceHeadings = (count: number) => {
    return (
        instance
            .post(`http://localhost:3007/api/generateHeadings`, { count })
            .then((response: AxiosResponse) => {
                const resultData: any = response.data as DataResponseModel;
                return resultData;
            })
            .catch((error: AxiosError) => {
                if (error.code === 'ERR_NETWORK') {
                    const errorResponse = {
                        status: false,
                        data: [],
                        message: error.message,
                        totalCount: 0
                    };
                    const errorData: any = errorResponse as DataResponseModel;
                    return errorData;
                } else {
                    const errorResponse = {
                        status: false,
                        data: [],
                        message: 'Something went wrong!',
                        totalCount: 0
                    };
                    const errorData: any = error.response ? (error.response.data as DataResponseModel) : (errorResponse as DataResponseModel);
                    return errorData;
                }
            })
    );
};

export const saveServiceDetails = (payload: any) => {   
    return instance
        .post(`${BASEURLDEV}${API_ENDPOINT.SaveServiceDetails}`, payload)
        .then((response: AxiosResponse) => {
            const resultData: any = response.data as DataResponseModel;
            return resultData;
        })
        .catch((error: AxiosError) => {
            if (error.code === 'ERR_NETWORK') {
                const errorResponse = {
                    status: false,
                    data: [],
                    message: error.message,
                    totalCount: 0
                };
                const errorData: any = errorResponse as DataResponseModel;
                return errorData;
            } else {
                const errorResponse = {
                    status: false,
                    data: [],
                    message: 'Something went wrong!',
                    totalCount: 0
                };
                const errorData: any = error.response ? (error.response.data as DataResponseModel) : (errorResponse as DataResponseModel);
                return errorData;
            }
        });
};

export const savePreviewData = (payload: any) => {   
    return instance
        .post(`${BASEURLDEV}${API_ENDPOINT.SaveServiceCards}`, payload)
        .then((response: AxiosResponse) => {
            return response.data;
        })
        .catch((error: AxiosError) => {
            console.error("Error saving preview data:", error);
            return null;
        });
};

import React, { useState, useEffect } from 'react';
import AssessmentModalContainer from '../../../../../../components/AssessmentModal/indexContainer';
import RenderModalContainer from '../../../../../../components/RenderModal/indexContainer';
import warning from '../../../../../../assets/images/warning.png';
import swal from 'sweetalert';
import { Tooltip } from 'react-tooltip';
import CostingModalContainer from '../../../../../../components/CostingModal/CostingModalContainer';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../../store';
import { getViewCosting, acceptCosting, moveToAssignment, SaveCosting, acceptNHSCosting } from '../../../../../../service/costing';
import { getLeaddata } from '../../../../../../service/leads';
import CostRejectModalContainer from '../../../../../../components/CostRejectModal/CostRejectModalContainer';
import { removeLeadInformationAction } from '../../../../../../store/lead/getLeaddataReducer';
import CancelContainer from '../../../../../../components/CancelModal/CancelContainer';
import eventEmitter from '../../../../../../utils/eventEmitter';
import { setNavigationAction } from '../../../../../../store/dashboard/navigationReducer';

const CostingView = (props: any) => {

    const { t } = useTranslation();
    const { StatusLeadInfo, DataLeadInfo, MessageLeadInfo, ErrorLeadInfo } = useSelector((state: RootState) => state.leadInformation);
    const { ShowClass, FormName, Data } = useSelector((state: RootState) => state.navigation);
    const { isNHSReferralTracker } = useSelector((state: RootState) => state.nhsLogin);
    const dispatch = useDispatch();
    const [leadAccepted, setleadAccepted] = useState<boolean>(false);
    const [hasMovedToAssignment, sethasMovedToAssignment] = useState<boolean>(false);
    const [title, settitle] = useState<any>('');
    const [cServiceName, setcServiceName] = useState<any>('');
    const [costingAddedByRm, setcostingAddedByRm] = useState<boolean>(false);
    const [cStatus, setcStatus] = useState<any>('');
    useEffect(() => {
        setCostingList(DataLeadInfo?.data?.costingList);
        //If Leas status is Assignment or any one costing is accepted then disable other button except View costing
        setleadAccepted(DataLeadInfo?.data?.costingList?.filter((e: any) => e.costingStatusName === t('Referral_Costing.Accepted') || e.hasMovedToAssignment).length > 0);
        sethasMovedToAssignment(DataLeadInfo?.data?.costingList?.filter((e: any) => e.hasMovedToAssignment).length > 0);
    }, [DataLeadInfo]);

    const [CostingList, setCostingList] = useState(DataLeadInfo?.data?.costingList);
    const onAddPartSelect = (i: number) => {
        const updatedData = CostingList.map((item: any, idx: number) => {
            if (item.rcId === i) {
                return {
                    ...item,
                    itemPartToggle: !item.itemPartToggle
                };
            }
            return item;
        });
        setCostingList(updatedData);
    };

    const [showModal, setShowModal] = useState(false);
    // const [showModal1, setShowModal1] = useState(false);

    // const handleClickModal = () =>{
    //     setShowModal(true);
    // };
    const [viewCosting, setViewCosting] = useState<any>();
    const [costingdata, setcostingdata] = useState<any>();
    const [isLeadCreatedByNhs, setLeadCreatedByNhs] = useState(0);

    const [searchValue, setSearchValue] = useState<any>('');
    const [showModal1, setShowModal1] = useState(false);
    const [showModal2, setShowModal2] = useState(false);
    const [isNHSAction, setNHSAction] = useState(false);
    const [iseditDisabled, setEditDisabled] = useState(false);
    const [rmCostingRevised, setRmCostingRevised] = useState(false)
    const handleClickModal = (referralCostingId: any, serviceName: any, isAddedByRm: any, isCreatedByNhs: any, costdata: any) => {
        getViewCostingData(referralCostingId, serviceName, isAddedByRm);
        setLeadCreatedByNhs(isCreatedByNhs)
        if (isAddedByRm && isCreatedByNhs && costdata?.costingStatusName === t('Referral_Costing.Revise_Costing_Initiated')) {
            setRmCostingRevised(true)
        }

    }

    const handleClickModal1 = (costdata: any) => {
        setcostingdata(costdata);
        setShowModal1(true);
        setNHSAction(false)
    }
    const handleClickModalNHSReject = (costdata: any) => {
        setcostingdata(costdata);
        setShowModal1(true);
        setNHSAction(true)
    }
    const handleClickModal2 = (costdata: any) => {
        setcostingdata(costdata);
        setShowModal2(true);
    }

    const handlerSearchValue = (e: any) => {
        setSearchValue(e.target.value);
    };
    useEffect(() => {
        async function getleaddata() {
            dispatch(removeLeadInformationAction());
            getLeaddata(Data.leadid, Data.leadstatus, searchValue);
        }
        getleaddata();
    }, [searchValue])

    const getViewCostingData = async (referralCostingId: any, serviceName: any, isAddedByRm: any) => {
        try {
            const resultData = await getViewCosting(referralCostingId);
            if (resultData.status === true && resultData.data !== undefined && resultData.data !== null) {
                setViewCosting(resultData.data);
                setShowModal(true);
                settitle('Costing - ' + serviceName);
                setcServiceName(serviceName);
                setcostingAddedByRm(isAddedByRm);
                setcStatus(resultData.data.costingStatusName)
                setEditDisabled(true);
            } else {
                setViewCosting('');
                setShowModal(false);
            }
        } catch (error) {
            
        }
    };

    const toggle = () => {
        swal({
            title: '',
            text: 'Are you sure you want to close?',
            icon: warning,
            buttons: ['No', 'Yes'],
            dangerMode: true
        })
            .then(async (confirm: any) => {
                if (confirm) {
                    setShowModal(false);
                    setShowModal1(false);
                    setShowModal2(false);
                    setSearchValue('');
                    //dispatch(removeSplitFundingAction())
                }
            })
    };

    const rejectToggle = () => {
        setShowModal(false);
        setShowModal1(false);
        setShowModal2(false);
        setSearchValue('');
        props.refreshData(4);
    };

    const Accept = (referralCostingId: any) => {
        swal({
            title: '',
            text: `Are you sure you want to accept the cost for lead ${props.referralid} ? `,
            icon: warning,
            buttons: ['No', 'Yes'],
            dangerMode: true
        }).then(async (confirm: any) => {
            if (confirm) {
                const response = await acceptCosting(referralCostingId);
                if (response.status == true) {
                    swal(response.message, {
                        icon: 'success'
                    });
                    setShowModal(false);
                    setShowModal1(false);
                    setShowModal2(false);
                    setSearchValue('');
                    props.refreshData(4);
                } else {
                    swal(response.message, {
                        icon: 'error',
                        dangerMode: true
                    });
                }
            }
        });

    };

    const saveToAssignment = (leadPoolingId: any, serviceName: any) => {
        swal({
            title: '',
            text: `Are you sure you want to move lead ${props.referralid} for assignment to ${serviceName} ?`,
            icon: warning,
            buttons: ['No', 'Yes'],
            dangerMode: true
        }).then(async (confirm: any) => {
            if (confirm) {
                const response = await moveToAssignment(leadPoolingId);
                if (response.status == true) {
                    swal(response.message, {
                        icon: 'success'
                    });
                    setShowModal(false);
                    setShowModal1(false);
                    setShowModal2(false);
                    setSearchValue('');
                    eventEmitter.emit('onAddNewLeadRM');
                    const nextStage = props.finalStatus > 5 ? props.finalStatus : 5;
                    props.refreshData(5, nextStage);
                } else {
                    swal(response.message, {
                        icon: 'error',
                        dangerMode: true
                    });
                }
            }
        });

    };

    async function saveUpdateCosting(obj: any, additionalvalues: any) {
        let costobj = {
            "isRevisionStatus": rmCostingRevised,
            "isRevised": rmCostingRevised,
            "rcId": obj?.rcId || 0,
            "loadPoolingId": obj?.loadPoolingId || 0,
            "serviceUserName": obj?.serviceUserName || '',
            "serviceUserId": obj?.serviceUserId || '',
            "serviceLocation": obj?.serviceLocation || '',
            "accommodation": obj?.accommodation || 0,
            "baselineStaffCost": obj?.baselineStaffCost || 0,
            "enhancedStaffingCost": obj?.enhancedStaffingCost || 0,
            "mdt": obj?.mdt || 0,
            "transport": obj?.transport || 0,
            "managementAndAdministration": obj?.managementAndAdministration || 0,
            "total": obj?.total?.toFixed(2) || 0,
            "isAddedByRm": true,
            costingLists: additionalvalues
        }
        const res = await SaveCosting(costobj);

        if (res.status === true) {
            swal(res.message, {
                icon: 'success'
            });
            eventEmitter.emit('onAddNewLeadRM');
            props.refreshData(4);
            setRmCostingRevised(false);
            handleClickModal(obj?.rcId, obj?.serviceUserName, true, isLeadCreatedByNhs, '');
            setShowModal(true);
        } else {
            swal(res.message, {
                icon: 'error'
            });
        }
    }
    const NHSAccept = (referralCostingId: any) => {
        swal({
            title: '',
            text: `Are you sure you want to accept the cost for lead ${props.referralid} ? `,
            icon: warning,
            buttons: ['No', 'Yes'],
            dangerMode: true
        }).then(async (confirm: any) => {
            if (confirm) {
                const response = await acceptNHSCosting(referralCostingId);
                if (response.status == true) {
                    swal(response.message, {
                        icon: 'success'
                    });
                    setShowModal(false);
                    setShowModal1(false);
                    setShowModal2(false);
                    setSearchValue('');
                    props.refreshData(4);
                } else {
                    swal(response.message, {
                        icon: 'error',
                        dangerMode: true
                    });
                }
            }
        });
    }
    const no_Change = (costdata: any) => {
        swal({
            title: '',
            text: `Are you sure you want to re-submit the costing for lead ${props.referralid} without any change? `,
            icon: warning,
            buttons: ['No', 'Yes'],
            dangerMode: true
        }).then(async (confirm: any) => {
            if (confirm) {
                setEditDisabled(true);
                const No_ChangeValues = {
                    isRevisionStatus: true,
                    isRevised: false,
                    rcId: costdata?.rcId,
                    loadPoolingId: costdata?.leadPoolingId
                };
                saveCosting(No_ChangeValues);
            } else {
            }
        });
    };

    async function saveCosting(obj: any) {
        let costobj = {
            "isRevisionStatus": true,
            "isRevised": false,
            "rcId": obj?.rcId || 0,
            "loadPoolingId": obj?.loadPoolingId || 0,
            "serviceUserName": '',
            "serviceUserId": '',
            "serviceLocation": '',
            "accommodation": 0,
            "baselineStaffCost": 0,
            "enhancedStaffingCost": 0,
            "mdt": 0,
            "transport": 0,
            "managementAndAdministration": 0,
            "total": 0,
        }
        const res = await SaveCosting(costobj);

        if (res.status === true) {
            swal(res.message, {
                icon: 'success'
            });
            props.refreshData(4);
        } else {
            swal(res.message, {
                icon: 'error'
            });
        }
    }

    return (
        <div>
            <div className="position-relative mb-3">
                <input type="text" placeholder="Search by Service Name or Status" name="invoiceSmartSearch" className="form-control white costing-search w-100" onChange={handlerSearchValue} />
                <i className="fa-sharp fa-solid fa-magnifying-glass search-icon costing-search-icon"></i>
            </div>



            <Tooltip id="my-tooltip-assignment" className="my-tooltip" />

            {CostingList?.length > 0 && CostingList.map((costdata: any, i: any) => {
                return (
                    <>
                        {
                            <div className='status-box mt-3'>
                                <div className='d-flex justify-content-between mb-2 align-items-start'>
                                    <p className='bold'>{costdata.serviceName}</p>
                                    <span className={`referral-status ${(costdata.costingStatusName === t('Referral_Costing.Rejected') || costdata.costingStatusName.includes(t('Referral_Costing.Cancelled')))
                                        ? 'red-status' : costdata.costingStatusName.toLowerCase().includes('revise')
                                            ? 'gray-status' : (costdata.costingStatusName.includes(t('Referral_Costing.Completed')) || costdata.costingStatusName.includes(t('Referral_Costing.Accepted')))
                                                ? 'green-status' : 'yellow-status'} `}>{costdata.costingStatusName}</span>
                                </div>
                                {costdata.referralLogList?.length > 0 && costdata.itemPartToggle && costdata.referralLogList.map((costLogdata: any, index: any) => {
                                    return (
                                        <div className='d-flex infobox' key={index}>
                                            <p className='mt-1'>
                                                <i>{costLogdata.logMessage}</i>{" "}
                                                {costLogdata?.reason !== null && <i className="fa-solid fa-circle-info" data-tooltip-id="my-tooltip-Costing" data-tooltip-content={`${costLogdata?.reason}-${costLogdata?.comment}`}></i>}
                                            </p>
                                        </div>
                                    );
                                })}
                                {costdata.referralLogList?.length > 0 && !costdata.itemPartToggle && costdata.referralLogList.slice(0, 3).map((costLogdata: any, index: any) => {
                                    return (
                                        <div className='d-flex infobox' key={index}>
                                            <p className='mt-1'>
                                                <i>{costLogdata.logMessage}</i>{" "}
                                                {costLogdata?.reason !== null && <i className="fa-solid fa-circle-info" data-tooltip-id="my-tooltip-Costing" data-tooltip-content={`${costLogdata?.reason}-${costLogdata?.comment}`}></i>}
                                            </p>
                                        </div>
                                    );
                                })}
                                {costdata?.costingStatusName === t('Referral_Costing.Revise_Costing_Initiated') &&
                                    costdata?.isAddedByRm && costdata?.isLeadCreatedByNhs === 1 &&
                                    !props.nhs &&
                                    !isNHSReferralTracker &&
                                    <div className='mt-3'>
                                        {/* <div className='d-flex justify-content-end mb-2'>
                                            <span className='referral-status gray-status'>{t('SM_Costing.Revise_Costing_Request')}</span>
                                        </div> */}
                                        {<p className="bold mt-2">
                                            {t('SM_Costing.Expected_Costing_Value')}{' '}
                                            <span className="bolder">
                                                £ {costdata?.referralLogList[0].expectedCost}{' '}
                                                <i
                                                    className="fa-solid fa-circle-info ms-1"
                                                    data-tooltip-id="my-tooltip-Costing"
                                                    data-tooltip-content={`${costdata?.referralLogList[0].reason} - ${costdata?.referralLogList[0].comment}`}
                                                ></i>
                                            </span>
                                        </p>
                                        }
                                        <div className="text-center mt-2">
                                            <button className="btn btn-primary btn-sm me-2" onClick={() => handleClickModal(costdata.rcId, costdata.serviceName, costdata.isAddedByRm, costdata.isLeadCreatedByNhs, costdata)} disabled={DataLeadInfo?.data?.referralCloseLog !== null}>
                                                {t('SM_Costing.Edit_Costing')}
                                            </button>
                                            <button className="btn btn-secondary btn-sm" onClick={() => no_Change(costdata)} disabled={iseditDisabled && !costdata.canEdit}>
                                                {t('SM_Costing.No_Change')}
                                            </button>
                                        </div>
                                    </div>
                                }

                                 
                                {costdata.hasCosting && <div className='mt-3 text-center'>
                                    {/* <a href="#"  className='btn btn-secondary btn-sm me-2' onClick={() => handleClickModal(costdata.rcId)}>{t('Referral_Costing.View_Costing')}</a>
                                    <a href="#" className='btn btn-primary btn-sm me-2'><i className="fa-solid fa-check"></i> {t('Referral_Costing.Accept')}</a> */}
                                    {(
                                        costdata.costingStatusName.includes(t('Referral_Costing.Completed')) ||
                                        costdata.costingStatusName.includes(t('Referral_Costing.Rejected')) ||
                                        costdata.costingStatusName.includes(t('Referral_Costing.Cancelled')) ||
                                        costdata.costingStatusName.includes(t('Referral_Costing.Revised')) ||
                                        costdata.costingStatusName.includes(t('Referral_Costing.Accepted')) ||
                                        costdata.costingStatusName.includes(t('Referral_Costing.Pending_Approval')) ||
                                        costdata.costingStatusName.includes(t('Referral_Costing.Not_Revised')) ||
                                        costdata.costingStatusName.includes(t('Referral_Costing.Revise_Costing_Initiated'))

                                    )
                                        && <button disabled={DataLeadInfo?.data?.referralCloseLog !== null} className='btn btn-secondary btn-sm me-2' onClick={() => handleClickModal(costdata.rcId, costdata.serviceName, costdata.isAddedByRm, costdata.isLeadCreatedByNhs, costdata)}>{costdata.isAddedByRm && !props.nhs && costdata.isLeadCreatedByNhs !== 1 ? t('Referral_Costing.Edit_View_btn') : t('Referral_Costing.View_Costing')}</button>
                                    }

                                    {(
                                        costdata.costingStatusName.includes(t('Referral_Costing.Cancelled')) ||
                                        costdata.costingStatusName.includes(t('Referral_Costing.Accepted'))) &&
                                        !props.nhs && !isNHSReferralTracker
                                        &&
                                        <button disabled={(!costdata.canEdit || hasMovedToAssignment ||
                                            costdata.costingStatusName.includes(t('Referral_Costing.Cancelled')) || costdata.costingStatusName.includes(t('Referral_Costing.Accepted')) || DataLeadInfo?.data?.referralCloseLog !== null || DataLeadInfo?.data?.leadEditable == false)} className='btn btn-secondary btn-sm me-2' onClick={() => handleClickModal2(costdata)}>{t('Referral_Costing.Cancel_btn')}</button>
                                    }

                                     
                                    {(
                                        costdata.costingStatusName.includes(t('Referral_Costing.Completed')) ||
                                        (costdata.costingStatusName.includes(t('Referral_Costing.Revised')) && !costdata?.isAddedByRm)  ||
                                        costdata.costingStatusName.includes(t('Referral_Costing.Not_Revised')) ||
                                        costdata.costingStatusName.includes(t('Referral_Costing.Rejected'))
                                        ||costdata.costingStatusName.includes(t('Referral_Costing.Pending_Approval'))
                                    )
                                        &&
                                        !props.nhs
                                        && (isNHSReferralTracker && DataLeadInfo?.data?.isLeadCreatedByNhs === 1) &&
                                        <button disabled={((!isNHSReferralTracker && !costdata.canEdit) || leadAccepted || costdata.costingStatusName.includes(t('Referral_Costing.Rejected')) || DataLeadInfo?.data?.referralCloseLog !== null || DataLeadInfo?.data?.leadEditable == false)} onClick={!isNHSReferralTracker ? () => Accept(costdata.rcId) : () => NHSAccept(costdata.rcId)} className='btn btn-primary btn-sm me-2' ><i className="fa-solid fa-check"></i> {t('Referral_Costing.Accept')}</button>
                                    }

{(
                                        costdata.costingStatusName.includes(t('Referral_Costing.Completed')) ||
                                        (costdata.costingStatusName.includes(t('Referral_Costing.Revised')) && !costdata?.isAddedByRm)  ||
                                        costdata.costingStatusName.includes(t('Referral_Costing.Not_Revised')) ||
                                        costdata.costingStatusName.includes(t('Referral_Costing.Rejected'))
                                        // ||costdata.costingStatusName.includes(t('Referral_Costing.Pending_Approval'))
                                    )
                                        &&
                                        !props.nhs
                                        && (!isNHSReferralTracker && DataLeadInfo?.data?.isLeadCreatedByNhs === 0) &&
                                        <button disabled={((!isNHSReferralTracker && !costdata.canEdit) || leadAccepted || costdata.costingStatusName.includes(t('Referral_Costing.Rejected')) || DataLeadInfo?.data?.referralCloseLog !== null || DataLeadInfo?.data?.leadEditable == false)} onClick={!isNHSReferralTracker ? () => Accept(costdata.rcId) : () => NHSAccept(costdata.rcId)} className='btn btn-primary btn-sm me-2' ><i className="fa-solid fa-check"></i> {t('Referral_Costing.Accept')}</button>
                                    }


                                    {(
                                        costdata.costingStatusName.includes(t('Referral_Costing.Completed')) ||
                                        (costdata.costingStatusName.includes(t('Referral_Costing.Revised')) && !costdata?.isAddedByRm) ||
                                        costdata.costingStatusName.includes(t('Referral_Costing.Not_Revised')) ||
                                        costdata.costingStatusName.includes(t('Referral_Costing.Rejected'))
                                        ||costdata.costingStatusName.includes(t('Referral_Costing.Pending_Approval'))
                                    )
                                        &&
                                        !props.nhs
                                        &&  (isNHSReferralTracker && DataLeadInfo?.data?.isLeadCreatedByNhs === 1) &&
                                        <button disabled={(!isNHSReferralTracker && !costdata.canEdit) || leadAccepted || costdata.costingStatusName.includes(t('Referral_Costing.Rejected')) ||
                                            costdata.costingStatusName.includes(t('Referral_Costing.Cancelled')) || DataLeadInfo?.data?.leadEditable == false} className='btn btn-sm white-text btn-delete red-background' onClick={!isNHSReferralTracker ? () => handleClickModal1(costdata) : () => handleClickModalNHSReject(costdata)}><i className="fa-solid fa-xmark"></i> {t('Referral_Costing.Reject')}</button>
                                    }

{(
                                        costdata.costingStatusName.includes(t('Referral_Costing.Completed')) ||
                                        (costdata.costingStatusName.includes(t('Referral_Costing.Revised')) && !costdata?.isAddedByRm) ||
                                        costdata.costingStatusName.includes(t('Referral_Costing.Not_Revised')) ||
                                        costdata.costingStatusName.includes(t('Referral_Costing.Rejected'))
                                        // ||costdata.costingStatusName.includes(t('Referral_Costing.Pending_Approval'))
                                    )
                                        &&
                                        !props.nhs
                                        &&  (!isNHSReferralTracker && DataLeadInfo?.data?.isLeadCreatedByNhs === 0) &&
                                        <button disabled={(!isNHSReferralTracker && !costdata.canEdit) || leadAccepted || costdata.costingStatusName.includes(t('Referral_Costing.Rejected')) ||
                                            costdata.costingStatusName.includes(t('Referral_Costing.Cancelled')) || DataLeadInfo?.data?.leadEditable == false} className='btn btn-sm white-text btn-delete red-background' onClick={!isNHSReferralTracker ? () => handleClickModal1(costdata) : () => handleClickModalNHSReject(costdata)}><i className="fa-solid fa-xmark"></i> {t('Referral_Costing.Reject')}</button>
                                    }


                                    {(costdata.costingStatusName.includes(t('Referral_Costing.Pending_Approval')) || (costdata.costingStatusName.includes(t('Referral_Costing.Revised')) && costdata?.isAddedByRm)|| (costdata.costingStatusName.includes(t('Referral_Costing.Not_Revised'))) && costdata?.isAddedByRm) &&
                                        props.nhs
                                        &&
                                        <div className='mt-3 border-top'>
                                            <div className='w-100 d-flex justify-content-end mt-2'>
                                                <span><i className="fa-regular fa-circle-check fs-4 me-2 cursor-pointer" onClick={() => NHSAccept(costdata.rcId)} style={{ color: "#00dc2d" }}></i></span>
                                                <span><i onClick={() => handleClickModalNHSReject(costdata)} className="fa-regular fa-circle-xmark fs-4 cursor-pointer" style={{ color: "#fa1100" }}></i></span>
                                            </div>
                                        </div>
                                    }

                                    {(
                                        costdata.costingStatusName.includes(t('Referral_Costing.Cancelled')) ||
                                        costdata.costingStatusName.includes(t('Referral_Costing.Accepted')) &&
                                        !props.nhs && !isNHSReferralTracker
                                    )
                                        &&
                                        <button className='btn btn-sm btn-primary' disabled={(!costdata.canEdit || hasMovedToAssignment || costdata.costingStatusName.includes(t('Referral_Costing.Cancelled')) || DataLeadInfo?.data?.referralCloseLog !== null)}
                                            data-tooltip-id="my-tooltip-assignment" data-tooltip-content="Move to Assignment" onClick={() => saveToAssignment(costdata.leadPoolingId, costdata.serviceName)}>{t('Referral_Costing.Assignment_btn')} <i className="fa-solid fa-arrow-right ms-1" ></i></button>
                                    }




                                </div>}
                                {costdata.referralLogList?.length > 3 &&
                                    <div className='text-right mt-3'>
                                        {!costdata.itemPartToggle && (
                                            <a href="#" className='bold' onClick={() => onAddPartSelect(costdata.rcId)}><i className="fa-solid fa-clock-rotate-left"></i> View History</a>
                                        )}
                                        {costdata.itemPartToggle && (
                                            <a href="#" className='bold' onClick={() => onAddPartSelect(costdata.rcId)}><i className="fa-solid fa-clock-rotate-left"></i> Hide History</a>
                                        )}
                                    </div>
                                }
                            </div>
                        }
                    </>
                )
            })
            }
            <RenderModalContainer>
                {showModal && <CostingModalContainer setViewCosting={viewCosting} onClose={toggle} title={title} referralid={props.referralid} serviceName={cServiceName} saveUpdateCosting={saveUpdateCosting} mode={costingAddedByRm && !cStatus.includes(t('Referral_Costing.Cancelled')) && !hasMovedToAssignment && (isLeadCreatedByNhs !== 1 && cStatus.includes(t('Referral_Costing.Accepted'))) || rmCostingRevised ? 'edit' : ''} />}
            </RenderModalContainer>

            <RenderModalContainer>
                {showModal1 && <CostRejectModalContainer costingData={costingdata} onClose={rejectToggle} referralid={props.referralid} isNHSAction={isNHSAction} />}
            </RenderModalContainer>
            <RenderModalContainer>
                {showModal2 && <CancelContainer costingData={costingdata} onClose={rejectToggle} title="Cancel Costing" referralid={props.referralid} />}
            </RenderModalContainer>
            <Tooltip id="my-tooltip-Costing" className="my-tooltip" />
        </div>
    );
};

export default CostingView;
import React from 'react';
import OnboardLocationView from './OnboardLocationView';

const OnboardLocationContainer = React.forwardRef((props, ref) => {
    return (
        <div>
            <OnboardLocationView {...props} ref={ref}/>
        </div>
    );
});

export default OnboardLocationContainer;
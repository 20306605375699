import { OnboardingProgressType } from "./model";

export const BASE_URL = process.env.REACT_APP_BASE_URL || "";

export const EventKey = {
    MapLayoutChange: "mapLayoutChange",
    ListLayoutChange: "listLayoutChange"
} as const;

export enum BedAvailability {
    Available = "Available",
    Occupied = "Occupied",
    Filling_Fast = "Filling Fast",
}

export enum rightNavigationPage {
    Vacant = 'Vacant',
    Admission = 'Admission',
    Draft = 'Draft',
    Occupied = 'Occupied',
    Reserved = 'Reserved',
    Maintenance = 'Maintenance',
    MaintenanceList = 'MaintenanceList',
    InvoiceDetailPage = 'InvoiceDetailPage',
    BillingDetailsPage = 'BillingDetailsPage',
    InvoiceTablePage = 'InvoiceTablePage',
    Task = 'Task',
    CreateLead = 'CreateLead',
    ReferralStages = 'ReferralStages',
    ServiceSidebar = 'ServiceSidebar',
    Messages = 'Messages',
    Notifications = 'Notifications',
    ReferralList = 'ReferralList',
    ServiceReferralSidebar = "ServiceReferralSidebar",
}

export enum InvoiceStatisticsFrequency {
    CurrentYear = 1,
    LastYear = 2,
    CurrentMonth = 3,
    LastMonth = 4
}

export enum AdmissionList {
    Admitted = 1,
    Reserved = 2
}

export enum ReasonList {
    Discharge = 1,
    Transfer = 2,
    Release = 3,
    Maintenance = 4,
    Close = 5
}

export enum coreFeesType {
    Daily = 1,
    Weekly = 2
}

export enum InvoiceFilterInvoiceType {
    Interim = 1,
    Discharge = 2,
    Reserve = 3
}

export enum InvoiceType {
    Interim = 1,
    Discharge = 2,
    Reserve = 3
}

export enum InvoiceFilterInvoiceStatus {
    Draft = 1,
    PostedOrConfirm = 2,
    Cancelled = 3
}

export enum InvoiceStatus {
    Draft = 1,
    PostedOrConfirm = 2,
    Cancelled = 3
}

export enum InvoiceFilterPaymentStatus {
    Paid = 1,
    InPayment = 2,
    Unpaid = 3,
    Overdue = 4
}

export enum ServiceTypeIconName {
    Hospital = "Hospital",
    ResidentialCare = "ResidentialCare",
    SupportedLiving = "SupportedLiving",
    NursingCare = "NursingCare"
}


export enum ServiceType {
    Hospital = "Hospital",
    ResidentialCare = "Residential Care",
    SupportedLiving = "Supported Living",
    NursingCare = "Nursing Care"
}
export enum ServiceUserType {
    Acute = "Acute",
    Respite = "Respite",
    LongTerm = "Long Term",
    D2A = "D2A"
}
export enum LeadStatus {
    Lead = "Lead",
    Pooling = "Pooling",
    Assessment = "Assessment",
    Costing = "Costing",
    Assignment = "Assignment",
    Contract = "Contract"
}

export enum LeadFilterReferralStatus {
    Open = 1,
    Closed = 2
}

export enum LeadFilterServiceType {
    Hospital = 1,
    ResidentialCare = 2,
    SupportedLiving = 3,
    NursingCare = 4,
}

export enum LeadFilterServiceUserType {
    Respite = 1,
    LongTerm = 2,
    D2A = 3
}
export enum Roles {
    ReferralManager = 1,
    ServiceManager = 2,
    NHS = 3,
    OnBoarding = 4,
    SiteUser = 5
}

export const RolesArray = [1, 2, 3, 4, 5]

export enum Modules {
    ReferralManagement = "referralManagement",
    BedManagement = "bedManagement",
    BillingManagement = "billingManagement",
    OnlineHelp = "onlineHelp",
    Administrator = "administrator",
    ViewMap = "viewMap",
    Onboarding = "OnBoarding"
}

export const ModulesAccessRights = {
    ReferralManagement: {
        moduleName: "referralManagement",
        userAccess: {
            accessCode: "SNDPRNTRTCOST",
            accessName: "send&PrintRTCosting"
        },
        exportAccess: {
            accessCode: "RM1003",
            accessName: "exportReferralDetails"
        }
    },
    BillingManagement: {
        moduleName: "billingManagement",
        editPostedInvoiceuserAccess: {
            accessCode: "BM1001",
            accessName: "editPostedInvoice"
        },
        sendandPrintInvoiceuserAccess: {
            accessCode: "SNDPRNTINV",
            accessName: "send&PrintInvoice"
        }
    },
    BedManagement: {
        moduleName: "bedManagement",
        exportAccess: {
            accessCode: "BM1002",
            accessName: "exportBedDetails"
        }
    },
    Administrator: {
        moduleName: "administrator",
        viewAccess: {
            accessCode: "AM1001",
            accessName: "viewIntegrationInterface"
        },
        userManagementAccess: {
            accessCode: "AM1002",
            accessName: "viewIntegrationInterface"
        }
    },

    Onboarding:{
        moduleName: "OnBoarding",
        QaEditAccess:{
            accessCode : "AM1010",
            accessName : "manageQ&A"
        },
        LocationEditAccess:{
            accessCode : "AM1009",
            accessName : "manageLocation"
        },
        ServiceTypeEditAccess:{
            accessCode : "AM1003",
            accessName : "manageServiceType"
        },
        AmenitiesEditAccess:{
            accessCode : "AM1007",
            accessName : "manageAmenities/BedBoard"
        },
        ServicesEditAccess:{
            accessCode : "AM1008",
            accessName : "manageServices"
        },
        GalleryEditAccess: {
            accessCode: "AM1011",
            accessName: "manageGallery"
        },
        AccreditationsAccess:{
            accessCode : "AM1006",
            accessName : "manageAccreditations"
        },
        CoordinatorAccess:{
            accessCode : "AM1004",
            accessName : "manageCoordinator"
        },
        AiDescriptionAccess:{
            accessCode : "AM1005",
            accessName : "manageAboutUs"
        }
    }
}

export enum StatisticsFrequency {
    CurrentYear = 1,
    LastYear = 2,
    CurrentMonth = 3,
    LastMonth = 4
}

export enum AssessmentStatus {
    InProgress = 1,
    Submitted = 2,
    Skipped = 3
}

export enum Source {
    Integration = "integration",
    Admission = "admission",
    Referral = "referral"
}

export const DEFAULT_PAGE_SIZE = 18

export const optionMapStyles = [
    {
        "stylers": [
            {
                "hue": "#bae3c5"
            },
            {
                "saturation": 0,
                "lightness": 33
            }
        ]
    },
    {
        "featureType": "administrative",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "color": "#222222"
            },
            {
                weight: 0.7
            }
        ]
    },
    {
        "featureType": "administrative.country",
        "elementType": "geometry.stroke",
        "stylers": [
            {
                "color": "#333333"
            }
        ]
    },
    {
        "featureType": "administrative.province",
        "elementType": "geometry.stroke",
        "stylers": [
            {
                "color": "#444444"
            }
        ]
    },
    {
        "featureType": "water",
        "elementType": "geometry",
        "stylers": [
            {
                "color": "#b3e6f4"
            }
        ]
    },
    {
        "featureType": "water",
        "elementType": "labels",
        "stylers": [
            {
                "color": "#1a8cff"
            }
        ]
    },
    {
        "featureType": "poi.park",
        "elementType": "labels",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "road.highway",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "color": "#ffffff"
            }
        ]
    },
    {
        "featureType": "road",
        "elementType": "geometry.stroke",
        "stylers": [
            {
                "color": "#C3C0BF"
            }
        ]
    },
    {
        "featureType": "road",
        "elementType": "labels.text.fill",
        "stylers": [
            {
                "color": "#8c8c8c"
            }
        ]
    },
    {
        "featureType": "road",
        "elementType": "labels.icon",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    }
]
export const DigitalContractEnum = {
    Charity: 0,
    ICB: 1,
    LA: 2,
    Self: 3,
    LA_ICB: 4,
    LA_Self: 5,
    ICB_Self: 6,
    ICB_LA_Self: 7,
    ICB_Charity: 8,
    LA_Charity: 9,
    Charity_Self: 10,
    ICB_LA_Charity: 11,
    ICB_Charity_Self: 12,
    LA_Charity_Self: 13,
    ICB_LA_Charity_Self: 14,
}

export const combination = [
    { funders: ["ICB", "Charity"] },
    { funders: ["LA", "Charity"] },
    { funders: ["Charity", "Self"] },
    { funders: ["ICB", "LA", "Charity"] },
    { funders: ["ICB", "Charity", "Self"] },
    { funders: ["LA", "Charity", "Self"] },
    { funders: ["ICB", "LA", "Charity", "Self"] }
]


export enum IntegrationSource {
    All = 0,
    LocateCareToOdoo = 1,
    IQVIAToLocateCare = 2,
    LocateCareToIQVIA = 3
}

export const timeZones = [
    { label: '(GMT-12:00) International Date Line West', value: 'Etc/GMT+12' },
    { label: '(GMT-11:00) Midway Island, Samoa', value: 'Pacific/Midway' },
    { label: '(GMT-10:00) Hawaii', value: 'Pacific/Honolulu' },
    { label: '(GMT-09:00) Alaska', value: 'US/Alaska' },
    { label: '(GMT-08:00) Pacific Time (US & Canada)', value: 'America/Los_Angeles' },
    { label: '(GMT-08:00) Tijuana, Baja California', value: 'America/Tijuana' },
    { label: '(GMT-07:00) Arizona', value: 'US/Arizona' },
    { label: '(GMT-07:00) Chihuahua, La Paz, Mazatlan', value: 'America/Chihuahua' },
    { label: '(GMT-07:00) Mountain Time (US & Canada)', value: 'US/Mountain' },
    { label: '(GMT-06:00) Central America', value: 'America/Managua' },
    { label: '(GMT-06:00) Central Time (US & Canada)', value: 'US/Central' },
    { label: '(GMT-06:00) Guadalajara, Mexico City, Monterrey', value: 'America/Mexico_City' },
    { label: '(GMT-06:00) Saskatchewan', value: 'Canada/Saskatchewan' },
    { label: '(GMT-05:00) Bogota, Lima, Quito, Rio Branco', value: 'America/Bogota' },
    { label: '(GMT-05:00) Eastern Time (US & Canada)', value: 'US/Eastern' },
    { label: '(GMT-05:00) Indiana (East)', value: 'US/East-Indiana' },
    { label: '(GMT-04:00) Atlantic Time (Canada)', value: 'Canada/Atlantic' },
    { label: '(GMT-04:00) Caracas, La Paz', value: 'America/Caracas' },
    { label: '(GMT-04:00) Manaus', value: 'America/Manaus' },
    { label: '(GMT-04:00) Santiago', value: 'America/Santiago' },
    { label: '(GMT-03:30) Newfoundland', value: 'Canada/Newfoundland' },
    { label: '(GMT-03:00) Brasilia', value: 'America/Sao_Paulo' },
    { label: '(GMT-03:00) Buenos Aires, Georgetown', value: 'America/Argentina/Buenos_Aires' },
    { label: '(GMT-03:00) Greenland', value: 'America/Godthab' },
    { label: '(GMT-03:00) Montevideo', value: 'America/Montevideo' },
    { label: '(GMT-02:00) Mid-Atlantic', value: 'America/Noronha' },
    { label: '(GMT-01:00) Cape Verde Is.', value: 'Atlantic/Cape_Verde' },
    { label: '(GMT-01:00) Azores', value: 'Atlantic/Azores' },
    { label: '(GMT+00:00) Casablanca, Monrovia, Reykjavik', value: 'Africa/Casablanca' },
    { label: '(GMT+00:00) Greenwich Mean Time : Dublin, Edinburgh, Lisbon, London', value: 'Etc/Greenwich' },
    { label: '(GMT+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna', value: 'Europe/Amsterdam' },
    { label: '(GMT+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague', value: 'Europe/Belgrade' },
    { label: '(GMT+01:00) Brussels, Copenhagen, Madrid, Paris', value: 'Europe/Brussels' },
    { label: '(GMT+01:00) Sarajevo, Skopje, Warsaw, Zagreb', value: 'Europe/Sarajevo' },
    { label: '(GMT+01:00) West Central Africa', value: 'Africa/Lagos' },
    { label: '(GMT+02:00) Amman', value: 'Asia/Amman' },
    { label: '(GMT+02:00) Athens, Bucharest, Istanbul', value: 'Europe/Athens' },
    { label: '(GMT+02:00) Beirut', value: 'Asia/Beirut' },
    { label: '(GMT+02:00) Cairo', value: 'Africa/Cairo' },
    { label: '(GMT+02:00) Harare, Pretoria', value: 'Africa/Harare' },
    { label: '(GMT+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius', value: 'Europe/Helsinki' },
    { label: '(GMT+02:00) Jerusalem', value: 'Asia/Jerusalem' },
    { label: '(GMT+02:00) Minsk', value: 'Europe/Minsk' },
    { label: '(GMT+02:00) Windhoek', value: 'Africa/Windhoek' },
    { label: '(GMT+03:00) Kuwait, Riyadh, Baghdad', value: 'Asia/Kuwait' },
    { label: '(GMT+03:00) Moscow, St. Petersburg, Volgograd', value: 'Europe/Moscow' },
    { label: '(GMT+03:00) Nairobi', value: 'Africa/Nairobi' },
    { label: '(GMT+03:00) Tbilisi', value: 'Asia/Tbilisi' },
    { label: '(GMT+03:30) Tehran', value: 'Asia/Tehran' },
    { label: '(GMT+04:00) Abu Dhabi, Muscat', value: 'Asia/Muscat' },
    { label: '(GMT+04:00) Baku', value: 'Asia/Baku' },
    { label: '(GMT+04:00) Yerevan', value: 'Asia/Yerevan' },
    { label: '(GMT+04:30) Kabul', value: 'Asia/Kabul' },
    { label: '(GMT+05:00) Yekaterinburg', value: 'Asia/Yekaterinburg' },
    { label: '(GMT+05:00) Islamabad, Karachi, Tashkent', value: 'Asia/Karachi' },
    { label: '(GMT+05:30) Chennai, Kolkata, Mumbai, New Delhi', value: 'Asia/Calcutta' },
    { label: '(GMT+05:30) Sri Jayawardenapura', value: 'Asia/Calcutta' },
    { label: '(GMT+05:45) Kathmandu', value: 'Asia/Katmandu' },
    { label: '(GMT+06:00) Almaty, Novosibirsk', value: 'Asia/Almaty' },
    { label: '(GMT+06:00) Astana, Dhaka', value: 'Asia/Dhaka' },
    { label: '(GMT+06:30) Yangon (Rangoon)', value: 'Asia/Rangoon' },
    { label: '(GMT+07:00) Bangkok, Hanoi, Jakarta', value: 'Asia/Bangkok' },
    { label: '(GMT+07:00) Krasnoyarsk', value: 'Asia/Krasnoyarsk' },
    { label: '(GMT+08:00) Beijing, Chongqing, Hong Kong, Urumqi', value: 'Asia/Hong_Kong' },
    { label: '(GMT+08:00) Kuala Lumpur, Singapore', value: 'Asia/Kuala_Lumpur' },
    { label: '(GMT+08:00) Irkutsk, Ulaan Bataar', value: 'Asia/Irkutsk' },
    { label: '(GMT+08:00) Perth', value: 'Australia/Perth' },
    { label: '(GMT+08:00) Taipei', value: 'Asia/Taipei' },
    { label: '(GMT+09:00) Osaka, Sapporo, Tokyo', value: 'Asia/Tokyo' },
    { label: '(GMT+09:00) Seoul', value: 'Asia/Seoul' },
    { label: '(GMT+09:00) Yakutsk', value: 'Asia/Yakutsk' },
    { label: '(GMT+09:30) Adelaide', value: 'Australia/Adelaide' },
    { label: '(GMT+09:30) Darwin', value: 'Australia/Darwin' },
    { label: '(GMT+10:00) Brisbane', value: 'Australia/Brisbane' },
    { label: '(GMT+10:00) Canberra, Melbourne, Sydney', value: 'Australia/Canberra' },
    { label: '(GMT+10:00) Hobart', value: 'Australia/Hobart' },
    { label: '(GMT+10:00) Guam, Port Moresby', value: 'Pacific/Guam' },
    { label: '(GMT+10:00) Vladivostok', value: 'Asia/Vladivostok' },
    { label: '(GMT+11:00) Magadan, Solomon Is., New Caledonia', value: 'Asia/Magadan' },
    { label: '(GMT+12:00) Auckland, Wellington', value: 'Pacific/Auckland' },
    { label: '(GMT+12:00) Fiji, Kamchatka, Marshall Is.', value: 'Pacific/Fiji' },
    { label: "(GMT+13:00) Nuku'alofa", value: 'Pacific/Tongatapu' }
];

export const WorkingHoursData = [
    {
        dayOfWeek: 'MONDAY',
        startTime: null,
        endTime: null,
        fromTime: null,
        toTime: null,
        error: '',
        active: false
    },
    {
        dayOfWeek: 'TUESDAY',
        startTime: null,
        endTime: null,
        fromTime: null,
        toTime: null,
        error: '',
        active: false
    },
    {
        dayOfWeek: 'WEDNESDAY',
        startTime: null,
        endTime: null,
        fromTime: null,
        toTime: null,
        error: '',
        active: false
    },
    {
        dayOfWeek: 'THURSDAY',
        startTime: null,
        endTime: null,
        fromTime: null,
        toTime: null,
        error: '',
        active: false
    },
    {
        dayOfWeek: 'FRIDAY',
        startTime: null,
        endTime: null,
        fromTime: null,
        toTime: null,
        error: '',
        active: false
    },
    {
        dayOfWeek: 'SATURDAY',
        startTime: null,
        endTime: null,
        fromTime: null,
        toTime: null,
        error: '',
        active: false
    },
    {
        dayOfWeek: 'SUNDAY',
        startTime: null,
        endTime: null,
        fromTime: null,
        toTime: null,
        error: '',
        active: false
    }
]

export const format = 'h:mm a';


export const wardNumbers = [
    {
        ward: 1,
        active: false
    },
    {
        ward: 2,
        active: false
    },
    {
        ward: 3,
        active: false
    },
    {
        ward: 4,
        active: false
    },
    {
        ward: 5,
        active: false
    },
    {
        ward: 6,
        active: false
    },
    {
        ward: 7,
        active: false
    },
    {
        ward: 8,
        active: false
    },
    {
        ward: 9,
        active: false
    },
    {
        ward: 10,
        active: false
    },
    {
        ward: 11,
        active: false
    },
    {
        ward: 12,
        active: false
    },
    {
        ward: 13,
        active: false
    },
    {
        ward: 14,
        active: false
    },
    {
        ward: 15,
        active: false
    },
    {
        ward: 16,
        active: false
    },
    {
        ward: 17,
        active: false
    },
    {
        ward: 18,
        active: false
    },
    {
        ward: 19,
        active: false
    },
    {
        ward: 20,
        active: false
    }
]



export const MAX_FILE_SIZE_IN_MB = 1;
export const MAX_FILES = 25;

export enum VerificationStatus {
    PENDING = "Pending",
    IN_REVIEW = "In Review",
    COMPLETED = "Completed"
}

export enum ProfileStatus {
    PENDING = "Pending",
    IN_PROGRESS = "In Progress",
    COMPLETED = "Completed"
}

export enum OpenAienums {
      SaveAmenities=1,
      FacilityOverview=2,
      KeyContact=3,
      ServiceCards=4,
}

export const wordLimit = 550 
export const wordLimitKeyContact = 300

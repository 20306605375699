import { useForm, useFieldArray } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useEffect, useRef, useState } from 'react';
import React from 'react';
import { coreFeesType } from '../../../../../../../utils/constant';
import { useTranslation } from 'react-i18next';
import RenderModalContainer from '../../../../../../../components/RenderModal/indexContainer';
import SplitFundingModalContainer from '../../../../../../../components/SplitFundingModal/indexContainer';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../../../store';
import { removeSplitFundingAction, setSplitFundingAction } from '../../../../../../../store/splitFunding/splitFundingReducer';
import { setTempSplitFundingAction, removeTempSplitFundingAction } from '../../../../../../../store/splitFunding/tempSplitFundingReducer';
import swal from 'sweetalert';
import warning from '../../../../../../../assets/images/warning.png';
import { Tooltip } from 'react-tooltip';
import eventEmitter from '../../../../../../../utils/eventEmitter';
import { ConstructionOutlined } from '@mui/icons-material';
import { getInclusiondisplay } from '../../../../../../../service/dashboard-service';

function ApplicableView(props: any) {
    const inputRef = useRef(null);
    const dispatch = useDispatch();
    let funders: any = [];
    let plans: any = [];
    let obj: any = {};
    const { t } = useTranslation();
    const { DataSplitFunding } = useSelector((state: RootState) => state.splitFunding);
    const { TempDataSplitFunding } = useSelector((state: RootState) => state.tempSplitFunding);
    const { PlanData } = useSelector((state: RootState) => state.planData);
    const [finalcorefee, setfinalcorefee] = useState<any>(0);
    const [finalcontinencefee, setfinalcontinencefee] = useState<any>(0);
    const [finalfncfee, setfinalfncfee] = useState<any>(0);
    const [finaltotalcorefee, setfinaltotalcorefee] = useState<any>(0);
    const [finaladditionalfee1, setfinaladditionalfee1] = useState<any>(0);
    const [finaladditionalfee2, setfinaladditionalfee2] = useState<any>(0);
    const [finaladditionalfee3, setfinaladditionalfee3] = useState<any>(0);
    const [finaladditionalfee4, setfinaladditionalfee4] = useState<any>(0);
    const [totaladditionalfee, settotaladditionalfee] = useState<any>(0);
    const [caladditionalfee, setcaladditionalfee] = useState<any>(0);
    const [caltotalfee, setcaltotalfee] = useState<any>(0);
    const [istotalfeevalid, settotalfeevalid] = useState<any>(false);
    const [inclusiondata, setinclusiondata] = useState<any>();
    const [showModal, setShowModal] = useState(false);
    const [plan, setplans] = useState([]);
    const [istemp, setistemp] = useState<boolean>(false);
    let [isaddplan, setisaddplan] = useState<boolean>(true);
    const { StatusPatientDraftInfo, DataPatientDraftInfo, MessagePatientDraftInfo, ErrorPatientDraftInfo } = useSelector((state: RootState) => state.patientDraft);
    const { Data } = useSelector((state: RootState) => state.navigation);
    const { StatusServiceDetail, DataServiceDetail } = useSelector((state: RootState) => state.serviceDetail);

    useEffect(() => {
        reset();
    }, [Data]);

    useEffect(() => { }, [DataSplitFunding]);

    // useEffect(() => {
    //     console.log(props.totaldailyfee);
    // }, [props.totaldailyfee]);

    const handleClickModal = () => {
        funders = [];
        setShowModal(true);
        if (props.funderlist?.ListData?.length === 1) {
            plans = [];
            for (let k = 0; k < TempDataSplitFunding.length; k++) {
                obj = {};
                funders = [];
                if (TempDataSplitFunding[k].enddate !== undefined && TempDataSplitFunding[k].enddate !== null && TempDataSplitFunding[k].enddate !== '') {
                    obj.corefee = Number(TempDataSplitFunding[k].corefee);

                    obj.dailyCoreFee = TempDataSplitFunding[k]?.dailyCoreFee;

                    obj.continenceCareFee = TempDataSplitFunding[k]?.continenceCareFee;

                    obj.fncFee = TempDataSplitFunding[k].fncFee;

                    obj.totalAdditionalServiceFee = Number(TempDataSplitFunding[k].totalAdditionalServiceFee).toFixed(2)
                } else {
                    obj.corefee = Number(finaltotalcorefee);
                    obj.dailyCoreFee = Number(finalcorefee);
                    obj.continenceCareFee = Number(finalcontinencefee);
                    obj.fncFee = Number(finalfncfee);
                    obj.totalAdditionalServiceFee = Number(totaladditionalfee).toFixed(2)
                }
                // obj.corefee = Number(TempDataSplitFunding[k].corefee) === Number(finalcorefee) ?Number(TempDataSplitFunding[k].corefee) :  Number(finalcorefee);
                obj.admissiondate = TempDataSplitFunding[k].admissiondate.toString();
                obj.enddate = TempDataSplitFunding[k].enddate?.toString() || '';
                obj.socialfund = TempDataSplitFunding[k].socialfund || 0;
                obj.healthfund = TempDataSplitFunding[k].healthfund || 0;
                /* adding code for prod issue */
                if (TempDataSplitFunding[k].planId === -1) {
                    obj.planId = 0;
                } else {
                    obj.planId = TempDataSplitFunding[k].planId || 0;
                }
                /*end code */
                obj.lastinvoicedate = TempDataSplitFunding[k].lastinvoicedate?.toString();
                for (let i = 0; i < props.funderlist?.ListData?.length; i++) {
                    let fid = props.funderlist?.ListData[i].funderData.ftype === '4' ? null : Number(props.funderlist?.ListData[i].funderData.fFunder);
                    let tfid = TempDataSplitFunding[k]?.funderlist[i]?.funderid === null ? null : Number(TempDataSplitFunding[k]?.funderlist[i]?.funderid);
                    if (fid === tfid || (fid === null && tfid === null)) {                        
                        /*adding code for prod issue */
                        let prop;
                        let propval;
                        if (TempDataSplitFunding[k].planId === -1) {
                            prop = Number(TempDataSplitFunding[k].corefee) === Number(finaltotalcorefee) ? Number(TempDataSplitFunding[k].corefee) : Number(finaltotalcorefee);
                            propval = Number(TempDataSplitFunding[k].corefee) === Number(finaltotalcorefee) ? Number(TempDataSplitFunding[k].corefee) : Number(finaltotalcorefee);
                        } else {
                            prop = TempDataSplitFunding[k].funderlist[i].proportion;
                            propval = TempDataSplitFunding[k].funderlist[i].proportionvalue;
                        }
                        /* old code comment for reference */
                        // funders.push({planDetailId : TempDataSplitFunding[k].funderlist[i].planDetailId || 0,funderid : TempDataSplitFunding[k].funderlist[i].funderid, fundername : TempDataSplitFunding[k].funderlist[i].fundername,fundertype:TempDataSplitFunding[k].funderlist[i].fundertype ,proportion : TempDataSplitFunding[k].funderlist[i].proportion,proportionvalue:TempDataSplitFunding[k].funderlist[i].proportionvalue,isapplicable:TempDataSplitFunding[k].funderlist[i].isapplicable,ispercentage:TempDataSplitFunding[k].funderlist[i].ispercentage});

                        /* new code */

                        funders.push({
                            planDetailId: TempDataSplitFunding[k].funderlist[i].planDetailId || 0,
                            funderid: TempDataSplitFunding[k].funderlist[i].funderid,
                            fundername: TempDataSplitFunding[k].funderlist[i].fundername,
                            fundertype: TempDataSplitFunding[k].funderlist[i].fundertype,
                            proportion: prop,
                            proportionvalue: propval,
                            isapplicable: TempDataSplitFunding[k].funderlist[i].isapplicable,
                            isautopay: TempDataSplitFunding[k].funderlist[i].isautopay,
                            ispercentage: TempDataSplitFunding[k].funderlist[i].ispercentage
                        });

                        /* code end */
                    } else {                        
                        funders.push({
                            planDetailId: 0,
                            funderid: props.funderlist?.ListData[i].funderData.ftype !== '4' ? props.funderlist?.ListData[i].funderData.fFunder : null,
                            fundername: props.funderlist?.ListData[i].funderData.fFunderVal,
                            fundertype:
                                props.funderlist?.ListData[i].funderData.ftype === '1'
                                    ? 'ICB'
                                    : props.funderlist?.ListData[i].funderData.ftype === '2'
                                        ? 'LA'
                                        : props.funderlist?.ListData[i].funderData.ftype === '3'
                                            ? 'Charity'
                                            : 'Self',
                            proportion: '',
                            proportionvalue: '',
                            isapplicable: false,
                            isautopay: true,
                            ispercentage: false
                        });
                    }

                    obj.funderlist = funders;
                }
                if (DataPatientDraftInfo !== '' && DataPatientDraftInfo?.data?.isInvoiceCreated !== 1) {
                    plans = [...plans, obj];
                    setplans(plans);
                } else {
                    if (DataPatientDraftInfo === '') {
                        plans = [...plans, obj];
                        setplans(plans);
                    }
                }
            }
            if (DataPatientDraftInfo !== '' && DataPatientDraftInfo?.data?.isInvoiceCreated !== 1) {
                dispatch(setTempSplitFundingAction(plans));
            } else {
                if (DataPatientDraftInfo === '') {
                    dispatch(setTempSplitFundingAction(plans));
                }
            }
        } else {
            if (TempDataSplitFunding.length === 0) {
                if (props.funderlist?.ListData?.length >= 2) {
                    for (let i = 0; i < props.funderlist?.ListData?.length; i++) {
                        funders.push({
                            planDetailId: 0,
                            funderid: props.funderlist?.ListData[i].funderData.ftype !== '4' ? props.funderlist?.ListData[i].funderData.fFunder : null,
                            fundername: props.funderlist?.ListData[i].funderData.fFunderVal,
                            fundertype:
                                props.funderlist?.ListData[i].funderData.ftype === '1'
                                    ? 'ICB'
                                    : props.funderlist?.ListData[i].funderData.ftype === '2'
                                        ? 'LA'
                                        : props.funderlist?.ListData[i].funderData.ftype === '3'
                                            ? 'Charity'
                                            : 'Self',
                            proportion: props.funderlist?.ListData[i]?.funderData?.fLeadPoolingDetailsId > 0 ? props.funderlist?.ListData[i]?.funderData?.fdailyCoreFee : '',
                            proportionvalue: props.funderlist?.ListData[i]?.funderData?.fLeadPoolingDetailsId > 0 ? props.funderlist?.ListData[i]?.funderData?.fdailyCoreFee : '',
                            isapplicable: false,
                            isautopay: true,
                            ispercentage: false
                        });
                    }
                    let obj: any = {
                        corefee: Number(finaltotalcorefee),
                        dailyCoreFee: Number(finalcorefee),
                        continenceCareFee: Number(finalcontinencefee),
                        fncFee: Number(finalfncfee),
                        totalAdditionalServiceFee: Number(totaladditionalfee).toFixed(2),
                        admissiondate: props.admissiondate.toString(),
                        enddate: '',
                        socialfund: getValues('Socialfunding'),
                        healthfund: getValues('Healthfunding'),
                        planId: 0,
                        funderlist: funders || []
                    };
                    plans = [];
                    plans.push(obj);
                    setplans(plans);
                    dispatch(setTempSplitFundingAction(plans));

                }
            } else {
                plans = [];
                for (let k = 0; k < TempDataSplitFunding.length; k++) {
                    obj = {};
                    funders = [];
                    if (TempDataSplitFunding[k].enddate !== undefined && TempDataSplitFunding[k].enddate !== null && TempDataSplitFunding[k].enddate !== '') {
                        obj.corefee = Number(TempDataSplitFunding[k].corefee);
                        obj.dailyCoreFee = TempDataSplitFunding[k]?.dailyCoreFee;

                        obj.continenceCareFee = TempDataSplitFunding[k]?.continenceCareFee;

                        obj.fncFee = TempDataSplitFunding[k]?.fncFee;
                        obj.totalAdditionalServiceFee = Number(TempDataSplitFunding[k]?.totalAdditionalServiceFee).toFixed(2)
                    } else {
                        obj.corefee = Number(finaltotalcorefee);
                        obj.dailyCoreFee = Number(finalcorefee);
                        obj.continenceCareFee = Number(finalcontinencefee);
                        obj.fncFee = Number(finalfncfee);
                        obj.totalAdditionalServiceFee = Number(totaladditionalfee).toFixed(2)
                    }

                    obj.admissiondate = TempDataSplitFunding[k]?.admissiondate?.toString();
                    obj.enddate = TempDataSplitFunding[k].enddate?.toString() || '';
                    obj.socialfund = TempDataSplitFunding[k].socialfund || 0;
                    obj.healthfund = TempDataSplitFunding[k].healthfund || 0;
                    obj.planId = TempDataSplitFunding[k].planId || 0;
                    obj.lastinvoicedate = TempDataSplitFunding[k].lastinvoicedate?.toString();
                    for (let i = 0; i < props.funderlist?.ListData?.length; i++) {
                        let fid = props.funderlist?.ListData[i].funderData.ftype === '4' ? null : Number(props.funderlist?.ListData[i].funderData.fFunder);
                        let tfid = TempDataSplitFunding[k]?.funderlist[i]?.funderid === null ? null : Number(TempDataSplitFunding[k]?.funderlist[i]?.funderid);
                        if (fid === tfid || (fid === null && tfid === null)) {
                            funders.push({
                                planDetailId: TempDataSplitFunding[k].funderlist[i].planDetailId || 0,
                                funderid: TempDataSplitFunding[k].funderlist[i].funderid,
                                fundername: TempDataSplitFunding[k].funderlist[i].fundername,
                                fundertype: TempDataSplitFunding[k].funderlist[i].fundertype,
                                proportion: TempDataSplitFunding[k].funderlist[i].proportion,
                                proportionvalue: TempDataSplitFunding[k].funderlist[i].proportionvalue,
                                isapplicable: TempDataSplitFunding[k].funderlist[i].isapplicable,
                                isautopay: TempDataSplitFunding[k].funderlist[i].isautopay,
                                ispercentage: TempDataSplitFunding[k].funderlist[i].ispercentage
                            });
                        } else {
                            let funderexists = TempDataSplitFunding[k]?.funderlist?.find((val: any) => {
                                // in case of self funder no need to cast to number
                                if (fid !== null ? val.funderid === Number(fid) : val.funderid === fid) {
                                    return val;
                                }
                            });
                            if (funderexists !== undefined) {
                                funders.push({
                                    planDetailId: funderexists.planDetailId || 0,
                                    funderid: funderexists.funderid,
                                    fundername: funderexists.fundername,
                                    fundertype: funderexists.fundertype,
                                    proportion: funderexists.proportion,
                                    proportionvalue: funderexists.proportionvalue,
                                    isapplicable: funderexists.isapplicable,
                                    isautopay: funderexists.isautopay,
                                    ispercentage: funderexists.ispercentage
                                });
                            } else {
                                if (props.funderlist?.ListData[0]?.isinvoicecreated === 1) {
                                    if (k === 1) {
                                        funders.push({
                                            planDetailId: 0,
                                            funderid: props.funderlist?.ListData[i].funderData.ftype !== '4' ? props.funderlist?.ListData[i].funderData.fFunder : null,
                                            fundername: props.funderlist?.ListData[i].funderData.fFunderVal,
                                            fundertype:
                                                props.funderlist?.ListData[i].funderData.ftype === '1'
                                                    ? 'ICB'
                                                    : props.funderlist?.ListData[i].funderData.ftype === '2'
                                                        ? 'LA'
                                                        : props.funderlist?.ListData[i].funderData.ftype === '3'
                                                            ? 'Charity'
                                                            : 'Self',
                                            proportion: '',
                                            proportionvalue: '',
                                            isapplicable: false,
                                            isautopay: true,
                                            ispercentage: false
                                        });
                                    }
                                } else {
                                    if (k === 0) {
                                        funders.push({
                                            planDetailId: 0,
                                            funderid: props.funderlist?.ListData[i].funderData.ftype !== '4' ? props.funderlist?.ListData[i].funderData.fFunder : null,
                                            fundername: props.funderlist?.ListData[i].funderData.fFunderVal,
                                            fundertype:
                                                props.funderlist?.ListData[i].funderData.ftype === '1'
                                                    ? 'ICB'
                                                    : props.funderlist?.ListData[i].funderData.ftype === '2'
                                                        ? 'LA'
                                                        : props.funderlist?.ListData[i].funderData.ftype === '3'
                                                            ? 'Charity'
                                                            : 'Self',
                                            proportion: '',
                                            proportionvalue: '',
                                            isapplicable: false,
                                            isautopay: true,
                                            ispercentage: false
                                        });
                                    }
                                }
                            }
                        }

                        obj.funderlist = funders;
                    }
                    //     if (DataPatientDraftInfo !== '') {
                    //         plans = [...plans, obj];
                    //         setplans(plans);
                    //     } else {
                    //         if (DataPatientDraftInfo === '') {
                    //             plans = [...plans, obj];
                    //             setplans(plans);
                    //         }
                    //     }
                    //     // plans = [...plans,obj];
                    //     // setplans(plans);
                    // }
                    // if (DataPatientDraftInfo !== '') {
                    //     dispatch(setTempSplitFundingAction(plans));
                    // } else {
                    //     if (DataPatientDraftInfo === '') {
                    //         plans = [...plans, obj];
                    //         setplans(plans);
                    //     }
                    // }
                    if (DataPatientDraftInfo !== "" && DataPatientDraftInfo?.data?.isInvoiceCreated !== 1) {
                        plans = [...plans, obj];
                        setplans(plans);
                    }
                    else {
                        if (DataPatientDraftInfo === "") {
                            plans = [...plans, obj];
                            setplans(plans);
                        }
                    }
                    // plans = [...plans,obj];
                    // setplans(plans);

                }
                if (DataPatientDraftInfo !== "" && DataPatientDraftInfo?.data?.isInvoiceCreated !== 1) {
                    dispatch(setTempSplitFundingAction(plans))
                }
                else {
                    if (DataPatientDraftInfo === "") {
                        plans = [...plans, obj];
                        setplans(plans);
                    }
                }
            }
        }
    };

    const toggle = (isValid: boolean) => {
        swal({
            title: '',
            text: 'Are you sure you want to close?',
            icon: warning,
            buttons: ['No', 'Yes'],
            dangerMode: true
        }).then(async (confirm: any) => {
            if (confirm) {
                setShowModal(false);
                if (isValid === false) {
                    props.splitchange(false);
                } else {
                    props.splitchange(true);
                }
                // dispatch(removeSplitFundingAction())
            }
        });
    };

    const savedata = () => {
        if (DataPatientDraftInfo?.data?.isAllInvoiceCancelled === 1) {
            swal({
                title: '',
                text: 'Manual invoices will have to be created against the cancelled invoices',
                icon: warning,
                dangerMode: true
            }).then(async (confirm: any) => {
                dispatch(setSplitFundingAction(TempDataSplitFunding));
                props.splitchange(true);
                setShowModal(false);
            });
        } else {
            dispatch(setSplitFundingAction(TempDataSplitFunding));
            props.splitchange(true);
            setShowModal(false);
        }
    };

    const addPlan = () => {
        plans.push(TempDataSplitFunding[0]);

        for (let k = 0; k < TempDataSplitFunding.length; k++) {
            obj = {};
            obj.corefee = Number(finaltotalcorefee);
            obj.dailyCoreFee = Number(finalcorefee);
            var date = new Date(TempDataSplitFunding[k].enddate);
            date.setDate(date.getDate() + 1);
            obj.admissiondate = date.toString();
            obj.enddate = '';
            obj.socialfund = getValues('Socialfunding');
            obj.healthfund = getValues('Healthfunding');
            obj.planId = PlanData?.data?.length === 2 ? PlanData?.data[1]?.planId : -1;
            obj.totalAdditionalServiceFee = Number(totaladditionalfee).toFixed(2);
            obj.continenceCareFee = Number(finalcontinencefee).toFixed(2);
            obj.fncFee = Number(finalfncfee).toFixed(2);
            obj.continenceCareFeeType = props.dailyOrWeeklyContinenceCareFeeToggle ? 2 : 1
            obj.fncFeeType = props.dailyOrWeeklyFNCFeeToggle ? 2 : 1
            if (props.funderlist?.ListData?.length > 1) {
                for (let i = 0; i < props.funderlist?.ListData?.length; i++) {
                    let fid = props.funderlist?.ListData[i].funderData.ftype === '4' ? null : Number(props.funderlist?.ListData[i].funderData.fFunder);
                    let isexists = PlanData?.data[PlanData?.data?.length === 1 ? 0 : 1]?.planDetails?.find((val: any) => {
                        if (val.funderId === (fid !== null ? Number(fid) : null)) {
                            return val;
                        }
                    });
                    // let tfid = TempDataSplitFunding[k]?.funderlist[i]?.funderid === null ? null : Number(TempDataSplitFunding[k]?.funderlist[i]?.funderid);
                    // if (fid === tfid || (fid === null && tfid === null)) {
                    //     let plandid = 0;
                    //     if (PlanData?.data?.length === 2) {
                    //         PlanData?.data[1]?.planDetails?.map((val: any) => {
                    //             if (val.funderId === Number(fid)) {
                    //                 plandid = val.planDetailId;
                    //             }
                    //         });
                    //     }
                    if (isexists) {
                        let plandid = 0;
                        if (PlanData?.data?.length === 2) {
                            PlanData?.data[1]?.planDetails?.map((val: any) => {
                                if (val.funderId === (fid !== null ? Number(fid) : null)) {
                                    plandid = val.planDetailId;
                                }
                            });
                        }
                        funders.push({
                            planDetailId: plandid,
                            funderid: isexists?.funderId,
                            fundername: isexists?.funderName,
                            fundertype: isexists?.funderType === 1 ? 'ICB' : isexists?.funderType === 2 ? 'LA' : isexists?.funderType === 3 ? 'Charity' : 'Self',
                            proportion: isexists?.proportion,
                            proportionvalue: isexists?.proportionValue,
                            isapplicable: isexists?.isApplicable === 1 ? false : true,
                            isautopay: isexists?.isAutoPay,
                            ispercentage: isexists?.amountType === 1 ? false : true
                        });
                    } else {
                        funders.push({
                            planDetailId: 0,
                            funderid: props.funderlist?.ListData[i].funderData.ftype !== '4' ? props.funderlist?.ListData[i].funderData.fFunder : null,
                            fundername: props.funderlist?.ListData[i].funderData.fFunderVal,
                            fundertype:
                                props.funderlist?.ListData[i].funderData.ftype === '1'
                                    ? 'ICB'
                                    : props.funderlist?.ListData[i].funderData.ftype === '2'
                                        ? 'LA'
                                        : props.funderlist?.ListData[i].funderData.ftype === '3'
                                            ? 'Charity'
                                            : 'Self',
                            proportion: '',
                            proportionvalue: '',
                            isapplicable: false,
                            isautopay: true,
                            ispercentage: false
                        });
                    }

                    obj.funderlist = funders;
                }
            } else if (props.funderlist?.ListData?.length === 1) {
                for (let i = 0; i < props.funderlist?.ListData?.length; i++) {
                    let fid = props.funderlist?.ListData[i].funderData.ftype === '4' ? null : Number(props.funderlist?.ListData[i].funderData.fFunder);
                    let tfid = TempDataSplitFunding[k]?.funderlist[i]?.funderid === null ? null : Number(TempDataSplitFunding[k]?.funderlist[i]?.funderid);
                    if (fid === tfid || (fid === null && tfid === null)) {
                        funders.push({
                            planDetailId: 0,
                            funderid: TempDataSplitFunding[k].funderlist[i].funderid,
                            fundername: TempDataSplitFunding[k].funderlist[i].fundername,
                            fundertype: TempDataSplitFunding[k].funderlist[i].fundertype,
                            proportion: Number(finaltotalcorefee),
                            proportionvalue: Number(finaltotalcorefee),
                            isapplicable: TempDataSplitFunding[k].funderlist[i].isapplicable,
                            isautopay: TempDataSplitFunding[k].funderlist[i].isautopay,
                            ispercentage: TempDataSplitFunding[k].funderlist[i].ispercentage
                        });
                    } else {
                        funders.push({
                            planDetailId: 0,
                            funderid: props.funderlist?.ListData[i].funderData.ftype !== '4' ? props.funderlist?.ListData[i].funderData.fFunder : null,
                            fundername: props.funderlist?.ListData[i].funderData.fFunderVal,
                            fundertype:
                                props.funderlist?.ListData[i].funderData.ftype === '1'
                                    ? 'ICB'
                                    : props.funderlist?.ListData[i].funderData.ftype === '2'
                                        ? 'LA'
                                        : props.funderlist?.ListData[i].funderData.ftype === '3'
                                            ? 'Charity'
                                            : 'Self',
                            proportion: '',
                            proportionvalue: '',
                            isapplicable: false,
                            isautopay: true,
                            ispercentage: false
                        });
                    }

                    obj.funderlist = funders;
                }
            }
            plans.push(obj);
            dispatch(setTempSplitFundingAction(plans));
        }
    };

    useEffect(() => {
        if (props.dailyfee !== '') {
            if (props.dailyweek) {
                setfinalcorefee(Number(props.dailyfee).toFixed(2));
            } else {
                setfinalcorefee((Number(props.dailyfee) * 7).toFixed(2));
            }
        } else {
            setfinalcorefee(0);
        }
    }, [props.dailyfee]);

    useEffect(() => {        
        if (props.dailyfee !== '') {
            if (props.dailyweek) {
                setfinalcorefee(Number(props.dailyfee).toFixed(2));
            } else {
                setfinalcorefee((Number(props.dailyfee) * 7).toFixed(2));
            }
        } else {
            setfinalcorefee(0);
        }
    }, [props.dailyweek]);

    // useEffect(()=>{

    //  let tmpdata = JSON.parse(JSON.stringify(TempDataSplitFunding));
    //  if(tmpdata.length === 1 && tmpdata.length > 0){
    //     tmpdata[0].dailyCoreFee = Number(finalcorefee);
    //     tmpdata[0].continenceCareFee = Number(finalcontinencefee);
    //     tmpdata[0].fncFee = Number(finalfncfee);
    //  }
    //  else if(tmpdata.length === 2 && tmpdata.length > 0){
    //     tmpdata[1].dailyCoreFee = Number(finalcorefee);
    //     tmpdata[1].continenceCareFee = Number(finalcontinencefee);
    //     tmpdata[1].fncFee = Number(finalfncfee);
    //  }
    //             dispatch(setTempSplitFundingAction(tmpdata))

    // },[finalcorefee,finalcontinencefee,finalfncfee]);

    // useEffect(() =>{
    //
    // },[TempDataSplitFunding])
    // useEffect(()=>{
    //  props.finalcorefee(Number(finaltotalcorefee))
    //  let tmpdata = JSON.parse(JSON.stringify(TempDataSplitFunding));
    //  if(tmpdata.length === 1 && tmpdata.length > 0){
    //  tmpdata[0].corefee = Number(finaltotalcorefee);
    //  } else if(tmpdata.length === 2 && tmpdata.length > 0) {
    //     tmpdata[1].corefee = Number(finaltotalcorefee);
    //  }
    //         dispatch(setTempSplitFundingAction(tmpdata))

    // },[finaltotalcorefee])

    useEffect(() => {
        if (finalcorefee !== 0) {
            if (props.Continencefee !== '') {
                if (props.Continencefee === '0') {
                    setValue('ContinenceInclude', false);
                    props.getupdatecheckbox('Continence', false);
                }
                if (props.ContinenceInclude) {
                    if (props.dailyfee !== '') {
                        setfinalcontinencefee(Number(0).toFixed(2));
                        if (props.dailyweek && props.dailyOrWeeklyContinenceCareFeeToggle) {
                            if (props.Continencefee > props.dailyfee) {
                                settotalfeevalid(true);
                            } else {
                                settotalfeevalid(false);
                            }
                        } else if (!props.dailyweek && !props.dailyOrWeeklyContinenceCareFeeToggle) {
                            if (props.Continencefee > props.dailyfee) {
                                settotalfeevalid(true);
                            } else {
                                settotalfeevalid(false);
                            }
                        } else {
                            if (!props.dailyweek && props.dailyOrWeeklyContinenceCareFeeToggle) {
                                let dayfee = ((Number(getValues('ContinenceCareFee')) * 7) / 7).toFixed(2);
                                if (dayfee > Number(props.dailyfee).toFixed(2)) {
                                    settotalfeevalid(true);
                                } else {
                                    settotalfeevalid(false);
                                }
                            } else if (props.dailyweek && !props.dailyOrWeeklyContinenceCareFeeToggle) {
                                let dayfee = (Number(finalcorefee) / 7).toFixed(2);
                                if (finalcontinencefee > Number(dayfee).toFixed(2)) {
                                    settotalfeevalid(true);
                                } else {
                                    settotalfeevalid(false);
                                }
                            }
                        }
                    }
                } else {
                    setfinalcontinencefee(Number(getValues('ContinenceCareFee')).toFixed(2));
                    if (!props.FNCInclude && !props.ContinenceInclude) {
                        settotalfeevalid(false);
                    }

                    if (props.dailyOrWeeklyContinenceCareFeeToggle) {
                        setfinalcontinencefee(Number(props.Continencefee).toFixed(2));
                    } else {
                        setfinalcontinencefee((Number(props.Continencefee) * 7).toFixed(2));
                    }
                }
            } else {
                setfinalcontinencefee(Number(0).toFixed(2));
                setValue('ContinenceInclude', false);
                props.getupdatecheckbox('Continence', false);
            }

            if (props.FNCfee !== '') {
                if (props.FNCfee === '0') {
                    setValue('FNCInclude', false);
                    props.getupdatecheckbox('FNC', false);
                }
                if (props.FNCInclude) {
                    if (props.dailyfee !== '') {
                        setfinalfncfee(Number(0).toFixed(2));
                        if (props.dailyweek && props.dailyOrWeeklyFNCFeeToggle) {
                            if (props.FNCfee > props.dailyfee) {
                                settotalfeevalid(true);
                            } else {
                                settotalfeevalid(false);
                            }
                        } else if (!props.dailyweek && !props.dailyOrWeeklyFNCFeeToggle) {
                            if (props.FNCfee > props.dailyfee) {
                                settotalfeevalid(true);
                            } else {
                                settotalfeevalid(false);
                            }
                        } else {
                            if (!props.dailyweek && props.dailyOrWeeklyFNCFeeToggle) {
                                let dayfee = ((Number(getValues('FNCFee')) * 7) / 7).toFixed(2);
                                if (dayfee > Number(props.dailyfee).toFixed(2)) {
                                    settotalfeevalid(true);
                                } else {
                                    settotalfeevalid(false);
                                }
                            } else if (props.dailyweek && !props.dailyOrWeeklyFNCFeeToggle) {
                                let dayfee = (Number(finalcorefee) / 7).toFixed(2);
                                if (finalfncfee > Number(dayfee).toFixed(2)) {
                                    settotalfeevalid(true);
                                } else {
                                    settotalfeevalid(false);
                                }
                            }
                        }
                    }
                } else {
                    setfinalfncfee(Number(getValues('FNCFee')).toFixed(2));
                    if (!props.FNCInclude && !props.ContinenceInclude) {
                        settotalfeevalid(false);
                    }

                    if (props.dailyOrWeeklyFNCFeeToggle) {
                        setfinalfncfee(Number(props.FNCfee).toFixed(2));
                    } else {
                        setfinalfncfee((Number(props.FNCfee) * 7).toFixed(2));
                    }
                }
            } else {
                setfinalfncfee(Number(0).toFixed(2));
                setValue('FNCInclude', false);
                props.getupdatecheckbox('FNC', false);
            }

            if (!props.Inclusion1included) {
                setValue('Inclusion1included', props.Inclusion1included);

                if (props.Inclusion1 !== '') {
                    if (props.AdditionalFeeH1 !== '') {
                        if (props?.In1dailyOrWeeklyToggle === false) {
                            let weekrate = Number(Number(props.Inclusion1) * 7).toFixed(2);
                            let finalrate = Number(Number(weekrate) * Number(props.AdditionalFeeH1)).toFixed(2);
                            setfinaladditionalfee1(Number(finalrate).toFixed(2));
                        } else {
                            let weekrate = Number(props.Inclusion1).toFixed(2);
                            let finalrate = Number(Number(weekrate) * Number(props.AdditionalFeeH1)).toFixed(2);
                            setfinaladditionalfee1(Number(finalrate).toFixed(2));
                        }
                        if (props.Inclusion2 === '' || props.Inclusion3 === '' || props.Inclusion4 === '') {
                            settotalfeevalid(false);
                        } else if (
                            props.Inclusion2 !== '' &&
                            props.AdditionalFeeH2 !== '' &&
                            props.Inclusion3 !== '' &&
                            props.AdditionalFeeH3 !== '' &&
                            props.Inclusion4 !== '' &&
                            props.AdditionalFeeH4 !== ''
                        ) {
                            settotalfeevalid(false);
                        }
                    } else {
                        settotalfeevalid(true);
                        setfinaladditionalfee1(Number(0).toFixed(2));
                    }
                } else {
                    if (props.Inclusion2 === '' || props.Inclusion3 === '' || props.Inclusion4 === '') {
                        settotalfeevalid(false);
                        setfinaladditionalfee1(Number(0).toFixed(2));
                    }
                }
            }
            else {
                if (props.Inclusion1included) {
                    setValue('Inclusion1included', props.Inclusion1included);
                    setfinaladditionalfee1(Number(0).toFixed(2));
                }
            }

            if (!props.Inclusion2included) {
                setValue('Inclusion2included', props.Inclusion2included);

                if (props.Inclusion2 !== '') {
                    if (props.AdditionalFeeH2 !== '') {
                        if (props?.In2dailyOrWeeklyToggle === false) {
                            let weekrate = Number(Number(props.Inclusion2) * 7).toFixed(2);
                            let finalrate = Number(Number(weekrate) * Number(props.AdditionalFeeH2)).toFixed(2);
                            setfinaladditionalfee2(Number(finalrate).toFixed(2));
                        } else {
                            let weekrate = Number(props.Inclusion2).toFixed(2);
                            let finalrate = Number(Number(weekrate) * Number(props.AdditionalFeeH2)).toFixed(2);
                            setfinaladditionalfee2(Number(finalrate).toFixed(2));
                        }
                        if (props.Inclusion1 === '' || props.Inclusion3 === '' || props.Inclusion4 === '') {
                            settotalfeevalid(false);
                        } else if (
                            props.Inclusion1 !== '' &&
                            props.AdditionalFeeH1 !== '' &&
                            props.Inclusion3 !== '' &&
                            props.AdditionalFeeH3 !== '' &&
                            props.Inclusion4 !== '' &&
                            props.AdditionalFeeH4 !== ''
                        ) {
                            settotalfeevalid(false);
                        }
                    } else {
                        settotalfeevalid(true);
                        setfinaladditionalfee2(Number(0).toFixed(2));
                    }
                } else {
                    if (props.Inclusion1 === '' || props.Inclusion3 === '' || props.Inclusion4 === '') {
                        settotalfeevalid(false);
                        setfinaladditionalfee2(Number(0).toFixed(2));
                    }
                }
            }
            else {
                if (props.Inclusion2included) {
                    setValue('Inclusion2included', props.Inclusion2included);
                    setfinaladditionalfee2(Number(0).toFixed(2));
                }
            }

            if (!props.Inclusion3included) {
                setValue('Inclusion3included', props.Inclusion3included);

                if (props.Inclusion3 !== '') {
                    if (props.AdditionalFeeH3 !== '') {
                        if (props?.In3dailyOrWeeklyToggle === false) {
                            let weekrate = Number(Number(props.Inclusion3) * 7).toFixed(2);
                            let finalrate = Number(Number(weekrate) * Number(props.AdditionalFeeH3)).toFixed(2);
                            setfinaladditionalfee3(Number(finalrate).toFixed(2));
                        } else {
                            let weekrate = Number(props.Inclusion3).toFixed(2);
                            let finalrate = Number(Number(weekrate) * Number(props.AdditionalFeeH3)).toFixed(2);
                            setfinaladditionalfee3(Number(finalrate).toFixed(2));
                        }
                        if (props.Inclusion1 === '' || props.Inclusion2 === '' || props.Inclusion4 === '') {
                            settotalfeevalid(false);
                        } else if (
                            props.Inclusion1 !== '' &&
                            props.AdditionalFeeH1 !== '' &&
                            props.Inclusion2 !== '' &&
                            props.AdditionalFeeH2 !== '' &&
                            props.Inclusion4 !== '' &&
                            props.AdditionalFeeH4 !== ''
                        ) {
                            settotalfeevalid(false);
                        }
                    } else {
                        settotalfeevalid(true);
                        setfinaladditionalfee3(Number(0).toFixed(2));
                    }
                } else {
                    if (props.Inclusion1 === '' || props.Inclusion2 === '' || props.Inclusion4 === '') {
                        settotalfeevalid(false);
                        setfinaladditionalfee3(Number(0).toFixed(2));
                    }
                }
            }
            else {
                if (props.Inclusion3included) {
                    setValue('Inclusion3included', props.Inclusion3included);
                    setfinaladditionalfee3(Number(0).toFixed(2));
                }
            }

            if (!props.Inclusion4included) {
                setValue('Inclusion4included', props.Inclusion4included);
                if (props.Inclusion4 !== '') {
                    if (props.AdditionalFeeH4 !== '') {
                        if (props?.In4dailyOrWeeklyToggle === false) {
                            let weekrate = Number(Number(props.Inclusion4) * 7).toFixed(2);
                            let finalrate = Number(Number(weekrate) * Number(props.AdditionalFeeH4)).toFixed(2);
                            setfinaladditionalfee4(Number(finalrate).toFixed(2));
                        } else {
                            let weekrate = Number(props.Inclusion4).toFixed(2);
                            let finalrate = Number(Number(weekrate) * Number(props.AdditionalFeeH4)).toFixed(2);
                            setfinaladditionalfee4(Number(finalrate).toFixed(2));
                        }
                        if (props.Inclusion1 === '' || props.Inclusion2 === '' || props.Inclusion3 === '') {
                            settotalfeevalid(false);
                        } else if (
                            props.Inclusion1 !== '' &&
                            props.AdditionalFeeH1 !== '' &&
                            props.Inclusion2 !== '' &&
                            props.AdditionalFeeH2 !== '' &&
                            props.Inclusion3 !== '' &&
                            props.AdditionalFeeH3 !== ''
                        ) {
                            settotalfeevalid(false);
                        }
                    } else {
                        settotalfeevalid(true);
                        setfinaladditionalfee4(Number(0).toFixed(2));
                    }
                } else {
                    if (props.Inclusion1 === '' || props.Inclusion2 === '' || props.Inclusion3 === '') {
                        settotalfeevalid(false);
                        setfinaladditionalfee4(Number(0).toFixed(2));
                    }
                }
            }
            else {
                if (props.Inclusion4included) {
                    setValue('Inclusion4included', props.Inclusion4included);
                    setfinaladditionalfee4(Number(0).toFixed(2));
                }
            }

            if (DataPatientDraftInfo?.data?.admissionDetail === undefined) {
                if (props?.AdditionalFeeH1 === '') {
                    setValue('Inclusion1included', false);
                    setValue('Inclusion1', '');
                    props.getupdatecheckbox('AdditionalFeeH1', false);

                    if (!props?.manualUpdate) {
                        setValue('Inclusion2included', false);
                        setValue('Inclusion2', '');
                        props.getupdatecheckbox('AdditionalFeeH2', false);

                        setValue('Inclusion3included', false);
                        setValue('Inclusion3', '');
                        props.getupdatecheckbox('AdditionalFeeH3', false);

                        setValue('Inclusion4included', false);
                        setValue('Inclusion4', '');
                        props.getupdatecheckbox('AdditionalFeeH4', false);
                    }
                }
                if (props?.AdditionalFeeH2 === '') {
                    setValue('Inclusion2included', false);
                    setValue('Inclusion2', '');
                    props.getupdatecheckbox('AdditionalFeeH2', false);
                }
                if (props?.AdditionalFeeH3 === '') {
                    setValue('Inclusion3included', false);
                    setValue('Inclusion3', '');
                    props.getupdatecheckbox('AdditionalFeeH3', false);
                }
                if (props?.AdditionalFeeH4 === '') {
                    setValue('Inclusion4included', false);
                    setValue('Inclusion4', '');
                    props.getupdatecheckbox('AdditionalFeeH4', false);
                }
            } else {
                const fieldState1 = getFieldState('AdditionalFeeH2');
                if (props?.AdditionalFeeH1 === '') {
                    setValue('Inclusion1included', false);
                    setValue('Inclusion1', '');
                    props.getupdatecheckbox('AdditionalFeeH1', false);
                    if (!props?.manualUpdate) {
                        setValue('Inclusion2included', false);
                        setValue('Inclusion2', '');
                        props.getupdatecheckbox('AdditionalFeeH2', false);

                        setValue('Inclusion3included', false);
                        setValue('Inclusion3', '');
                        props.getupdatecheckbox('AdditionalFeeH3', false);

                        setValue('Inclusion4included', false);
                        setValue('Inclusion4', '');
                        props.getupdatecheckbox('AdditionalFeeH4', false);
                    }
                } else if (!fieldState1.isDirty && DataPatientDraftInfo === "") {
                    setValue('Inclusion1', DataPatientDraftInfo.data.admissionDetail.inclusionHours1?.toString());
                    setValue('Inclusion1included', DataPatientDraftInfo.data.admissionDetail.isInclusionHours1Included);

                }
                const fieldState2 = getFieldState('AdditionalFeeH2');
                if (props?.AdditionalFeeH2 === '') {
                    setValue('Inclusion2included', false);
                    setValue('Inclusion2', '');
                    props.getupdatecheckbox('AdditionalFeeH2', false);
                } else if (!fieldState2.isDirty && DataPatientDraftInfo === "") {
                    setValue('Inclusion2', DataPatientDraftInfo.data.admissionDetail.inclusionHours2?.toString());
                    setValue('Inclusion2included', DataPatientDraftInfo.data.admissionDetail.isInclusionHours2Included);
                }
                const fieldState3 = getFieldState('AdditionalFeeH3');
                if (props?.AdditionalFeeH3 === '') {
                    setValue('Inclusion3included', false);
                    setValue('Inclusion3', '');
                    props.getupdatecheckbox('AdditionalFeeH3', false);
                } else if (!fieldState3.isDirty && DataPatientDraftInfo === "") {
                    setValue('Inclusion3', DataPatientDraftInfo.data.admissionDetail.inclusionHours3?.toString());
                    setValue('Inclusion3included', DataPatientDraftInfo.data.admissionDetail.isInclusionHours3Included);
                }
                const fieldState4 = getFieldState('AdditionalFeeH4');
                if (props?.AdditionalFeeH4 === '') {
                    setValue('Inclusion4included', false);
                    setValue('Inclusion4', '');
                    props.getupdatecheckbox('AdditionalFeeH4', false);
                } else if (!fieldState4.isDirty && DataPatientDraftInfo === "") {
                    setValue('Inclusion4', DataPatientDraftInfo.data.admissionDetail.inclusionHours4?.toString());
                    setValue('Inclusion4included', DataPatientDraftInfo.data.admissionDetail.isInclusionHours4Included);

                }
            }
        }
    }, [
        props.dailyOrWeeklyContinenceCareFeeToggle,
        props.ContinenceInclude,
        props.Continencefee,
        props.dailyOrWeeklyFNCFeeToggle,
        props.FNCInclude,
        props.FNCfee,
        props.draftData,
        props.AdditionalFeeH1,
        props.AdditionalFeeH2,
        props.AdditionalFeeH3,
        props.AdditionalFeeH4,
        props.Inclusion1,
        props.Inclusion2,
        props.Inclusion3,
        props.Inclusion4,
        props?.In1dailyOrWeeklyToggle,
        props?.In2dailyOrWeeklyToggle,
        props?.In3dailyOrWeeklyToggle,
        props?.In4dailyOrWeeklyToggle,
        props.Inclusion1included,
        props.Inclusion2included,
        props.Inclusion3included,
        props.Inclusion4included,
        finalcorefee,
        finaltotalcorefee
    ]);

    useEffect(() => {
        eventEmitter.on('eventClearFormArray', eventClearArray);
        return () => {
            eventEmitter.off('eventClearFormArray', eventClearArray);
        };
    });
    const eventClearArray = () => {
        setfinaltotalcorefee(0);
        setfinalcorefee(0);
        setfinalfncfee(0);
        setfinalcontinencefee(0);
        setfinaladditionalfee1(0);
        setfinaladditionalfee2(0);
        setfinaladditionalfee3(0);
        setfinaladditionalfee4(0);
        settotaladditionalfee(0);
        props.resetprops();
    };

    const eventClearformation = async () => {
        let currentplan = JSON.parse(JSON.stringify(TempDataSplitFunding));
        currentplan[0].funderlist = [];
        dispatch(setTempSplitFundingAction(currentplan));
        dispatch(setSplitFundingAction(currentplan));
    };

    useEffect(() => {
        eventEmitter.on('eventClearSpliting', eventClearformation);
        return () => {
            eventEmitter.off('eventClearSpliting', eventClearformation);
        };
    });

    useEffect(() => {
        if (props.funderlist?.ListData?.length > 0) {
            if (props.funderlist?.ListData?.length === 1) {
                setTimeout(() => {
                    props.splitingoninvoice(false);
                }, 500);
            }
        }
    }, [props.funderlist])

    useEffect(() => {
        console.log(DataPatientDraftInfo);
        console.log(props.funderlist);
        // when user voided or cancel funder from admision then remove it from the plan when invoice is not created
        // if(props.funderlist === "" && DataPatientDraftInfo?.data?.isInvoiceCreated !== 1 && TempDataSplitFunding.length > 0){
        //     let currentplan = JSON.parse(JSON.stringify(TempDataSplitFunding));
        //     currentplan[0].funderlist = [];
        //     dispatch(setTempSplitFundingAction(currentplan));
        //     dispatch(setSplitFundingAction(currentplan));
        // }
        setfinaltotalcorefee(
            (
                Number(finalcorefee) +
                Number(finalcontinencefee) +
                Number(finalfncfee) +
                Number(finaladditionalfee1) +
                Number(finaladditionalfee2) +
                Number(finaladditionalfee3) +
                Number(finaladditionalfee4)
            ).toFixed(2)
        );
        setValue(
            'TotalDailyFee',
            (
                Number(finalcorefee) +
                Number(finalcontinencefee) +
                Number(finalfncfee) +
                Number(finaladditionalfee1) +
                Number(finaladditionalfee2) +
                Number(finaladditionalfee3) +
                Number(finaladditionalfee4)
            ).toFixed(2),
            { shouldValidate: true }
        );
        settotaladditionalfee((Number(finaladditionalfee1) +
            Number(finaladditionalfee2) +
            Number(finaladditionalfee3) +
            Number(finaladditionalfee4)).toFixed(2));
        props.finalcorefee(Number(finaltotalcorefee));

        // if(DataSplitFunding.length > 0){
        //     let updatecorefee = JSON.parse(JSON.stringify(DataSplitFunding));
        //     updatecorefee[DataSplitFunding.length === 1 ? 0 : 1].corefee =   (
        //         Number(finalcorefee) +
        //         Number(finalcontinencefee) +
        //         Number(finalfncfee) +
        //         Number(finaladditionalfee1) +
        //         Number(finaladditionalfee2) +
        //         Number(finaladditionalfee3) +
        //         Number(finaladditionalfee4)
        //     ).toFixed(2);
        //     updatecorefee[DataSplitFunding.length === 1 ? 0 : 1].totalFees =   (
        //         Number(finalcorefee) +
        //         Number(finalcontinencefee) +
        //         Number(finalfncfee) +
        //         Number(finaladditionalfee1) +
        //         Number(finaladditionalfee2) +
        //         Number(finaladditionalfee3) +
        //         Number(finaladditionalfee4)
        //     ).toFixed(2);
        //     dispatch(setTempSplitFundingAction(updatecorefee));
        //     dispatch(setSplitFundingAction(updatecorefee));
        // }

        if (finalcorefee !== '' && props.funderlist?.ListData?.length === 1 && DataPatientDraftInfo === '') {
            plans = [];
            /* prod issue code */
            for (let i = 0; i < props.funderlist?.ListData?.length; i++) {
                funders.push({
                    planDetailId: 0,
                    funderid: props.funderlist?.ListData[i].funderData.ftype !== '4' ? props.funderlist?.ListData[i].funderData.fFunder : null,
                    fundername: props.funderlist?.ListData[i].funderData.fFunderVal,
                    fundertype:
                        props.funderlist?.ListData[i].funderData.ftype === '1'
                            ? 'ICB'
                            : props.funderlist?.ListData[i].funderData.ftype === '2'
                                ? 'LA'
                                : props.funderlist?.ListData[i].funderData.ftype === '3'
                                    ? 'Charity'
                                    : 'Self',
                    proportion: finaltotalcorefee,
                    proportionvalue: finaltotalcorefee,
                    isapplicable: false,
                    isautopay: true,
                    ispercentage: false,

                });
            }
            let obj: any = {
                corefee: Number(finaltotalcorefee),
                dailyCoreFee: Number(finalcorefee),
                continenceCareFee: !props.dailyOrWeeklyContinenceCareFeeToggle ? (Number(getValues('ContinenceCareFee')) * 7).toFixed(2) : Number(getValues('ContinenceCareFee')).toFixed(2),
                fncFee: !props.dailyOrWeeklyFNCFeeToggle ? (Number(getValues('FNCFee')) * 7).toFixed(2) : Number(getValues('FNCFee')).toFixed(2),
                admissiondate: props.admissiondate.toString(),
                enddate: '',
                socialfund: getValues('Socialfunding'),
                healthfund: getValues('Healthfunding'),
                planId: 0,
                funderlist: funders || [],
                totalAdditionalServiceFee: totaladditionalfee
            };
            plans.push(obj);
            setplans(plans);
            dispatch(setTempSplitFundingAction(plans));
            dispatch(setSplitFundingAction(plans));
        } else if (finalcorefee !== '' && props.funderlist?.ListData?.length > 1 && DataPatientDraftInfo === '') {
            dispatch(removeSplitFundingAction());
            dispatch(removeTempSplitFundingAction());
        } else if (finalcorefee !== '' && props.funderlist?.ListData?.length === 1 && DataPatientDraftInfo !== '') {

            console.log(props.funderlist?.ListData);
            let planstemp: any;
            if (TempDataSplitFunding.length > 0) {
                for (let k = 0; k < TempDataSplitFunding.length; k++) {
                    /* prod issue change code */
                    if (DataPatientDraftInfo?.data?.isInvoiceCreated === 1 && DataPatientDraftInfo?.data?.contractDetails.length === props.funderlist?.ListData?.length) {
                        if (DataPatientDraftInfo?.data?.contractDetails[0]?.funderId === props.funderlist?.ListData[0]?.funderData?.fFunderId || Number(DataPatientDraftInfo?.data?.contractDetails[0]?.funderId) === props.funderlist?.ListData[0]?.funderData?.fFunderId) {
                            if (TempDataSplitFunding.length === 1 && (Number(TempDataSplitFunding[0].corefee) === Number(finaltotalcorefee))) {
                                planstemp = [];
                                obj = {};
                                funders = [];

                                obj.corefee = Number(TempDataSplitFunding[0].corefee);

                                obj.dailyCoreFee = Number(TempDataSplitFunding[0].dailyCoreFee).toFixed(2);
                                obj.acontinenceCareFee = TempDataSplitFunding[0].acontinenceCareFee;
                                obj.afncFee = TempDataSplitFunding[0].afncFee;
                                obj.continenceCareFeeType = TempDataSplitFunding[0].continenceCareFeeType;
                                obj.fncFeeType = TempDataSplitFunding[0].fncFeeType;
                                obj.continenceCareFee = TempDataSplitFunding[0].continenceCareFeeType == 1 ? (Number(TempDataSplitFunding[0].acontinenceCareFee) * 7).toFixed(2) : Number(TempDataSplitFunding[0].acontinenceCareFee).toFixed(2);

                                obj.fncFee = TempDataSplitFunding[0].fncFeeType === 1 ? (Number(TempDataSplitFunding[0].afncFee) * 7).toFixed(2) : Number(TempDataSplitFunding[0].afncFee).toFixed(2);


                                if (DataPatientDraftInfo?.data?.admissionDetail?.isReserved === 1) {
                                    obj.admissiondate = props.admissiondate?.toString();
                                } else {
                                    obj.admissiondate = TempDataSplitFunding[0]?.admissiondate?.toString();
                                }

                                obj.totalAdditionalServiceFee = Number(TempDataSplitFunding[0]?.totalAdditionalServiceFee).toFixed(2);
                                obj.enddate = TempDataSplitFunding[0].enddate?.toString() || '';
                                obj.socialfund = TempDataSplitFunding[0].socialfund || 0;
                                obj.healthfund = TempDataSplitFunding[0].healthfund || 0;
                                obj.planId = TempDataSplitFunding[0].planId || 0;
                                obj.lastinvoicedate = TempDataSplitFunding[0].lastinvoicedate?.toString();

                                for (let i = 0; i < props.funderlist?.ListData?.length; i++) {
                                    let fid = props.funderlist?.ListData[i].funderData.ftype === '4' ? null : Number(props.funderlist?.ListData[i].funderData.fFunder);
                                    let tfid = TempDataSplitFunding[0]?.funderlist[i]?.funderid === null ? null : Number(TempDataSplitFunding[0]?.funderlist[i]?.funderid);
                                    if (fid === tfid || (fid === null && tfid === null)) {
                                        funders.push({
                                            planDetailId: TempDataSplitFunding[0].funderlist[i].planDetailId || 0,
                                            funderid: TempDataSplitFunding[0].funderlist[i].funderid,
                                            fundername: TempDataSplitFunding[0].funderlist[i].fundername,
                                            fundertype: TempDataSplitFunding[0].funderlist[i].fundertype,
                                            proportion: Number(TempDataSplitFunding[0].corefee),
                                            proportionvalue: Number(TempDataSplitFunding[0].corefee),
                                            isapplicable: TempDataSplitFunding[0].funderlist[i].isapplicable,
                                            isautopay: TempDataSplitFunding[0].funderlist[i].isautopay,
                                            ispercentage: TempDataSplitFunding[0].funderlist[i].ispercentage
                                        });
                                    } else {
                                        funders.push({
                                            planDetailId: 0,
                                            funderid: props.funderlist?.ListData[i].funderData.ftype !== '4' ? props.funderlist?.ListData[i].funderData.fFunder : null,
                                            fundername: props.funderlist?.ListData[i].funderData.fFunderVal,
                                            fundertype:
                                                props.funderlist?.ListData[i].funderData.ftype === '1'
                                                    ? 'ICB'
                                                    : props.funderlist?.ListData[i].funderData.ftype === '2'
                                                        ? 'LA'
                                                        : props.funderlist?.ListData[i].funderData.ftype === '3'
                                                            ? 'Charity'
                                                            : 'Self',
                                            proportion: Number(TempDataSplitFunding[0].corefee) === Number(finaltotalcorefee) ? Number(TempDataSplitFunding[0].corefee) : Number(finaltotalcorefee),
                                            proportionvalue: Number(TempDataSplitFunding[0].corefee) === Number(finaltotalcorefee) ? Number(TempDataSplitFunding[0].corefee) : Number(finaltotalcorefee),
                                            isapplicable: false,
                                            isautopay: true,
                                            ispercentage: false
                                        });
                                    }

                                    obj.funderlist = funders;
                                }
                                if (planstemp !== undefined) {
                                    plans = [...planstemp, obj];
                                    setplans(planstemp);
                                }
                            } else {

                                plans = [];
                                funders = [];
                                plans.push(TempDataSplitFunding[0]);
                                /* prod issue code */
                                for (let i = 0; i < props.funderlist?.ListData?.length; i++) {
                                    funders.push({
                                        planDetailId: TempDataSplitFunding[1]?.funderlist[i]?.planDetailId ? TempDataSplitFunding[1]?.funderlist[i]?.planDetailId : 0,
                                        funderid: props.funderlist?.ListData[i].funderData.ftype !== '4' ? props.funderlist?.ListData[i].funderData.fFunder : null,
                                        fundername: props.funderlist?.ListData[i].funderData.fFunderVal,
                                        fundertype:
                                            props.funderlist?.ListData[i].funderData.ftype === '1'
                                                ? 'ICB'
                                                : props.funderlist?.ListData[i].funderData.ftype === '2'
                                                    ? 'LA'
                                                    : props.funderlist?.ListData[i].funderData.ftype === '3'
                                                        ? 'Charity'
                                                        : 'Self',
                                        proportion: finaltotalcorefee,
                                        proportionvalue: finaltotalcorefee,
                                        isapplicable: false,
                                        isautopay: true,
                                        ispercentage: false,

                                    });
                                }
                                var date = new Date(TempDataSplitFunding[0].enddate);
                                date.setDate(date.getDate() + 1);
                                let obj: any = {
                                    corefee: Number(finaltotalcorefee),
                                    dailyCoreFee: Number(finalcorefee),
                                    continenceCareFee: !props.dailyOrWeeklyContinenceCareFeeToggle ? (Number(getValues('ContinenceCareFee')) * 7).toFixed(2) : Number(getValues('ContinenceCareFee')).toFixed(2),
                                    fncFee: !props.dailyOrWeeklyFNCFeeToggle ? (Number(getValues('FNCFee')) * 7).toFixed(2) : Number(getValues('FNCFee')).toFixed(2),

                                    admissiondate: TempDataSplitFunding[1]?.planId ? TempDataSplitFunding[1]?.admissiondate?.toString() : date.toString(),
                                    enddate: '',
                                    socialfund: getValues('Socialfunding'),
                                    healthfund: getValues('Healthfunding'),
                                    planId: TempDataSplitFunding[1]?.planId ? TempDataSplitFunding[1]?.planId : 0,
                                    funderlist: funders || [],
                                    totalAdditionalServiceFee: totaladditionalfee,
                                    continenceCareFeeType: props.dailyOrWeeklyContinenceCareFeeToggle ? 2 : 1,
                                    fncFeeType: props.dailyOrWeeklyFNCFeeToggle ? 2 : 1
                                };
                                plans.push(obj);
                                let cplan = JSON.parse(JSON.stringify(plans));
                                if (cplan[0].enddate !== "") {
                                    cplan[0].acontinenceCareFee = TempDataSplitFunding[0].acontinenceCareFee;
                                    cplan[0].afncFee = TempDataSplitFunding[0].afncFee;
                                    cplan[0].continenceCareFeeType = TempDataSplitFunding[0].continenceCareFeeType;
                                    cplan[0].fncFeeType = TempDataSplitFunding[0].fncFeeType;
                                    cplan[0].continenceCareFee = TempDataSplitFunding[0].continenceCareFeeType == 1 ? (Number(TempDataSplitFunding[0].acontinenceCareFee) * 7).toFixed(2) : Number(TempDataSplitFunding[0].acontinenceCareFee).toFixed(2);

                                    cplan[0].fncFee = TempDataSplitFunding[0].fncFeeType === 1 ? (Number(TempDataSplitFunding[0].afncFee) * 7).toFixed(2) : Number(TempDataSplitFunding[0].afncFee).toFixed(2);
                                }
                                setplans(cplan);
                                dispatch(setTempSplitFundingAction(cplan));
                                dispatch(setSplitFundingAction(cplan));
                            }
                        } else {
                            console.log("fall here");
                            plans = [];
                            funders = [];
                            plans.push(TempDataSplitFunding[0]);
                            /* prod issue code */
                            for (let i = 0; i < props.funderlist?.ListData?.length; i++) {
                                funders.push({
                                    planDetailId: TempDataSplitFunding[1]?.funderlist[i]?.planDetailId ? TempDataSplitFunding[1]?.funderlist[i]?.planDetailId : 0,
                                    funderid: props.funderlist?.ListData[i].funderData.ftype !== '4' ? props.funderlist?.ListData[i].funderData.fFunder : null,
                                    fundername: props.funderlist?.ListData[i].funderData.fFunderVal,
                                    fundertype:
                                        props.funderlist?.ListData[i].funderData.ftype === '1'
                                            ? 'ICB'
                                            : props.funderlist?.ListData[i].funderData.ftype === '2'
                                                ? 'LA'
                                                : props.funderlist?.ListData[i].funderData.ftype === '3'
                                                    ? 'Charity'
                                                    : 'Self',
                                    proportion: finaltotalcorefee,
                                    proportionvalue: finaltotalcorefee,
                                    isapplicable: false,
                                    isautopay: true,
                                    ispercentage: false,

                                });
                            }
                            var date = new Date(TempDataSplitFunding[0].enddate);
                            date.setDate(date.getDate() + 1);
                            let obj: any = {
                                corefee: Number(finaltotalcorefee),
                                dailyCoreFee: Number(finalcorefee),
                                continenceCareFee: !props.dailyOrWeeklyContinenceCareFeeToggle ? (Number(getValues('ContinenceCareFee')) * 7).toFixed(2) : Number(getValues('ContinenceCareFee')).toFixed(2),
                                fncFee: !props.dailyOrWeeklyFNCFeeToggle ? (Number(getValues('FNCFee')) * 7).toFixed(2) : Number(getValues('FNCFee')).toFixed(2),
                                admissiondate: TempDataSplitFunding[1]?.planId ? TempDataSplitFunding[1]?.admissiondate?.toString() : date.toString(),
                                enddate: '',
                                socialfund: getValues('Socialfunding'),
                                healthfund: getValues('Healthfunding'),
                                planId: TempDataSplitFunding[1]?.planId ? TempDataSplitFunding[1]?.planId : 0,
                                funderlist: funders || [],
                                totalAdditionalServiceFee: totaladditionalfee
                            };
                            plans.push(obj);
                            setplans(plans);
                            dispatch(setTempSplitFundingAction(plans));
                            dispatch(setSplitFundingAction(plans));
                        }
                    } else if (DataPatientDraftInfo?.data?.isInvoiceCreated !== 1) {
                        /* prod issue change code */                        
                        planstemp = [];
                        obj = {};
                        funders = [];

                        obj.corefee = Number(TempDataSplitFunding[k].corefee) === Number(finaltotalcorefee) ? Number(TempDataSplitFunding[k].corefee) : Number(finaltotalcorefee);

                        obj.dailyCoreFee = Number(finalcorefee);
                        obj.continenceCareFee = !props.dailyOrWeeklyContinenceCareFeeToggle
                            ? (Number(getValues('ContinenceCareFee')) * 7).toFixed(2)
                            : Number(getValues('ContinenceCareFee')).toFixed(2);

                        obj.fncFee = !props.dailyOrWeeklyFNCFeeToggle ? (Number(getValues('FNCFee')) * 7).toFixed(2) : Number(getValues('FNCFee')).toFixed(2);

                        if (DataPatientDraftInfo?.data?.admissionDetail?.isReserved === 1) {
                            obj.admissiondate = props.admissiondate?.toString();
                        } else {
                            obj.admissiondate =
                                DataPatientDraftInfo?.data?.referralId || DataPatientDraftInfo?.data?.isDraft === 1
                                    ? props.admissiondate.toString()
                                    : TempDataSplitFunding[k]?.admissiondate?.toString();
                        }
                        obj.enddate = TempDataSplitFunding[k].enddate?.toString() || '';
                        obj.socialfund = TempDataSplitFunding[k].socialfund || 0;
                        obj.healthfund = TempDataSplitFunding[k].healthfund || 0;
                        obj.planId = TempDataSplitFunding[k].planId || 0;
                        obj.lastinvoicedate = TempDataSplitFunding[k].lastinvoicedate?.toString();
                        obj.totalAdditionalServiceFee = totaladditionalfee;
                        for (let i = 0; i < props.funderlist?.ListData?.length; i++) {
                            let fid = props.funderlist?.ListData[i].funderData.ftype === '4' ? null : Number(props.funderlist?.ListData[i].funderData.fFunder);
                            // let tfid = TempDataSplitFunding[k]?.funderlist[i]?.funderid === null ? null : Number(TempDataSplitFunding[k]?.funderlist[i]?.funderid);

                            // if(fid === tfid || (fid === null && tfid === null)){
                            let funderexists = TempDataSplitFunding[k]?.funderlist?.find((val: any) => {
                                if (val.funderid === (fid !== null ? Number(fid) : null)) {
                                    return val;
                                }
                            });                            
                            if (funderexists !== undefined) {
                                // funders.push({planDetailId : TempDataSplitFunding[k].funderlist[i].planDetailId || 0,funderid : TempDataSplitFunding[k].funderlist[i].funderid, fundername : TempDataSplitFunding[k].funderlist[i].fundername,fundertype:TempDataSplitFunding[k].funderlist[i].fundertype ,proportion : Number(TempDataSplitFunding[k].corefee) === Number(finalcorefee) ?Number(TempDataSplitFunding[k].corefee) : Number(finalcorefee),proportionvalue:Number(TempDataSplitFunding[k].corefee) === Number(finalcorefee) ?Number(TempDataSplitFunding[k].corefee) : Number(finalcorefee),isapplicable:TempDataSplitFunding[k].funderlist[i].isapplicable,ispercentage:TempDataSplitFunding[k].funderlist[i].ispercentage});
                                funders.push({
                                    planDetailId: funderexists.planDetailId || 0,
                                    funderid: funderexists.funderid,
                                    fundername: funderexists.fundername,
                                    fundertype: funderexists.fundertype,
                                    proportion: Number(TempDataSplitFunding[k].corefee) === Number(finaltotalcorefee) ? Number(TempDataSplitFunding[k].corefee) : Number(finaltotalcorefee),
                                    proportionvalue: Number(TempDataSplitFunding[k].corefee) === Number(finaltotalcorefee) ? Number(TempDataSplitFunding[k].corefee) : Number(finaltotalcorefee),
                                    isapplicable: funderexists.isapplicable,
                                    isautopay: funderexists.isautopay,
                                    ispercentage: funderexists.ispercentage
                                });
                            } else {                                
                                funders.push({
                                    planDetailId: 0,
                                    funderid: props.funderlist?.ListData[i].funderData.ftype !== '4' ? props.funderlist?.ListData[i].funderData.fFunder : null,
                                    fundername: props.funderlist?.ListData[i].funderData.fFunderVal,
                                    fundertype:
                                        props.funderlist?.ListData[i].funderData.ftype === '1'
                                            ? 'ICB'
                                            : props.funderlist?.ListData[i].funderData.ftype === '2'
                                                ? 'LA'
                                                : props.funderlist?.ListData[i].funderData.ftype === '3'
                                                    ? 'Charity'
                                                    : 'Self',
                                    proportion: Number(TempDataSplitFunding[k].corefee) === Number(finaltotalcorefee) ? Number(TempDataSplitFunding[k].corefee) : Number(finaltotalcorefee),
                                    proportionvalue: Number(TempDataSplitFunding[k].corefee) === Number(finaltotalcorefee) ? Number(TempDataSplitFunding[k].corefee) : Number(finaltotalcorefee),
                                    isapplicable: false,
                                    isautopay: true,
                                    ispercentage: false
                                });
                            }

                            obj.funderlist = funders;
                        }
                        if (planstemp !== undefined) {
                            plans = [...plans, obj];
                            setplans(plans);
                        }
                    } else {                                                
                        planstemp = [];
                        planstemp.push(DataSplitFunding[0]);

                        obj = {};
                        funders = [];
                        obj.corefee = Number(finaltotalcorefee);
                        obj.dailyCoreFee = Number(finalcorefee);
                        obj.continenceCareFee = !props.dailyOrWeeklyContinenceCareFeeToggle
                            ? (Number(getValues('ContinenceCareFee')) * 7).toFixed(2)
                            : Number(getValues('ContinenceCareFee')).toFixed(2);
                        obj.fncFee = !props.dailyOrWeeklyFNCFeeToggle ? (Number(getValues('FNCFee')) * 7).toFixed(2) : Number(getValues('FNCFee')).toFixed(2);
                        var date = new Date(TempDataSplitFunding[0].enddate);
                        date.setDate(date.getDate() + 1);
                        if (DataPatientDraftInfo?.data?.admissionDetail?.isReserved === 1) {
                            obj.admissiondate = date?.toString();
                        } else {
                            obj.admissiondate = date?.toString();
                        }
                        obj.enddate = '';
                        obj.socialfund = getValues('Socialfunding');
                        obj.healthfund = getValues('Healthfunding');
                        obj.planId = PlanData?.data?.length === 2 ? PlanData?.data[1]?.planId : 0;
                        obj.totalAdditionalServiceFee = totaladditionalfee;

                        for (let i = 0; i < props.funderlist?.ListData?.length; i++) {
                            let fid = props.funderlist?.ListData[i].funderData.ftype === '4' ? null : Number(props.funderlist?.ListData[i].funderData.fFunder);
                            // let tfid = TempDataSplitFunding[0]?.funderlist[i]?.funderid === null ? null : Number(TempDataSplitFunding[0]?.funderlist[i]?.funderid);
                            // if (fid === tfid || (fid === null && tfid === null)) {
                            //     let plandid = 0;
                            //     if (PlanData?.data?.length === 2) {
                            //         PlanData?.data[1]?.planDetails?.map((val: any) => {
                            //             if (val.funderId === Number(fid)) {
                            //                 plandid = val.planDetailId;
                            //             }
                            //         });
                            //     }
                            let isexists = PlanData?.data[PlanData?.data?.length === 1 ? 0 : 1]?.planDetails?.find((val: any) => {
                                if (val.funderId === (fid !== null ? Number(fid) : null)) {
                                    return val;
                                }
                            });
                            if (isexists) {
                                let plandid = 0;
                                if (PlanData?.data?.length === 2) {
                                    PlanData?.data[1]?.planDetails?.map((val: any) => {
                                        if (val.funderId === (fid !== null ? Number(fid) : null)) {
                                            plandid = val.planDetailId;
                                        }
                                    });
                                }

                                funders.push({
                                    planDetailId: plandid,
                                    funderid: isexists?.funderId,
                                    fundername: isexists?.funderName,
                                    fundertype: isexists?.funderType === 1 ? 'ICB' : isexists?.funderType === 2 ? 'LA' : isexists?.funderType === 3 ? 'Charity' : 'Self',
                                    proportion: Number(finaltotalcorefee),
                                    proportionvalue: Number(finaltotalcorefee),
                                    isapplicable: isexists?.isApplicable === 1 ? false : true,
                                    isautopay: isexists?.isAutoPay,
                                    ispercentage: isexists?.amountType === 1 ? false : true
                                });
                            } else {
                                funders.push({
                                    planDetailId: 0,
                                    funderid: props.funderlist?.ListData[i].funderData.ftype !== '4' ? props.funderlist?.ListData[i].funderData.fFunder : null,
                                    fundername: props.funderlist?.ListData[i].funderData.fFunderVal,
                                    fundertype:
                                        props.funderlist?.ListData[i].funderData.ftype === '1'
                                            ? 'ICB'
                                            : props.funderlist?.ListData[i].funderData.ftype === '2'
                                                ? 'LA'
                                                : props.funderlist?.ListData[i].funderData.ftype === '3'
                                                    ? 'Charity'
                                                    : 'Self',
                                    proportion: Number(finaltotalcorefee),
                                    proportionvalue: Number(finaltotalcorefee),
                                    isapplicable: false,
                                    isautopay: true,
                                    ispercentage: false
                                });
                            }

                            obj.funderlist = funders;
                        }
                        plans = [...planstemp, obj];
                        setplans(planstemp);
                    }
                    if (planstemp !== undefined) {
                        dispatch(setTempSplitFundingAction(plans));
                        dispatch(setSplitFundingAction(plans));
                    }
                }
            } else {
                plans = [];
                for (let i = 0; i < props.funderlist?.ListData?.length; i++) {
                    funders.push({
                        planDetailId: 0,
                        funderid: props.funderlist?.ListData[i].funderData.ftype !== '4' ? props.funderlist?.ListData[i].funderData.fFunder : null,
                        fundername: props.funderlist?.ListData[i].funderData.fFunderVal,
                        fundertype:
                            props.funderlist?.ListData[i].funderData.ftype === '1'
                                ? 'ICB'
                                : props.funderlist?.ListData[i].funderData.ftype === '2'
                                    ? 'LA'
                                    : props.funderlist?.ListData[i].funderData.ftype === '3'
                                        ? 'Charity'
                                        : 'Self',
                        proportion: finaltotalcorefee,
                        proportionvalue: finaltotalcorefee,
                        isapplicable: false,
                        isautopay: true,
                        ispercentage: false,

                    });
                }
                let obj: any = {
                    corefee: Number(finaltotalcorefee),
                    dailyCoreFee: Number(finalcorefee),
                    continenceCareFee: !props.dailyOrWeeklyContinenceCareFeeToggle ? (Number(getValues('ContinenceCareFee')) * 7).toFixed(2) : Number(getValues('ContinenceCareFee')).toFixed(2),
                    fncFee: !props.dailyOrWeeklyFNCFeeToggle ? (Number(getValues('FNCFee')) * 7).toFixed(2) : Number(getValues('FNCFee')).toFixed(2),
                    admissiondate: props.admissiondate.toString(),
                    enddate: '',
                    socialfund: getValues('Socialfunding'),
                    healthfund: getValues('Healthfunding'),
                    planId: 0,
                    funderlist: funders || [],
                    totalAdditionalServiceFee: totaladditionalfee,

                };
                plans.push(obj);
                setplans(plans);
                dispatch(setTempSplitFundingAction(plans));
                dispatch(setSplitFundingAction(plans));
            }
        } else if (finalcorefee !== '' && props.funderlist?.ListData?.length > 1 && DataPatientDraftInfo !== '') {
            // dispatch(removeSplitFundingAction());            
            if (DataPatientDraftInfo?.data?.isInvoiceCreated === 1) {
                console.log(DataSplitFunding.length);
                if (TempDataSplitFunding.length === 2) {
                    console.log('in length 2');
                    if (TempDataSplitFunding[1]?.funderlist?.length === props.funderlist?.ListData?.length) {
                        if (TempDataSplitFunding[1]?.planId !== -1) {
                            props.splitchange(true);
                        }
                        else {
                            props.splitchange(false);
                        }

                        let oldplan = JSON.parse(JSON.stringify(TempDataSplitFunding));
                        // let temp = removepreplan.pop();
                        // console.log(removepreplan);

                        oldplan[0].continenceCareFee = TempDataSplitFunding[0].continenceCareFeeType === 1
                            ? (Number(TempDataSplitFunding[0].acontinenceCareFee) * 7).toFixed(2)
                            : Number(TempDataSplitFunding[0].acontinenceCareFee).toFixed(2);

                        oldplan[0].fncFee = TempDataSplitFunding[0].fncFeeType ? (Number(TempDataSplitFunding[0].afncFee) * 7).toFixed(2) : Number(TempDataSplitFunding[0].afncFee).toFixed(2);


                        oldplan[1].continenceCareFee = !props.dailyOrWeeklyContinenceCareFeeToggle
                            ? (Number(getValues('ContinenceCareFee')) * 7).toFixed(2)
                            : Number(getValues('ContinenceCareFee')).toFixed(2);
                        oldplan[1].fncFee = !props.dailyOrWeeklyFNCFeeToggle ? (Number(getValues('FNCFee')) * 7).toFixed(2) : Number(getValues('FNCFee')).toFixed(2);

                        oldplan[1].corefee = Number(finaltotalcorefee);
                        oldplan[1].totalFees = Number(finaltotalcorefee);
                        oldplan[1].dailyCoreFee = Number(finalcorefee);
                        oldplan[1].totalAdditionalServiceFee = totaladditionalfee;
                        if (props?.draftData?.isReserved === 1) {
                            oldplan[1].admissiondate = props.admissiondate?.toString();
                        }
                        dispatch(setTempSplitFundingAction(oldplan));
                        // dispatch(setSplitFundingAction(oldplan));
                    } else {
                        props.splitchange(false);
                        let removepreplan = JSON.parse(JSON.stringify(TempDataSplitFunding));
                        let temp = removepreplan.pop();
                        console.log(removepreplan);

                        removepreplan[0].continenceCareFee = TempDataSplitFunding[0].continenceCareFeeType === 1
                            ? (Number(TempDataSplitFunding[0].acontinenceCareFee) * 7).toFixed(2)
                            : Number(TempDataSplitFunding[0].acontinenceCareFee).toFixed(2);

                        removepreplan[0].fncFee = TempDataSplitFunding[0].fncFeeType ? (Number(TempDataSplitFunding[0].afncFee) * 7).toFixed(2) : Number(TempDataSplitFunding[0].afncFee).toFixed(2);
                        if (removepreplan.length === 2) {
                            removepreplan[1].continenceCareFee = !props.dailyOrWeeklyContinenceCareFeeToggle
                                ? (Number(getValues('ContinenceCareFee')) * 7).toFixed(2)
                                : Number(getValues('ContinenceCareFee')).toFixed(2);
                            removepreplan[1].fncFee = !props.dailyOrWeeklyFNCFeeToggle ? (Number(getValues('FNCFee')) * 7).toFixed(2) : Number(getValues('FNCFee')).toFixed(2);
                            removepreplan[1].corefee = Number(finaltotalcorefee);
                            removepreplan[1].totalFees = Number(finaltotalcorefee);
                            removepreplan[1].dailyCoreFee = Number(finalcorefee);
                            removepreplan[1].totalAdditionalServiceFee = totaladditionalfee;
                        }

                        if (props?.draftData?.isReserved === 1) {
                            removepreplan[0].admissiondate = props.admissiondate?.toString();
                        }
                        dispatch(setTempSplitFundingAction(removepreplan));
                        // dispatch(setSplitFundingAction(removepreplan));
                    }


                } else {
                    console.log('three funder to two funder');
                    if (TempDataSplitFunding[0]?.funderlist?.length === props.funderlist?.ListData?.length) {
                        if (TempDataSplitFunding[0]?.planId !== -1) {
                            props.splitchange(true);
                        }
                        else {
                            props.splitchange(false);
                        }
                    } else {
                        props.splitchange(false);
                    }
                    // props.seteditflag(true);
                    let previousplan = JSON.parse(JSON.stringify(TempDataSplitFunding));
                    if (previousplan[0].enddate === "") {
                        previousplan[0].continenceCareFee = !props.dailyOrWeeklyContinenceCareFeeToggle ? (Number(TempDataSplitFunding[0].acontinenceCareFee) * 7).toFixed(2) : Number(TempDataSplitFunding[0].continenceCareFee).toFixed(2);
                        previousplan[0].fncFee = !props.dailyOrWeeklyFNCFeeToggle ? (Number(TempDataSplitFunding[0].afncFee) * 7).toFixed(2) : Number(TempDataSplitFunding[0].fncFee).toFixed(2);
                    }
                    if (props?.draftData?.isReserved === 1) {
                        previousplan[0].admissiondate = props.admissiondate?.toString();
                    }
                    console.log(previousplan);
                    dispatch(setTempSplitFundingAction(previousplan));
                    // dispatch(setSplitFundingAction(previousplan));
                }
            }



            else {
                if (TempDataSplitFunding.length === 2) {
                    console.log('in length 2');
                    if (TempDataSplitFunding[1]?.funderlist?.length === props.funderlist?.ListData?.length) {
                        if (TempDataSplitFunding[1]?.planId !== -1) {
                            props.splitchange(true);
                        }
                        else {
                            props.splitchange(false);
                        }
                        let oldplan = JSON.parse(JSON.stringify(TempDataSplitFunding));
                        // let temp = removepreplan.pop();
                        // console.log(removepreplan);
                        if (props?.draftData?.isReserved === 1) {
                            oldplan[1].admissiondate = props.admissiondate?.toString();
                        }
                        dispatch(setTempSplitFundingAction(oldplan));
                        // dispatch(setSplitFundingAction(oldplan));
                    } else {
                        props.splitchange(false);
                        let removepreplan = JSON.parse(JSON.stringify(TempDataSplitFunding));
                        let temp = removepreplan.pop();
                        console.log(removepreplan);
                        if (props?.draftData?.isReserved === 1) {
                            removepreplan[0].admissiondate = props.admissiondate?.toString();
                        }
                        dispatch(setTempSplitFundingAction(removepreplan));
                        // dispatch(setSplitFundingAction(removepreplan));
                    }


                } else {
                    console.log('three funder to two funder');
                    if (TempDataSplitFunding[0]?.funderlist?.length === props.funderlist?.ListData?.length) {
                        if (TempDataSplitFunding[0]?.planId !== -1) {
                            props.splitchange(true);
                        } else {
                            props.splitchange(false);
                        }
                    } else {
                        props.splitchange(false);
                    }
                    // props.seteditflag(true);
                    let previousplan = JSON.parse(JSON.stringify(TempDataSplitFunding));
                    if (previousplan.length > 0) {
                        previousplan[0].continenceCareFee = !props.dailyOrWeeklyContinenceCareFeeToggle
                            ? (Number(getValues('ContinenceCareFee')) * 7).toFixed(2)
                            : Number(getValues('ContinenceCareFee')).toFixed(2);
                        previousplan[0].fncFee = !props.dailyOrWeeklyFNCFeeToggle ? (Number(getValues('FNCFee')) * 7).toFixed(2) : Number(getValues('FNCFee')).toFixed(2);
                    }

                    if (props?.draftData?.isReserved === 1) {
                        previousplan[0].admissiondate = props.admissiondate?.toString();
                    } else {
                        if (previousplan.length > 0) {
                            previousplan[0].admissiondate =
                                DataPatientDraftInfo?.data?.referralId || DataPatientDraftInfo?.data?.isDraft === 1
                                    ? props.admissiondate.toString()
                                    : previousplan[0]?.admissiondate?.toString();
                        }
                    }
                    dispatch(setTempSplitFundingAction(previousplan));
                    // dispatch(setSplitFundingAction(previousplan));
                }
            }
        }

    }, [
        props.funderlist,
        props.dailyweek,
        props.dailyfee,
        props.totaldailyfee,
        finalcorefee,
        DataPatientDraftInfo,
        istemp,
        props.admissiondate,
        finaltotalcorefee,
        finalcontinencefee,
        finalfncfee,
        finaladditionalfee1,
        finaladditionalfee2,
        finaladditionalfee3,
        finaladditionalfee4,
        totaladditionalfee
    ]);

    useEffect(() => {
        props.updateadditionalfee(totaladditionalfee)
    }, [totaladditionalfee])

    useEffect(() => {        
        if (TempDataSplitFunding.length > 0) {
            setistemp(true);
        }
    }, [TempDataSplitFunding]);

    const getInclusiondata = async () => {
        let res = await getInclusiondisplay(DataServiceDetail.serviceId);
        if (res.status) {
            setinclusiondata(res.data);
        }
    }

    const validationSchema = Yup.object().shape({
        DailyOrWeekly: Yup.boolean().notRequired(),
        In1DailyOrWeekly: Yup.boolean().notRequired(),
        In2DailyOrWeekly: Yup.boolean().notRequired(),
        In3DailyOrWeekly: Yup.boolean().notRequired(),
        In4DailyOrWeekly: Yup.boolean().notRequired(),
        TotalFeeDailyOrWeekly: Yup.boolean().notRequired(),
        TotalDailyOrWeekly: Yup.boolean().notRequired(),
        DailyOrWeeklyContinenceCareFee: Yup.boolean().notRequired(),
        DailyOrWeeklyFNCCareFee: Yup.boolean().notRequired(),
        TotalDailyFee: Yup.string().required(),
        TotalDailyFeeday: Yup.string().notRequired(),
        ContinenceInclude: Yup.boolean().notRequired(),
        FNCInclude: Yup.boolean().notRequired(),
        DailyFee: Yup.string()
            .matches(/^\d{0,8}(\.\d{1,2})?$/)
            .strict(true)
            .required('DailyFee required'),
        ContinenceCareFee: Yup.string()
            .matches(/^\d{0,8}(\.\d{1,2})?$/)
            .nullable()
            .notRequired(),
        FNCFee: Yup.string()
            .matches(/^\d{0,8}(\.\d{1,2})?$/)
            .nullable()
            .notRequired(),
        AdditionalFeeH1: Yup.string().when('Inclusion1', {
            is: '',
            then: (schema: any) => schema.matches(/^\d{0,8}(\.\d{1,2})?$/).nullable().notRequired(),
            otherwise: (schema: any) => schema.matches(/^\d{0,8}(\.\d{1,2})?$/).nonNullable().required()
        }),
        AdditionalFeeH2: Yup.string().when('Inclusion2', {
            is: '',
            then: (schema: any) => schema.matches(/^\d{0,8}(\.\d{1,2})?$/).nullable().notRequired(),
            otherwise: (schema: any) => schema.matches(/^\d{0,8}(\.\d{1,2})?$/).nonNullable().required()
        }),
        AdditionalFeeH3: Yup.string().when('Inclusion3', {
            is: '',
            then: (schema: any) => schema.matches(/^\d{0,8}(\.\d{1,2})?$/).nullable().notRequired(),
            otherwise: (schema: any) => schema.matches(/^\d{0,8}(\.\d{1,2})?$/).nonNullable().required()
        }),
        AdditionalFeeH4: Yup.string().when('Inclusion4', {
            is: '',
            then: (schema: any) => schema.matches(/^\d{0,8}(\.\d{1,2})?$/).nullable().notRequired(),
            otherwise: (schema: any) => schema.matches(/^\d{0,8}(\.\d{1,2})?$/).nonNullable().required()
        }),
        Inclusion1: Yup.string()
            .matches(/^$|^\d+(\.\d{1,2})?$/)
            .nullable()
            .notRequired().default(''),
        Inclusion1included: Yup.boolean().notRequired(),
        Inclusion2: Yup.string()
            .matches(/^$|^\d+(\.\d{1,2})?$/)
            .nullable()
            .notRequired().default(''),
        Inclusion2included: Yup.boolean().notRequired(),
        Inclusion3: Yup.string()
            .matches(/^$|^\d+(\.\d{1,2})?$/)
            .nullable()
            .notRequired().default(''),
        Inclusion3included: Yup.boolean().notRequired(),
        Inclusion4: Yup.string()
            .matches(/^$|^\d+(\.\d{1,2})?$/)
            .nullable()
            .notRequired().default(''),
        Inclusion4included: Yup.boolean().notRequired(),
        Socialfunding: Yup.string()
            .matches(/^$|^[0-9]+(\.[0-9]{1,2})?$/)
            .nullable()
            .notRequired(),
        Healthfunding: Yup.string()
            .matches(/^$|^[0-9]+(\.[0-9]{1,2})?$/)
            .nullable()
            .notRequired(),
        isSplitFunding: Yup.boolean().notRequired()
    });
    const { register, control, handleSubmit, getValues, reset, formState, setValue, getFieldState, formState: { isDirty } } = useForm({ mode: 'all', resolver: yupResolver(validationSchema) });
    const { errors } = formState;

    console.log(errors);

    useEffect(() => {
        reset();
        // if (props?.draftData?.isReserved !== 1) {
        //     setTimeout(() => {
        //         props.splitchange(true); // Janak had commented this line.
        //     }, 3500);
        // }

        getInclusiondata();
    }, []);

    useEffect(() => {                
        if (!istotalfeevalid && formState.isValid) {
            props.getapplicableerror(true);
        } else {
            if (DataPatientDraftInfo !== "" && DataPatientDraftInfo?.data?.isFromLeadPooling === false) {
                props.getapplicableerror(true);
            } else {
                props.getapplicableerror(false);
            }

        }
        // props.getapplicableerror(formState.isValid);
    }, [formState, istotalfeevalid]);

    // useEffect(()=>{
    //   if(!istotalfeevalid){
    //     props.getapplicableerror(true);
    //   } else{
    //     props.getapplicableerror(false);
    //   }
    // },[istotalfeevalid])

    useEffect(() => {
        setValue('DailyOrWeekly', props?.dailyOrWeeklyToggle);
        setValue('In1DailyOrWeekly', props?.In1dailyOrWeeklyToggle);
        setValue('In2DailyOrWeekly', props?.In2dailyOrWeeklyToggle);
        setValue('In3DailyOrWeekly', props?.In3dailyOrWeeklyToggle);
        setValue('In4DailyOrWeekly', props?.In4dailyOrWeeklyToggle);
        setValue('DailyOrWeeklyContinenceCareFee', props?.dailyOrWeeklyContinenceCareFeeToggle);
        setValue('DailyOrWeeklyFNCCareFee', props?.dailyOrWeeklyFNCFeeToggle);
        setValue('FNCInclude', props?.FNCInclude);
        setValue('ContinenceInclude', props?.ContinenceInclude);
        setValue('TotalDailyOrWeekly', props.TotalDailyOrWeekly);
        if (props.TotalDailyOrWeekly === false) {
            setcaladditionalfee(Number(totaladditionalfee / 7).toFixed(2));
        }
        setValue('TotalFeeDailyOrWeekly', props.TotalFeeDailyOrWeekly);
        if (props.TotalFeeDailyOrWeekly === false) {
            setcaltotalfee(Number(finaltotalcorefee / 7).toFixed(2));
            setValue('TotalDailyFeeday', (Number(finaltotalcorefee / 7).toFixed(2)));
        }
    }, [
        props?.In1dailyOrWeeklyToggle,
        props?.In2dailyOrWeeklyToggle,
        props?.In3dailyOrWeeklyToggle,
        props?.In4dailyOrWeeklyToggle,
        props.dailyOrWeeklyFNCFeeToggle,
        props.FNCInclude,
        props.ContinenceInclude,
        props.TotalDailyOrWeekly,
        props.TotalFeeDailyOrWeekly,
        totaladditionalfee,
        finaltotalcorefee
    ]);
    useEffect(() => {
        if (!props?.manualUpdate) {
            setValue('AdditionalFeeH1', props?.AdditionalFeeH1?.toString() || '', { shouldValidate: true });
            setValue('AdditionalFeeH2', props?.AdditionalFeeH2?.toString() || '', { shouldValidate: true });
            setValue('AdditionalFeeH3', props?.AdditionalFeeH3?.toString() || '', { shouldValidate: true });
            setValue('AdditionalFeeH4', props?.AdditionalFeeH4?.toString() || '', { shouldValidate: true });
        }
    }, [props?.AdditionalFeeH1])

    useEffect(() => {
        if (props.draftData !== undefined) {
            setValue('DailyOrWeekly', props.draftData.coreFeesType === coreFeesType.Daily ? false : true);
            setValue('In1DailyOrWeekly', props.draftData.inclusionHoursType1 === coreFeesType.Daily ? false : true);
            setValue('In2DailyOrWeekly', props.draftData.inclusionHoursType2 === coreFeesType.Daily ? false : true);
            setValue('In3DailyOrWeekly', props.draftData.inclusionHoursType3 === coreFeesType.Daily ? false : true);
            setValue('In4DailyOrWeekly', props.draftData.inclusionHoursType4 === coreFeesType.Daily ? false : true);
            setValue('DailyFee', props.draftData.dailyCoreFee?.toString() || '', { shouldValidate: true });
            setValue('AdditionalFeeH1', props.draftData.hourlyFee1?.toString() || '', { shouldValidate: true });
            setValue('AdditionalFeeH2', props.draftData.hourlyFee2?.toString() || '', { shouldValidate: true });
            setValue('AdditionalFeeH3', props.draftData.hourlyFee3?.toString() || '', { shouldValidate: true });
            setValue('AdditionalFeeH4', props.draftData.hourlyFee4?.toString() || '', { shouldValidate: true });

            setValue('Inclusion1', props.draftData.inclusionHours1?.toString() || '', { shouldValidate: true });
            setValue('Inclusion2', props.draftData.inclusionHours2?.toString() || '', { shouldValidate: true });
            setValue('Inclusion3', props.draftData.inclusionHours3?.toString() || '', { shouldValidate: true });
            setValue('Inclusion4', props.draftData.inclusionHours4?.toString() || '', { shouldValidate: true });
            setValue('Socialfunding', props.draftData.socialFunding?.toString() || '', { shouldValidate: true });
            setValue('Healthfunding', props.draftData.healthFunding?.toString() || '', { shouldValidate: true });

            setValue('DailyOrWeeklyContinenceCareFee', props.draftData.continenceCareFeeType === coreFeesType.Daily ? false : true);
            setValue('ContinenceCareFee', props.draftData.continenceCareFee?.toString() || '', { shouldValidate: false });
            setValue('isSplitFunding', props.draftData.isSplitFundingOninvoice === 1 ? true : false);
            setValue('FNCFee', props.draftData?.fncFee);
        }
        else {
            reset();
        }
    }, [props.draftData]);


    return (
        <div className="accordion accordion-flush  mt-3" id="rate-accordion">
            <div className="accordion-item white mt-0">
                <h2 className="accordion-header" id="rate">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#funder-rate" aria-expanded="false" aria-controls="funder-rate">
                        {t('VacantRightbar.applicable')}
                    </button>
                </h2>
                <div id="funder-rate" className="accordion-collapse collapse" aria-labelledby="rate" data-bs-parent="#rate-accordion">
                    <div className="accordion-body">
                        <div className="funder-detail">
                            <form style={{ pointerEvents: props.iseditable ? 'all' : 'none' }}>
                                <div>
                                    <div className="border-box">
                                        <label className="bold mb-2">{t('VacantRightbar.total_core')}</label>
                                        <div className="d-flex ">
                                            <div className="d-flex align-items-center">
                                                <span className="me-1">&pound;</span>
                                                {props.TotalFeeDailyOrWeekly &&
                                                    <input
                                                        type="text"
                                                        {...register('TotalDailyFee', {
                                                            onChange: (e: any) => {
                                                                props.updateAData(e);
                                                            }
                                                        })}
                                                        disabled={true}
                                                        name={'TotalDailyFee' as const}
                                                        //@ts-ignore
                                                        className={`form-control white custom-width-input-app`}
                                                    />
                                                }
                                                {props.TotalFeeDailyOrWeekly === false &&
                                                    <input
                                                        type="text"
                                                        {...register('TotalDailyFeeday', {
                                                            onChange: (e: any) => {
                                                                props.updateAData(e);
                                                            }
                                                        })}
                                                        disabled={true}
                                                        name={'TotalDailyFeeday' as const}
                                                        //@ts-ignore
                                                        className={`form-control white custom-width-input-app`}
                                                    />
                                                }
                                                <p className="ms-1"> / {props.TotalFeeDailyOrWeekly === false ? 'Day' : 'Week'}</p>
                                            </div>
                                            {inclusiondata?.isTotalFeeToggleVisible === 1 &&
                                                <div className="d-flex justify-content-end align-items-center ms-3">
                                                    <p className="me-1 bold small">{t('VacantRightbar.day')}</p>
                                                    <label className="switch-custom">
                                                        <input
                                                            type="checkbox"
                                                            {...register('TotalFeeDailyOrWeekly', {
                                                                onChange: (e: any) => {
                                                                    props.updateAData(e);
                                                                }
                                                            })}
                                                        />
                                                        <span className="slider round"></span>
                                                    </label>
                                                    <p className="ms-1 bold small">{t('VacantRightbar.week')}</p>
                                                </div>
                                            }
                                        </div>

                                        <label className="bold mb-2 mt-3">{t('VacantRightbar.coref')}</label>
                                        <div className="d-flex">
                                            <div className="d-flex align-items-center">
                                                <span className="me-1">&pound;</span>
                                                <input
                                                    type="text"
                                                    {...register('DailyFee', {
                                                        onChange: (e: any) => {
                                                            props.updateAData(e);
                                                        }
                                                    })}
                                                    name={'DailyFee' as const}
                                                    onKeyDown={(e: any) => {
                                                        const cursorPosition = e.target.selectionStart;
                                                        if (
                                                            e.key === 'ArrowLeft' ||
                                                            e.key === 'ArrowRight' ||
                                                            e.key === 'Backspace' ||
                                                            e.key === 'Delete' ||
                                                            e.key === 'Tab' ||
                                                            (e.key === 'a' && e.ctrlKey) // Ctrl+A
                                                        ) {
                                                            // Allow the default behavior for these keys
                                                            return;
                                                        } else {
                                                            if (
                                                                window.$utils.onKeypressValidateApplicable(
                                                                    'number',
                                                                    e.target.value.substring(0, cursorPosition) + e.key + e.target.value.substring(cursorPosition)
                                                                ) === false
                                                            ) {
                                                                e.preventDefault();
                                                            }
                                                        }
                                                    }}
                                                    //@ts-ignore
                                                    className={`form-control white custom-width-input-app ${errors.DailyFee ? 'is-invalid' : ''}`}
                                                />
                                                <p className="ms-1"> / {props?.dailyOrWeeklyToggle === false ? 'Day' : 'Week'}</p>
                                            </div>
                                            <div className="d-flex justify-content-center align-items-center ms-3">
                                                <p className="me-1 small bold">{t('VacantRightbar.day')}</p>
                                                <label className="switch-custom">
                                                    <input
                                                        type="checkbox"
                                                        {...register('DailyOrWeekly', {
                                                            onChange: (e: any) => {
                                                                props.updateAData(e);
                                                            }
                                                        })}
                                                    />
                                                    <span className="slider round"></span>
                                                </label>
                                                <p className="ms-1 small bold">{t('VacantRightbar.week')}</p>
                                            </div>
                                        </div>
                                        {inclusiondata?.isContinenceCareFeeVisible === 1 &&
                                            <div className="mt-3">
                                                <label className="bold mb-1">{t('VacantRightbar.Continence_Care_Fee')}</label>
                                                <br />
                                                {inclusiondata?.isContinenceCareFeeInclusionVisible === 1 &&
                                                    <label className="mt-1 mb-3">
                                                        <span className="d-flex align-items-center justify-content-center">
                                                            <input
                                                                type="checkbox"
                                                                {...register('ContinenceInclude', {
                                                                    onChange: (e: any) => {
                                                                        props.updateAData(e);
                                                                    }
                                                                })}
                                                                disabled={finalcorefee !== 0 ? false : true}
                                                                className="me-2"
                                                            />{' '}
                                                            Included in Core Fee
                                                        </span>
                                                    </label>
                                                }
                                                <div className="d-flex">
                                                    <div className="d-flex align-items-center">
                                                        <span className="me-1">&pound;</span>
                                                        <input
                                                            type="text"
                                                            {...register('ContinenceCareFee', {
                                                                onChange: (e: any) => {
                                                                    props.updateAData(e);
                                                                }
                                                            })}
                                                            onKeyDown={(e: any) => {
                                                                const cursorPosition = e.target.selectionStart;
                                                                if (
                                                                    e.key === 'ArrowLeft' ||
                                                                    e.key === 'ArrowRight' ||
                                                                    e.key === 'Backspace' ||
                                                                    e.key === 'Delete' ||
                                                                    e.key === 'Tab' ||
                                                                    (e.key === 'a' && e.ctrlKey) // Ctrl+A
                                                                ) {
                                                                    // Allow the default behavior for these keys
                                                                    return;
                                                                } else {
                                                                    if (
                                                                        window.$utils.onKeypressValidateApplicable(
                                                                            'number',
                                                                            e.target.value.substring(0, cursorPosition) + e.key + e.target.value.substring(cursorPosition)
                                                                        ) === false
                                                                    ) {
                                                                        e.preventDefault();
                                                                    }
                                                                }
                                                            }}
                                                            disabled={finalcorefee !== 0 ? false : true}
                                                            name={'ContinenceCareFee' as const}
                                                            className={`form-control white custom-width-input-app ${errors.ContinenceCareFee ? 'is-invalid' : ''}`}
                                                        />
                                                        <p className="ms-1"> / {props?.dailyOrWeeklyContinenceCareFeeToggle === false ? 'Day' : 'Week'}</p>
                                                    </div>
                                                    <div className="d-flex justify-content-center align-items-center ms-3">
                                                        <p className="me-1 small bold">{t('VacantRightbar.day')}</p>
                                                        <label className="switch-custom">
                                                            <input
                                                                type="checkbox"
                                                                {...register('DailyOrWeeklyContinenceCareFee', {
                                                                    onChange: (e: any) => {
                                                                        props.updateAData(e);
                                                                    }
                                                                })}
                                                            />
                                                            <span className="slider round"></span>
                                                        </label>
                                                        <p className="ms-1 small bold">{t('VacantRightbar.week')}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                        {inclusiondata?.isFncFeeVisible === 1 &&
                                            <div className="mt-3">
                                                <label className="bold mb-2">{t('VacantRightbar.fnc')}</label>
                                                <br />
                                                {inclusiondata?.isFncFeeInclusionVisible === 1 &&
                                                    <label className="mt-1 mb-3">
                                                        <span className="d-flex align-items-center justify-content-center">
                                                            <input
                                                                type="checkbox"
                                                                {...register('FNCInclude', {
                                                                    onChange: (e: any) => {
                                                                        props.updateAData(e);
                                                                    }
                                                                })}
                                                                disabled={finalcorefee !== 0 ? false : true}
                                                                className="me-2"
                                                            />{' '}
                                                            Included in Core Fee
                                                        </span>
                                                    </label>
                                                }
                                                <div className="d-flex">
                                                    <div className="d-flex align-items-center">
                                                        <span className="me-1">&pound;</span>
                                                        <input
                                                            type="text"
                                                            {...register('FNCFee', {
                                                                onChange: (e: any) => {
                                                                    props.updateAData(e);
                                                                }
                                                            })}
                                                            name={'FNCFee' as const}
                                                            onKeyDown={(e: any) => {
                                                                const cursorPosition = e.target.selectionStart;
                                                                if (
                                                                    e.key === 'ArrowLeft' ||
                                                                    e.key === 'ArrowRight' ||
                                                                    e.key === 'Backspace' ||
                                                                    e.key === 'Delete' ||
                                                                    e.key === 'Tab' ||
                                                                    (e.key === 'a' && e.ctrlKey) // Ctrl+A
                                                                ) {
                                                                    // Allow the default behavior for these keys
                                                                    return;
                                                                } else {
                                                                    if (
                                                                        window.$utils.onKeypressValidateApplicable(
                                                                            'number',
                                                                            e.target.value.substring(0, cursorPosition) + e.key + e.target.value.substring(cursorPosition)
                                                                        ) === false
                                                                    ) {
                                                                        e.preventDefault();
                                                                    }
                                                                }
                                                            }}
                                                            disabled={finalcorefee !== 0 ? false : true}
                                                            className={`form-control white custom-width-input-app ${errors.FNCFee ? 'is-invalid' : ''}`}
                                                        />
                                                        <p className="ms-1"> / {props?.dailyOrWeeklyFNCFeeToggle === false ? 'Day' : 'Week'}</p>
                                                    </div>
                                                    <div className="d-flex justify-content-center align-items-center ms-3">
                                                        <p className="me-1 small bold">{t('VacantRightbar.day')}</p>
                                                        <label className="switch-custom">
                                                            <input
                                                                type="checkbox"
                                                                {...register('DailyOrWeeklyFNCCareFee', {
                                                                    onChange: (e: any) => {
                                                                        props.updateAData(e);
                                                                    }
                                                                })}
                                                            />
                                                            <span className="slider round"></span>
                                                        </label>
                                                        <p className="ms-1 small bold">{t('VacantRightbar.week')}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                        {/* {props.funderlist !== undefined && props.funderlist?.ListData?.length >= 2 && <>
                                        <div className='mt-3'>
                                            <div className='d-flex align-items-center  mb-2'>
                                                <label className=" me-1">{t('RightSidebarContraclList.Health_Funding')}:</label>
                                                <span className="me-1">&pound;</span>
                                                <input
                                                    type="text"
{...register('Healthfunding', {
                                                        onChange: (e: any) => {
props.updateAData(e);
                                                        }
                                                    })}
name={'Healthfunding' as const}
className="form-control white custom-width-input" />
                                            </div>
                                            <div className='d-flex align-items-center'>
                                                <label className=" me-2">{t('RightSidebarContraclList.Social_Funding')}:</label>
                                                <span className="me-1">&pound;</span>
                                                <input
                                                     type="text"
{...register('Socialfunding', {
                                                         onChange: (e: any) => {
props.updateAData(e);
                                                         }
                                                     })}
name={'Socialfunding' as const}
className="form-control white custom-width-input" />
                                            </div>

                                        </div>
                                        </>} */}
                                    </div>


                                    {/* <span> / week</span> */}
                                    {(inclusiondata?.is1to1AdditionalFeeVisible === 1 || inclusiondata?.is1to2AdditionalFeeVisible === 1 || inclusiondata?.is1to3AdditionalFeeVisible === 1 || inclusiondata?.is1to4AdditionalFeeVisible === 1) &&
                                        <>
                                            <div className="d-flex justify-content-between align-items-center">
                                                <div>
                                                    <label className="mt-2 mb-2 bold">{t('VacantRightbar.addfee')}</label>
                                                    <label className="mt-2 mb-2 ms-1 bold"><span className="me-1">£</span>{props.TotalDailyOrWeekly === true ? totaladditionalfee : caladditionalfee}</label>
                                                </div>
                                                {inclusiondata?.isAdditionalFeeToggleVisible === 1 &&
                                                    <>
                                                        <div className='d-flex'>
                                                            <p className="me-1 bold small">{t('VacantRightbar.day')}</p>
                                                            <label className="switch-custom">
                                                                <input
                                                                    type="checkbox"
                                                                    {...register('TotalDailyOrWeekly', {
                                                                        onChange: (e: any) => {
                                                                            props.updateAData(e);
                                                                        }
                                                                    })}
                                                                />
                                                                <span className="slider round"></span>
                                                            </label>
                                                            <p className="ms-1 bold small">{t('VacantRightbar.week')}</p>
                                                        </div>
                                                    </>
                                                }
                                            </div>
                                            <table width="100%" className="rate-table">
                                                <tbody>
                                                    <tr>
                                                        <th colSpan={1}>{t('VacantRightbar.service')}</th>
                                                        <th colSpan={1} className="padding-left">{t('VacantRightbar.rate')}</th>
                                                        <th colSpan={1} className="text-center">{t('VacantRightbar.inc')}</th>
                                                        <th colSpan={1} className="text-center">{t('VacantRightbar.dayweek')}</th>
                                                    </tr>
                                                    {inclusiondata?.is1to1AdditionalFeeInclusionVisible === 1 && inclusiondata?.is1to1AdditionalFeeVisible === 1 &&
                                                        <>
                                                            <tr>
                                                                <td colSpan={3} className='pb-0 pt-1'>
                                                                    <p>
                                                                        <label className="mt-1">
                                                                            <span className="d-flex align-items-center justify-content-center">
                                                                                <input
                                                                                    type="checkbox"
                                                                                    {...register('Inclusion1included', {
                                                                                        onChange: (e: any) => {
                                                                                            props.updateAData(e);
                                                                                        }
                                                                                    })}

                                                                                    className="me-2"
                                                                                />{' '}
                                                                                Included in Core Fee
                                                                            </span>
                                                                        </label>
                                                                    </p>
                                                                </td>
                                                            </tr>
                                                        </>
                                                    }

                                                    {inclusiondata?.is1to1AdditionalFeeVisible === 1 &&
                                                        <tr>
                                                            <td>
                                                                {' '}
                                                                <label className="bold">{t('VacantRightbar.1')}</label>
                                                            </td>
                                                            <td>
                                                                <div className="d-flex justify-content-center align-items-center">
                                                                    <span className="me-1">&pound;</span>
                                                                    <input
                                                                        type="text"
                                                                        {...register('AdditionalFeeH1', {
                                                                            onChange: (e: any) => {
                                                                                props.updateAData(e);
                                                                            }
                                                                        })}
                                                                        name={'AdditionalFeeH1' as const}
                                                                        onKeyDown={(e: any) => {
                                                                            const cursorPosition = e.target.selectionStart;
                                                                            if (
                                                                                e.key === 'ArrowLeft' ||
                                                                                e.key === 'ArrowRight' ||
                                                                                e.key === 'Backspace' ||
                                                                                e.key === 'Delete' ||
                                                                                e.key === 'Tab' ||
                                                                                (e.key === 'a' && e.ctrlKey) // Ctrl+A
                                                                            ) {
                                                                                // Allow the default behavior for these keys
                                                                                return;
                                                                            } else {
                                                                                if (
                                                                                    window.$utils.onKeypressValidateApplicable(
                                                                                        'number',
                                                                                        e.target.value.substring(0, cursorPosition) + e.key + e.target.value.substring(cursorPosition)
                                                                                    ) === false
                                                                                ) {
                                                                                    e.preventDefault();
                                                                                }
                                                                            }
                                                                        }}
                                                                        //@ts-ignore
                                                                        className={`form-control white custom-width-input ${errors.AdditionalFeeH1 ? 'is-invalid' : ''}`}
                                                                    />
                                                                    <p className="ms-1 small"> {t('VacantRightbar.hr')}</p>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div className="d-flex align-items-center">
                                                                    <input
                                                                        type="text"
                                                                        {...register('Inclusion1', {
                                                                            onChange: (e: any) => {
                                                                                props.updateAData(e);
                                                                            }
                                                                        })}
                                                                        name={'Inclusion1' as const}
                                                                        onKeyDown={(e: any) => {
                                                                            const cursorPosition = e.target.selectionStart;
                                                                            if (
                                                                                e.key === 'ArrowLeft' ||
                                                                                e.key === 'ArrowRight' ||
                                                                                e.key === 'Backspace' ||
                                                                                e.key === 'Delete' ||
                                                                                e.key === 'Tab' ||
                                                                                (e.key === 'a' && e.ctrlKey) // Ctrl+A
                                                                            ) {
                                                                                // Allow the default behavior for these keys
                                                                                return;
                                                                            } else {
                                                                                if (
                                                                                    window.$utils.onKeypressValidateApplicable(
                                                                                        'number',
                                                                                        e.target.value.substring(0, cursorPosition) + e.key + e.target.value.substring(cursorPosition)
                                                                                    ) === false
                                                                                ) {
                                                                                    e.preventDefault();
                                                                                }
                                                                            }
                                                                        }}
                                                                        className={`form-control white custom-width-input small ${errors.Inclusion1 ? 'is-invalid' : ''}`}
                                                                    />
                                                                    <p className="ms-1 small"> / {props?.In1dailyOrWeeklyToggle === false ? 'Day' : 'Week'}</p>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div className="d-flex justify-content-end align-items-center">
                                                                    <p className="me-1 bold small">{t('VacantRightbar.day')}</p>
                                                                    <label className="switch-custom">
                                                                        <input
                                                                            type="checkbox"
                                                                            {...register('In1DailyOrWeekly', {
                                                                                onChange: (e: any) => {
                                                                                    props.updateAData(e);
                                                                                }
                                                                            })}
                                                                        />
                                                                        <span className="slider round"></span>
                                                                    </label>
                                                                    <p className="ms-1 bold small">{t('VacantRightbar.week')}</p>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    }
                                                    {inclusiondata?.is1to2AdditionalFeeInclusionVisible === 1 && inclusiondata?.is1to2AdditionalFeeVisible === 1 &&
                                                        <>
                                                            <tr>
                                                                <td colSpan={3} className='pb-0 pt-1'>
                                                                    <label className="mt-1">
                                                                        <span className="d-flex align-items-center justify-content-center">
                                                                            <input
                                                                                type="checkbox"
                                                                                {...register('Inclusion2included', {
                                                                                    onChange: (e: any) => {
                                                                                        props.updateAData(e);
                                                                                    }
                                                                                })}
                                                                                disabled={finalcorefee !== 0 ? false : true}
                                                                                className="me-2"
                                                                            />{' '}
                                                                            Included in Core Fee
                                                                        </span>
                                                                    </label>
                                                                </td>
                                                            </tr>
                                                        </>

                                                    }
                                                    {inclusiondata?.is1to2AdditionalFeeVisible === 1 &&
                                                        <tr>
                                                            <td>
                                                                {' '}
                                                                <label className="bold">{t('VacantRightbar.2')} </label>
                                                            </td>
                                                            <td>
                                                                <div className="d-flex justify-content-center align-items-center">
                                                                    <span className="me-1">&pound;</span>
                                                                    <input
                                                                        type="text"
                                                                        {...register('AdditionalFeeH2', {
                                                                            onChange: (e: any) => {
                                                                                props.updateAData(e);
                                                                            }
                                                                        })}
                                                                        name={'AdditionalFeeH2' as const}
                                                                        onKeyDown={(e: any) => {
                                                                            const cursorPosition = e.target.selectionStart;
                                                                            if (
                                                                                e.key === 'ArrowLeft' ||
                                                                                e.key === 'ArrowRight' ||
                                                                                e.key === 'Backspace' ||
                                                                                e.key === 'Delete' ||
                                                                                e.key === 'Tab' ||
                                                                                (e.key === 'a' && e.ctrlKey) // Ctrl+A
                                                                            ) {
                                                                                // Allow the default behavior for these keys
                                                                                return;
                                                                            } else {
                                                                                if (
                                                                                    window.$utils.onKeypressValidateApplicable(
                                                                                        'number',
                                                                                        e.target.value.substring(0, cursorPosition) + e.key + e.target.value.substring(cursorPosition)
                                                                                    ) === false
                                                                                ) {
                                                                                    e.preventDefault();
                                                                                }
                                                                            }
                                                                        }}
                                                                        //@ts-ignore
                                                                        className={`form-control white custom-width-input ${errors.AdditionalFeeH2 ? 'is-invalid' : ''}`}
                                                                    />
                                                                    <p className="ms-1 small"> {t('VacantRightbar.hr')}</p>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div className="d-flex align-items-center">
                                                                    <input
                                                                        type="text"
                                                                        {...register('Inclusion2', {
                                                                            onChange: (e: any) => {
                                                                                props.updateAData(e);
                                                                            }
                                                                        })}
                                                                        name={'Inclusion2' as const}
                                                                        onKeyDown={(e: any) => {
                                                                            const cursorPosition = e.target.selectionStart;
                                                                            if (
                                                                                e.key === 'ArrowLeft' ||
                                                                                e.key === 'ArrowRight' ||
                                                                                e.key === 'Backspace' ||
                                                                                e.key === 'Delete' ||
                                                                                e.key === 'Tab' ||
                                                                                (e.key === 'a' && e.ctrlKey) // Ctrl+A
                                                                            ) {
                                                                                // Allow the default behavior for these keys
                                                                                return;
                                                                            } else {
                                                                                if (
                                                                                    window.$utils.onKeypressValidateApplicable(
                                                                                        'number',
                                                                                        e.target.value.substring(0, cursorPosition) + e.key + e.target.value.substring(cursorPosition)
                                                                                    ) === false
                                                                                ) {
                                                                                    e.preventDefault();
                                                                                }
                                                                            }
                                                                        }}
                                                                        className={`form-control white custom-width-input small ${errors.Inclusion2 ? 'is-invalid' : ''}`}
                                                                    />
                                                                    <p className="ms-1 small"> / {props?.In2dailyOrWeeklyToggle === false ? 'Day' : 'Week'}</p>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div className="d-flex justify-content-end align-items-center">
                                                                    <p className="me-1 bold small">{t('VacantRightbar.day')}</p>
                                                                    <label className="switch-custom">
                                                                        <input
                                                                            type="checkbox"
                                                                            {...register('In2DailyOrWeekly', {
                                                                                onChange: (e: any) => {
                                                                                    props.updateAData(e);
                                                                                }
                                                                            })}
                                                                        />
                                                                        <span className="slider round"></span>
                                                                    </label>
                                                                    <p className="ms-1 bold small">{t('VacantRightbar.week')}</p>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    }
                                                    {inclusiondata?.is1to3AdditionalFeeInclusionVisible === 1 && inclusiondata?.is1to3AdditionalFeeVisible === 1 &&
                                                        <>
                                                            <tr>
                                                                <td colSpan={3} className='pb-0 pt-1'>
                                                                    <label className="mt-1">
                                                                        <span className="d-flex align-items-center justify-content-center">
                                                                            <input
                                                                                type="checkbox"
                                                                                {...register('Inclusion3included', {
                                                                                    onChange: (e: any) => {
                                                                                        props.updateAData(e);
                                                                                    }
                                                                                })}
                                                                                disabled={finalcorefee !== 0 ? false : true}
                                                                                className="me-2"
                                                                            />{' '}
                                                                            Included in Core Fee
                                                                        </span>
                                                                    </label>
                                                                </td>
                                                            </tr>
                                                        </>
                                                    }
                                                    {inclusiondata?.is1to3AdditionalFeeVisible === 1 &&
                                                        <tr>
                                                            <td>
                                                                {' '}
                                                                <label className="bold">{t('VacantRightbar.3')} </label>
                                                            </td>
                                                            <td>
                                                                <div className="d-flex justify-content-center align-items-center">
                                                                    <span className="me-1">&pound;</span>
                                                                    <input
                                                                        type="text"
                                                                        {...register('AdditionalFeeH3', {
                                                                            onChange: (e: any) => {
                                                                                props.updateAData(e);
                                                                            }
                                                                        })}
                                                                        name={'AdditionalFeeH3' as const}
                                                                        onKeyDown={(e: any) => {
                                                                            const cursorPosition = e.target.selectionStart;
                                                                            if (
                                                                                e.key === 'ArrowLeft' ||
                                                                                e.key === 'ArrowRight' ||
                                                                                e.key === 'Backspace' ||
                                                                                e.key === 'Delete' ||
                                                                                e.key === 'Tab' ||
                                                                                (e.key === 'a' && e.ctrlKey) // Ctrl+A
                                                                            ) {
                                                                                // Allow the default behavior for these keys
                                                                                return;
                                                                            } else {
                                                                                if (
                                                                                    window.$utils.onKeypressValidateApplicable(
                                                                                        'number',
                                                                                        e.target.value.substring(0, cursorPosition) + e.key + e.target.value.substring(cursorPosition)
                                                                                    ) === false
                                                                                ) {
                                                                                    e.preventDefault();
                                                                                }
                                                                            }
                                                                        }}
                                                                        //@ts-ignore
                                                                        className={`form-control white custom-width-input ${errors.AdditionalFeeH3 ? 'is-invalid' : ''}`}
                                                                    />
                                                                    <p className="ms-1 small"> {t('VacantRightbar.hr')}</p>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div className="d-flex align-items-center">
                                                                    <input
                                                                        type="text"
                                                                        {...register('Inclusion3', {
                                                                            onChange: (e: any) => {
                                                                                props.updateAData(e);
                                                                            }
                                                                        })}
                                                                        name={'Inclusion3' as const}
                                                                        onKeyDown={(e: any) => {
                                                                            const cursorPosition = e.target.selectionStart;
                                                                            if (
                                                                                e.key === 'ArrowLeft' ||
                                                                                e.key === 'ArrowRight' ||
                                                                                e.key === 'Backspace' ||
                                                                                e.key === 'Delete' ||
                                                                                e.key === 'Tab' ||
                                                                                (e.key === 'a' && e.ctrlKey) // Ctrl+A
                                                                            ) {
                                                                                // Allow the default behavior for these keys
                                                                                return;
                                                                            } else {
                                                                                if (
                                                                                    window.$utils.onKeypressValidateApplicable(
                                                                                        'number',
                                                                                        e.target.value.substring(0, cursorPosition) + e.key + e.target.value.substring(cursorPosition)
                                                                                    ) === false
                                                                                ) {
                                                                                    e.preventDefault();
                                                                                }
                                                                            }
                                                                        }}
                                                                        className={`form-control white custom-width-input small ${errors.Inclusion3 ? 'is-invalid' : ''}`}
                                                                    />
                                                                    <p className="ms-1 small"> / {props?.In3dailyOrWeeklyToggle === false ? 'Day' : 'Week'}</p>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div className="d-flex justify-content-end align-items-center">
                                                                    <p className="me-1 bold small">{t('VacantRightbar.day')}</p>
                                                                    <label className="switch-custom">
                                                                        <input
                                                                            type="checkbox"
                                                                            {...register('In3DailyOrWeekly', {
                                                                                onChange: (e: any) => {
                                                                                    props.updateAData(e);
                                                                                }
                                                                            })}
                                                                        />
                                                                        <span className="slider round"></span>
                                                                    </label>
                                                                    <p className="ms-1 bold small">{t('VacantRightbar.week')}</p>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    }
                                                    {inclusiondata?.is1to4AdditionalFeeInclusionVisible === 1 && inclusiondata?.is1to4AdditionalFeeVisible === 1 &&
                                                        <>
                                                            <tr>
                                                                <td colSpan={3} className='pb-0 pt-1'>
                                                                    <label className="mt-1">
                                                                        <span className="d-flex align-items-center justify-content-center">
                                                                            <input
                                                                                type="checkbox"
                                                                                {...register('Inclusion4included', {
                                                                                    onChange: (e: any) => {
                                                                                        props.updateAData(e);
                                                                                    }
                                                                                })}
                                                                                disabled={finalcorefee !== 0 ? false : true}
                                                                                className="me-2"
                                                                            />{' '}
                                                                            Included in Core Fee
                                                                        </span>
                                                                    </label>
                                                                </td>
                                                            </tr>
                                                        </>

                                                    }
                                                    {inclusiondata?.is1to4AdditionalFeeVisible === 1 &&
                                                        <tr>
                                                            <td>
                                                                {' '}
                                                                <label className="bold">{t('VacantRightbar.4')}</label>
                                                            </td>
                                                            <td>
                                                                <div className="d-flex justify-content-center align-items-center">
                                                                    <span className="me-1">&pound;</span>
                                                                    <input
                                                                        type="text"
                                                                        {...register('AdditionalFeeH4', {
                                                                            onChange: (e: any) => {
                                                                                props.updateAData(e);
                                                                            }
                                                                        })}
                                                                        name={'AdditionalFeeH4' as const}
                                                                        onKeyDown={(e: any) => {
                                                                            const cursorPosition = e.target.selectionStart;
                                                                            if (
                                                                                e.key === 'ArrowLeft' ||
                                                                                e.key === 'ArrowRight' ||
                                                                                e.key === 'Backspace' ||
                                                                                e.key === 'Delete' ||
                                                                                e.key === 'Tab' ||
                                                                                (e.key === 'a' && e.ctrlKey) // Ctrl+A
                                                                            ) {
                                                                                // Allow the default behavior for these keys
                                                                                return;
                                                                            } else {
                                                                                if (
                                                                                    window.$utils.onKeypressValidateApplicable(
                                                                                        'number',
                                                                                        e.target.value.substring(0, cursorPosition) + e.key + e.target.value.substring(cursorPosition)
                                                                                    ) === false
                                                                                ) {
                                                                                    e.preventDefault();
                                                                                }
                                                                            }
                                                                        }}
                                                                        //@ts-ignore
                                                                        className={`form-control white custom-width-input ${errors.AdditionalFeeH4 ? 'is-invalid' : ''}`}
                                                                    />
                                                                    <p className="ms-1 small"> {t('VacantRightbar.hr')}</p>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div className="d-flex align-items-center">
                                                                    <input
                                                                        type="text"
                                                                        {...register('Inclusion4', {
                                                                            onChange: (e: any) => {
                                                                                props.updateAData(e);
                                                                            }
                                                                        })}
                                                                        name={'Inclusion4' as const}
                                                                        onKeyDown={(e: any) => {
                                                                            const cursorPosition = e.target.selectionStart;
                                                                            if (
                                                                                e.key === 'ArrowLeft' ||
                                                                                e.key === 'ArrowRight' ||
                                                                                e.key === 'Backspace' ||
                                                                                e.key === 'Delete' ||
                                                                                e.key === 'Tab' ||
                                                                                (e.key === 'a' && e.ctrlKey) // Ctrl+A
                                                                            ) {
                                                                                // Allow the default behavior for these keys
                                                                                return;
                                                                            } else {
                                                                                if (
                                                                                    window.$utils.onKeypressValidateApplicable(
                                                                                        'number',
                                                                                        e.target.value.substring(0, cursorPosition) + e.key + e.target.value.substring(cursorPosition)
                                                                                    ) === false
                                                                                ) {
                                                                                    e.preventDefault();
                                                                                }
                                                                            }
                                                                        }}
                                                                        className={`form-control white custom-width-input small ${errors.Inclusion4 ? 'is-invalid' : ''}`}
                                                                    />

                                                                    <p className="ms-1 small"> / {props?.In4dailyOrWeeklyToggle === false ? 'Day' : 'Week'}</p>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div className="d-flex justify-content-end align-items-center">
                                                                    <p className="me-1 bold small">{t('VacantRightbar.day')}</p>
                                                                    <label className="switch-custom">
                                                                        <input
                                                                            type="checkbox"
                                                                            {...register('In4DailyOrWeekly', {
                                                                                onChange: (e: any) => {
                                                                                    props.updateAData(e);
                                                                                }
                                                                            })}
                                                                        />
                                                                        <span className="slider round"></span>
                                                                    </label>
                                                                    <p className="ms-1 bold small">{t('VacantRightbar.week')}</p>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    }
                                                </tbody>
                                            </table>
                                        </>
                                    }
                                </div>
                            </form>
                            {props.funderlist !== undefined && props.funderlist?.ListData?.length >= 2 && props.isAction === 'admit' && props.dailyfee !== '' && !istotalfeevalid && (
                                <>
                                    <label className="mt-3">
                                        <span className="d-flex align-items-center justify-content-center">
                                            <input
                                                type="checkbox"
                                                {...register('isSplitFunding', {
                                                    onChange: (e: any) => {
                                                        props.updateAData(e);
                                                    }
                                                })}
                                                className="me-2"
                                            />{' '}
                                            Define split funding during invoicing
                                            <a
                                                href="#"
                                                className="ms-1 mt-1"
                                                data-tooltip-id="tooltip-splitfunding"
                                                data-tooltip-content={`"The invoice will not be generated until you complete the splitting. It is your responsibility to complete the splitting before the billing frequency."`}
                                            >
                                                <i className="fa-solid fa-circle-info"></i>
                                            </a>
                                        </span>
                                    </label>
                                    <a href="#" className="btn btn-block btn-gray mt-3" onClick={handleClickModal}>
                                        Define Split Funding <i className="fa-solid fa-arrow-right ms-1"></i>{' '}
                                    </a>
                                </>
                            )}
                            {props.funderlist !== undefined && props.funderlist?.ListData?.length === 1 && props.isAction === 'admit' && props.dailyfee !== '' && !istotalfeevalid && (
                                <>
                                    <a href="#" className="btn btn-block btn-gray mt-3" onClick={handleClickModal}>
                                        {' '}
                                        Update Plan <i className="fa-solid fa-arrow-right ms-1"></i>{' '}
                                    </a>
                                </>
                            )}
                            <RenderModalContainer>
                                {showModal && (
                                    <SplitFundingModalContainer
                                        onAddplan={addPlan}
                                        onSave={savedata}
                                        onClose={toggle}
                                        isaddplan={isaddplan}
                                        iseditable={props.iseditable}
                                        funderlist={props.funderlist?.ListData}
                                    />
                                )}
                            </RenderModalContainer>
                            <Tooltip id="tooltip-splitfunding" className="my-tooltip tooltip-custom-width" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ApplicableView; 
import React, {useState, useEffect} from 'react';
import { RootState } from '../../../../../../store';
import { useDispatch, useSelector } from 'react-redux';
import TransferTabDeatil from './transferView';
import { getListTransferReasons, getListVacantBeds, transferPatientService } from '../../../../../../service/transfer-service';
import { ReasonList } from '../../../../../../utils/constant';
import swal from 'sweetalert';
import eventEmitter from '../../../../../../utils/eventEmitter';
import logo from '../../../../../../assets/images/warning.png';

const TransferTabContainer = (props: any) => {
    const { StatusLogin, DataLogin } = useSelector((state: RootState) => state.login);
    const { StatusListReasons, DataListReasons } = useSelector((state: RootState) => state.transferReasons);
    const { StatusVacantBedReasons, DataVacantBedReasons } = useSelector((state: RootState) => state.vacantBed);
    const { StatusServiceDetail, DataServiceDetail } = useSelector((state: RootState) => state.serviceDetail);
    const [listReasons, setListReasons] = useState<any>([])
    const [listVacantBeds, setListVacantBeds] = useState<any>([])
    const [bed, setBed] = useState<any>([])
    const [wardData, setWardData] = useState<any>([])

    
    const setData = (data: any) => {
        setBed(data)
    }
    const setWardDataHandler = (data: any) => {
        setWardData(data)
    }
    const updateData = (e: any) => {
        eventEmitter.emit('eventCloseOccupiedSideMenu');
    }
    const onSubmitHandler = async(data: any) => {
        
        if (bed.bedId === undefined) {
            swal("Room not selected for transfer.", {
                icon: 'error',
                dangerMode: true
            });
            return false;
        }
        data.newBedId = bed.bedId
        data.admissionId = props.patientData?.admissionId
        if(data.admissionId !== undefined && data.admissionId !== '') {
            data.reasonId = Number(data.reasonId)
            swal({
                title: '',
                text: `Are you sure you want to transfer the patient from Ward "${props.patientData?.wardName}" Room "${props.patientData.bedName}" to Ward "${wardData.wardName}" Room "${bed.bedName}"?`,
                icon: logo,
                buttons: ['No', 'Yes'],
                dangerMode: true
            })
            .then(async (confirm: any) => {
                if (confirm) {
                    const response = await transferPatientService(data)
                    if(response.status === true) {
                        swal(`Patient transferred from Ward "${props.patientData?.wardName}" Room "${props.patientData?.bedName}" to Ward "${wardData.wardName}" Room "${bed.bedName}" successfully.`, {
                            icon: 'success'
                        });
                        props.closeSideMenu(true)
                        eventEmitter.emit('eventGetBedInformation');
                        eventEmitter.emit('eventGetWardInformation');
                        eventEmitter.emit('onAdmitReservePatient');
                    } else {
                        swal(response.message, {
                            icon: 'error',
                            dangerMode: true
                        });
                    }
                }
            });
        }
    }

    useEffect(() => {
        async function getPatientDetails() {
            const payload: any = { id: DataServiceDetail.serviceId};
            await getListVacantBeds(payload);
            const payload2: any = { id: ReasonList.Transfer};
            await getListTransferReasons(payload2);
        }
        getPatientDetails();
    },[DataLogin])
    useEffect(() => {
        if(StatusListReasons === true) {
            setListReasons(DataListReasons.data)
        }
        if(StatusVacantBedReasons === true) {
            setListVacantBeds(DataVacantBedReasons.data)
        }
    },[StatusListReasons, DataListReasons, StatusVacantBedReasons, DataVacantBedReasons])

    return <TransferTabDeatil listReasons={listReasons} listVacantBeds={listVacantBeds} patientData={props.patientData} setData={setData} setWardData={setWardDataHandler} onSubmitHandler={onSubmitHandler} updateData={updateData} />;
};

export default TransferTabContainer;

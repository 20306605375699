import React from 'react';
import FacilitiesRegiCategoryView from './FacilitiesRegiCategoryView';

const FacilitiesRegiCategoryContainer = React.forwardRef<any>((props, ref) => {
    return (
        <div>
            <FacilitiesRegiCategoryView {...props} ref={ref} />
        </div>
    );
});

export default FacilitiesRegiCategoryContainer;

import React, { useState } from 'react';
import { DragSourceMonitor, useDrag, useDrop } from 'react-dnd';
import { GalleryItem } from './GalleryView';

export const ItemType = {
    CARD: 'draggableImage'
};

interface DraggableItemProps {
    item: GalleryItem;
    index: number;
    subArrayIndex: number;
    moveItem: (subArrayIndex: number, dragIndex: number, hoverIndex: number) => void;
    deleteItem: (subArrayIndex: number, itemIndex: number) => void;
}

const DraggableItem: React.FC<DraggableItemProps> = ({ item, index, moveItem, subArrayIndex, deleteItem }) => {
    const ref = React.useRef<HTMLDivElement>(null);

    const [, drop] = useDrop({
        accept: ItemType.CARD,
        hover: (draggedItem: { index: number }) => {
            if (!ref.current) return;
            if (draggedItem.index === index) return;

            moveItem(subArrayIndex, draggedItem.index, index);
            draggedItem.index = index;
        }
    });

    const [{ isDragging }, drag] = useDrag({
        type: ItemType.CARD,
        item: { id: item.imageId, index, imagePath: item.imagePath, imageName: item.imageName },
        collect: (monitor: DragSourceMonitor) => ({
            isDragging: monitor.isDragging()
        })
    });

    drag(drop(ref));

    const handleDelete = () => {
        deleteItem(subArrayIndex, index);
    };

    return (
        <div className="col-md-2" ref={ref}>
            <div className="gallery-img-thumbnail position-relative">
                <img src={item.imagePath} className="img-fluid" alt={item.imageName} />
                    <div
                        className="edit-icon-preview article-menu float-end img-above-edit-icon-preview cursor-pointer"
                        data-tooltip-id="my-tooltip"
                        data-tooltip-content="Delete Image"
                        data-tooltip-place="top"
                        onClick={() => {
                            handleDelete();
                        }}
                    >
                        <i className="fa fa-trash" aria-hidden="true"></i>
                    </div>
            </div>
        </div>
    );
};

export default DraggableItem;

import React, { useImperativeHandle } from 'react';
import DescribeYourFacilityView from './DescribeYourFacilityView';

const DescribeYourFacilityContainer = React.forwardRef((props, ref) => {
    
    return (
        <div>
            <DescribeYourFacilityView {...props} ref={ref} />
        </div>
    );
});

export default DescribeYourFacilityContainer;

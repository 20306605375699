import { useEffect, useState } from 'react';
// import DatePickerView from '../../../../../../../components/DatePicker/dateView';
import { yupResolver } from '@hookform/resolvers/yup';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import moment from 'moment';
import 'react-datepicker/dist/react-datepicker.css';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import * as Yup from 'yup';
import { API_ENDPOINT, BASEURLDEV } from '../../../../../../../service/ApiConstants';
import { RootState } from '../../../../../../../store';
import SmartSearchDropdownPatientDetailContainer from '../../../../../../../components/SmartSearchDropdownPatientDetail/SmartSearchDropdownPatientDetailContainer';
const AdmissionDetailView = (props: any) => {
    const { Data } = useSelector((state: RootState) => state.navigation);
    const { DataServiceDetail } = useSelector((state: RootState) => state.serviceDetail);
    const [minDate, setmindate] = useState<any>('');
    const [admissiondate, setadmissiondate] = useState<any>(null);
    const [dischargedate, setdischargedate] = useState<any>(null);
    const [estadmissiondate, setestadmissiondate] = useState<any>(null);
    let [patientTypeSmartSearch, setPatientTypeSmartSearch] = useState<any>(undefined);

    let [defaultpatient, setdefaultpatient] = useState<any>(undefined);

    const { t } = useTranslation();
    let validationSchema = Yup.object().shape({
        patienttype: Yup.string().trim().strict(true).required('patienttype is required'),
        doa: Yup.date().test('', '', (value: any, ctx: any) => {
            if (props.isAction == 'admit') {
                if (ctx.parent.doa !== undefined && ctx.parent.doa !== null && (new Date(value).getTime() <= new Date().getTime() )) {
                    return true;
                } else {
                    return false;
                }
            }
            if (props.isAction == 'reserve') {
                if (ctx.parent.doa === undefined || ctx.parent.doa !== null || ctx.parent.doa == null ) {
                    return true;
                } else {
                    return false;
                }
            } else {
                return true;
            }
        }),
        edoa: Yup.date().test('', '', (value: any, ctx: any) => {
            if (props.isAction == 'reserve') {
                if (ctx.parent.edoa !== undefined && ctx.parent.edoa !== null) {
                    return true;
                } else {
                    return false;
                }
            } else if (props.isAction == 'admit') {
                if ((ctx.parent.edoa == undefined || ctx.parent.edoa == null || ctx.parent.edoa !== null)) {
                    return true;
                }
            } else {
                return true;
            }
        }),

        dod: Yup.string().notRequired()
    });

    const { register, control, handleSubmit, reset, getValues, formState, setValue } = useForm({ mode: 'all', resolver: yupResolver(validationSchema) });
    const { errors } = formState;
    const [ptype, setptype] = useState<any>('');
    useEffect(() => {
        
        setmindate('');
        setadmissiondate(null);
        setestadmissiondate(null);

        // reset(); // No need to reset all the values as we need the Patient type persistent. Instead we can do the following.
        setValue('doa', undefined, { shouldValidate: false });
        setValue('edoa', undefined, { shouldValidate: false });
    }, [props.isAction]);

    useEffect(() => {
        if (props.clearpatient !== undefined) {
            setdefaultpatient(undefined);
        }
    }, [props.clearpatient]);

    useEffect(() => {
        if (props.draftData !== undefined) {
            setValue('patienttype', props?.draftData?.patientDetail?.patientTypeId == 0 ? '' : props?.draftData?.patientDetail?.patientTypeId?.toString());
            if (props?.draftData?.admissionDetail?.admissionDate != null && props?.draftData?.admissionDetail?.admissionDate != '') {
                setValue('doa', props?.draftData?.admissionDetail?.admissionDate ? window.$utils.dateFormat(props?.draftData?.admissionDetail?.admissionDate) : '', { shouldValidate: true });
                let date = moment(props?.draftData?.admissionDetail?.admissionDate, 'DD-MM-YYYY');
                setadmissiondate(props?.draftData?.admissionDetail?.admissionDate ? dayjs(new Date(date.toDate())) : '');
            }
            if (props?.draftData?.admissionDetail?.estStartDate) {
                setValue('edoa', props?.draftData?.admissionDetail?.estStartDate ? window.$utils.dateFormat(props?.draftData?.admissionDetail?.estStartDate) : '', { shouldValidate: true });
                let date = moment(props?.draftData?.admissionDetail?.estStartDate, 'DD-MM-YYYY');
                setestadmissiondate(props?.draftData?.admissionDetail?.estStartDate ? dayjs(new Date(date.toDate())) : '');
            }
            setValue('dod', props?.draftData?.admissionDetail?.estDischargeDate ? window.$utils.dateFormat(props?.draftData?.admissionDetail?.estDischargeDate) : '');
            let date = moment(props?.draftData?.admissionDetail?.estDischargeDate, 'DD-MM-YYYY');
            setdischargedate(props?.draftData?.admissionDetail?.estDischargeDate ? dayjs(new Date(date.toDate())) : '');
            setptype(props?.draftData?.patientDetail?.patientTypeId == 0 ? '' : props?.draftData?.patientDetail.patientTypeId?.toString());
            setdefaultpatient(props?.draftData?.patientDetail.patientTypeId);
        } else {
            reset();
            setptype('');
        }
    }, [props.draftData,props.isAction]);

    useEffect(() => {
        let dval = getValues('doa');
        let edval = getValues('edoa');
        if (props.isAction == 'admit') {
            setValue('edoa', null, { shouldValidate: true });
            setValue('edoa', edval, { shouldValidate: true });
            setmindate(getValues('doa') !== undefined ? dayjs(getValues('doa')) : '');
        }
        if (props.isAction == 'reserve') {
            setValue('doa', null, { shouldValidate: true });
            setValue('doa', dval, { shouldValidate: true });
            setmindate(getValues('edoa') !== undefined ? dayjs(getValues('edoa')) : '');
        }
    }, [props.isAction]);
    
    useEffect(() => {
        props.getadmissionerror(formState.isValid);
    }, [formState, props.isAction]);

    const clearPatientTypeSmartSearch = () => {
        setValue('patienttype', '', { shouldValidate: true });
        setptype('');
        setPatientTypeSmartSearch(undefined);
    };

    useEffect(() => {
        if (patientTypeSmartSearch === undefined) {
            let objCitySmartSearch = {
                url: `${BASEURLDEV}${API_ENDPOINT.GetDDLpatienttypelist}`
            };
            setPatientTypeSmartSearch(objCitySmartSearch);
        }
    }, [patientTypeSmartSearch]);

    useEffect(() => {
        if (props.draftData === undefined) {
            setmindate('');
            setadmissiondate(null);
            setdischargedate(null);
            setestadmissiondate(null);

            // reset(); // No need to reset all the values as we need the Patient type persistent. Instead we can do the following.
            setValue('doa', undefined, { shouldValidate: false });
            setValue('edoa', undefined, { shouldValidate: false });
            setValue('dod', undefined, { shouldValidate: false });
            if (patientTypeSmartSearch !== undefined) {
                clearPatientTypeSmartSearch();
            }
        }
    }, [Data]);
    return (
        <div>
            <form>
                <div className="input-group date mb-2 datepicker-input-css" id="datepicker">
                    {DataServiceDetail?.iqviaServiceEnabled === false && props.isAction == 'admit' && (
                        <Controller
                            control={control}
                            name="doa"
                            render={({ field: { value, ...fieldProps }, fieldState: { error } }) => {
                                return (
                                    <DatePicker
                                        {...fieldProps}
                                        className={`form-control white ${error ? 'is-invalid' : ''}`}
                                        label={t('VacantRightbar.doa')}
                                        value={admissiondate}
                                        format="DD/MM/YYYY"
                                        disabled={props.isedit === 'edit' ? true : false}
                                        onChange={(date: any) => {
                                            fieldProps.onChange(date);
                                            setmindate(dayjs(date));
                                            setadmissiondate(date);
                                            props.getAdmissionDate(date);
                                        }}
                                        disableFuture
                                        views={['year', 'month', 'day']}
                                        slotProps={{ actionBar: { actions: ['clear', 'today'] } }}
                                    />
                                );
                            }}
                        />
                    )}
                    {DataServiceDetail?.iqviaServiceEnabled === false && props.isAction == 'reserve' && (
                        <Controller
                            control={control}
                            name="edoa"
                            render={({ field: { value, ...fieldProps }, fieldState: { error } }) => {
                                return (
                                    <DatePicker
                                        {...fieldProps}
                                        className={`form-control white ${error ? 'is-invalid' : ''}`}
                                        label={t('VacantRightbar.edoa')}
                                        value={estadmissiondate}
                                        format="DD/MM/YYYY"
                                        onChange={(date: any) => {
                                            fieldProps.onChange(date);
                                            setmindate(dayjs(date));
                                            setestadmissiondate(date);
                                            props.getAdmissionDate(date);
                                        }}
                                        disablePast
                                        views={['year', 'month', 'day']}
                                        slotProps={{ actionBar: { actions: ['clear', 'today'] } }}
                                    />
                                );
                            }}
                        />
                    )}
                </div>
                <div className="input-group date mb-2 datepicker-input-css" id="datepicker">
                    {DataServiceDetail?.iqviaServiceEnabled === false && (
                        <Controller
                            control={control}
                            name="dod"
                            render={({ field: { value, ...fieldProps }, fieldState: { error } }) => {
                                return (
                                    <DatePicker
                                        className={`form-control white ${error ? 'is-invalid' : ''}`}
                                        label={t('VacantRightbar.edd')}
                                        value={dischargedate}
                                        format="DD/MM/YYYY"
                                        {...fieldProps}
                                        onChange={(date: any) => {
                                            fieldProps.onChange(date);
                                            setdischargedate(date);
                                            props.getDischargeDate(date);
                                        }}
                                        minDate={minDate}
                                        views={['year', 'month', 'day']}
                                        slotProps={{ actionBar: { actions: ['clear', 'today'] } }}
                                    />
                                );
                            }}
                        />
                    )}                    
                </div>
                {/* <select
                    {...register('patienttype', {
                        onChange: (e: any) => {
                            setptype(e.target.value);
                            props.updateData(e);
                        }
                    })}
                    name={'patienttype' as const}
                    //@ts-ignore
                    className={`form-control white  ${errors.patienttype ? 'is-invalid' : ''}`}
                    id="patienttype"
                    value={ptype}
                >
                    <option disabled={true} value={''}>
                        {' '}
                        {t('VacantRightbar.ptype')}
                    </option>
                    {props.patientType &&
                        props.patientType.data.map((patienttype: any) => {
                            return (
                                <option key={patienttype.key} value={patienttype.key}>
                                    {patienttype.value}
                                </option>
                            );
                        })}
                </select> */}
                {patientTypeSmartSearch !== undefined && (
                    <div className="auto-complete-dropdown auto-complete-dropdown-cus">
                        <SmartSearchDropdownPatientDetailContainer
                            smartSearchLabel={t('VacantRightbar.ptype')}
                            defaultValue={defaultpatient}
                            patientTypes={props?.patientType?.data}
                            errors={errors.patienttype}
                            onChangeSmartSearch={(value: any) => {
                                const event = new Event('change');
                                let obj = {
                                    name: 'patienttype',
                                    value: value?.id?.toString()
                                };
                                //@ts-ignore
                                Object.defineProperty(event, 'target', { writable: false, value: obj });
                                setptype(value?.id);
                                setValue('patienttype', value?.id?.toString(), { shouldValidate: true });
                                if (value !== '' || value !== undefined) {
                                    props.updateData(event);
                                }
                            }}
                            smartSearch={patientTypeSmartSearch}
                        />
                    </div>
                )}
            </form>
        </div>
    );
};

export default AdmissionDetailView;

import React, { useEffect, useState } from 'react';
import ServiceUserDetailView from './serviceView';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../../../store';
import moment from "moment";
import dayjs from 'dayjs';
import eventEmitter from '../../../../../../../utils/eventEmitter';
import { getRelationshipData, getServiceAdd, getAdmitPatientByNhs } from '../../../../../../../service/dashboard-service';
import swal from 'sweetalert';
import warning from  '../../../../../../../assets/images/warning.png'
const ServiceUserDetailContainer = (props: any) => {    
    const [data, setData] = useState({'gender':'1','kgender':'1','sameasservice':false,'NhsId':'','LocalId':'','FullName':''});
    const [dob, setDob] = useState({});
    const [sdob,setdob] =useState<any>(null);
    const [sadd,setsadd] = useState<any>();    
    const [patient,setPatient] = useState<any>();
    const [patientExist,setpatientExist] = useState<boolean>(false);
    const [NhsId,setnhsId] = useState<any>('');
    const [LocalId,setlocalId] = useState<any>('');

    const [clearcity,setclearcity] = useState<any>(undefined);
    const [clearrel,setclearrel] = useState<any>(undefined);

    const { StatusCityInfo, DataCityInfo, MessageCityInfo, ErrorCityInfo } = useSelector((state: RootState) => state.cityInformation);
    const { StatusPatientDraftInfo, DataPatientDraftInfo, MessagePatientDraftInfo, ErrorPatientDraftInfo } = useSelector((state: RootState) => state.patientDraft);
    const { StatusServiceDetail, DataServiceDetail } = useSelector((state: RootState) => state.serviceDetail);    
    const [hasPatientnhsId,sethasPatientnhsId] = useState<boolean>(props.leadnhsid !== null && props.leadnhsid !== ''? true: false);
    const [hasPatientlocalId,sethasPatientlocalId] = useState<boolean>(props.leadlocalid !== null && props.leadlocalid !== ''? true: false);
    const [PatientDraftInfo,setPatientDraftInfo] = useState<any>(DataPatientDraftInfo?.data?.patientDetail);
    useEffect(() =>{        
        // 
    },[data])

    async function getrelationshipdata(gid:any) {
        if(gid === ''){
            gid = 1
        }
        const payload: any = gid;
        await getRelationshipData(payload);
    }

    useEffect(()=>{
        getrelationshipdata('');
    },[])

    useEffect(()=>{              
    if(DataPatientDraftInfo?.data?.patientDetail !== '' && DataPatientDraftInfo !== ""){     
        getrelationshipdata(DataPatientDraftInfo?.data?.patientDetail.kinGender);   
        let date = moment(DataPatientDraftInfo?.data?.patientDetail.dateOfBirth, 'DD-MM-YYYY');
        setdob(dayjs(new Date(date.toDate())));        
     let obj={
        ...data,
             DOB: DataPatientDraftInfo?.data?.patientDetail.dateOfBirth ? date : '',
            'FullName': DataPatientDraftInfo?.data?.patientDetail.patientName || '',
            'NhsId': DataPatientDraftInfo?.data?.patientDetail.nhsId || '',
            'LocalId': DataPatientDraftInfo?.data?.patientDetail.localId || '',
            'Address1': DataPatientDraftInfo?.data?.patientDetail.addressLine1 || '',
            'Address2': DataPatientDraftInfo?.data?.patientDetail.addressLine2  || '',
            'City': DataPatientDraftInfo?.data?.patientDetail?.cityId?.toString() || '',
            'PoBox': DataPatientDraftInfo?.data?.patientDetail.pobox || '',
            'ContactNo': DataPatientDraftInfo?.data?.patientDetail.contactNo || '',
            'EmailId': DataPatientDraftInfo?.data?.patientDetail.email || '',
            'gender': DataPatientDraftInfo?.data?.patientDetail.gender?.toString() || '1', 
            'kgender': DataPatientDraftInfo?.data?.patientDetail.kinGender?.toString() || '1', 
            'kContactNo': DataPatientDraftInfo?.data?.patientDetail.kinContactNo || '',
            'kAddress': DataPatientDraftInfo?.data?.patientDetail.kinAddress || '',
            'kRelationship': DataPatientDraftInfo?.data?.patientDetail?.kinRelationshipId !== null ? DataPatientDraftInfo?.data?.patientDetail?.kinRelationshipId?.toString() : 0,
            'kFullName': DataPatientDraftInfo?.data?.patientDetail.kinName || '',
            'sameasservice':DataPatientDraftInfo?.data?.patientDetail.sameAsServiceAddress,
            'dateOfBirth':DataPatientDraftInfo?.data?.patientDetail.dateOfBirth ? date : '',
     }
     setData(obj);
     props.servicedata(obj);
    }else{
        let obj={
            ...data,                            
                'FullName':'',
                'NhsId': '',
                'LocalId': '',
                'sameasservice':false,
        }
        setData(obj);
        setnhsId('');
        setlocalId('');
    }
    },[DataPatientDraftInfo])

    const updatekData = (e: any) => {
       eventEmitter.emit('eventCloseVacantSideMenu');
       eventEmitter.emit('clearSmartSearch');
        if (e.target.type === 'radio') {
            getrelationshipdata(e.target.value);
            setData({
                ...data,
                kgender: e.target.value
            });
            let obj = {
                ...data,
                kgender: e.target.value
            };
                props.servicedata(obj);
        }
       
    }

    useEffect(()=>{
    },[dob])

    useEffect(()=>{
    console.log(data);
    },[data])

    async function updateAdd(e: any) {
        console.log(data);
        if(e.target.checked){
           const res = await getServiceAdd(DataServiceDetail.serviceId);
           if(res.status){
             setsadd(res.data);
             let obj={
                 ...data,
                     'Address1': res?.data?.addressLine1 || '',
                     'Address2': res?.data?.addressLine2  || '',
                     'City': res?.data?.countyId?.toString() || '',
                     'PoBox': res?.data?.postalCode || '',
                     'ContactNo': res?.data?.phoneNo || '',
                     'EmailId': res?.data?.email || '',
                     'sameasservice':true
              }
              setData(obj);
              props.servicedata(obj);
           }
        }
        else{
         setsadd(undefined);
         let obj={
             ...data,
                 'Address1': '',
                 'Address2':  '',
                 'City':  '',
                 'PoBox':  '',
                 'ContactNo': '',
                 'EmailId':  '',
                 'sameasservice':false
          }
          setData(obj);
          props.servicedata(obj);
       }
     }
     const updatesmartsearchData = (e:any) =>{
        eventEmitter.emit('eventCloseVacantSideMenu'); 
        setTimeout(() => {
            if(e.target.name === "City"){
               setclearcity('yes');
            }  
           }, 1000);
            setTimeout(() => {
               if(e.target.name === "kRelationship"){
                   setclearrel('yes');
                 }
            }, 1000);
            
            setData({
                ...data,
                [e.target.name]: e.target.value
            });
            let obj = {
                ...data,
                [e.target.name]: e.target.value
            };
            props.servicedata(obj);
     }
     const updateData = (e: any) => {        
         eventEmitter.emit('eventCloseVacantSideMenu');    
         if (e.target.type === 'radio') {
             setData({
                 ...data,
                 gender: e.target.value
             });
             let obj = {
                 ...data,
                 gender: e.target.value
             };
                 props.servicedata(obj);
         } else {
                setData({
                    ...data,
                    [e.target.name]: e.target.value
                });
                let obj = {
                    ...data,
                    [e.target.name]: e.target.value
                };
                props.servicedata(obj);
            
             if(data.sameasservice){
                 if(e.target.name === 'Address1' || e.target.name === 'Address2' || e.target.name === 'City' || e.target.name === 'ContactNo' || e.target.name === 'EmailId' || e.target.name === 'PoBox'){
                    let nobj={
                     ...data,
                     [e.target.name]: e.target.value,
                    //  'sameasservice':false
                    }
                    setData(nobj);
                    props.servicedata(nobj);
                 }
             }
                 
         }
     };

     const resetData = () => {
        let obj = {'gender':'1','kgender':'1','sameasservice':false,'NhsId':'','LocalId':'','FullName':''}
        setData(obj);
        
        props.servicedata(obj);
        setpatientExist(false)
     }

    const getDate = (date:Date) => {
        eventEmitter.emit('eventCloseVacantSideMenu');
        let admidate = new Date(date);
        setDob({ DOB: admidate });
        let obj = {
            ...data,
            DOB: admidate
        };
            setData(obj);
            props.servicedata(obj);
    };

    const getpatientbynhs = (e:any) =>{  
        e.preventDefault();
        let nhsId = data.NhsId;
        let localId = data.LocalId;
        let isNhsLocalchanged = false;
        let isNhsId = e.target.name.toLowerCase() == 'nhsid';
        let nhslocalId = e.target.value;
        let admissionId = DataPatientDraftInfo?.data?.admissionDetail?.admissionId;
        if (LocalId == localId && NhsId == nhsId) {
            return;
        }

        if ((LocalId != '' && LocalId != localId) || (NhsId != '' && NhsId != nhsId)) {
            isNhsLocalchanged = true;
        }
        if (NhsId != nhsId && isNhsId) {
            setnhsId(nhsId);
        }
        if (LocalId != localId && !isNhsId) {
            setlocalId(localId);
        }
        async function GetPatientByNhs() {
            const result = await getAdmitPatientByNhs(nhslocalId, isNhsId, admissionId);
            if (result.status === true) {
                if (result?.data != null) {
                    if (patientExist) {
                        swal({
                            title: '',
                            text: 'Patient Information entered will be replaced/lost. Are you sure you want to continue?',
                            icon: warning,
                            buttons: ['No', 'Yes'],
                            dangerMode: true
                        }).then(async (confirm: any) => {
                            if (confirm) {
                                setPatientData(result?.data, nhsId, localId);
                            }
                        });
                    } else {
                        setPatientData(result?.data, nhsId, localId);
                    }
                } else {
                    setpatientExist(false);
                    if (!isNhsLocalchanged || (data.FullName == '' && Object.keys(dob).length === 0)|| patientExist || props.isedit === 'edit') {
                        return;
                    }
                    swal({
                        title: '',
                        text: 'Patient Information entered will be replaced/lost. Are you sure you want to continue?',
                        icon: warning,
                        buttons: ['No', 'Yes'],
                        dangerMode: true
                    }).then(async (confirm: any) => {
                        if (confirm) {
                            let obj = {
                                ...data,
                                //'FullName':props.isedit === 'edit' && data.FullName != null? data.FullName || '' : data.FullName != null? data.FullName : '',
                                FullName: '',
                                NhsId: props.isedit === 'edit' && data.NhsId != null ? data.NhsId || '' : data.NhsId != null ? data.NhsId : '',
                                LocalId: props.isedit === 'edit' && data.LocalId != null ? data.LocalId || '' : data.LocalId != null ? data.LocalId : '',
                                Address1: '',
                                Address2: '',
                                City: '',
                                PoBox: '',
                                ContactNo: '',
                                EmailId: '',
                                gender: '1',
                                kgender: '1',
                                kContactNo: '',
                                kAddress: '',
                                kRelationship: 0,
                                kFullName: '',
                                sameasservice: false,
                                dateOfBirth: ''
                            };
                            let patientObj = {
                                ...data,
                                //'patientName':props.isedit === 'edit' && data.FullName != null? data.FullName || '' : data.FullName != null? data.FullName : '',
                                patientName: '',
                                nhsId: props.isedit === 'edit' && data.NhsId != null ? data.NhsId || '' : data.NhsId != null ? data.NhsId : '',
                                localId: props.isedit === 'edit' && data.LocalId != null ? data.LocalId || '' : data.LocalId != null ? data.LocalId : '',
                                address1: '',
                                address2: '',
                                city: '',
                                poBox: '',
                                contactNo: '',
                                email: '',
                                gender: '1',
                                kinGender: '1',
                                kinContactNo: '',
                                kinAddress: '',
                                kinRelationshipId: null,
                                kinName: '',
                                dateOfBirth: ''
                            };
                            if (DataPatientDraftInfo == '') {
                                setPatient(patientObj);
                                setdob('');
                            } else {
                                //DataPatientDraftInfo?.data?.patientDetail.addressLine1='';
                                setPatientDraftInfo(patientObj);
                            }
                            setData(obj);
                            
                            props.servicedata(obj);
                        }
                    });
                }
            } else {
                swal(result.message, {
                    icon: 'error',
                    dangerMode: true
                });
            }
        }
        // if(e.target.value !== ""){
        GetPatientByNhs();
        //}
    };

    const setPatientData = (data: any, nhsId: any, localId: any) => {
        setPatient(data);
        setpatientExist(true);
        let date = moment(data.dateOfBirth, 'DD-MM-YYYY');
        setdob(dayjs(new Date(date.toDate())));

        let obj = {
            ...data,
            DOB: data.dateOfBirth ? date : '',
            FullName: data.patientName || '',
            NhsId: data.nhsId != null ? data.nhsId : nhsId,
            LocalId: data.localId != null ? data.localId : localId,
            Address1: data.address1 || '',
            Address2: data.address2 || '',
            City: data?.city?.toString() || '',
            PoBox: data.poBox || '',
            ContactNo: data.contactNo || '',
            EmailId: data.email || '',
            gender: data.gender?.toString() || '1',
            kgender: data.kinGender?.toString() || '1',
            kContactNo: data.kinContactNo || '',
            kAddress: data.kinAddress || '',
            kRelationship: data?.kinRelationshipId !== null ? data?.kinRelationshipId?.toString() : 0,
            kFullName: data.kinName || '',
            sameasservice: data.sameAsServiceAddress,
            dateOfBirth: data.dateOfBirth ? date : ''
        };
        setData(obj);
        props.servicedata(obj);
    };
    const checkSelfFunder = (e: any) => {        
        props.checkSelfFunder(e);
    }

    return (
        <>
            <div>
                <ServiceUserDetailView
                    citylist={DataCityInfo}
                    updateData={updateData}
                    updatekData={updatekData}
                    getDate={getDate}
                    isValid={props.serviceerrordata}
                    draftData={PatientDraftInfo}
                    dob={sdob}
                    isedit={props.isedit}
                    updateAdd={updateAdd}
                    sadd={sadd}
                    ischeck={data.sameasservice}
                    leadPoolingId={DataPatientDraftInfo?.data?.leadPoolingId}
                    getpatientbynhs={getpatientbynhs}
                    patient={patient}
                    patientExist={patientExist}
                    hasLeadPatientnhsId={hasPatientnhsId}
                    hasLeadPatientlocalId={hasPatientlocalId}
                    data={data}
                    resetData={resetData}
                    clearcity={clearcity}
                    clearrel={clearrel}
                    checkSelfFunder={checkSelfFunder}
                    updatesmartsearchData={updatesmartsearchData}
                />
            </div>
        </>
    );
};

export default ServiceUserDetailContainer;

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm, useFieldArray, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { checkSplitFunding, getProductLines, getUomData, saveSplitFunding } from '../../../../service/invoicedetail-service';
import swal from 'sweetalert';
import warning from '../../../../assets/images/warning.png';
import { getPlanData } from '../../../../service/dashboard-service';
import { useDispatch, useSelector } from 'react-redux';
import { setTempSplitFundingAction } from '../../../../store/splitFunding/tempSplitFundingReducer';
import RenderModalContainer from '../../../../components/RenderModal/indexContainer';
import SplitFundingModalContainer from '../../../../components/SplitFundingModal/indexContainer';
import { RootState } from '../../../../store';
import eventEmitter from '../../../../utils/eventEmitter';

const InvoiceLinesView = (props: any) => {
    const { t } = useTranslation();
    let [line, setline] = useState<any>([]);
    let [product, setproduct] = useState<any>();
    let [uom, setuom] = useState<any>();
    let [isaddline, setisaddline] = useState<boolean>(false);
    let [showModal, setShowModal] = useState<boolean>(false);
    let [isaddplan, setisaddplan] = useState<boolean>(false);
    let [isformclear, setformclear] = useState<boolean>(false);
    let [untax, setuntax] = useState<any>(0);
    let [discount, setdiscount] = useState<Number>(0);
    let [total, settotal] = useState<any>(0);
    let [defaulttotal, setdefaulttotal] = useState<number>(0);
    let plans: any = [];
    let funders: any = [];
    var planlist: any = [];
    const dispatch = useDispatch();
    const { TempDataSplitFunding } = useSelector((state: RootState) => state.tempSplitFunding);
    useEffect(() => {
        GetUom();
    }, []);

    const validationSchema = Yup.object().shape({
        invoicelines: Yup.array().of(
            Yup.object().shape({
                product: Yup.string().notRequired(),
                productdesc: Yup.string().notRequired(),
                quantity: Yup.number()
                .test('decimal-places', 'No more than two decimal places allowed', (value) => {
                    if (value === undefined || value === null) return true;
                    const valueStr = value.toFixed(2); // Convert number to string with two decimal places
                    return /^(\d+(\.\d{1,2})?)?$/.test(valueStr);
                })
                .required(),
                uom: Yup.string().notRequired(),
                price: Yup.string().notRequired(),
                taxes: Yup.string().notRequired(),
                subtotal: Yup.string().notRequired(),
                action: Yup.mixed().notRequired()
            })
        )
    });

    const { register, control, handleSubmit, watch, getValues, resetField,reset, formState, setValue,setError } = useForm({ mode: 'all', resolver: yupResolver(validationSchema) });
    const { errors } = formState;
    const { fields, append, remove } = useFieldArray({ name: 'invoicelines', control });

    async function GetProductLines(id: any) {
        const result = await getProductLines(id);
        if (result.status === true) {
            setproduct(result.data);
        }
    }

    async function GetUom() {
        const result = await getUomData();
        if (result.status === true) {
            setuom(result.data);
        }
    }

    async function checksplit() {
        let data = {
            invoiceHeaderId: 0,
            admissionId: Number(props.admissionId),
            funderId: props.invoicefields?.funder !== null ? Number(props.invoicefields?.funder) : props.invoicefields?.funder,
            periodFrom: props.invoicefields?.periodfrom !== undefined ? window.$utils.dateFormatDDMMYYYY(props.invoicefields?.periodfrom) : undefined,
            periodTo: props.invoicefields?.periodto !== undefined ? window.$utils.dateFormatDDMMYYYY(props.invoicefields?.periodto) : undefined,
            invoiceDate: props.invoicefields?.invoicedate !== undefined ? window.$utils.dateFormatDDMMYYYY(props.invoicefields?.invoicedate) : undefined,
            dueDate: '',
            paymentReference: '',
            documentPath: '',
            documentName: '',
            notes: ''
        };

        const result = await checkSplitFunding(data);
        
        if (!result.status) {
            swal({
                title: '',
                text: result?.message + 'Are you sure you want to define split funding?',
                icon: warning,
                buttons: ['No', 'Yes'],
                dangerMode: true
            }).then(async (confirm: any) => {
                if (confirm) {
                    getplandata(props.admissionId);
                }
            });
        } else {
            let data = {
                admissionId: Number(props.admissionId),
                funderId: props.invoicefields?.funder !== null ? Number(props.invoicefields?.funder) : props.invoicefields?.funder,
                periodFrom: props.invoicefields?.periodfrom !== undefined ? window.$utils.dateFormatDDMMYYYY(props.invoicefields?.periodfrom) : undefined,
                periodTo: props.invoicefields?.periodto !== undefined ? window.$utils.dateFormatDDMMYYYY(props.invoicefields?.periodto) : undefined
            };
            GetProductLines(data);
        }
    }

    async function getplandata(aid: any) {
        const response = await getPlanData(aid);
        if (response.status == true) {
            plans = [];
            for (let j = 0; j < response.data.length; j++) {
                if (response.data[j].planType !== 0) {
                    let obj: any = {};
                    funders = [];
                    obj.corefee = response?.data[j]?.totalFees;
                    obj.admissiondate = response?.data[j]?.startDate?.toString();
                    obj.enddate = response?.data[j]?.endDate !== null ? response?.data[j]?.endDate?.toString() : '';
                    obj.socialfund = response?.data[j]?.socialServiceCharges || 0;
                    obj.healthfund = response?.data[j]?.healthcareChanges || 0;
                    obj.planId = response?.data[j]?.planId;

                    obj.dailyCoreFee = response?.data[j]?.dailyCoreFeeType === 1 ? Number(Number(response?.data[j]?.dailyCoreFee) * 7).toFixed(2) : Number(response?.data[j]?.dailyCoreFee);
                    obj.continenceCareFee= response?.data[j]?.continenceCareFeeType === 1 ? Number(Number(response?.data[j]?.continenceCareFee) * 7).toFixed(2) : Number(response?.data[j]?.continenceCareFee);
                    obj.fncFee = response?.data[j]?.fncFeeType === 1 ? Number(Number(response?.data[j]?.fncFee) * 7).toFixed(2) : Number(response?.data[j]?.fncFee);
                    obj.totalFees= response?.data[j]?.totalFees;

                    obj.continencefeetype = response?.data[j]?.continenceCareFeeType; 
                    obj.fncfeetype = response?.data[j]?.fncFeeType;
                    obj.dailyfeetype= Number(response?.data[j]?.calculatedDailyCoreFee);
                    obj.totalAdditionalServiceFee = response?.data[j]?.totalAdditionalServiceFee

                    for (let i = 0; i < response?.data[j]?.planDetails?.length; i++) {
                        funders.push({
                            planDetailId: response?.data[j]?.planDetails[i].planDetailId,
                            funderid: response?.data[j]?.planDetails[i].funderId,
                            fundername: response?.data[j]?.planDetails[i].funderName,
                            fundertype:
                                response?.data[j]?.planDetails[i].funderType === 1
                                    ? 'ICB'
                                    : response?.data[j]?.planDetails[i].funderType === 2
                                    ? 'LA'
                                    : response?.data[j]?.planDetails[i].funderType === 3
                                    ? 'Charity'
                                    : 'Self',
                            proportion: response?.data[j]?.planDetails[i].proportion,
                            proportionvalue: response?.data[j]?.planDetails[i].proportionValue,
                            isapplicable: response?.data[j]?.planDetails[i].isApplicable === 1 ? false : true,
                            ispercentage: response?.data[j]?.planDetails[i].amountType === 1 ? false : true
                        });

                        obj.funderlist = funders;
                    }
                    plans = [...plans, obj];
                    dispatch(setTempSplitFundingAction(plans));
                    setShowModal(true);
                }
            }
        }
    }

    const clearData = async () => {
        setformclear(true);
        setproduct(undefined);
        setTimeout(() => {
            setline([]);
            remove();
        }, 400);
    };

    useEffect(() => {
        if (isformclear) {
            setTimeout(() => {
                setformclear(false);
            }, 300);
        }
    }, [isformclear]);

    useEffect(() => {
        eventEmitter.on('clearData', clearData);
        return () => {
            eventEmitter.off('clearData', clearData);
        };
    });

      useEffect(() => {
        
        if (props.invoicedata === undefined) {
            setline([
                {
                    product: '',
                    productdessc: 0,
                    quantity: 0,
                    uom: '',
                    price: 0,
                    taxes: 0,
                    subtotal: 0,
                    action: false,
                    invoiceLineId: 0,
                    invoiceHeaderId: 0,
                    discountAmount: 0,
                    taxPercentage: 0,
                    taxedAmount: 0,
                    untaxedAmount: 0
                }
            ]);
            
            let data = {
                admissionId: Number(props.admissionId),
                funderId: Number(props.invoicefields?.funder),
                periodFrom: props.invoicefields?.periodfrom !== undefined ? window.$utils.dateFormatDDMMYYYY(props.invoicefields?.periodfrom) : undefined,
                periodTo: props.invoicefields?.periodto !== undefined ? window.$utils.dateFormatDDMMYYYY(props.invoicefields?.periodto) : undefined
            };
            if (
                props.admissionId !== undefined &&
                props.invoicefields?.funder !== undefined &&
                props.invoicefields?.periodto !== undefined &&
                props.invoicefields?.periodfrom !== undefined &&
                props.invoicefields?.invoicedate !== undefined &&
                !isformclear
            ) {
                checksplit();
            }
        } else {
            let data = {
                admissionId: Number(props.invoicedata?.admissionDetail?.admissionId),
                funderId: props.invoicedata?.invoiceModel?.funderId !== null ? Number(props.invoicedata?.invoiceModel?.funderId) : props.invoicedata?.invoiceModel?.funderId,
                periodFrom: props.invoicefields?.periodfrom !== undefined ? window.$utils.dateFormatDDMMYYYY(props.invoicefields?.periodfrom) : undefined,
                periodTo: props.invoicefields?.periodto !== undefined ? window.$utils.dateFormatDDMMYYYY(props.invoicefields?.periodto) : undefined
            };

            GetProductLines(data);
            if (props.duplicateStatus === false) {
                setuntax(props.invoicedata?.invoiceModel?.untaxedAmount);
                setdiscount(props.invoicedata?.invoiceModel?.discountAmount);
                settotal(Number(props.invoicedata?.invoiceModel?.total).toFixed(2));
                if (props.invoicedata?.invoiceModel?.invoiceLineModel?.length > 0) {
                        let lines = [];
                        for (let i = 0; i < props.invoicedata?.invoiceModel?.invoiceLineModel?.length; i++) {
                            let obj = {
                                product: props.invoicedata?.invoiceModel?.invoiceLineModel[i].productId,
                                productdessc: props.invoicedata?.invoiceModel?.invoiceLineModel[i].unitId,
                                quantity: props.invoicedata?.invoiceModel?.invoiceLineModel[i].quantity,
                                uom: props.invoicedata?.invoiceModel?.invoiceLineModel[i].unitId,
                                price: props.invoicedata?.invoiceModel?.invoiceLineModel[i].price,
                                taxes: '',
                                subtotal: Number(props.invoicedata?.invoiceModel?.invoiceLineModel[i].subTotal),
                                action: props.invoicedata?.invoiceModel?.invoiceLineModel[i].isAutoGenerated === 1 ? true : false,
                                invoiceLineId: props.invoicedata?.invoiceModel?.invoiceLineModel[i].invoiceLineId,
                                invoiceHeaderId: props.invoicedata?.invoiceModel?.invoiceLineModel[i].invoiceHeaderId,
                                discountAmount: Number(props.invoicedata?.invoiceModel?.invoiceLineModel[i].discountAmount),
                                taxPercentage: props.invoicedata?.invoiceModel?.invoiceLineModel[i].taxPercentage,
                                taxedAmount: Number(props.invoicedata?.invoiceModel?.invoiceLineModel[i].taxedAmount),
                                untaxedAmount: Number(props.invoicedata?.invoiceModel?.invoiceLineModel[i].untaxedAmount)
                            };
                            
                            lines.push(obj);
                        }
                        setline(lines);
                    }
            } else {
                    setline([]);
                    reset({invoicelines : []});
                    setproduct(undefined);
                    if( props.invoicefields?.periodfrom !== undefined &&
                        props.invoicefields?.periodto !== undefined){
                            let data = {
                                admissionId: Number(props.invoicedata?.admissionDetail?.admissionId),
                                funderId: props.invoicedata?.invoiceModel?.funderId !== null ? Number(props.invoicedata?.invoiceModel?.funderId) : props.invoicedata?.invoiceModel?.funderId,
                                periodFrom: props.invoicefields?.periodfrom !== undefined ? window.$utils.dateFormatDDMMYYYY(props.invoicefields?.periodfrom) : undefined,
                                periodTo: props.invoicefields?.periodto !== undefined ? window.$utils.dateFormatDDMMYYYY(props.invoicefields?.periodto) : undefined
                            };
                
                            GetProductLines(data);

                            setline([
                                {
                                    product: '',
                                    productdessc: 0,
                                    quantity: 0,
                                    uom: '',
                                    price: 0,
                                    taxes: 0,
                                    subtotal: 0,
                                    action: false,
                                    invoiceLineId: 0,
                                    invoiceHeaderId: 0,
                                    discountAmount: 0,
                                    taxPercentage: 0,
                                    taxedAmount: 0,
                                    untaxedAmount: 0
                                }
                            ]);
                        }

            }
        }
    }, [
        props.invoicedata,
        props.admissionId,
        props.duplicateStatus,
        props.invoicefields?.funder,
        props.invoicefields?.periodfrom,
        props.invoicefields?.periodto,
        props.invoicefields?.invoicedate,
        isformclear
    ]);
    useEffect(() => {
        
        const newVal = line?.length;
        const oldVal = fields.length;
        if (product !== undefined && uom !== undefined) {
            
            if (newVal > oldVal) {
                if (line.length > 0) {
                    for (let i = 0; i < line.length; i++) {
                        let total = ((Number(untax) + Number(line[i].subtotal)).toFixed(2));
                        setuntax((Number(untax) + Number(line[i].subtotal)).toFixed(2));
                        // if(props.invoicedata === undefined){
                        settotal((Number(total) + Number(discount)).toFixed(2));
                        // }

                        for (let j = 0; j < product.length; j++) {
                            if (Number(line[i].product) === product[j].productId) {
                                product[j].isActive = false;
                            }
                        }
                    }
                }
                for (let i = oldVal; i < newVal; i++) {
                    append(line[newVal - 1], {shouldFocus: false});
                    setValue(`invoicelines[${i}].product`, line[i].product);
                    setValue(`invoicelines[${i}].uom`, line[i].uom);
                    setValue(`invoicelines[${i}].action`, line[i].action);
                    setValue(`invoicelines[${i}].quantity`, line[i].quantity);
                    setValue(`invoicelines[${i}].price`, line[i].price);
                    setValue(`invoicelines[${i}].subtotal`, line[i].subtotal);
                }
            } else if (newVal === oldVal) {
                let stotal: any = 0;
                for (let i = 0; i < line.length; i++) {
                    setValue(`invoicelines[${i}].product`, line[i].product, { shouldValidate: true });
                    setValue(`invoicelines[${i}].uom`, line[i].uom, { shouldValidate: true });
                    setValue(`invoicelines[${i}].action`, line[i].action, { shouldValidate: true });
                    setValue(`invoicelines[${i}].quantity`, line[i].quantity, { shouldValidate: true });
                    setValue(`invoicelines[${i}].price`, line[i].price, { shouldValidate: true });
                    setValue(`invoicelines[${i}].subtotal`, line[i].subtotal, { shouldValidate: true });
                    stotal = (Number(line[i].subtotal) + Number(stotal)).toFixed(2);
                }
                setuntax(stotal);
                // if(props.invoicedata === undefined){
                let totaladis: any = 0;
                totaladis = (Number(stotal) - Number(discount)).toFixed(2);
                settotal(totaladis);
                // }
            }
            // else if (newVal < oldVal) {
            //     for(let i=0;i<line.length;i++){
            //     setuntax(Number(untax)-Number(line[i].subtotal));
            //     settotal(Number(untax)-Number(line[i].subtotal));
            //     }
            // }
        }
    }, [line, product, uom]);

    const addnewline = () => {
        eventEmitter.emit('eventInvoiceEdit');
        setline([
            ...line,
            {
                product: '',
                productdessc: 0,
                quantity: 0,
                uom: '',
                price: 0,
                taxes: 0,
                subtotal: 0,
                action: false,
                invoiceLineId: 0,
                invoiceHeaderId: 0,
                discountAmount: 0,
                taxPercentage: 0,
                taxedAmount: 0,
                untaxedAmount: 0
            }
        ]);
    };

    const removeline = (lineindex: any, data: any) => {
        eventEmitter.emit('eventInvoiceEdit');
        swal({
            title: '',
            text: 'Are you sure you want to delete the line?',
            icon: warning,
            buttons: ['No', 'Yes'],
            dangerMode: true
        }).then(async (confirm: any) => {
            if (confirm) {
                for(let i=0;i<product.length;i++){
                    if(Number(data?.product) === product[i]?.productId){
                        product[i].isActive = true;
                    }
                }
                remove(lineindex);
                const deletedLines = line.filter((data: any, index: number) => index !== lineindex);
                setline(deletedLines);
                let hasData = props.invoicedata?.invoiceModel?.invoiceLineModel.some((val: any) => val.productId === data.product);
                if (props.invoicedata?.invoiceModel?.invoiceLineModel !== undefined && hasData && props.invoicedata?.invoiceModel?.invoiceLineModel?.length > 0) {
                    props.deletedlines(props.invoicedata?.invoiceModel?.invoiceLineModel?.at(lineindex));
                }
            } else {
            }
        });
    };

    const updateData = (e: any, index: number) => {
        eventEmitter.emit('eventInvoiceEdit');
        if (e.target.name === `invoicelines.${index}.product`) {
            let temporaryarray = line.slice();
            product.filter((data: any) => {
                if (data.productId === Number(e.target.value)) {
                    if (data.admissionProductPrice === null) {
                        swal({
                            title: '',
                            text: 'Price not configured for this product on the respective admission, hence you are not allowed to add it. Please update price in admission to add the product on invoice.',
                            icon: warning,
                            dangerMode: true
                        });
                        return;
                    } else {
                        temporaryarray[index]['product'] = e.target.value;
                        temporaryarray[index]['price'] = Number(data.admissionProductPrice).toFixed(2);
                        temporaryarray[index]['quantity'] = Number(temporaryarray[index]['quantity']) === 0 ? 1 : Number(temporaryarray[index]['quantity']);
                        let stotal = (Number(temporaryarray[index]['quantity']) * Number(temporaryarray[index]['price'])).toFixed(2);
                        temporaryarray[index]['subtotal'] = stotal;
                        temporaryarray[index]['untaxedAmount'] = stotal;
                        temporaryarray[index]['uom'] = data.uom;
                        let total = (Number(untax) + Number(stotal));
                        
                        setuntax(Number(untax) + Number(stotal));
                        settotal(Number(total) - Number(discount));
                    }
                }
            });
            setline(temporaryarray);
        }
        if (e.target.name === `invoicelines.${index}.quantity`) {
            let temporaryarray = line.slice();
            if (Number(e.target.value) === 0 || isNaN(e.target.value) || Number(e.target.value) <= 0 || !/^[0-9]+$/.test(e.target.value)) {
                if (e.target.value === '' || isNaN(e.target.value)) {
                    const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
                    let periodfromDate: any = new Date(props?.invoicefields?.periodfrom);
                    let periodtoDate: any = new Date(props?.invoicefields?.periodto);
                    let daysDiff = Math.round(Math.abs((periodfromDate - periodtoDate) / oneDay));
                    daysDiff = daysDiff + 1;

                    swal({
                        title: '',
                        text:
                            e.target.value === ''
                                ? 'Product quantity cannot be empty. Value has been updated based on the Period From and To range.'
                                : 'Product quantity should be number. Value has been updated based on the Period From and To range.',
                        icon: warning,
                        dangerMode: true
                    });
                    e.target.value = daysDiff;
                    temporaryarray[index]['quantity'] = daysDiff;
                    setline(temporaryarray);
                    return;
                } else if (e.target.value == 0 || e.target.value <= 0) {
                    const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
                    let periodfromDate: any = new Date(props?.invoicefields?.periodfrom);
                    let periodtoDate: any = new Date(props?.invoicefields?.periodto);
                    let daysDiff = Math.round(Math.abs((periodfromDate - periodtoDate) / oneDay));
                    daysDiff = daysDiff + 1;
                    swal({
                        title: '',
                        text:
                            e.target.value == 0
                                ? 'Product quantity cannot be zero. Value has been updated based on the Period From and To range.'
                                : 'Product quantity cannot be negative. Value has been updated based on the Period From and To range.',
                        icon: warning,
                        dangerMode: true
                    });
                    e.target.value = daysDiff;
                    temporaryarray[index]['quantity'] = daysDiff;
                    setline(temporaryarray);
                    return;
                } else if (!/^$|^\d+(\.\d{1,2})?$/.test(e.target.value)) {
                    e.target.value = e.target.value.slice(0,-1)
                    // below commented code is for old implementation in quantity when you put unvalid input it was before opening a validation popup now we are not allowing to type in that field as same we do in costing
                    
                    // const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
                    // let periodfromDate: any = new Date(props?.invoicefields?.periodfrom);
                    // let periodtoDate: any = new Date(props?.invoicefields?.periodto);
                    // let daysDiff = Math.round(Math.abs((periodfromDate - periodtoDate) / oneDay));
                    // daysDiff = daysDiff + 1;
                    // // swal({
                    // //     title: '',
                    // //     text: 'Product quantity should be whole number. Value has been updated based on the Period From and To range.',
                    // //     icon: warning,
                    // //     dangerMode: true
                    // // });
                    // e.target.value = daysDiff;
                    // temporaryarray[index]['quantity'] = daysDiff;
                    // setline(temporaryarray);
                    return;
                }

                temporaryarray[index]['quantity'] = e.target.value !== '' ? (e.target.value == 0 ? 1 : e.target.value) : e.target.value;
                let stotal = (Number(temporaryarray[index]['quantity']) * Number(temporaryarray[index]['price'])).toFixed(2);
                temporaryarray[index]['subtotal'] = stotal;
                temporaryarray[index]['untaxedAmount'] = stotal;
            } else {
                temporaryarray[index]['quantity'] = e.target.value;
                let stotal = (Number(e.target.value) * Number(temporaryarray[index]['price'])).toFixed(2);
                temporaryarray[index]['subtotal'] = stotal;
                temporaryarray[index]['untaxedAmount'] = stotal;
                let total = Number(untax) + Number(stotal);
                setuntax(Number(untax) + Number(stotal));
                settotal(Number(total) - Number(discount));
            }
            setline(temporaryarray);
        }
        if (e.target.name === `invoicelines.${index}.uom`) {
            let temporaryarray = line.slice();
            temporaryarray[index]['uom'] = e.target.value;
            setline(temporaryarray);
        }
    };

    useEffect(() => {
        if (product !== undefined) {
            if (Object.keys(errors).length === 0) {
                if (getValues(`invoicelines.${line.length - 1}.product`) !== '' && getValues(`invoicelines.${line.length - 1}.uom`) !== '') {
                    setisaddline(true);
                    // props.getInvoicelines(line);
                    props.getInvoicelineerror(true);
                } else {
                    setisaddline(false);
                    props.getInvoicelineerror(false);
                }
            } else {
                setisaddline(false);
                // props.getInvoicelines([]);
                props.getInvoicelineerror(false);
            }
        } else {
            props.getInvoicelineerror(false);
        }
        if (formState.isValid === false) {
            props.getInvoicelineerror(false);
        }
        // 
        // 
    }, [formState]);

    useEffect(() => {
        if (line !== undefined && line.length > 0) {
            props.getInvoicelines(line);
        } else {
            props.getInvoicelines([]);
        }
    }, [line]);

    const savedata = () => {
        savesplitfunding();
        // dispatch(setSplitFundingAction(TempDataSplitFunding))
        // props.splitchange(true);
        setShowModal(false);
    };
    const addPlan = () => {};

    const toggle = () => {
        swal({
            title: '',
            text: 'Are you sure you want to close?',
            icon: warning,
            buttons: ['No', 'Yes'],
            dangerMode: true
        }).then(async (confirm: any) => {
            if (confirm) {
                setShowModal(false);
                // dispatch(removeTempSplitFundingAction())
            }
        });
    };

    async function savesplitfunding() {
      let fundersdata =[];
      planlist = [];
        if(TempDataSplitFunding.length > 0){
            
            for(let i=0;i<TempDataSplitFunding.length;i++){
                let obj : any ={};
                fundersdata=[];
                obj.planId = TempDataSplitFunding[i].planId;
                obj.weeklyCharge = TempDataSplitFunding[i].corefee;
                obj.StartDate = TempDataSplitFunding[i].admissiondate ? window.$utils.dateFormatDDMMYYYY(TempDataSplitFunding[i].admissiondate) : '';
                obj.endDate = TempDataSplitFunding[i].enddate ? window.$utils.dateFormatDDMMYYYY(TempDataSplitFunding[i].enddate) : '';

                obj.dailyCoreFee= TempDataSplitFunding[i].dailyfeetype === 1 ? Number(Number(TempDataSplitFunding[i].dailyCoreFee) / 7).toFixed(2) : Number(TempDataSplitFunding[i].dailyCoreFee);
                obj.dailyCoreFeeType= TempDataSplitFunding[i].dailyfeetype;

                obj.continenceCareFee= TempDataSplitFunding[i].continencefeetype === 1 ? Number(Number(TempDataSplitFunding[i].continenceCareFee) / 7).toFixed(2) : Number(TempDataSplitFunding[i].continenceCareFee);
                obj.continenceCareFeeType= TempDataSplitFunding[i].continencefeetype;

                obj.fncFee= TempDataSplitFunding[i].fncfeetype === 1 ? Number(Number(TempDataSplitFunding[i].fncFee) / 7).toFixed(2) : Number(TempDataSplitFunding[i].fncFee);
                obj.fncFeeType= TempDataSplitFunding[i].fncfeetype;
                obj.totalAdditionalServiceFee = TempDataSplitFunding[i].totalAdditionalServiceFee;

                for (let j = 0; j < TempDataSplitFunding[i].funderlist.length; j++) {
                    fundersdata.push({
                        planDetailId: TempDataSplitFunding[i].funderlist[j].planDetailId,
                        funderid: TempDataSplitFunding[i].funderlist[j].funderid === null ? null : Number(TempDataSplitFunding[i].funderlist[j].funderid),
                        amountType: TempDataSplitFunding[i].funderlist[j].ispercentage ? 2 : 1,
                        proportion: Number(TempDataSplitFunding[i].funderlist[j].proportion),
                        proportionValue: Number(TempDataSplitFunding[i].funderlist[j].proportionvalue),
                        isApplicable: TempDataSplitFunding[i].funderlist[j].isapplicable ? 0 : 1
                    });
                }

                obj.planDetails = fundersdata;
                planlist = [...planlist, obj];
            }
            let data = {
                admissionId: Number(props.admissionId),
                plans: planlist
            };
            const result = await saveSplitFunding(data);
            if (result.status) {
                swal(result?.message, {
                    icon: 'success'
                }).then(() => {
                    checksplit();
                });
            } else {
                swal(result?.message, {
                    icon: 'error'
                });
            }
        }
    }
    return (
        <div className="row p-3">
            <div className="col-md-12">
                <p className="purple-text lead-text bolder mb-3">{t('InvoiceDetail.Invoice_Lines')}</p>

                <div className="table-responsive table-custom">
                    <table className="table mb-0 ">
                        <thead>
                            <tr>
                                <th style={{ width: '18%' }}>{t('InvoiceDetail.Product')}</th>
                                <th style={{ width: '18%' }}>{t('InvoiceDetail.Product_Description')}</th>
                                <th style={{ width: '10%' }}>{t('InvoiceDetail.Quantity')}</th>
                                <th style={{ width: '16%' }}>{t('InvoiceDetail.UoM')}</th>
                                <th className="text-right" style={{ width: '8%' }}>
                                    {t('InvoiceDetail.Price')}
                                </th>
                                <th className="ps-5">{t('InvoiceDetail.Taxes')}</th>
                                <th className="text-right" style={{ width: '6%' }}>
                                    {t('InvoiceDetail.Subtotal')}
                                </th>
                                <th className="ps-5" style={{ width: '20%' }}>
                                    {t('InvoiceDetail.Action')}
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {line.length > 0 &&
                                fields.map((data: any, i: number) => {
                                    return (
                                        <tr>
                                            {/* <td>Per Day Rate</td> */}
                                            <td>
                                                {!props.invoiceDraftStatus && (
                                                    <p>{product !== undefined ? product?.filter((val: any) => val.productId === line[i]?.product)[0]?.productCode || '' : ''}</p>
                                                )}

                                                {props.invoiceDraftStatus && (
                                                    <select
                                                        {...register(`invoicelines.${i}.product`, {
                                                            onChange: (e: any) => {
                                                                updateData(e, i);
                                                            }
                                                        })}
                                                        name={`invoicelines.${i}.product` as const}
                                                        disabled={data.action}
                                                        id="product"
                                                        className={`form-control white`}
                                                    >
                                                        <option disabled={true} value="">
                                                            Select Product
                                                        </option>
                                                        {product !== undefined &&
                                                            product.map((prod: any) => {
                                                                return (
                                                                    <option key={prod.productId} value={prod.productId} disabled={prod.isActive === false ? true : false}>
                                                                        {prod.productCode}
                                                                    </option>
                                                                );
                                                            })}
                                                    </select>
                                                )}
                                            </td>
                                            <td className="whitespace-wrap">
                                                {!props.invoiceDraftStatus && (
                                                    <p>{product !== undefined ? product?.filter((val: any) => val?.productId === line[i]?.product)[0]?.productDescription || '' : ''}</p>
                                                )}
                                                {props.invoiceDraftStatus && (
                                                    <p>
                                                        {product !== undefined
                                                            ? product?.filter((val: any) => val?.productId === Number(getValues(`invoicelines.${i}.product`)))[0]?.productDescription || ''
                                                            : ''}
                                                    </p>
                                                )}
                                            </td>

                                            {/* <td>20</td> */}
                                            <td>
                                                {!props.invoiceDraftStatus && <p>{line[i]?.quantity}</p>}
                                                {props.invoiceDraftStatus && (
                                                    <input
                                                        type="number"
                                                        min="1"
                                                        step="1"
                                                        {...register(`invoicelines.${i}.quantity`, {
                                                            onChange: (e: any) => {
                                                                updateData(e, i);
                                                            }
                                                        })}
                                                        name={`invoicelines.${i}.quantity` as const}
                                                        // placeholder={`${t('VacantRightbar.fname')}`}
                                                        //@ts-ignore
                                                        className={`form-control white flex-7 pe-1 ${(errors.invoicelines as any)?.[i]?.quantity ? 'is-invalid' : ''}`}                                                     
                                                    />
                                                )}
                                            </td>
                                            {/* <td>Day</td> */}
                                            <td>
                                                {!props.invoiceDraftStatus && <p>{uom !== undefined ? uom?.filter((val: any) => val?.unitId === line[i]?.uom)[0]?.unitName || '' : ''}</p>}
                                                {props.invoiceDraftStatus && (
                                                    <select
                                                        {...register(`invoicelines.${i}.uom`, {
                                                            // onChange: (e: any) => {
                                                            //     updateData(e, i);
                                                            // }
                                                        })}
                                                        name={`invoicelines.${i}.uom` as const}
                                                        id="product"
                                                        disabled={true}
                                                        className={`form-control white`}
                                                    >
                                                        <option disabled={true} value="">
                                                            Select UoM
                                                        </option>
                                                        {uom !== undefined &&
                                                            uom.map((uomdata: any) => {
                                                                return (
                                                                    <option key={uomdata.unitId} value={uomdata.unitId}>
                                                                        {uomdata.unitName}
                                                                    </option>
                                                                );
                                                            })}
                                                    </select>
                                                )}
                                            </td>
                                            <td className="text-right">£ {line[i]?.price}</td>
                                            <td className="ps-5">
                                                <span className="status">Zero rated sales</span>
                                            </td>
                                            <td className="text-right">£ {line[i]?.subtotal}</td>
                                            <td className="ps-5">
                                                {props.invoiceDraftStatus && getValues(`invoicelines.${i}.action`) === false && (
                                                    <a onClick={(e: any) => removeline(i, line[i])} className="ms-3">
                                                        <i className="fa-solid fa-trash danger-text"></i>
                                                    </a>
                                                )}
                                            </td>
                                        </tr>
                                    );
                                })}
                        </tbody>
                    </table>
                </div>
                <div className="ps-0 pt-3 pb-3">
                    {(props.invoiceDraftStatus && line?.length !== product?.length) && (
                        <button disabled={!isaddline} onClick={(e: any) => addnewline()} className="btn btn-secondary btn-sm">
                            {' '}
                            <i className="fa fa-plus"></i> {t('InvoiceDetail.Add_Lines')}
                        </button>
                    )}
                </div>
                <div className="total-div" >
                    {line?.length > 0 && 
                    <>
                     <p className="d-flex justify-content-between ps-3 pe-3 pt-3 lead-text">
                        {t('InvoiceDetail.Untaxed_Amount')} <span className="bolder">£ {untax}</span>
                    </p>
                    {Number(discount) > 0 &&
                         <p className="d-flex justify-content-between ps-3 pe-3 pt-2 lead-text">
                         {t('InvoiceDetail.Discount')} <span className="bolder">£ {discount}</span>
                     </p>
                    }
                    <p className="d-flex justify-content-between ps-3 pe-3 pt-2 lead-text">
                        {' '}
                        {t('InvoiceDetail.Tax')}
                        <span className="bolder">0.00</span>
                    </p>
                    <p className="d-flex justify-content-between ps-3 pe-3 pt-2 dark-gray-bg pb-2 mt-3 lead-text">
                        {t('InvoiceDetail.Total')} <span className="bolder">£ {total}</span>
                    </p>
                    </>}
                   
                </div>
            </div>
            <RenderModalContainer>
                {showModal && <SplitFundingModalContainer onAddplan={addPlan} onSave={savedata} onClose={toggle} isaddplan={isaddplan} iseditable={true} funderlist={[]} />}
            </RenderModalContainer>
        </div>
    );
};

export default InvoiceLinesView;

import React from 'react';
import LoginView from '../../pages/LoginPage/indexView';
import CircularProgressBarView from './CircularProgressbarView';

const CircularProgressBarContainer = (props:any) => {
    return (
        <div>
            <CircularProgressBarView progressValue={props.progressValue} />
        </div>
    );
};

export default CircularProgressBarContainer;

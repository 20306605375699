import { OnboardingProgressPayload, OnboardingProgressType } from '../../utils/model';
import { createSlice } from '@reduxjs/toolkit';

const initialState: OnboardingProgressType = {
    stageNumber: undefined,
    stageHeading: undefined,
    stageDescription: undefined
};

export const onboardingProgressSlice = createSlice({
    name: 'onboardingProgress',
    initialState,
    reducers: {
        setOnboardingProgress: (state, { payload }: OnboardingProgressPayload) => {
            state.stageNumber = payload.stageNumber;
            state.stageHeading = payload.stageHeading;
            state.stageDescription = payload.stageDescription;
        }
    }
});

export const { setOnboardingProgress } = onboardingProgressSlice.actions;

export default onboardingProgressSlice.reducer;

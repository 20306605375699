import React, { useImperativeHandle, useState } from 'react';
import { DragSourceMonitor, useDrag, useDrop } from 'react-dnd';

const DroppableImageComponent = React.forwardRef((props: { topIndex: number; swapImages: any }, ref) => {
    const ImageRef = React.useRef<HTMLDivElement>(null);
    const [image, setImage] = useState<{ id: number; index: number; imagePath: string; imageName: string; topIndex: number } | null>(null);

    const { topIndex, swapImages } = props;

    const [{ isOver }, drop] = useDrop({
        accept: ['draggableImage', 'draggableTopImage'],
        drop: async (item: { id: number; index: number; imagePath: string; imageName: string; topIndex?: number }, monitor) => {
            const itemType = monitor.getItemType();
            if (itemType === 'draggableImage') {
                setImage({ ...item, topIndex });
            } else if (itemType === 'draggableTopImage') {
                const temp = image;
                setImage({ ...item, topIndex });
                swapImages(item.topIndex, temp);
            }
        },
        collect: (monitor) => ({
            isOver: !!monitor.isOver()
        })
    });
    useImperativeHandle(ref, () => ({
        image,
        setImage
    }));

    const [{ isDragging }, drag] = useDrag({
        type: 'draggableTopImage',
        item: { id: image?.id, index: image?.index, imagePath: image?.imagePath, imageName: image?.imageName, topIndex },
        collect: (monitor: DragSourceMonitor) => ({
            isDragging: monitor.isDragging()
        })
    });

    drag(drop(ImageRef));

    return (
        <div
            ref={ImageRef}
            className="gallery-img position-relative"
            style={isOver ? { filter: 'brightness(70%)', border: '2px dashed black', borderRadius: 15 } : {}}
        >
            {image && <img className="img-fluid" style={isOver ? { filter: 'brightness(70%)', borderRadius: 15 } : {}} src={image.imagePath} alt={image.imageName} />}
            {image && (
                <div
                    className="edit-icon-preview article-menu float-end img-above-edit-icon-preview cursor-pointer"
                    data-tooltip-id="my-tooltip"
                    data-tooltip-content="Delete Image"
                    data-tooltip-place="top"
                    onClick={() => {
                        setImage(null);
                    }}
                >
                    <i className="fa fa-trash" aria-hidden="true"></i>
                </div>
            )}
        </div>
    );
});

export default DroppableImageComponent;

import React, { useRef, useState, useEffect, ChangeEvent } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import ModalWrapperContainer from '../ModalWrapper/indexContainer';
import { Tooltip } from 'react-tooltip';
import { useTranslation } from 'react-i18next';
import ReactToPrint from 'react-to-print';
import PrintCostingContainer from '../../pages/ReferralManagementPage/ReferralManager/PrintCosting/PrintCostingContainer';
import RenderModalContainer from '../RenderModal/indexContainer';
import SendPrintCostingContainer from './SendPrintCostingModal/SendPrintCostingContainer';
import warning from '../../assets/images/warning.png';
import swal from 'sweetalert';
import { ModulesAccessRights } from '../../utils/constant';
import { getCostingRecipients, getCostingemailDetails, getadditionalfields } from '../../service/costing';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useFieldArray, useForm } from 'react-hook-form';
import AddYourCommentModalView from '../AddYourCommentModal/AddYourCommentModalView';
import AddYourCommentModalContainer from '../AddYourCommentModal/AddYourCommentModalContainer';
import eventEmitter from '../../utils/eventEmitter';

interface ModalProps {
    onClose: () => any;
    title: string;
    content: any;
    className: any;
    viewCosting: any;
    referralid: any;
    serviceName: any;
    mode?: any;
    saveCosting: any;
}

const CostingModalView = (props: ModalProps) => {
    const { StatusLogin, DataLogin } = useSelector((state: RootState) => state.login);
    const { ShowClass, FormName, Data } = useSelector((state: RootState) => state.navigation);
    const [showModal, setShowModal] = useState(false);
    const [hasAccessSNDPRNT, setAccessSNDPRNT] = useState<any>(false);
    const [costingRecepients, setCostingRecepients] = useState<any>([]);
    const [selectedValue, setSelectedValue] = useState<any>([]);

    const [emailData, setCostingEmailData] = useState<any>(undefined);
    let [amountChanged, setAmountChanged] = useState<Number>(0);
    const [total, set_total] = useState<number>();
    let [datavalid, setdatavalid] = useState<boolean>(false);
    const [isDisabled, setDisabled] = useState(true);
    let [additionalField, setadditionalField] = useState<any>([]);
    let [line, setline] = useState<any>([]);
    let [additionalvalues, setadditionalvalues] = useState<any>([]);
    const [showAddCommentModal, setShowAddCommentModal] = useState<boolean>(false);
    const [commentText, setCommentText] = useState<string | null>("")
    const commonStringValidator = Yup.string()
        .matches(/^(?:\d*\.\d{1,2}|\d+)$/)
        .required('Is required');

    const validationSchema = Yup.object().shape({
        serviceUserName: Yup.string().notRequired(),
        serviceUserId: Yup.string().notRequired(),
        serviceLocation: Yup.string().notRequired(),
        accommodation: commonStringValidator,
        baselineStaffCost: commonStringValidator,
        enhancedStaffingCost: commonStringValidator,
        mdt: commonStringValidator,
        transport: commonStringValidator,
        managementAndAdministration: commonStringValidator,
        total: Yup.string().notRequired(),
        additionalfield: Yup.array().of(
            Yup.object().shape({
                costingTypeId: Yup.string().required(),
                amount: commonStringValidator
            })
        )
    });
    const { register, reset, control, formState, setValue, watch } = useForm({ mode: 'all', resolver: yupResolver(validationSchema) });
    const { errors } = formState;
    const { fields, append, remove } = useFieldArray({ name: 'additionalfield', control });

    const [costingInput, setCostingInput] = useState<any>({
        accommodation: '',
        baselineStaffCost: '',
        enhancedStaffingCost: '',
        mdt: '',
        transport: '',
        managementAndAdministration: '',
        total: 0
    });

    const handleClickModal = () => {
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };
    const toggle = () => {
        swal({
            title: '',
            text: 'Are you sure you want to close?',
            icon: warning,
            buttons: ['No', 'Yes'],
            dangerMode: true
        }).then(async (confirm: any) => {
            if (confirm) {
                setShowModal(false);
            }
        });
    };

    const toggleComment = () => {
        setShowAddCommentModal(false);
    };
    const { t } = useTranslation();
    const componentRef = useRef<HTMLDivElement>(null);
    const printPriviewButton = useRef<any>(null);

    const onClickPrintPriview = () => {
        printPriviewButton.current.click();
    };

    const handleChangeInput = (e: any, i: any) => {
        let name = e.target.name;
        let value = e.target.value;

        const validated = value.match(/^(\d*\.{0,1}\d{0,2}$)/);

        if (!validated) {
            var splitVal = value.split('.');
            if (splitVal.length == 2 && splitVal[1].length > 2) {
                // user entered invalid input
                e.target.value = splitVal[0] + '.' + splitVal[1].substr(0, 2);
            }

            //e.target.value = '';
            return;
        }
        if (isNaN(value) || value == '') {
            const newValues = {
                ...costingInput,
                [name]: ''
            };
            setCostingInput(newValues);
        } else {
            const newValues = {
                ...costingInput,
                [name]: value
            };

            setCostingInput(newValues);
        }
        setDisabled(false);
        setAmountChanged(value);

    };

    async function GetAdditionalfields() {
        const result = await getadditionalfields();
        if (result.status === true) {
            setadditionalField(result.data.result);
        }
    }

    useEffect(() => {
        console.log(additionalField);
    }, [additionalField]);

    useEffect(() => {
        GetAdditionalfields();
    }, []);

    useEffect(() => {
        const fetchModule = DataLogin?.userModel?.modules?.filter((item: any) => item?.module === ModulesAccessRights.ReferralManagement.moduleName);
        const fetchAccessSendPrint =
            fetchModule !== undefined && fetchModule[0]?.userAccess.length > 0
                ? fetchModule[0]?.userAccess?.filter((item: any) => item?.accessCode === ModulesAccessRights.ReferralManagement.userAccess.accessCode)
                : undefined;
        if (fetchAccessSendPrint !== undefined && fetchAccessSendPrint?.length > 0 && fetchAccessSendPrint[0].accessCode === ModulesAccessRights.ReferralManagement.userAccess.accessCode) {
            setAccessSNDPRNT(true);
        }
    }, [DataLogin]);
    useEffect(() => {
        if (Data?.leadid !== undefined && Data?.leadid !== null) {
            const fetchCostingRecipients = async () => {
                const response = await getCostingRecipients(Data?.leadid);
                if (response?.data?.length > 0) {
                    setCostingRecepients(response?.data);
                }
            };
            fetchCostingRecipients();
        }
        if (props?.viewCosting?.rcId !== undefined && props?.viewCosting?.rcId !== null) {
            const fetchCostingemailDetails = async () => {
                const response = await getCostingemailDetails(props?.viewCosting?.rcId);
                if (response?.data !== '') {
                    setCostingEmailData(response?.data);
                }
            };
            fetchCostingemailDetails();
        }
    }, []);

    useEffect(() => {
        // Calling the method to sum the value
        calc_total(costingInput, additionalvalues);
    }, [amountChanged, line]);

    const calc_total = (newValues: any, additional: any) => {
        const { accommodation, baselineStaffCost, enhancedStaffingCost, mdt, transport, managementAndAdministration } = newValues;
        const newTotal = Number(accommodation) + Number(baselineStaffCost) + Number(enhancedStaffingCost) + Number(mdt) + Number(transport) + Number(managementAndAdministration);
        var additionalsum: number = 0;
        if (line?.length > 0) {
            for (let i = 0; i < line?.length; i++) {
                if (line[i]?.amount !== "") {
                    additionalsum = additionalsum + Number(line[i]?.amount);
                }
            }
        }
        console.log(additionalsum);
        set_total(newTotal + additionalsum);

        setCostingInput((old: any) => ({
            ...old,
            total: (newTotal + additionalsum)
        }));

        setAmountChanged(newTotal);
    };

    useEffect(() => {
        if (formState.isValid) {
            setdatavalid(true);
        } else {
            setdatavalid(false);
        }
    }, [formState]);

    useEffect(() => {
        if (props.viewCosting !== undefined && props.viewCosting !== '') {
            if (props.viewCosting?.rcId > 0) {
                setValue('accommodation', props.viewCosting.accommodation || '', { shouldValidate: props.viewCosting.accommodation > 0 ? true : false });
                setValue('baselineStaffCost', props.viewCosting.baselineStaffCost || '', { shouldValidate: props.viewCosting.baselineStaffCost > 0 ? true : false });
                setValue('enhancedStaffingCost', props.viewCosting?.enhancedStaffingCost || '', { shouldValidate: props.viewCosting.enhancedStaffingCost > 0 ? true : false });
                setValue('mdt', props.viewCosting.mdt || '', { shouldValidate: props.viewCosting.mdt > 0 ? true : false });
                setValue('transport', props.viewCosting.transport || '', { shouldValidate: props.viewCosting.transport > 0 ? true : false });
                setValue('managementAndAdministration', props.viewCosting.managementAndAdministration || '', { shouldValidate: props.viewCosting.managementAndAdministration > 0 ? true : false });
                setValue('total', props.viewCosting?.total?.toFixed(2) || '');

                set_total(props.viewCosting?.total);
                setline(props.viewCosting?.costingLists);
            }
            const newValues = {
                ...costingInput,
                rcId: props?.viewCosting?.rcId || 0,
                loadPoolingId: props.viewCosting?.loadPoolingId,
                serviceUserName: props.viewCosting?.serviceUserName,
                serviceUserId: props.viewCosting?.serviceUserId,
                serviceLocation: props.viewCosting?.serviceLocation,
                accommodation: props.viewCosting?.accommodation || 0,
                baselineStaffCost: props.viewCosting?.baselineStaffCost || 0,
                enhancedStaffingCost: props.viewCosting?.enhancedStaffingCost || 0,
                mdt: props.viewCosting?.mdt || 0,
                transport: props.viewCosting?.transport || 0,
                managementAndAdministration: props.viewCosting?.managementAndAdministration || 0,
                total: props.viewCosting?.total || '',
                isRevisionStatus: false,
                isRevised: false
            };
            setadditionalvalues(props.viewCosting?.costingLists);
            setCostingInput(newValues);
        }
    }, [props.viewCosting]);

    const saveUpdateCosting = () => {
        swal({
            title: '',
            text: `Are you sure you want to ${props?.viewCosting?.rcId !== undefined && props?.viewCosting?.rcId !== null ? `update` : 'submit'} the costing for lead ${props.referralid} ?`,
            icon: warning,
            buttons: ['No', 'Yes'],
            dangerMode: true
        }).then(async (confirm: any) => {
            if (confirm) {
                props.saveCosting(costingInput, additionalvalues);
            }
        });
    };

    const handleClick = (event: any) => {
        event.currentTarget.disabled = true;
        setDisabled(true);
    };

    useEffect(() => {
        const newVal = line?.length;
        const oldVal = fields.length;

        if (line.length === 0 && props?.viewCosting?.costingLists?.length === 0) {
            setDisabled(false);
        }
        if (additionalField.length > 0) {
            if (newVal > oldVal) {
                if (line.length > 0) {
                    for (let i = 0; i < line.length; i++) {
                        for (let j = 0; j < additionalField.length; j++) {
                            if (Number(line[i].costingTypeId) === additionalField[j].id) {
                                additionalField[j].isActive = false;
                            }
                        }
                    }
                }

                for (let i = oldVal; i < newVal; i++) {
                    append(line[newVal - 1], { shouldFocus: false });
                    setValue(`additionalfield[${i}].costingTypeId`, line[i].costingTypeId);
                    setValue(`additionalfield[${i}].amount`, line[i].amount);
                    setValue(`additionalfield[${i}].costingTypeName`, line[i].costingTypeName);
                }
            } else if (newVal === oldVal) {
                let stotal: any = 0;
                for (let i = 0; i < line.length; i++) {
                    setValue(`additionalfield[${i}].costingTypeId`, line[i].costingTypeId, { shouldValidate: true });
                    setValue(`additionalfield[${i}].amount`, line[i].amount, { shouldValidate: true });
                    setValue(`additionalfield[${i}].costingTypeName`, line[i].costingTypeName, { shouldValidate: true });
                }
            } else {

            }
        }
    }, [line, additionalField]);

    const addRow = () => {
        if (line.length === 0) {
            setDisabled(true);
            setline([
                ...line,
                {
                    costingTypeId: '',
                    amount: '',
                    costingTypeName: null
                }
            ]);
        }
        else {
            for (let i = 0; i < line.length; i++) {
                if (line[line.length - 1].costingTypeId !== "" && line[line.length - 1].amount !== '') {
                    setDisabled(true);
                    setline([
                        ...line,
                        {
                            costingTypeId: '',
                            amount: '',
                            costingTypeName: null
                        }
                    ]);
                }
                else {

                }
            }
        }
    };

    const updateData = (e: any, index: number) => {
        let temporaryarray = line.slice();
        additionalField.filter((data: any) => {
            if (e.target.name === `additionalfield.${index}.costingTypeId`) {
                if (data.id === Number(e.target.value)) {
                    temporaryarray[index]['costingTypeId'] = Number(e.target.value);
                }
            } else if (e.target.name === `additionalfield.${index}.amount`) {

                if (e.target.value.includes('.')) {
                    var splitVal = e.target.value.split('.');
                    if (splitVal.length == 2 && splitVal[1].length > 2) {
                        // user entered invalid input
                        temporaryarray[index]['amount'] = splitVal[0] + '.' + splitVal[1].substr(0, 2);
                    } else {
                        temporaryarray[index]['amount'] = splitVal[0] + '.' + splitVal[1].substr(0, 2);
                    }
                }
                else {
                    if (e.target.value.length < 7) {
                        temporaryarray[index]['amount'] = e.target.value;
                    }

                }

            }
        });
        if (temporaryarray[index]['amount'] !== "" && temporaryarray[index]['costingTypeId'] !== "") {
            setDisabled(false);
        } else {
            setDisabled(true);
        }
        setadditionalvalues(temporaryarray);
        setline(temporaryarray);
    };

    useEffect(() => {
        let temporaryarray = line.slice();
        const index = temporaryarray.findIndex((item: any) => item.costingTypeId === 7);
        additionalField.filter((data: any) => {
            if (!temporaryarray[index]) {
                temporaryarray[index] = {}; 
            }
            temporaryarray[index]['costingTypeName'] = commentText ? commentText : '';
        })
        setadditionalvalues(temporaryarray);
    }, [commentText])
    const removeline = (lineindex: any, data: any) => {
        swal({
            title: '',
            text: 'Are you sure you want to delete the field?',
            icon: warning,
            buttons: ['No', 'Yes'],
            dangerMode: true
        }).then(async (confirm: any) => {
            if (confirm) {
                for (let i = 0; i < additionalField.length; i++) {
                    if (data.costingTypeId === additionalField[i].id) {
                        additionalField[i].isActive = true;
                    }
                }
                const newSelectedValues = selectedValue.filter((a: any, index: any) => index !== lineindex);
                setSelectedValue(newSelectedValues);
                remove(lineindex);
                const deletedLines = line.filter((data: any, index: number) => index !== lineindex);
                setline(deletedLines);
                setadditionalvalues(deletedLines);
                setDisabled(false);
                // let hasData = props.invoicedata?.invoiceModel?.invoiceLineModel.some((val: any) => val.productId === data.product);
                // if (props.invoicedata?.invoiceModel?.invoiceLineModel !== undefined && hasData && props.invoicedata?.invoiceModel?.invoiceLineModel?.length > 0) {
                //     props.deletedlines(props.invoicedata?.invoiceModel?.invoiceLineModel?.at(lineindex));
                // }
            } else {
            }
        });
    };

    console.log(errors);
    const handleAddComment = (e: any) => {
        e.stopPropagation();
        setShowAddCommentModal(true);
    }

    useEffect(() => {
        const handleCommentStateChange = (value: string) => {
            setCommentText(value !== '' ? value : null);
        };
        eventEmitter.on('commentStateChange', handleCommentStateChange);
        return () => {
            eventEmitter.off('commentStateChange', handleCommentStateChange);
        };
    });

    useEffect(() => {
        const handleupdateBtnDisable = (value: boolean | null) => {
            if (value !== null) {
                setDisabled(value)
            }
        };
        eventEmitter.on('updateBtnDisable', handleupdateBtnDisable);
        return () => {
            eventEmitter.off('updateBtnDisable', handleupdateBtnDisable);
        };
    });

    const commentLinkName = () => {
        let  name = t('Referral_Costing.Add_Comment');
    
        if (props?.viewCosting?.costingLists?.length > 0) {
            if (props?.viewCosting?.costingLists?.find((item: any) => item?.costingTypeId === 7)?.costingTypeName &&
                line?.find((item: any) => item?.costingTypeId === 7)?.costingTypeName) {
                    name = t('Referral_Costing.Edit_Comment');
            } else {
                name = t('Referral_Costing.Add_Comment');
            }
        } else {
            if (line?.find((item: any) => item?.costingTypeId === 7)?.costingTypeName) {
                name = t('Referral_Costing.Edit_Comment');
            } else {
                name = t('Referral_Costing.Add_Comment');
            }
        }
        return name
    }
    return (
        <div>
            <ModalWrapperContainer
                title={props.title}
                onClose={props.onClose}
                className="modal modal-small costing-modal"
                content={
                    <div>
                        <div className="text-right mb-2">
                            {hasAccessSNDPRNT && props.mode !== 'edit' && (
                                <button
                                    className="btn btn-white bold btn-sm me-1"
                                    data-tooltip-id="my-tooltip-assessment"
                                    data-tooltip-content="Send & Print Costing"
                                    onClick={() => handleClickModal()}
                                >
                                    <i className="fa-solid fa-envelope"></i> {t('SendPrintCosting.Send_Print')}
                                </button>
                            )}
                            {props?.viewCosting?.rcId !== undefined && props?.viewCosting?.rcId !== null && (
                                <ReactToPrint
                                    trigger={() => (
                                        <button
                                            className="btn btn-white bold btn-sm me-2 ms-2"
                                            ref={printPriviewButton}
                                            data-tooltip-id="my-tooltip-assessment"
                                            data-tooltip-content="Print/Download Costing"
                                        >
                                            <i className="fa-sharp fa-solid fa-download"></i> {t('Referral_Costing.Download')}
                                        </button>
                                    )}
                                    content={() => componentRef.current}
                                    onBeforePrint={() => {
                                        document.title = `Costing_${props.referralid}_${props.serviceName}`;
                                    }}
                                />
                            )}
                        </div>
                        <p className="purple-text bold mb-3">Comprehensive {props.viewCosting?.serviceTypeName} Costing</p>
                        <div className="row">
                            <div className="col-md-4">
                                <label>{t('Referral_Costing.Service_Name')}: </label>
                                <p className="bold">{props.viewCosting?.serviceUserName}</p>
                            </div>
                            <div className="col-md-4">
                                <label>{t('Referral_Costing.Service_Id')}: </label>
                                <p className="bold">{props.viewCosting?.serviceUserId}</p>
                            </div>
                            <div className="col-md-4">
                                <label>{t('Referral_Costing.Proposed_Location')}:</label>
                                <p className="bold">{props.viewCosting?.serviceLocation}</p>
                            </div>
                        </div>
                        <div className="row mt-3">
                            <div className="col-lg-9">
                                <div className="table-radius">
                                    <table className="table table-border table-custom-border border-0 mb-0">
                                        <tr>
                                            <td>
                                                <p className="purple-text bold p-0">{t('Referral_Costing.Accommodation')}</p>
                                            </td>
                                            <td>
                                                <p className="bold mb-0 p-0">
                                                    £{' '}
                                                    {props.mode === 'edit' ? (
                                                        <input
                                                            type="number"
                                                            min="0"
                                                            className={`form-control costing-input '${errors.accommodation ? 'is-invalid' : ''}`}
                                                            {...register('accommodation')}
                                                            name={'accommodation' as const}
                                                            onChange={e => handleChangeInput(e, 0)}
                                                        />
                                                    ) : (
                                                        props.viewCosting?.accommodation
                                                    )}
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <p className="purple-text bold p-0">{t('Referral_Costing.Baseline_Staff_Cost')}</p>
                                            </td>
                                            <td>
                                                <p className="bold mb-0 p-0">
                                                    £{' '}
                                                    {props.mode === 'edit' ? (
                                                        <input
                                                            type="number"
                                                            min="0"
                                                            className={`form-control costing-input '${errors.baselineStaffCost ? 'is-invalid' : ''}`}
                                                            {...register('baselineStaffCost')}
                                                            name="baselineStaffCost"
                                                            onChange={e => handleChangeInput(e, 0)}
                                                        />
                                                    ) : (
                                                        props.viewCosting?.baselineStaffCost
                                                    )}
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <p className="purple-text bold p-0">{t('Referral_Costing.Enhanced_Staffing_costs')}</p>
                                            </td>
                                            <td>
                                                <p className="bold mb-0 p-0">
                                                    £{' '}
                                                    {props.mode === 'edit' ? (
                                                        <input
                                                            type="number"
                                                            min="0"
                                                            className={`form-control costing-input '${errors.enhancedStaffingCost ? 'is-invalid' : ''}`}
                                                            {...register('enhancedStaffingCost')}
                                                            name="enhancedStaffingCost"
                                                            onChange={e => handleChangeInput(e, 0)}
                                                        />
                                                    ) : (
                                                        props.viewCosting?.enhancedStaffingCost
                                                    )}
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <p className="purple-text bold p-0">{t('Referral_Costing.MDT')}</p>
                                            </td>
                                            <td>
                                                <p className="bold mb-0 p-0">
                                                    £{' '}
                                                    {props.mode === 'edit' ? (
                                                        <input
                                                            type="number"
                                                            min="0"
                                                            className={`form-control costing-input '${errors.mdt ? 'is-invalid' : ''}`}
                                                            {...register('mdt')}
                                                            name="mdt"
                                                            onChange={e => handleChangeInput(e, 0)}
                                                        />
                                                    ) : (
                                                        props.viewCosting?.mdt
                                                    )}
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <p className="purple-text bold p-0">{t('Referral_Costing.Transport')}</p>
                                            </td>
                                            <td>
                                                <p className="bold mb-0 p-0">
                                                    £{' '}
                                                    {props.mode === 'edit' ? (
                                                        <input
                                                            type="number"
                                                            min="0"
                                                            className={`form-control costing-input '${errors.transport ? 'is-invalid' : ''}`}
                                                            {...register('transport')}
                                                            name="transport"
                                                            onChange={e => handleChangeInput(e, 0)}
                                                        />
                                                    ) : (
                                                        props.viewCosting?.transport
                                                    )}
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <p className="purple-text bold p-0">{t('Referral_Costing.Management_Administration')}</p>
                                            </td>
                                            <td>
                                                <p className="bold mb-0 p-0">
                                                    £{' '}
                                                    {props.mode === 'edit' ? (
                                                        <input
                                                            type="number"
                                                            min="0"
                                                            className={`form-control costing-input '${errors.managementAndAdministration ? 'is-invalid' : ''}`}
                                                            {...register('managementAndAdministration')}
                                                            name="managementAndAdministration"
                                                            onChange={e => handleChangeInput(e, 0)}
                                                        />
                                                    ) : (
                                                        props.viewCosting?.managementAndAdministration
                                                    )}
                                                </p>
                                            </td>
                                        </tr>
                                        {line?.length > 0 &&
                                            fields?.map((data: any, i: number) => {
                                                return (
                                                    <tr>
                                                        <td>
                                                            {props.mode === 'edit' ? (
                                                                <div className='position-relative p-0'>
                                                                    <select
                                                                        {...register(`additionalfield.${i}.costingTypeId`, {
                                                                            onChange: (e: any) => {
                                                                                const newSelectedValues = [...selectedValue];
                                                                                newSelectedValues[i] = e.target.value;
                                                                                setSelectedValue(newSelectedValues);
                                                                                updateData(e, i);
                                                                            }
                                                                        })}
                                                                        name={`additionalfield.${i}.costingTypeId` as const}
                                                                        // disabled={data.action}
                                                                        //@ts-ignore
                                                                        className={`form-control white ${errors.additionalfield?.[i]?.costingTypeId ? 'is-invalid' : ''}`}
                                                                    >
                                                                        <option disabled={true} value="">
                                                                            Select Type
                                                                        </option>
                                                                        {additionalField !== undefined &&
                                                                            additionalField.map((prod: any) => {
                                                                                return (
                                                                                    <option key={prod.id} value={prod.id} disabled={prod.isActive === false ? true : false}>
                                                                                        {prod.value}
                                                                                    </option>
                                                                                );
                                                                            })}
                                                                    </select>

                                                                    {(selectedValue[i] == '7' || data.costingTypeId == '7') && (
                                                                        <a href="#" onClick={(e) => handleAddComment(e)} className='add-comments'>
                                                                            {commentLinkName()}
                                                                        </a>
                                                                    )}
                                                                </div>
                                                            ) : (<div className='position-relative p-0'>
                                                                <select
                                                                    {...register(`additionalfield.${i}.costingTypeId`, {
                                                                        onChange: (e: any) => {
                                                                            const newSelectedValues = [...selectedValue];
                                                                            newSelectedValues[i] = e.target.value;
                                                                            setSelectedValue(newSelectedValues);
                                                                            updateData(e, i);
                                                                        }
                                                                    })}
                                                                    name={`additionalfield.${i}.costingTypeId` as const}
                                                                    // disabled={data.action}
                                                                    disabled={true}
                                                                    //@ts-ignore
                                                                    className={`form-control white ${errors.additionalfield?.[i]?.costingTypeId ? 'is-invalid' : ''}`}
                                                                >
                                                                    <option disabled={true} value="">
                                                                        Select Type
                                                                    </option>
                                                                    {additionalField !== undefined &&
                                                                        additionalField.map((prod: any) => {
                                                                            return (
                                                                                <option key={prod.id} value={prod.id} disabled={prod.isActive === false ? true : false}>
                                                                                    {prod.value}
                                                                                </option>
                                                                            );
                                                                        })}
                                                                </select>

                                                                {(selectedValue[i] == '7' || data.costingTypeId == '7') && (
                                                                    <a href="#" onClick={(e) => handleAddComment(e)} className='add-comments'>
                                                                        {props?.viewCosting?.costingLists?.find((item: any) => item?.costingTypeId === 7)?.costingTypeName ? "View Comment" : ""}
                                                                    </a>
                                                                )}
                                                            </div>)}
                                                        </td>
                                                        <td>
                                                            <p className="bold mb-0 p-0">
                                                                £{' '}
                                                                {props.mode === 'edit' ? (
                                                                    <input
                                                                        type="number"
                                                                        min="0"
                                                                        //@ts-ignore
                                                                        className={`form-control costing-input ${errors.additionalfield?.[i]?.amount ? 'is-invalid' : ''}`}
                                                                        {...register(`additionalfield.${i}.amount`, {
                                                                            onChange: (e: any) => {
                                                                                updateData(e, i)
                                                                            }
                                                                        })}
                                                                        //    onKeyDown={(e: any) => {
                                                                        //     const cursorPosition = e.target.selectionStart;
                                                                        //     if (
                                                                        //         e.key === 'ArrowLeft' ||
                                                                        //         e.key === 'ArrowRight' ||
                                                                        //         e.key === 'Backspace' ||
                                                                        //         e.key === 'Delete' ||
                                                                        //         e.key === 'Tab' ||
                                                                        //         (e.key === 'a' && e.ctrlKey) // Ctrl+A
                                                                        //     ) {
                                                                        //         // Allow the default behavior for these keys
                                                                        //         return;
                                                                        //     } else {
                                                                        //         if (
                                                                        //             window.$utils.onKeypressValidateCosting(
                                                                        //                 'number',
                                                                        //                 e.target.value.substring(0, cursorPosition) + e.key + e.target.value.substring(cursorPosition)
                                                                        //             ) === false
                                                                        //         ) {
                                                                        //             e.preventDefault();
                                                                        //         }
                                                                        //     }
                                                                        // }}
                                                                        name={`additionalfield.${i}.amount`}


                                                                    />
                                                                ) : (
                                                                    props.viewCosting?.costingLists[i].amount
                                                                )}
                                                            </p>
                                                            {/* <p className="bold mb-0 p-0">
                                                                £{' '}
                                                                {props.mode === 'edit' ? (
                                                                    <input
                                                                        type="text"
                                                                        className={`form-control costing-input}`}
                                                                        {...register(`additionalfield.${i}.amount`, {
                                                                            onChange: (e: any) => {
                                                                                updateData(e, i);
                                                                            }
                                                                        })}
                                                                        name={`additionalfield.${i}.amount`}
                                                                        
                                                                    />
                                                                ) : (
                                                                    props.viewCosting?.managementAndAdministration
                                                                )}
                                                            </p> */}
                                                        </td>
                                                        {props.mode === 'edit' ? (
                                                            <td>
                                                                <a onClick={(e: any) => removeline(i, data)} className="ms-3">
                                                                    <i className="fa-solid fa-trash danger-text"></i>
                                                                </a>
                                                            </td>
                                                        ) : (<></>)}
                                                    </tr>
                                                );
                                            })}
                                        {(props.mode === 'edit' && line.length !== additionalField.length) ? (
                                            <tr>
                                                <td className="p-0">
                                                    <div>
                                                        <button onClick={addRow} className="add_left_content">
                                                            <i className="fa-solid fa-plus"></i>{' '}
                                                        </button>
                                                    </div>
                                                </td>
                                            </tr>
                                        ) : (<></>)}
                                        <tr>
                                            <td className="purple-background">
                                                <p className="white-text bold p-0">{t('Referral_Costing.Total_week')}</p>
                                            </td>
                                            <td className="purple-background">
                                                {' '}
                                                <p className="white-text p-0">
                                                    <p className="bold" {...register('total')}>
                                                        £ {props.mode === 'edit' ? total?.toFixed(2) : props.viewCosting?.total?.toFixed(2)}
                                                    </p>
                                                </p>
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="col-lg-9 text-center">
                                    {props?.viewCosting?.rcId == null && props.mode === 'edit' && (
                                        <button
                                            className="btn btn-primary"
                                            disabled={!datavalid || isDisabled}
                                            onClick={(e) => {
                                                saveUpdateCosting();
                                                handleClick(e);
                                            }}
                                        >
                                            {t('SM_Costing.Submit_btn')}
                                        </button>
                                    )}

                                    {props?.viewCosting?.rcId !== undefined && props?.viewCosting?.rcId !== null && props.mode === 'edit' && (
                                        <button
                                            className="btn btn-primary"
                                            disabled={!datavalid || isDisabled}
                                            onClick={(e) => {
                                                saveUpdateCosting();
                                                handleClick(e);
                                            }}
                                        >
                                            {t('SM_Costing.Update_btn')}
                                        </button>
                                    )}
                                </div>
                            </div>
                        </div>
                        <Tooltip className="my-tooltip tooltip-custom-width1" id="my-tooltip-assessment" />
                        <RenderModalContainer>
                            {showModal && (
                                <SendPrintCostingContainer
                                    viewCosting={props.viewCosting}
                                    onClickPrintPriview={onClickPrintPriview}
                                    onCloseWC={handleCloseModal}
                                    onClose={toggle}
                                    title="Send & Print Costing"
                                    costingRecepients={costingRecepients}
                                    emailData={emailData}
                                />
                            )}
                        </RenderModalContainer>
                        <RenderModalContainer>
                            {showAddCommentModal && <AddYourCommentModalContainer
                                title={!isDisabled && props?.viewCosting?.costingLists?.find((item: any) => item?.costingTypeId === 7)?.costingTypeName &&
                                    line?.find((item: any) => item?.costingTypeId === 7)?.costingTypeName && props.mode === 'edit'
                                    ?
                                    "Edit Comment" : props.mode !== 'edit' ? "View Comment" : "Add Comment"}
                                onClose={toggleComment}
                                costingLists={props?.viewCosting?.costingLists}
                                commentText={commentText}
                                mode={props.mode}
                                isFromRM={true}
                                line={line}
                            />}
                        </RenderModalContainer>
                    </div>
                }
            />
            <div ref={componentRef}>
                <PrintCostingContainer viewCosting={props.viewCosting} additionalField={additionalField} />
            </div>
        </div>
    );
};

export default CostingModalView;

import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store';
import ClientPortal from '../../../../components/ClientPortal';
import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { submitAssisstanceQuery } from '../../../../service/onboarding-service';
import swal from 'sweetalert';
import { AssistQuery } from './types';
import LoaderContainer from '../../../../components/Loader/loaderContainer';


const ProgressHeaderView = () => {
    const [showPortal, setShowPortal] = useState(false);
    const [charsCount, setCharCount] = useState(0);
    const [isLoading, setIsLoading] = useState(false)

    const { serviceId, serviceName }: any = useSelector((state: RootState) => state.onboardingService);
    const { StatusLogin, DataLogin } = useSelector((state: RootState) => state.login);



    const onboardingProgress = useSelector((state: RootState) => state.onboardingProgress);
    const ValidationSchema = Yup.object().shape({
        name: Yup.string().required(),
        email: Yup.string().email().required(),
        subject: Yup.string().required(),
        assist_Query: Yup.string().required().max(500, 'Maximum 500 characters are allowed'),
    });

    const { register, formState, handleSubmit, trigger, reset, setValue, getValues } = useForm<AssistQuery>({
        mode: 'all',
        resolver: yupResolver(ValidationSchema),
        defaultValues: {
            name: DataLogin?.userModel?.userName,
            email: DataLogin?.userModel?.email
        }
    });

    const { errors, isValid } = formState;

    useEffect(() => {
        return () => {
            reset();
        };
    }, []);

    // useEffect(() => {

    //     setValue("name", DataLogin?.userModel?.email)
    //     setValue("email", DataLogin?.userModel?.userName)

    // }, [DataLogin])

    const handleModal = () => {
        setShowPortal((prevState) => !prevState);
        reset();
        setCharCount(0)
    };

    const haandleBlur = (name: keyof AssistQuery) => {
        trigger(name);
    };

    const handleTextChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        const value = event.target.value;
        if (value.length <= 500) {
            setCharCount(value.length);
            setValue('assist_Query', value, { shouldValidate: true });
        } else {
            setValue('assist_Query', getValues('assist_Query'), { shouldValidate: true });
        }
    };


    const handleSubmitQuery: SubmitHandler<AssistQuery> = async (data: AssistQuery) => {
        const totalData = {
            Service_Id: serviceId,
            ...data
        };
        setIsLoading(true)
        const response = await submitAssisstanceQuery(totalData);
        if (response?.status) {
            setIsLoading(false)
            swal({
                title: '',
                text: response?.message,
                icon: 'success',
                dangerMode: true
            }).then(async (confirm: boolean) => {
                if (confirm) {
                    handleModal();
                }
            });
        } else {
            setIsLoading(false)
        }
    };

    useEffect(() => {
        if (showPortal) {
            document.body.classList.add("modal-open");
        } else {
            document.body.classList.remove("modal-open");
        }
        return () => {
            document.body.classList.remove("modal-open");
        };
    }, [showPortal]);

    return (
        <div>
            <div className="section-title-parent">
                <h5 className="section-title">
                    <span className="gradient-text">{onboardingProgress.stageHeading!}</span>
                </h5>
            </div>
            <div className="section-description d-flex align-items-center justify-content-between mt-4 mb-4">
                <div className="head-title-left">
                    {onboardingProgress.stageDescription && (
                        <p dangerouslySetInnerHTML={{ __html: onboardingProgress.stageDescription! }}>
                            {/* You can select one or more categories that best describes the services for <span className="bolder"> Southern Hill Hospital.</span> */}
                        </p>
                    )}
                </div>
                <div className="head-btns-right d-flex justify-content-end">
                    <button className="classic-btn need-assistance-btn small-btn" onClick={handleModal}>
                        <span>Need Assistance?</span>
                    </button>
                </div>
            </div>
            <ClientPortal selector="myportal" show={showPortal}>
                <div className="modalParent">
                    <div className="modalContainer">
                        <div className="overlay" onClick={handleModal}></div>
                        <div className="modal need-assistance-popup">
                            <div className="modal-header justify-content-between mb-1 border-0 p-0">
                                <h4 className="mb-0">Send a message to Locate.Care</h4>
                                <a className="removeButton" onClick={handleModal}>
                                    <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 384 512">
                                        <path
                                            fill="#ffffff"
                                            d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"
                                        ></path>
                                    </svg>
                                </a>
                            </div>
                            <div className="modal-body amenities-modal-body p-0 mt-4">

                                <form onSubmit={handleSubmit(handleSubmitQuery)}>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <p className="mb-2 label">Name*</p>
                                            <input className={`form-control white mb-3 ${errors?.name ? 'is-invalid' : ''}`} type="text" {...register('name')} onBlur={() => haandleBlur('name')} />
                                        </div>
                                        <div className="col-md-6">
                                            <p className="mb-2 label">Email Address*</p>
                                            <input
                                                className={`form-control white mb-3 overview-input ${errors?.email ? 'is-invalid' : ''} `}
                                                type="text"
                                                {...register('email')}
                                                onBlur={() => haandleBlur('email')}
                                            />
                                        </div>
                                        <div className="col-md-12">
                                            <p className="mb-2 label">Subject*</p>
                                            <input className={`form-control white mb-3 ${errors?.subject ? 'is-invalid' : ''}`} type="text" {...register('subject')} onBlur={() => haandleBlur('subject')} />
                                        </div>
                                        <div className="col-md-12">
                                            <p className="mb-2 label position-relative">How may we assist you* <span className="fixed-msg-count">{charsCount}/500</span></p>
                                            <textarea
                                                className={`form-control white mb-3 ${errors?.assist_Query ? 'is-invalid' : ''}`}
                                                {...register('assist_Query')}
                                                onBlur={() => haandleBlur('assist_Query')}
                                                onChange={handleTextChange}

                                            ></textarea>
                                        </div>
                                        <div className="d-flex justify-content-center">
                                            <button type="submit" className="btn primary-btn mt-1 px-5" disabled={!isValid || isLoading}>
                                                {isLoading ? "...Loading" : "Send"}
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </ClientPortal>
        </div>
    );
};

export default ProgressHeaderView;

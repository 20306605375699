import React from 'react';
import OnboardingPreviewAboutUsView from './OnboardingPreviewAboutusView';

const OnboardingPreviewAboutUsContainer = (props: any) => {
    return (
        <div>
            <OnboardingPreviewAboutUsView {...props}/>
        </div>
    );
};

export default OnboardingPreviewAboutUsContainer;
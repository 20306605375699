import { AxiosError, AxiosResponse } from 'axios';
import { DataResponseModel } from '../utils/model';
import { API_ENDPOINT, BASEURLDEV } from './ApiConstants';
import instance from './axiosinstance';

export const getOnboardingAmenity = () => {
    return (
        instance
            .get(`${BASEURLDEV}${API_ENDPOINT.GetAmenityList}`)
            // return (
            //     instance
            // .get(`http://localhost:3007/api/amenity`)
            .then((response: AxiosResponse) => {
                const resultData: any = response.data as DataResponseModel;
                return resultData;
            })
            .catch((error: AxiosError) => {
                if (error.code === 'ERR_NETWORK') {
                    const errorResponse = {
                        status: false,
                        data: [],
                        message: error.message,
                        totalCount: 0
                    };
                    const errorData: any = errorResponse as DataResponseModel;
                    return errorData;
                } else {
                    const errorResponse = {
                        status: false,
                        data: [],
                        message: 'Something went wrong!',
                        totalCount: 0
                    };
                    const errorData: any = error.response ? (error.response.data as DataResponseModel) : (errorResponse as DataResponseModel);
                    return errorData;
                }
            })
    );
};

export const getOnboardingWardList = (payload: { Service_Id: number; WardId: number | null }) => {
    //     return instance
    //         .get(`${BASEURLDEV}${API_ENDPOINT.GetWardList}?serviceId=48`, {
    //             headers: {
    //                 Authorization: `Bearer ${token}`
    //             }
    //         })
    return instance
        .post(`${BASEURLDEV}${API_ENDPOINT.GetWardAndBedAmenitiesDetails}`, payload)
        .then((response: AxiosResponse) => {
            const resultData: any = response.data as DataResponseModel;
            return resultData;
        })
        .catch((error: AxiosError) => {
            if (error.code === 'ERR_NETWORK') {
                const errorResponse = {
                    status: false,
                    data: [],
                    message: error.message,
                    totalCount: 0
                };
                const errorData: any = errorResponse as DataResponseModel;
                return errorData;
            } else {
                const errorResponse = {
                    status: false,
                    data: [],
                    message: 'Something went wrong!',
                    totalCount: 0
                };
                const errorData: any = error.response ? (error.response.data as DataResponseModel) : (errorResponse as DataResponseModel);
                return errorData;
            }
        });
};

// Pending for deployment in backend.
export const generateServiceHeading = (serviceId:number, pageId: number) => {
    return instance
        .get(`${BASEURLDEV}${API_ENDPOINT.GenerateAIHeadingForAmenities}${serviceId}&pageId=${pageId}`)
        .then((response: AxiosResponse) => {
            const resultData: any = response.data as DataResponseModel;
            return resultData;
        })
        .catch((error: AxiosError) => {
            if (error.code === 'ERR_NETWORK') {
                const errorResponse = {
                    status: false,
                    data: [],
                    message: error.message,
                    totalCount: 0
                };
                const errorData: any = errorResponse as DataResponseModel;
                return errorData;
            } else {
                const errorResponse = {
                    status: false,
                    data: [],
                    message: 'Something went wrong!',
                    totalCount: 0
                };
                const errorData: any = error.response ? (error.response.data as DataResponseModel) : (errorResponse as DataResponseModel);
                return errorData;
            }
        });
};

export const deleteBed = (payload: { Ward_Id: number; BedId: number; BedName: string; Service_Id: number }) => {
    return instance
        .post(`${BASEURLDEV}${API_ENDPOINT.DeleteBed}`, payload)
        .then((response: AxiosResponse) => {
            const resultData: any = response.data as DataResponseModel;
            return resultData;
        })
        .catch((error: AxiosError) => {
            if (error.code === 'ERR_NETWORK') {
                const errorResponse = {
                    status: false,
                    data: [],
                    message: error.message,
                    totalCount: 0
                };
                const errorData: any = errorResponse as DataResponseModel;
                return errorData;
            } else {
                const errorResponse = {
                    status: false,
                    data: [],
                    message: 'Something went wrong!',
                    totalCount: 0
                };
                const errorData: any = error.response ? (error.response.data as DataResponseModel) : (errorResponse as DataResponseModel);
                return errorData;
            }
        });
};

export const addBedByWardId = (payload: { Ward_Id: number; BedName: string; Service_Id: number }) => {
    return instance
        .post(`${BASEURLDEV}${API_ENDPOINT.AddBed}`, payload)
        .then((response: AxiosResponse) => {
            const resultData: any = response.data as DataResponseModel;
            return resultData;
        })
        .catch((error: AxiosError) => {
            if (error.code === 'ERR_NETWORK') {
                const errorResponse = {
                    status: false,
                    data: [],
                    message: error.message,
                    totalCount: 0
                };
                const errorData: any = errorResponse as DataResponseModel;
                return errorData;
            } else {
                const errorResponse = {
                    status: false,
                    data: [],
                    message: 'Something went wrong!',
                    totalCount: 0
                };
                const errorData: any = error.response ? (error.response.data as DataResponseModel) : (errorResponse as DataResponseModel);
                return errorData;
            }
        });
};

export const applyAmenitiesToBed = (amenitiesData: { Service_Id: number; WardId: number; BedId: number; AmenityIds: number[]; WardIds: number[]; IsMultipleWard: boolean }) => {
    return instance
        .post(`${BASEURLDEV}${API_ENDPOINT.ApplyAmenitiesToBed}`, amenitiesData)
        .then((response: AxiosResponse) => {
            const resultData: any = response.data as DataResponseModel;
            return resultData;
        })
        .catch((error: AxiosError) => {
            if (error.code === 'ERR_NETWORK') {
                const errorResponse = {
                    status: false,
                    data: [],
                    message: error.message,
                    totalCount: 0
                };
                const errorData: any = errorResponse as DataResponseModel;
                return errorData;
            } else {
                const errorResponse = {
                    status: false,
                    data: [],
                    message: 'Something went wrong!',
                    totalCount: 0
                };
                const errorData: any = error.response ? (error.response.data as DataResponseModel) : (errorResponse as DataResponseModel);
                return errorData;
            }
        });
};
export const saveServiceTypesMappings = (payload: {
    Service_Id: number;
    ServiceCare: {
        serviceCareLevelId: number;
        serviceTypeIds: number[];
    }[];
}) => {
    return instance
        .post(`${BASEURLDEV}${API_ENDPOINT.SaveServiceTypesMappings}`, payload)
        .then((response: AxiosResponse) => {
            const resultData: any = response.data as DataResponseModel;
            return resultData;
        })
        .catch((error: AxiosError) => {
            if (error.code === 'ERR_NETWORK') {
                const errorResponse = {
                    status: false,
                    data: [],
                    message: error.message,
                    totalCount: 0
                };
                const errorData: any = errorResponse as DataResponseModel;
                return errorData;
            } else {
                const errorResponse = {
                    status: false,
                    data: [],
                    message: 'Something went wrong!',
                    totalCount: 0
                };
                const errorData: any = error.response ? (error.response.data as DataResponseModel) : (errorResponse as DataResponseModel);
                return errorData;
            }
        });
};

export const editBedName = (payload: { Ward_Id: number; BedId: number; BedName: string; Service_Id: number }) => {
    return instance
        .post(`${BASEURLDEV}${API_ENDPOINT.EditBedName}`, payload)
        .then((response: AxiosResponse) => {
            const resultData: any = response.data as DataResponseModel;
            return resultData;
        })
        .catch((error: AxiosError) => {
            if (error.code === 'ERR_NETWORK') {
                const errorResponse = {
                    status: false,
                    data: [],
                    message: error.message,
                    totalCount: 0
                };
                const errorData: any = errorResponse as DataResponseModel;
                return errorData;
            } else {
                const errorResponse = {
                    status: false,
                    data: [],
                    message: 'Something went wrong!',
                    totalCount: 0
                };
                const errorData: any = error.response ? (error.response.data as DataResponseModel) : (errorResponse as DataResponseModel);
                return errorData;
            }
        });
};
export const editWardName = (payload: { Ward_Id: number; WardName: string; Service_Id: number }) => {
    return instance
        .post(`${BASEURLDEV}${API_ENDPOINT.EditWardName}`, payload)
        .then((response: AxiosResponse) => {
            const resultData: any = response.data as DataResponseModel;
            return resultData;
        })
        .catch((error: AxiosError) => {
            if (error.code === 'ERR_NETWORK') {
                const errorResponse = {
                    status: false,
                    data: [],
                    message: error.message,
                    totalCount: 0
                };
                const errorData: any = errorResponse as DataResponseModel;
                return errorData;
            } else {
                const errorResponse = {
                    status: false,
                    data: [],
                    message: 'Something went wrong!',
                    totalCount: 0
                };
                const errorData: any = error.response ? (error.response.data as DataResponseModel) : (errorResponse as DataResponseModel);
                return errorData;
            }
        });
};

interface SaveAmenitiesToBedType {
    Service_Id: number;
    Step_Id: number;
    Wards: {
        WardId: number;
        Beds: {
            BedId: number;
            AmenityIds: number[];
        }[];
    }[];
    openairesponse: {
        airesponseId: number;
        generatedResponse: string;
        isSelected: boolean;
    }[];
}

export const saveAmenitiesToBed = (payload: SaveAmenitiesToBedType) => {
    return instance
        .post(`${BASEURLDEV}${API_ENDPOINT.SaveAmenitiesToBed}`, payload)
        .then((response: AxiosResponse) => {
            const resultData: any = response.data as DataResponseModel;
            return resultData;
        })
        .catch((error: AxiosError) => {
            if (error.code === 'ERR_NETWORK') {
                const errorResponse = {
                    status: false,
                    data: [],
                    message: error.message,
                    totalCount: 0
                };
                const errorData: any = errorResponse as DataResponseModel;
                return errorData;
            } else {
                const errorResponse = {
                    status: false,
                    data: [],
                    message: 'Something went wrong!',
                    totalCount: 0
                };
                const errorData: any = error.response ? (error.response.data as DataResponseModel) : (errorResponse as DataResponseModel);
                return errorData;
            }
        });
};

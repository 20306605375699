import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState : {isPageValid: boolean | null} = {
    isPageValid: false
};

export const onboardingPageValiditySlice = createSlice({
    name: 'onboardingPageValidity',
    initialState,
    reducers: {
        setOnboardingPageValidity: (state, action: PayloadAction<boolean | null>) => {
            state.isPageValid = action.payload;
        }
    }
});

export const { setOnboardingPageValidity } = onboardingPageValiditySlice.actions;

export default onboardingPageValiditySlice.reducer;

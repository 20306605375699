import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RootState } from '../../../../../../store';
import { useSelector } from 'react-redux';
import { getLeaddata } from '../../../../../../service/leads';
import RejectContainer from '../../../../../../components/RejectModal/RejectContainer';
import RenderModalContainer from '../../../../../../components/RenderModal/indexContainer';
import { updateDocuSign } from '../../../../../../service/contract-service';
import PreviewContractContainer from '../../../../../../components/PreviewContractModal/PreviewContractContainer';

const ContractingView = () => {
    const { t } = useTranslation();

    const [showAll, setShowAll] = useState(false);
    const { StatusLeadInfo, DataLeadInfo, MessageLeadInfo, ErrorLeadInfo } = useSelector((state: RootState) => state.leadInformation);    
    const [showCancelModal, setshowCancelModal] = useState(false);
    const [ccdata, setccdata] = useState<any>();
    const [showcancel, setshowcancel] = useState<boolean>(false);
    const [previewdata, setpreviewdata] = useState<any>();
    const [printFileName, setprintFileName] = useState<any>();
    const [showModal, setShowModal] = useState(false);

    const toggleShowAll = () => {
        setShowAll(!showAll);
    };
    const paragraphs = [
        t('RM_Contracting.Contract_requested_on') + ' 17/07/2023 2:00 PM',
        t('RM_Contracting.Contract_sent_funder_on') + ' 17/07/2023 2:00 PM',
        t('RM_Contracting.Contract_signed_funder_on') + ' 17/07/2023 11:30 AM',
        t('RM_Contracting.Contract_signed_SM_on') + ' 17/07/2023 12:00 PM'
    ];
    const visibleParagraphs = showAll ? paragraphs : paragraphs.slice(0, 3);

    const toggle = () => {
        setshowCancelModal(false);
    };

    const toggleModalPreview = () => {
        setShowModal(false);
        setpreviewdata(undefined);
    };

    const handleCancelModal = (i: any, id: any) => {
        let data = {
            leadid: DataLeadInfo?.data?.leadId,
            funderid: DataLeadInfo?.data?.contract[0].funderId,
            contractid: DataLeadInfo?.data?.contract[0].digitalContractId
        };
        setccdata(data);
        setshowCancelModal(true);
    };

    useEffect(() => {
        if (DataLeadInfo !== '') {
            setprintFileName(`Contract_${DataLeadInfo?.data?.referralId}_${DataLeadInfo?.data?.serviceName}`);
            setContractList(DataLeadInfo?.data?.referralLogs);
            for (let i = 0; i < DataLeadInfo?.data?.contract?.length; i++) {
                if (
                    DataLeadInfo?.data?.contract[i].digitalContractId !== 0 &&
                    DataLeadInfo?.data?.contract[i].status !== 'Completed' &&
                    DataLeadInfo?.data?.contract[i].status !== 'Signed by Funder' &&
                    DataLeadInfo?.data?.contract[i].status !== 'Cancelled' &&
                    DataLeadInfo?.data?.contract[i].status !== 'Voided'
                ) {
                    setshowcancel(true);
                    break;
                } else {
                    if (
                        DataLeadInfo?.data?.contract[i].status === 'Completed' ||
                        DataLeadInfo?.data?.contract[i].status === 'Signed by Funder' ||
                        DataLeadInfo?.data?.contract[i].status === 'Cancelled' ||
                        DataLeadInfo?.data?.contract[i].status !== 'Voided'
                    ) {
                        setshowcancel(false);
                        break;
                    }
                }
            }
        }
    }, [DataLeadInfo]);

    async function handleEditModal(id: any) {
        // if (DataLeadInfo?.data?.contract[i]?.status !== '') {
        //     if (DataLeadInfo?.data?.contract[i]?.status === 'Completed') {
                const docures = await updateDocuSign(id);
                if (docures.status) {
                    setShowModal(true);
                    setpreviewdata(docures.data);
                }
        //     }
        // }
    }
    const [ContractList, setContractList] = useState(DataLeadInfo?.data?.referralLogs);

    useEffect(()=>{
     console.log(ContractList);
    },[ContractList])
    const onAddPartSelect = () => {
        const updatedData = ContractList.map((item: any, idx: number) => {
            // if (0 === 0) {
                return {
                    ...item,
                    itemPartToggle: !item.itemPartToggle
                };
            // }
            // return item;
        });
        console.log(updatedData);
        setContractList(updatedData);
    };

    return (
        <div>
            <p className="bold mb-3">{DataLeadInfo?.data?.serviceName}</p>
            {DataLeadInfo?.data?.contract?.length > 0 && !DataLeadInfo?.data?.isCancelledByRM  && (
                <>
                    {DataLeadInfo?.data?.contract?.map((data: any, i: number) => {
                        return (
                            <>
                                <div className="status-box mb-3">
                                    <div className="d-flex justify-content-between">
                                        <span className="gray-light-bg bold ps-2 pe-2 d-flex align-items-center">{data.funderType}</span>
                                        {data.status !== 'Completed' && data.status !== 'Voided' && data.status !== 'Cancelled' && <span className="referral-status yellow-status">{t('RM_Contracting.In_Progress')}</span>}
                                        {data.status === 'Completed' && <span className="referral-status green-status">{t('RM_Contracting.Completed')}</span>}
                                        {data.status === 'Cancelled' && <span className="referral-status red-status">{t('RM_Contracting.Cancelled')}</span>}
                                        {data.status === 'Voided' && <span className="referral-status red-status">{t('RM_Contracting.Voided')}</span>}
                                    </div>
                                    <p className="bold mt-2 mb-2">{data.funderName}</p>

                                   
                                </div>
                            </>
                        );
                    })}
                </>
            )}

            {ContractList !== null && DataLeadInfo?.data?.referralLogs?.length > 0 &&
            <>
                    <div className="status-box mb-3">
                    {ContractList?.length > 0 && ContractList[0]?.itemPartToggle && 
                                        ContractList?.map((rdata: any) => {
                                            return (
                                                <p className="mb-1">
                                                    <i>{rdata.logMessage}</i>
                                                </p>
                                                
                                            );
                                        })}
                                    {ContractList?.length > 0 && !ContractList[0]?.itemPartToggle && 
                                        ContractList?.slice(0,3).map((rdata: any) => {
                                            return (
                                                
                                                <p className="mb-1">
                                                    <i>{rdata.logMessage}</i>
                                                </p>
                                                
                                            );
                                        })}    
                                    {/* <p>{t('RM_Contracting.Contract_requested_on')} 19/07/2023 10:00AM</p> */}
                                    {DataLeadInfo?.data?.contract[DataLeadInfo?.data?.contract?.length - 1]?.status === 'Completed' && (
                                        <div className="text-center mt-2">
                                            <button onClick={() => handleEditModal(DataLeadInfo?.data?.contract[DataLeadInfo?.data?.contract?.length - 1]?.digitalContractId)} className="btn btn-primary btn-sm">
                                                {t('RM_Contracting.View_Contract')}
                                            </button>
                                        </div>
                                    )}
                                    {ContractList?.length > 3 && (
                                        <div className="text-right mt-3">
                                            {!ContractList[0]?.itemPartToggle && (
                                                <a href="#" className="bold" onClick={() => onAddPartSelect()}>
                                                    <i className="fa-solid fa-clock-rotate-left"></i> View History
                                                </a>
                                            )}
                                            {ContractList[0]?.itemPartToggle && (
                                                <a href="#" className="bold" onClick={() => onAddPartSelect()}>
                                                    <i className="fa-solid fa-clock-rotate-left"></i> Hide History
                                                </a>
                                            )}
                                        </div>
                                    )}
                    
                        </div>
            </>
            
            }

            {DataLeadInfo?.data?.contract?.length > 0 && DataLeadInfo?.data?.isCancelledByRM && (
                <>
                    {DataLeadInfo?.data?.contract?.map((data: any, i: number) => {
                        return (
                            <>
                                <div className="status-box mb-3">
                                    <div className="d-flex justify-content-between">
                                        <span className="gray-light-bg bold ps-2 pe-2 d-flex align-items-center">{data.funderType}</span>
        
                                       <span className="referral-status red-status">{t('RM_Contracting.Cancelled')}</span>
                                    </div>
                                    <p className="bold mt-2 mb-2">{data.funderName}</p>

                                </div>
                            </>
                        );
                    })}
                </>
            )}

            {/* <div className="status-box mt-3">
                <div className="d-flex justify-content-between">
                    <span className="gray-light-bg bold ps-2 pe-2 d-flex align-items-center">Self</span>
                    <span className="referral-status yellow-status">{t('RM_Contracting.In_Progress')}</span>
                </div>
                <p className="bold mt-2 mb-2">South West London and St George's Mental Health NHS Trust</p>
               
                <p >{t('RM_Contracting.Contract_requested_on')} 19/07/2023 10:00AM</p>
                <p className='mt-1'>{t('RM_Contracting.Contract_sent_funder_on')} 17/07/2023 10:00 AM</p>
            </div> */}
            {/* <div className="status-box mt-3">
                <div className="d-flex justify-content-between">
                    <span className="gray-light-bg bold ps-2 pe-2 d-flex align-items-center">Self</span>
                    <span className="referral-status yellow-status">{t('RM_Contracting.In_Progress')}</span>
                </div>
                <p className="bold mt-2 mb-2">South West London and St George's Mental Health NHS Trust</p>
               
                <p >{t('RM_Contracting.Contract_requested_on')} 19/07/2023 10:00AM</p>
                <p className='mt-1'>{t('RM_Contracting.Contract_sent_funder_on')} 17/07/2023 10:00 AM</p>
                <p className='mt-1'>{t('RM_Contracting.Contract_signed_funder_on')} 17/07/2023 <br /> 10:00 AM</p>
            </div> */}
            {/* <div className="status-box mt-3">
                <div className="d-flex justify-content-between">
                    <span className="gray-light-bg bold ps-2 pe-2 d-flex align-items-center">LA</span>
                    <span className="referral-status green-status">{t('RM_Contracting.Completed')}</span>
                </div>
                <p className="bold mt-2 mb-2">NHS South East London CCG</p> */}

            {/* {visibleParagraphs.map((paragraph, index) => (
                    <p key={index} className={showAll ? 'mt-1' : 'mt-1'}>
                        <i>{paragraph}</i>
                    </p>
                ))} */}

            {/* <div className='text-center mt-2'>
                    <a href="#" className='btn btn-primary btn-sm'>{t('RM_Contracting.View_Contract')}</a>
                </div> */}

            {/* <div className='text-right mt-3'>
                    {!showAll && (
                        <a href="#" className='bold' onClick={toggleShowAll}><i className="fa-solid fa-clock-rotate-left"></i> {t('RM_Contracting.View_History')}</a>
                    )}
                    {showAll && (
                        <a href="#" className='bold' onClick={toggleShowAll}><i className="fa-solid fa-clock-rotate-left"></i>  {t('RM_Contracting.Hide_History')}</a>
                    )}
                    </div> */}
            {/* </div> */}
            {showcancel && (
                <div className="d-flex justify-content-center  right_content_btn">
                    <button disabled={DataLeadInfo?.data?.referralCloseLog !== null} onClick={() => handleCancelModal(0, 0)} className="btn btn-secondary">
                        {t('RM_Contracting.Cancel')}
                    </button>
                </div>
            )}
            <RenderModalContainer>
                {showCancelModal && <RejectContainer onClose={toggle} leadid={ccdata} mname={'contract'} title="Cancel Contract" />}

                {previewdata !== undefined && <RenderModalContainer>{showModal && <PreviewContractContainer onClose={toggleModalPreview} previewdata={previewdata} isContractFile={true} printFileName={printFileName}/>}</RenderModalContainer>}
            </RenderModalContainer>
        </div>
    );
};

export default ContractingView;
function dispatch(arg0: any) {
    throw new Error('Function not implemented.');
}

function removeLeadInformationAction(): any {
    throw new Error('Function not implemented.');
}

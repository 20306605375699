import React, { useEffect, useState } from 'react';
//@ts-ignore
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useTranslation } from 'react-i18next';
import { Tooltip } from 'react-tooltip';
import moment from 'moment';
import { downloadAccreditationFile } from '../../../../../../service/onboarding-accreditation-service';
import LoaderContainer from '../../../../../../components/Loader/loaderContainer';
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import OwlCarousel from "react-owl-carousel";
 
interface Accreditation {
    accreditationsName: string
    imageUrl: string
    accreditationsDescription: string
    accreditationId: number
    certificatePath: string | null
    issuedOn: string
    validity: string
}
 
const AccreditationView = (prop: any) => {
 
    const { serviceAccreditation } = prop;
 
    const [refresh, setRefresh] = useState(1);
    const [isLoading, setIsLoading] = useState(false)
    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
 
    const handleViewFile = async (path: string) => {
        const filePath = path
        if (filePath) {
            setIsLoading(true)
            const response = await downloadAccreditationFile(filePath);
            if (response?.status) {
                setIsLoading(false)
                const a = document.createElement('a');
                a.target = '_blank';
                a.href = response.data;
                document.body.append(a);
                a.click();
                document.body.removeChild(a);
            }
            setIsLoading(false)
        }
    };
 
    useEffect(() => {
        setRefresh((prev) => (prev + 1))
    }, [serviceAccreditation])
 
    useEffect(() => {
        AOS.init();
    }, [])
 
    const { t } = useTranslation();
 
    return (
        <div className="">
            {isLoading && <LoaderContainer />}
            <div className='container-fluid service-slider'>
                <div className="row">
                    <h4 className="small-title indigo-text bolder mb-3" data-aos="fade-up" data-aos-duration="1500" data-aos-once="true">
                        {t("Accreditations.Accreditations")}
                    </h4>
                    <div data-aos="fade-up" data-aos-duration="1500" data-aos-delay="300" data-aos-once="true">
                        <OwlCarousel
                            key={refresh}
                            className="owl-theme all-owl"
                            dots={false}
                            margin={20}
                            mouseDrag={true}
                            smartSpeed={800}
                            touchDrag={true}
                            nav={true}
                            responsive={{
                                "0": { items: 2 },
                                "768": { items: 3 },
                                "1200": { items: 4 },
                                "1400": { items: 4 },
                            }}>
                            {serviceAccreditation?.length > 1 && serviceAccreditation?.map((item: Accreditation, index: number) => {
                                return <div className='item'>
                                    <div className="coordinator-details-box view-acc-sec position-relative">
                                        <img
                                            src={item?.imageUrl}
                                            className="img-fluid mb-1"
                                            alt={item?.accreditationsName}
                                            width={100}
                                        />
                                        {index === 0 ? <p className="lead-text bolder d-flex flex-column mb-2 ">
                                            <span className="pt-1 pb-2 bold border-bottom d-flex align-items-center">
                                                <span className="underline me-1" data-tooltip-id="acc-name" data-tooltip-content={"CQC Rating: " + prop?.cqcRating} data-tooltip-place="top">CQC Rating: {prop?.cqcRating?.length > 10 ? `${prop?.cqcRating?.slice(0, 10)}..` : prop?.cqcRating}</span><span className={`${prop && prop?.cqcRating ?
                                                    (
                                                        prop?.cqcRating?.toLowerCase() === 'requires improvement' ? 'dot-round-orange' :
                                                            (prop?.cqcRating?.toLowerCase() === 'inadequate' ? 'dot-round-red' :
                                                                (prop?.cqcRating?.toLowerCase() === 'good' ? 'dot-round' : 'dot-round'))
                                                    )
                                                    : ''
                                                    }`}></span>
                                            </span>
                                        </p> : <p className="lead-text bolder d-flex flex-column mb-2 ">
                                            <span className="pt-1 pb-2 bold border-bottom d-flex align-items-center">
                                                <span className="underline" data-tooltip-id="acc-name" data-tooltip-content={item?.accreditationsName} data-tooltip-place="top">
                                                    {item?.accreditationsName?.length > 25 ? `${item?.accreditationsName?.slice(0, 25)}..` : item?.accreditationsName}
                                                </span>
                                            </span>
                                        </p>}
                                        {index === 0 ? <div className="accreditations-info">
                                            <p className="mb-1">
                                                <span className="bold">{t("Accreditations.Latest_Inspection")}: </span>
                                                {prop?.cqcLatestInspection && moment.tz(prop?.cqcLatestInspection, userTimeZone).format("DD/MM/YYYY")}
                                            </p>
                                            <p className="mb-1">
                                                <span className="bold">{t("Accreditations.Report_Published")}: </span>
                                                {prop?.cqcReportPublished && moment.tz(prop?.cqcReportPublished, userTimeZone).format("DD/MM/YYYY")}
                                            </p>
                                            {/* <p>
                                    <span className="bold">{t("Accreditations.Latest_Review")} :</span>
                                    {prop?.cqcLatestReview}
                                </p> */}
                                        </div> : <div className="accreditations-info">
                                            <p className="mb-1">
                                                <span className="bold">Issue on: </span>
                                                {item?.issuedOn && moment.tz(item?.issuedOn, userTimeZone).format("DD/MM/YYYY")}
                                            </p>
                                            <p className="mb-1">
                                                <span className="bold">Validity: </span>
                                                {item?.validity && moment.tz(item?.validity, userTimeZone).format("DD/MM/YYYY")}
                                            </p>
                                            <p className="mb-1">
                                            </p>
                                            {item?.certificatePath &&
                                                <p className="mb-1">
                                                    <a className="lightblue underline" onClick={() => handleViewFile(item?.certificatePath!)} >
                                                        View Certificate
                                                    </a>
                                                </p>
                                            }
                                        </div>}
                                        {index === 0 && <a aria-labelledby="Click Text" className="click-btn" href={`${process.env.REACT_APP_CQC_URL}${prop?.cqcLocationId}`} target='_blank' rel="noreferrer"><i className="fa-solid fa-plus"></i></a>}
                                    </div>
                                </div>
                            })}
                        </OwlCarousel>
                    </div>
                </div>
            </div>
            <Tooltip id="acc-name" className="my-tooltip" />
        </div>
    );
};
 
export default AccreditationView;
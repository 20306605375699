import React, {useEffect, useState} from 'react';
import DischargeTabDetail from './dischargeView';
import { useSelector, useDispatch } from 'react-redux';
import { getListReasons } from '../../../../../../service/discharge-service';
import { ReasonList } from '../../../../../../utils/constant';
import { RootState } from '../../../../../../store';
import { dischargePatientService } from '../../../../../../service/discharge-service';
import swal from 'sweetalert';
import warning from '../../../../../../assets/images/warning.png'
import eventEmitter from '../../../../../../utils/eventEmitter';

const DischargeTabContainer = (props: any) => {
    const { StatusListReasons, DataListReasons } = useSelector((state: RootState) => state.listReasons);
    const [listReasons, setListReasons] = useState<any>([]);
    const { StatusServiceDetail, DataServiceDetail } = useSelector((state: RootState) => state.serviceDetail);
    useEffect(() => {
        async function getPatientDetails() {
            const payload: any = { id: ReasonList.Discharge};
            await getListReasons(payload);
        }
        getPatientDetails();
    },[])

    useEffect(() => {
        if(StatusListReasons === true) {
            setListReasons(DataListReasons.data)
        }
    },[StatusListReasons, DataListReasons])

    const updateData = (e: any) => {
        eventEmitter.emit('eventCloseOccupiedSideMenu');
    }

    const onSubmitHandler = async(data: any) => {
        data.admissionId = props.patientData?.admissionId
        if(data.admissionId !== undefined && data.admissionId !== '') {
            data.clinicallyDischarge = data.clinicallyDischarge === true ? 1 : 0
            data.financialDischarge = data.financialDischarge === true ? 1 : 0
            data.dischargeDate = window.$utils.dateFormatDDMMYYYYDB(data.dischargeDate)
            data.reason_Id = Number(data.reason_Id)
            data.serviceId = DataServiceDetail.serviceId
            swal({
                title: '',
                text: `Are you sure you want to discharge the patient from Bed ${props.patientData?.bedName}?`,
                icon: warning,
                buttons: ['No', 'Yes'],
                dangerMode: true
            })
            .then(async (confirm: any) => {
                if (confirm) {
                    const response = await dischargePatientService(data)
                    if(response.status === true) {
                        swal(response.message, {
                            icon: 'success'
                        });
                        props.closeSideMenu(true)
                        eventEmitter.emit('eventGetBedInformation');
                        eventEmitter.emit('eventGetWardInformation');
                        eventEmitter.emit('onAdmitReservePatient');
                    } else {
                        swal(response.message, {
                            icon: 'error',
                            dangerMode: true
                        });
                    }
                }
            });
        }
    }

    return (
        <div>
            <DischargeTabDetail patientData={props.patientData} listReasons={listReasons} onSubmitHandler={onSubmitHandler} updateData={updateData} />
        </div>
    );
};

export default DischargeTabContainer;

import React, { useEffect, useState } from 'react'
import ClientPortal from '../../../../../components/ClientPortal';
import Slider from 'react-slick';
import AccreditationsDetailView from '../../../Accreditations/AccreditationsDetail/AccreditationsDetailView';
import LoaderContainer from '../../../../../components/Loader/loaderContainer';
import { SubmitAccreditationMapping } from '../../../../../service/onboarding-accreditation-service';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../store';
import swal from 'sweetalert';
import warning from "../../../../../assets/images/warning.png"

const OnboardingPreviewAccreditaionPortalVeiw = ({ accreditations, setAccreditations, previewShowPortal, setPreviewShowPortal, handlePreviewShowPortal, cqcRating }: any) => {
    var settings = {
        // dots: true,
        infinite: false,
        speed: 800,
        slidesToShow: 4,
        slidesToScroll: 4,
        afterChange: handleSlideChange,
    };

    const [isLoading, setIsloading] = useState(false)
    const [currentSlide, setCurrentSlide] = useState(0);
    const sliderRef = React.useRef(null);

    const { serviceId, serviceName } = useSelector((state: RootState) => state.onboardingService);


    function handleSlideChange(index: number) {
        setCurrentSlide(index);
    };

    const handleSaveAccreditationsMapping = async () => {
        const accreditationIds = accreditations
            .filter((accreditation: any) => accreditation.isSelected === true)
            .map((accreditation: any) => accreditation.accreditationId);
        const payload = {
            Service_Id: serviceId!,
            AccreditationsIds: accreditationIds
        }

        swal({
            title: '',
            text: "Do you want to save the changes done to the accreditations?",
            icon: warning,
            buttons: ['No', 'Yes'],
            dangerMode: true
        }).then(async (confirm: boolean) => {
            if (confirm) {
                setIsloading(true)
                const response = await SubmitAccreditationMapping(payload)
                if (response.status === true) {
                    setIsloading(false)
                    swal({
                        title: '',
                        text: response.message,
                        icon: "success",
                        // buttons: ['No', 'Yes'],
                        dangerMode: true
                    }).then(async (confirm: any) => {
                        setPreviewShowPortal(false)
                    })
                }
            }
        })


        setIsloading(false)
    }

    // if (isLoading) {
    //     return <LoaderContainer />
    // }

    return (
        <ClientPortal selector="myportal" show={previewShowPortal}>
            {isLoading && <LoaderContainer />}
            <div className="overlay" onClick={handlePreviewShowPortal}></div>
            <div className="modal modal-collage-image p-0 nhs-body">
                <div className="header">
                    <a onClick={handlePreviewShowPortal} className="d-flex align-items-center">
                        <i className="fa-solid fa-angle-left back-collage-btn"></i>
                        <h4 className="bolder mb-0 ms-2">Update Accreditations</h4>
                    </a>
                </div>
                <div className="body service-detail-padding">
                    <div className="container-fluid service-slider mt-3 float-button-sec-margin">
                        <div className="accreditation-slider">
                            {accreditations?.length > 0 && (
                                <Slider {...settings} ref={sliderRef} >
                                    {accreditations.map((item: any, index: number) => (
                                        <div className="accred-cards" key={item?.accreditationId}>
                                            <div className="classic-card no-hover accreditationsbox line-preview-accridations">
                                                <h6>{item.title}</h6>
                                                <p className='accreditations-text text-small'>
                                                    {item?.description}
                                                </p>
                                                <div className="acc-img d-flex align-items-center justify-content-center">
                                                    <img src={item?.imagePath} className="img-fluid" alt="map image" />
                                                </div>
                                                <AccreditationsDetailView accreditations={accreditations} setAccreditations={setAccreditations} item={item} setIsloading={setIsloading} currentSlide={currentSlide} sliderRef={sliderRef} index={index} cqcRating={cqcRating} />
                                            </div>
                                        </div>
                                    ))}
                                </Slider>
                            )}
                        </div>
                    </div>
                </div>
                <div className="modal-footer--sticky p-2">
                    <div className="d-flex justify-content-center"><button type="submit" className="btn primary-btn mt-1 px-5" onClick={handleSaveAccreditationsMapping} >Save</button></div>
                </div>
            </div>
        </ClientPortal>
    )
}

export default OnboardingPreviewAccreditaionPortalVeiw
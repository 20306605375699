"use client";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from 'react-i18next';
//@ts-ignore
import AOS from "aos";
import "aos/dist/aos.css";
import { useParams } from "react-router-dom";
import { getQuestionsAndAnswersByServiceslug } from "../../../../service/onboarding-preview";

const QuestionAnswerView = (props: any) => {
    const { t } = useTranslation();
    const [questions, setQuestions] = useState([]);
    const [openPreviewAccordian, setPreviewAccordian] = useState<number | null>(null);
    const params = useParams();

    useEffect(() => {
        AOS.init();
    }, []);

    const [activeItem, setActiveItem] = useState("0");
    const handleToggleAccordion = (event: any, eventKey: any) => {
        event.preventDefault();
        setActiveItem(prevActiveItem => (prevActiveItem === eventKey ? null : eventKey));
    };
    const handleAccordionClick = (event: any, eventKey: any) => {
        event.preventDefault();
        setActiveItem(eventKey.toString());
    }
    const togglePreviewAccordion = (index: number) => {
        setPreviewAccordian(openPreviewAccordian === index ? null : index);
    };
    useEffect(() => {
        GetServiceDetailsList();
    }, [])

    const GetServiceDetailsList = async () => {
        const result = await getQuestionsAndAnswersByServiceslug(params?.serviceSlug)
        setQuestions(result?.data?.questionsAndAnswers)
    }
    return (
        <div>

            <div className="service-detail-padding section-padding" id="question-answer-section">
                <div className="container-fluid container-fluid-service p-0">
                    <h4 className="text-center section-title mb-3 bolder" data-aos="fade-up" data-aos-duration="1500" data-aos-once="true">
                        {t("QandA.Question_answer_description")}</h4>

                    <p className="section-sub-title text-center mb-4 bolder" data-aos="fade-up" data-aos-duration="1500" data-aos-once="true">
                        {t("QandA.Question_answer")}
                    </p>
                </div>
                <div className="container-fluid p-0">
                    <div className="question-answer-accordion" data-aos="fade-up" data-aos-duration="1500" data-aos-once="true">
                        <div className="accordion qa-accordion" id="accordionExample">
                            {questions && questions?.length > 0 && questions.map((item: any, index: any) => {
                                if (item?.isChecked) {
                                    return (
                                        <div key={index} className="accordion-item d-flex">
                                            <div className="w-100">
                                                <h2 className="accordion-header" id="headingOne">
                                                    <button
                                                        type="button"
                                                        data-bs-toggle="collapsePreview"
                                                        data-bs-target="#collapseOne"
                                                        className={`accordion-button ${openPreviewAccordian === index ? '' : 'collapsed'}`}
                                                        onClick={() => togglePreviewAccordion(index)}
                                                        aria-expanded={openPreviewAccordian === index}
                                                        aria-controls={`collapsePreview-${index}`}
                                                    >
                                                        {item?.questionText}
                                                    </button>
                                                </h2>

                                                <div
                                                    data-bs-parent="#accordionExample" id={`collapse-${index}`}
                                                    className={`accordion-collapse collapse ${openPreviewAccordian === index ? 'show' : ''}`}
                                                    aria-labelledby={`heading-${index}`}>
                                                    <div className="accordion-body">{item?.answerText}</div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }
                            })}
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
};

export default QuestionAnswerView;
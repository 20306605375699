import React, { useRef, useState, useEffect, ChangeEvent } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import ModalWrapperContainer from '../ModalWrapper/indexContainer';
import eventEmitter from '../../utils/eventEmitter';


interface ModalProps {
    onClose: () => any;
    title: string;
    mode?: any;
    costingLists: any;
    commentText: string;
    isFromRM: boolean;
    line: any;
    isDisabled: any
}

const AddYourCommentModalView = (props: ModalProps) => {
    const [comment, setComment] = useState<string>('')
    useEffect(() => {
        if (props?.line?.find((item: any) => item?.costingTypeId === 7)?.costingTypeName &&
            props?.costingLists?.find((item: any) => item?.costingTypeId === 7)?.costingTypeName) {
            setComment(props?.costingLists?.find((item: any) => item?.costingTypeId === 7)?.costingTypeName)
        } else if ((props?.costingLists === undefined) || props?.costingLists?.length === 0) {
            setComment(props?.line?.find((item: any) => item?.costingTypeId === 7)?.costingTypeName)
        } else {
            setComment('')
        }
    }, [props?.costingLists, props.commentText, props?.line])

    const handleComment = (e: any) => {
        e.stopPropagation();
        const nameValue = e.target.value;
        setComment(nameValue)
    }

    const onSaveComment = () => {
        eventEmitter.emit('updateBtnDisable', false);
        eventEmitter.emit('commentStateChange', comment);
        props.onClose();
    }
    const onModalClose = () => {
        props.onClose();
        eventEmitter.emit('updateBtnDisable', null);
        if ((props.isFromRM && props?.costingLists !== undefined) || (!props?.isFromRM && props?.costingLists.length !== 0)) {
            if (comment && !props?.costingLists?.find((item: any) => item?.costingTypeId === 7)?.costingTypeName) {
                setComment('')
                eventEmitter.emit('commentStateChange', '');
            } else if (!comment && props?.costingLists?.find((item: any) => item?.costingTypeId === 7)?.costingTypeName) {
                setComment(props?.costingLists?.find((item: any) => item?.costingTypeId === 7)?.costingTypeName)
                eventEmitter.emit('commentStateChange', props?.costingLists?.find((item: any) => item?.costingTypeId === 7)?.costingTypeName);
            }
            else {
                setComment(props?.costingLists?.find((item: any) => item?.costingTypeId === 7)?.costingTypeName)
                eventEmitter.emit('commentStateChange', props?.costingLists?.find((item: any) => item?.costingTypeId === 7)?.costingTypeName);
            }
        } else {
            setComment(props?.line?.find((item: any) => item?.costingTypeId === 7)?.costingTypeName)
            eventEmitter.emit('commentStateChange', props?.line?.find((item: any) => item?.costingTypeId === 7)?.costingTypeName);
        }

    }
    const isSaveButtonDisabled = () => {
        if (props?.isFromRM) {
            if (props?.mode === 'edit') {
                if (props?.costingLists !== undefined) {
                    if (!comment && !props?.costingLists?.find((item: any) => item?.costingTypeId === 7)?.costingTypeName) {
                        return true
                    } else if (!comment && props?.costingLists?.find((item: any) => item?.costingTypeId === 7)?.costingTypeName) {
                        return false
                    } else if (comment && props?.costingLists?.find((item: any) => item?.costingTypeId === 7)?.costingTypeName) {
                        return false
                    } else if (comment && !props?.costingLists?.find((item: any) => item?.costingTypeId === 7)?.costingTypeName) {
                        return false
                    }
                    else {
                        return true
                    }
                } else {
                    if (!comment && !props?.line?.find((item: any) => item?.costingTypeId === 7)?.costingTypeName) {
                        return true
                    } else if (!comment && props?.line?.find((item: any) => item?.costingTypeId === 7)?.costingTypeName) {
                        return false
                    } else if (comment && props?.line?.find((item: any) => item?.costingTypeId === 7)?.costingTypeName) {
                        return false
                    } else if (comment && !props?.line?.find((item: any) => item?.costingTypeId === 7)?.costingTypeName) {
                        return false
                    }
                    else {
                        return true
                    }
                }
            } else {
                return true
            }

        } else {
            if (props?.isDisabled) {
                return true
            } {

            } if (!comment && (props?.line?.find((item: any) => item?.costingTypeId === 7)?.costingTypeName &&
                props?.costingLists?.find((item: any) => item?.costingTypeId === 7)?.costingTypeName)) {
                return false
            } else if (comment && (!props?.line?.find((item: any) => item?.costingTypeId === 7)?.costingTypeName &&
                !props?.costingLists?.find((item: any) => item?.costingTypeId === 7)?.costingTypeName)) {
                return false
            } else if (!comment && (!props?.line?.find((item: any) => item?.costingTypeId === 7)?.costingTypeName &&
                !props?.costingLists?.find((item: any) => item?.costingTypeId === 7)?.costingTypeName)) {
                return true
            } else {
                return false
            }

        }
    }
    
    return (
        <div>
            <ModalWrapperContainer
                title={props.title}
                onClose={onModalClose}
                className="modal w-300"
                content={
                    <div>
                        <div className='row'>
                            <div>
                                {props?.isFromRM ?
                                    <input
                                        type="text"
                                        className="form-control white mb-2 overview-input"
                                        placeholder="Description"
                                        value={comment}
                                        onChange={(e) => handleComment(e)}
                                        disabled={props?.mode !== 'edit'}
                                    /> :
                                    <input
                                        type="text"
                                        className="form-control white mb-2 overview-input"
                                        placeholder="Description"
                                        value={comment}
                                        onChange={(e) => handleComment(e)}
                                        disabled={props?.isDisabled}
                                    />
                                }

                            </div>
                        </div>
                        <div className='d-flex justify-content-center'>
                            <div>
                                <button className="btn btn-primary me-2" disabled={isSaveButtonDisabled()} onClick={onSaveComment}>Save</button>
                                <button onClick={onModalClose} className="btn btn-secondary">Cancel</button>
                            </div>
                        </div>
                    </div>
                }
            />
        </div>
    );
};

export default AddYourCommentModalView;

'use client';
import React, { useEffect, useRef, useState } from 'react';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import OwlCarousel from 'react-owl-carousel';

import listimage5 from '../../assets/images/listimage5.png';
import listimage3 from '../../assets/images/listimage3.png';
import CQCImage from '../../assets/images/CQC.png';
import { BASE_URL, BedAvailability } from '../../utils/constant';
import { RootState } from '../../store';
import { useDispatch, useSelector } from 'react-redux';
import { Roles } from '../../utils/constant';
import { saveForLaterModal } from '../../store/SaveForLater/saveForLaterModalReducer';
import { GetServiceDetails } from '../../service/service-details-service';
import SaveForLaterModalContainer from '../../pages/NHSPage/HomeList/ListBox/SaveForLaterModal/SaveForLaterModalContainer';
import { setOnboardingService } from '../../store/onboarding/onboardingServiceReducer';
import { setOnboardingIsPublished } from '../../store/onboarding/onboardingPublishedReducer';

const GoogleMapMarkerBoxComponent = (props: any) => {

  const carouselRef = useRef<OwlCarousel>(null);

  const [isBoxHovered, setIsBoxHovered] = useState<boolean>(false);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [isCarouselChanged, setIsCarouselChanged] = useState(false);

  const {serviceImageList, serviceId, serviceName, slugName} = props.data
  const [imageList, setImageList] = useState<any[]>([serviceImageList])
  const [imageKey, setImageKey] = useState<number>(0);
  const [serviceDetails, setServiceDetails] = useState<any>(null);
  const { saveForLaterModelOpen } = useSelector((state: RootState) => state.saveForLaterModal);
  const { StatusLogin, DataLogin } = useSelector((state: RootState) => state.login);
  const dispatch = useDispatch();
  useEffect(() => {
    if (serviceImageList.length <= 0) {
      setImageList([
        { imageURL: `${BASE_URL}/${listimage5}` }
      ]);
    } else {
      setImageList([...serviceImageList]);
    }
    setImageKey(imageKey+1)
  }, [serviceImageList]);
  
  const { StatusRoleSelected, DataRoleSelected } = useSelector((state: RootState) => state.roleSelected);

  useEffect(() => {
    GetServiceDetailsList();
}, [])

  const GetServiceDetailsList = async () => {
      const result = await GetServiceDetails(props?.data?.slugName)
      setServiceDetails(result)
  }


  useEffect(() => {
    const handleClickOutside = (event:any) => {
      const domElement = document.querySelector(`#pin-view`);
      if (domElement && !domElement.contains(event.target)) {
        props.closeOverLay();
      }
    };

    const handleKeyDown = (event:any) => {
      if (event.keyCode === 27) {
        props.closeOverLay();
      }
    };

    document.addEventListener('click', handleClickOutside, true);
    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('click', handleClickOutside, true);
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [props.closeOverLay]);

  const clickFn = () => {
    dispatch(setOnboardingService({ serviceId, serviceName, userName: "", slugName }))
    dispatch(setOnboardingIsPublished(true));
    // const url = DataRoleSelected?.roleId !== Roles.NHS ? `${process.env.REACT_APP_PUBLIC_BASE_URL}/ServiceDetail/${props?.data?.slugName}` : `${process.env.REACT_APP_BASE_URL}/service-detail/${props?.data?.slugName}`
    const url = `${process.env.REACT_APP_BASE_URL}/service-detail/${slugName}`
    setTimeout(() => {
      window.open(url, "_blank");
    }, 1000);
  };

  const handleClickModal = (e: any) => {
    e.stopPropagation();
    dispatch(saveForLaterModal({
        saveForLaterModelOpen: true,
        serviceId: serviceDetails?.data?.serviceId,
        serviceName: serviceDetails?.data?.serviceName
    }))
  }

  const goToPrevSlide = () => {
    if (carouselRef.current) {
      carouselRef.current.prev(0);
      setIsCarouselChanged(true);
    }
  };

  const goToNextSlide = () => {
    if (carouselRef.current){
      carouselRef.current.next(0);
      setIsCarouselChanged(true);
    }
  };

  const handleBoxHoveredIn = () => {
    setIsBoxHovered(true)
  }
    
  const handleBoxHoveredOut = () => {
    setIsBoxHovered(false)
    setIsCarouselChanged(false);
  }

  const handleSlideChange = (event: any) => {
    setCurrentSlide(event.item.index);
  };

  const showPrevButton = () => {
    if(isBoxHovered){
      if(currentSlide > 0){
        return true;
      } else {
        if(isCarouselChanged){
          return true
        } else {
          return false
        }
      }
    } else {
      return false;
    }
  }
  const showNextButton = () => {
    if(isBoxHovered){
      if(currentSlide < imageList.length-1){
        return true;
      } else {
        if(isCarouselChanged){
          return true
        } else {
          return false
        }
      }
    } else {
      return false;
    }
  }

  return (
        <>
            {/* <div style={{ background: "#fff" }}>
        <div><button type="button" onClick={props.closeOverLay}>
                      Close
                    </button></div>
        <div>
          <OwlCarousel
            className="owl-theme"
            dots={false}
            autoWidth={false}
            items={1}
            margin={30}
            mouseDrag={false}
            nav
            navText={[
              '<i class="fa fa-chevron-left"></i>',
              '<i class="fa fa-chevron-right"></i>',
            ]}
          >
            {props?.data?.serviceImageList?.length > 0 &&
              props?.data?.serviceImageList.map((item: any, index: any) => {
                return (
                  <div className="item" key={index}>
                    <img
                      src={item.imageURL}
                      // className="img-fluid"
                      alt="list-image"
                      style={{ height: 150, width: 150 }}
                    />
                  </div>
                );
              })}
          </OwlCarousel>
        </div>
        <div>
          <h2>{props?.data?.serviceName}</h2>
          <p>{props?.data?.address}</p>
        </div>
        <div>
          <div>
            {BedAvailability.Available === props?.data?.bedAvailability && <span className="purple">{props?.data?.bedAvailability}</span>}
            {BedAvailability.Filling_Fast === props?.data?.bedAvailability && <span className="Mustard">{props?.data?.bedAvailability}</span>}
            {BedAvailability.Occupied === props?.data?.bedAvailability && <span className="red">{props?.data?.bedAvailability}</span>}
          </div>
          <div>Bed Count: {props.data.availableBedCount}</div>
          <div>
            <img
              className="img-fluid"
              width="100px"
              alt="list-image"
              src={CQCImage}
            ></img>{" "}
            {props?.data?.cqcRating}
          </div>
        </div>
      </div> */}
            <div className="nhs-body no-nhs-body">
                <div 
                  className="map-box-pin map-view-image-box unselectable" 
                  id="pin-view" 
                  key={imageKey}
                  onMouseEnter={handleBoxHoveredIn}
                  onMouseLeave={handleBoxHoveredOut}
                >
                    <div className="icon-pin-box" onClick={(e:any)=>{e.stopPropagation(); clickFn();}}>
                        <div>
                          <p className="rate-div good d-flex align-items-center">
                            <span className="bold">CQC Rating </span>
                            <span className="ms-1 text-ellipsis"> {props?.data?.cqcRating}</span>
                            <span className={`rate-dot ${
                                props?.data?.cqcRating.toLowerCase() === "good"
                                  ? "good"
                                  : props?.data?.cqcRating.toLowerCase() === "requires improvement"
                                  ? "requiresImprovement"
                                  : props?.data?.cqcRating.toLowerCase() === "inadequate"
                                  ? "inadequate"
                                  : props?.data?.cqcRating.toLowerCase() === "outstanding"
                                  ? "outstanding"
                                  : "na"
                              } ms-1`}></span>
                          </p>
                        </div>
                        <div className="d-flex icon-right-box">
                          {DataLogin?.userModel?.userRoleId === Roles.NHS && (
                            <svg
                                className='heart-icon'
                                version="1.1"
                                id="Layer_2"
                                xmlns="http://www.w3.org/2000/svg"
                                xmlnsXlink="http://www.w3.org/1999/xlink"
                                x="0px"
                                width={25}
                                y="0px"
                                viewBox="0 0 3154.5 2836.4"
                                xmlSpace="preserve"
                                onClick={(e:any)=>{e.stopPropagation();props.closeOverLay();handleClickModal(e);}}
                            >
                                <style>{`"enable-background:new 0 0 3154.5 2836.4;"`} </style>
                                <g className="st0" style={{ opacity: 0.5 }}>
                                    <path
                                        className="st1"
                                        fill="#FFFFFF"
                                        d="M923.6,159c-283.4,12.6-757,222.5-777.8,853.7c-7.4,224.7,166.8,903.5,1426.1,1678.2
    C2828.3,1918,3017,1238.1,3011.6,1012.7c-15.2-631.5-493.1-841.1-777.8-853.7c-466.2-20.7-655.2,321.5-655.2,321.5
    S1389.9,138.3,923.6,159z"
                                    />
                                </g>
                                <g>
                                    <path
                                        className="st2"
                                        fill="#010101;"
                                        d="M1571.8,2788.4l-43.5-26.8C107.2,1887.3,58.2,1149.1,62.8,1010c23.4-709.7,571-921.3,857.1-934l3.7,83l-3.7-83
    c352.6-15.2,558.3,152.4,658.7,265.6C1679,228.5,1886.3,60.5,2237.5,76c288.9,12.8,839.9,224.7,857.1,934.7
    c2.1,84.8-21.9,854.3-1479.3,1750.9L1571.8,2788.4z M963.8,241.2c-12,0-24.2,0.2-36.5,0.8c-233.1,10.4-679.2,185.3-698.5,773.6
    c-2.6,74.6,14.6,746.3,1342.9,1577.6c1083.5-678.5,1363.4-1309.7,1356.7-1578.4C2914.4,427.4,2465.4,252.4,2230,242
    c-399.7-16.1-571.9,266.8-578.9,279L1578,650.3l-72-129.7C1499.4,509.2,1343.2,241.2,963.8,241.2z"
                                    />
                                </g>
                            </svg>
                          )}                            
                            <i onClick={(e:any)=>{e.stopPropagation();props.closeOverLay();}} className="fa fa-times ms-2" aria-hidden="true"></i>
                        </div>
                    </div>
                    <div
                      className="carousel-container"
                      onClick={(e:any) => e.stopPropagation()}
                      onMouseUp={(e:any) => e.stopPropagation()}
                      onMouseDown={(e:any) => e.stopPropagation()}
                      onTouchStart={(e:any) => e.stopPropagation()}
                    >
                      {showPrevButton() && <div className="nav-div nav-div-prev-button" onClick={(e)=>{e.stopPropagation()}}>
                        {isBoxHovered && currentSlide > 0 && <button className="nav-button slider-prev-button" onClick={goToPrevSlide}>
                          <i className="fa fa-chevron-left"></i>
                        </button>}
                      </div>}
                      <OwlCarousel
                          ref={carouselRef}
                          className="owl-theme map-box-carousel"
                          dots={true}
                          dotsEach={3}
                          items={1}
                          margin={30}
                          mouseDrag={true}
                          touchDrag={true}
                          nav={false}
                          onDoubleClick={(e:any) =>  e.stopPropagation()}
                          onChanged={handleSlideChange}
                          startPosition={currentSlide}
                        >
                        {imageList.map((item: any, index: number) => {
                            return (
                              <div className="item map-image-box" key={item.imageURL+index} onClick={(e:any)=>{e.stopPropagation();clickFn()}}>
                                <img src={item.imageURL} alt="list-image" />
                              </div>
                            );
                          })
                        }
                      </OwlCarousel>
                      {showNextButton() && <div className="nav-div nav-div-next-button" onClick={(e)=>{e.stopPropagation()}}>
                        {isBoxHovered && currentSlide < imageList.length-1 && <button className="nav-button slider-next-button" onClick={goToNextSlide}>
                          <i className="fa fa-chevron-right"></i>
                        </button>}
                      </div>}
                    </div>
                    <div className="p-2" onClick={(e:any)=>{e.stopPropagation();clickFn()}}>
                        <h3 className="bolder lead-text map-box-title">
                          {props?.data?.serviceName}, {props?.data?.address}
                        </h3>
                        <ul className="pin-box-service">
                            <li>{props?.data?.serviceTypeName}</li>
                            {/* <li className="ms-1 me-1">Hospital</li>
                            <li>Rehabilitation</li> */}
                        </ul>
                        <div className="pin-status">
                            {BedAvailability.Available === props?.data?.bedAvailability && <p className="green">{props?.data?.bedAvailability}</p>}
                            {BedAvailability.Filling_Fast === props?.data?.bedAvailability && <p className="green">{props?.data?.bedAvailability}</p>}
                            {BedAvailability.Occupied === props?.data?.bedAvailability && <p className="red">{props?.data?.bedAvailability}</p>}
                            <span className="ms-1">
                                <svg
                                    version="1.1"
                                    id="Layer_1"
                                    xmlns="http://www.w3.org/2000/svg"
                                    xmlnsXlink="http://www.w3.org/1999/xlink"
                                    x="0px"
                                    width={30}
                                    y="0px"
                                    viewBox="0 0 409.6 409.6"
                                    xmlSpace="preserve"
                                >
                                    <style>{`"enable-background:new 0 0 409.6 409.6;"`} </style>
                                    <g>
                                        <path
                                            fill="#4e4851"
                                            className="st0"
                                            d="M375.3,291.3c-1,2.5-2.4,4.7-5.3,5.4c-4.8,1.1-8.7-1.9-8.8-7.2c-0.1-8,0-16,0-24c0-1.2,0-2.4,0-3.8
		c-104.3,0-208.2,0-312.6,0c0,1.3,0,2.5,0,3.7c0,10.3,0,20.6,0,31c0,3.2-1.2,5.7-4.1,7c-2.5,1.2-5.2,1.1-7.1-0.9
		c-1.3-1.3-2.1-3.3-2.8-5.1c-0.4-1-0.1-2.2-0.1-3.3c0-59.6,0-119.2,0-178.8c0-1.6,0.1-3.4,0.5-4.9c0.9-3.4,3.9-5.2,7.3-4.9
		c3.2,0.3,5.7,2.7,6.2,6c0.2,1.3,0.2,2.7,0.2,4c0,15.4,0,30.8,0,46.3c0,1.2,0,2.4,0,4c1-0.3,1.7-0.6,2.4-0.8
		c4.3-0.9,8.6-2.6,12.9-2.7c13.4-0.3,26.8-0.2,40.3-0.1c11.4,0.1,21.2,4.3,29.4,12.1c7.5,7.1,8.5,15.9,6.6,25.5
		c-0.3,1.5-0.7,3-1.1,5c1.6,0,2.9,0,4.3,0c74.1,0,148.2,0,222.4-0.1c4.4,0,7.8,1.2,9.7,5.4C375.3,237.1,375.3,264.2,375.3,291.3z
		 M360.9,247.2c0-9.3,0-18.7,0-28c-104.2,0-208.2,0-312.1,0c0,9.5,0,18.7,0,28C152.8,247.2,256.7,247.2,360.9,247.2z M91,204.7
		C91,204.7,91,204.7,91,204.7c9.3,0,18.6,0.1,27.9-0.1c1.8,0,4.2-0.7,5.3-1.9c3.8-4.2,3-14.8-1.1-18.7c-5.5-5.2-12.1-7.7-19.6-7.8
		c-13,0-25.9,0-38.9,0c-2.1,0-4.2,0.2-6.2,0.8c-6.5,2-10.5,8.5-9.6,15.2c1,7.1,6.6,12.2,13.9,12.3C72.2,204.8,81.6,204.7,91,204.7z"
                                        />
                                    </g>
                                </svg>
                            </span>
                            {props.data.availableBedCount}
                        </div>
                    </div>
                    {/* <p className="ms-auto bold map-box-subtitle">
          <i className="fa-solid fa-star yellow-star"></i> 4.5 (153)
        </p> */}
          {saveForLaterModelOpen && <SaveForLaterModalContainer />}
                </div>
              
            </div>
            {/* <div style={{ background: "#fff" }}>
        <div><button type="button" onClick={props.closeOverLay}>
                      Close
                    </button></div>
        <div>
          <OwlCarousel
            className="owl-theme"
            dots={false}
            autoWidth={false}
            items={1}
            margin={30}
            mouseDrag={false}
            nav
            navText={[
              '<i class="fa fa-chevron-left"></i>',
              '<i class="fa fa-chevron-right"></i>',
            ]}
          >
            {props?.data?.serviceImageList?.length > 0 &&
              props?.data?.serviceImageList.map((item: any, index: any) => {
                return (
                  <div className="item" key={index}>
                    <img
                      src={item.imageURL}
                      // className="img-fluid"
                      alt="list-image"
                      style={{ height: 150, width: 150 }}
                    />
                  </div>
                );
              })}
          </OwlCarousel>
        </div>
        <div>
          <h2>{props?.data?.serviceName}</h2>
          <p>{props?.data?.address}</p>
        </div>
        <div>
          <div>
            {BedAvailability.Available === props?.data?.bedAvailability && <span className="purple">{props?.data?.bedAvailability}</span>}
            {BedAvailability.Filling_Fast === props?.data?.bedAvailability && <span className="Mustard">{props?.data?.bedAvailability}</span>}
            {BedAvailability.Occupied === props?.data?.bedAvailability && <span className="red">{props?.data?.bedAvailability}</span>}
          </div>
          <div>
            <img
              className="img-fluid"
              width="100px"
              alt="list-image"
              src={CQCImage.src}
            ></img>{" "}
            {props?.data?.cqcRating}
          </div>
        </div>
      </div> */}
        </>
    );
};

export default GoogleMapMarkerBoxComponent;

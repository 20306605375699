import React from 'react';
import OnboardingPreviewCoordinatorView from './OnboardingPreviewCoordinatorView';

const OnboardingPreviewCoordinatorContainer = (props:any) => {
    return (
        <div>
            <OnboardingPreviewCoordinatorView {...props}/>
        </div>
    );
};

export default OnboardingPreviewCoordinatorContainer;
import React from 'react';
import WardNumberView from './WardNumberView';

const WardNumberContainer = React.forwardRef((props, ref) => {
    return (
        <div>
            <WardNumberView {...props} ref={ref} />
        </div>
    );
});

export default WardNumberContainer;
import React from 'react';
import ModalWrapperContainer from '../ModalWrapper/indexContainer';
import { OpenFile,Viewer } from '@react-pdf-viewer/core';
import { getFilePlugin, RenderDownloadProps } from '@react-pdf-viewer/get-file';
import '@react-pdf-viewer/core/lib/styles/index.css';
import { Tooltip } from 'react-tooltip';

import { Document, Page, pdfjs } from 'react-pdf';
//pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

interface ModalProps {
    onClose: () => any;
    title: string;
    content: any;
    className: any;
    previewdata: any;
    isContractFile: boolean;
    printFileName:any;
}

const PreviewContractView = (props: ModalProps) => {
    
    const base64 = `data:application/pdf;base64,${props.previewdata}`;
    const pdfContentType = 'application/pdf';
    const getFilePluginInstance = getFilePlugin({
        fileNameGenerator: (file: OpenFile) => {
            // `file.name` is the URL of opened file            
            const fileName = file.name.substring(file.name.lastIndexOf('/') + 1);
            const finalFileName = props.isContractFile != undefined && props.isContractFile ?`${props.printFileName}` :`${fileName}`;
            return finalFileName;
        },
    });    
    const { Download } = getFilePluginInstance;

    const base64toBlob = (data: any) => {
        // Cut the prefix `data:application/pdf;base64` from the raw base 64
        const base64WithoutPrefix = data.substr(`data:${pdfContentType};base64,`.length);

        const bytes = atob(base64WithoutPrefix);
        let length = bytes.length;
        let out = new Uint8Array(length);

        while (length--) {
            out[length] = bytes.charCodeAt(length);
        }

        return new Blob([out], { type: pdfContentType });
    };

    const url = URL.createObjectURL(base64toBlob(base64));
    const title = props.isContractFile != undefined && props.isContractFile ? `Print/Download` :`Download`;

    return (
        <ModalWrapperContainer
            title="Preview Contract"
            onClose={props.onClose}
            className="modal modal-small modal-preview"
            content={
                <div>
                    <Download>
                        {(props: RenderDownloadProps) => (
                            <div className="text-right">
                                <button className="btn btn-white bold btn-sm me-2 ms-2 mb-3" data-tooltip-id="my-tooltip-contract" data-tooltip-content="Print/Download Contract" onClick={props.onClick}>
                                    <i className="fa-sharp fa-solid fa-download me-2"></i>
                                    {title}
                                </button>
                                <Tooltip id="my-tooltip-contract" className="my-tooltip" />
                            </div>
                        )}
                    </Download>
                    <Viewer fileUrl={url} plugins={[getFilePluginInstance]} />
                </div>
            }
        />
    );
};

export default PreviewContractView;

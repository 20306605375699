import { useForm, useFieldArray, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useEffect, useState } from 'react';
import { FilePond, registerPlugin } from 'react-filepond';
import 'filepond/dist/filepond.min.css';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
// import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type'; 'filepond-plugin-file-validate-type';
import { Tooltip } from 'react-tooltip';
import { useSelector } from 'react-redux';
import swal from 'sweetalert';
import { useTranslation } from 'react-i18next';
import { RootState, store } from '../../../../../../store';
import { deleteContactDocument, getContactDocument } from '../../../../../../service/dashboard-service';
import { BASEURLDEV, API_ENDPOINT } from '../../../../../../service/ApiConstants';
import { LeadFilterServiceType } from '../../../../../../utils/constant';
import RenderModalContainer from '../../../../../../components/RenderModal/indexContainer';
import NoteContainer from '../../../../../../components/NoteModal/indexContainer';
import note from '../../../../../../assets/images/note.svg';
import warning from '../../../../../../assets/images/warning.png';
registerPlugin(FilePondPluginFileValidateSize,FilePondPluginFileValidateType);

const validationSchema = Yup.object().shape({
    fEmailId: Yup.string()
        .trim()
        .matches(/^$|^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)
        .email('Email is Invalid')
        .notRequired(),
    fFunder: Yup.string().trim().required(),
    fContactNo: Yup.string()
        .trim()
        .matches(/^$|^(?:\+44|0)(?:\d\s?){9}\d$/)
        .nonNullable()
        .notRequired(),
    fAddress1: Yup.string().trim().notRequired(),
    fCity: Yup.string().trim().notRequired(),
    fPoBox: Yup.string()
        .trim()
        .matches(/^$|^[A-Z]{1,2}[0-9R][0-9A-Z]? [0-9][A-Z]{2}$/)
        .nonNullable()
        .notRequired(),
    funderContract: Yup.string().notRequired(),
    fCheck: Yup.boolean().notRequired(),
    ftype: Yup.string().nonNullable().notRequired(),
    fpo: Yup.string().notRequired(),
    cEmailId: Yup.string().when('ftype', {
        is: '4',
        then : (schema: any) => schema.nonNullable().notRequired(),
        otherwise:  (schema: any) => schema.matches(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/, { message: "Email is Invalid", excludeEmptyString: true})
        // .email('Email is Invalid')
        // .required('Email is required')}),
        .notRequired()}),
    cFunder:  Yup.string().when('ftype', {
        is: '4',
        then : (schema: any) => schema.nonNullable().notRequired(),
        otherwise:  (schema: any) =>schema.required()}),
    cContactNo:  Yup.string().when('ftype', {
        is: '4',
        then : (schema: any) => schema.nonNullable().notRequired(),
        otherwise:  (schema: any) => schema.matches(/^(?:\+44|0)(?:\d\s?){9}\d$/, { message: "Contact is Invalid", excludeEmptyString: true})
        // .nonNullable()
        // .required('contact is required')}),
        .notRequired()}),
    cAddress1: Yup.string().trim().notRequired(),
    cCity: Yup.string().trim().notRequired(),
    cPoBox: Yup.string()
        .trim()
        .matches(/^$|^$|^[A-Z]{1,2}[0-9R][0-9A-Z]? [0-9][A-Z]{2}$/)
        .nonNullable()
        .notRequired(),

        sEmailId: Yup.string()
        .trim()
        .matches(/^$|^$|^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)
        .email('Email is Invalid')
        .notRequired(),
    sFunder: Yup.string().trim().notRequired(),
    sContactNo: Yup.string()
        .trim()
        .matches(/^$|^$|^(?:\+44|0)(?:\d\s?){9}\d$/)
        .nonNullable()
        .notRequired(),
    sAddress1: Yup.string().trim().notRequired(),
    sCity: Yup.string().trim().notRequired(),
    sPoBox: Yup.string()
        .trim()
        .matches(/^$|^[A-Z]{1,2}[0-9R][0-9A-Z]? [0-9][A-Z]{2}$/)
        .nonNullable()
        .notRequired(),
        contractPDF: Yup.mixed().notRequired()
});

const ContractView = (props: any) => {
    const { t } = useTranslation();
    const { StatusFunderInfo, DataFunderInfo, MessageFunderInfo, ErrorFunderInfo } = useSelector((state: RootState) => state.funderInformation);
    const { register, control, handleSubmit, reset, getValues, formState, setValue, clearErrors } = useForm({ mode: 'all', resolver: yupResolver(validationSchema) });
    const { errors } = formState;
    const [isValid, setisValid] = useState<any>(false);
    const [file, setFiles] = useState<any[]>([]);
    const [fileres, setFileres] = useState<any>('');
    const state = store.getState();
    const token = state.login.DataLogin.jwtToken;
    const { ShowClass, FormName, Data } = useSelector((state: RootState) => state.navigation);
    const { StatusServiceDetail, DataServiceDetail } = useSelector((state: RootState) => state.serviceDetail);
    const [isEditing, setisEditing] = useState(false);
    const [showNoteModal, setShowNoteModal] = useState(false);
    const [funderNote, setFunderNote] = useState<any>('');
    const [funderName, setFunderName] = useState<any>('');
    const { DataLeadInfo } = useSelector((state: RootState) => state.leadInformation);
    useEffect(() => {
        setValue('fFunder', '');
        setValue('fEmailId', '');
        setValue('fContactNo', '');
        setValue('fAddress1', '');
        setValue('fCity', '');
        setValue('fPoBox', '');
        setValue('ftype','');
        setValue('fpo','');
        setValue('cFunder', '');
        setValue('cEmailId', '');
        setValue('cContactNo', '');
        setValue('cAddress1', '');
        setValue('cCity', '');
        setValue('cPoBox', '');

        setValue('sFunder', '');
        setValue('sEmailId', '');
        setValue('sContactNo', '');
        setValue('sAddress1', '');
        setValue('sCity', '');
        setValue('sPoBox', '');
        if (props.isdisplay === true) {
            setValue('contractPDF', [], { shouldValidate: true });
        } else {
            setValue('contractPDF', []);
        }
        setFiles([]);

        setValue('fCheck', false);
        setFileres('');
    }, [props.isdisplay]);

    useEffect(()=>{
      setValue('ftype',props.fundertype?.toString());
    },[props.fundertype])
    useEffect(()=>{
       if(props.changetype){
        setValue('fFunder', '');
        setValue('fEmailId', '');
        setValue('fContactNo', '');
        setValue('fAddress1', '');
        setValue('fCity', '');
        setValue('fPoBox', '');
        setValue('fpo', '');
        setValue('cFunder', '');
        setValue('cEmailId', '');
        setValue('cContactNo', '');
        setValue('cAddress1', '');
        setValue('cCity', '');
        setValue('cPoBox', '');

        setValue('sFunder', '');
        setValue('sEmailId', '');
        setValue('sContactNo', '');
        setValue('sAddress1', '');
        setValue('sCity', '');
        setValue('sPoBox', '');
       }
      },[props.changetype])

      useEffect(()=>{
        
       if(getValues('ftype') === '4'){
        const isexists = [...props.selectedfunder].some((val: any) => val.funderData.fFunder === props.selfData.FullName);
            if (isexists) {
             swal('Funder is already added', {
                 icon: 'warning',
                 dangerMode: true
             });
            }
            else{
                setTimeout(() => {
                    setValue('fFunder', props.selfData?.name,{shouldValidate: true});
                    setValue('fEmailId', props.selfData?.EmailId,{shouldValidate: true});
                    setValue('fContactNo', props.selfData?.ContactNo,{shouldValidate: true});
                    setValue('fAddress1', props.selfData?.Address1,{shouldValidate: true});
                    setValue('fCity', props.selfData?.City,{shouldValidate: true});
                    setValue('fPoBox', props.selfData?.PoBox,{shouldValidate: true});
                }, 200);
            }
     
       
       }
      },[props.selfData,props.fundertype])


    useEffect(() => {
        if (!props.isCheck) {
            setValue('fCheck', false);
        }
    }, [props.isCheck]);

    useEffect(() => {
        setValue('contractPDF', file);
    }, [file]);
    useEffect(() => {
        setValue('contractPDF', file);
    }, [file]);

    useEffect(() => {
        
        
        if (!isValid) {
        }

        if (formState.isValid) {
            setisValid(true);
            props.getcontracterror(formState.isValid);
        } else {
            setisValid(false);

            props.getcontracterror(formState.isValid);
        }
    }, [formState]);

    useEffect(() => {
        if (props.commissionerData != '') {
            setValue('cFunder', props.commissionerData.cFunder || '');
            setValue('cEmailId', props.commissionerData.cEmailId || '');
            setValue('cContactNo', props.commissionerData.cContactNo || '');
            setValue('cAddress1', props.commissionerData.cAddress1 || '');
            setValue('cCity', props.commissionerData.cCity || '');
            setValue('cPoBox', props.commissionerData.cPoBox || '');
        }
    }, [props.commissionerData]);
    // (val.funderData.fFunder === e.target.value) &&(fid?.funderTypeId !== Number(val?.funderData?.ftype)));
    const updateFileds =  (e: any,fid:any) => {
        const isexists = [...props.selectedfunder].some((val: any) => {
            // console.log(val);
            // console.log(fid);
            if(fid?.funderTypeId !== Number(val?.funderData?.ftype)){
                if (val.funderData.fFunder === e.target.value){
                       return true;
                } else{
                    return false;
                }
            } else{
                return true;
            }

        })
        if (isexists) {
                swal('Funder is already added', {
                    icon: 'warning',
                    dangerMode: true
                });
                props.updateData(e);
                setValue('fFunder','',{shouldValidate: true});
                setValue('fEmailId', '', { shouldValidate: true });
                setValue('fContactNo', '', { shouldValidate: true });
                setValue('fAddress1', '', { shouldValidate: true });
                setValue('fCity', '', { shouldValidate: true });
                setValue('fPoBox', '', { shouldValidate: true });
        }
        else{
        for(let i=0;i<DataFunderInfo?.data?.length;i++){
            if(DataFunderInfo?.data[i]?.key === Number(e.target.value)){
                setValue('fEmailId', DataFunderInfo.data[i].funderEmail || '',{shouldValidate: true});
                setValue('fContactNo', DataFunderInfo.data[i].funderPhoneNo || '',{shouldValidate: true});
                setValue('fAddress1', DataFunderInfo.data[i].funderAddressLine1 || '',{shouldValidate: true});
                setValue('fCity', DataFunderInfo.data[i].funderCityId?.toString() || '',{shouldValidate: true});
                setValue('fPoBox', DataFunderInfo.data[i].funderPobox || '',{shouldValidate: true});
                setFunderName(DataFunderInfo.data[i].value );
                props.updateData(e);
            }
        }
    }
    };

    const removepdf = (e: any) => {
        e.preventDefault();
        swal({
            title: '',
            text: 'Are you sure you want to remove contract?',
            icon: 'warning',
            buttons: ['No', 'Yes'],
            dangerMode: true
        }).then(async (confirm: any) => {
            if (confirm) {
                const payload = { path: fileres };
                const response = await deleteContactDocument(payload);
                if (response.status == true) {
                    setFiles([]);
                    setFileres('');
                    props.updatefilepond();
                } else {
                }
            }
        });
    };

    const openpdf = async (e: any) => {
        e.preventDefault();
        const payload = { path: fileres };
        const response = await getContactDocument(payload);
        if (response.status === true) {
            let alink = document.createElement('a');
            alink.target = '_blank';
            alink.href = response.data;
            alink.click();
        }
    };
    const hideForm = () =>{
        props.hideform(true);
    }

    const handleNoteClickModal = (noteval:any) => {
        setFunderNote(noteval);
        setShowNoteModal(true);
    }
    const toggle = () => {
        swal({
            title: '',
            text: 'Are you sure you want to close?',
            icon: warning,
            buttons: ['No', 'Yes'],
            dangerMode: true
        })
            .then(async (confirm: any) => {
                if (confirm) {
                    setShowNoteModal(false);
                }
            })
    };

    const updateNote = (e: any,i: any=0) => {
        if(e.target.rawValue.length > 0)
        {
            setFunderNote(e.target.value);
            setisEditing(true);
        }else{
            setFunderNote('');
            setisEditing(false);
        }
        
        setShowNoteModal(false);
        props.updateData(e);
    }
    
    return (
        <>
            {props.isdisplay === true && (
                <>
                  {props.isback === true &&
                    <div className='text-right'>
                        <a onClick={hideForm} className="btn-icon gray-background" data-tooltip-id="my-tooltip-back" data-tooltip-content="Back">
                        <i className="fa-solid fa-arrow-left"></i></a>
                    </div>
                }
                <div style={{ pointerEvents: ((Data?.fleadstatus === 1 && DataLeadInfo === '') || (DataLeadInfo?.data?.leadEditable === true)) ? 'all' : 'none' }}>
                 <div className="d-flex justify-content-between mb-1 mt-2" >
                        <label className="mb-2 me-2">{t('LeadSidebar.ftype')}</label>

                        <label className="form--radio-label mb-2  d-flex align-items-center">
                            <input  type="radio"
                            {...register('ftype', {
                                onChange: (e: any) => {
                                    props.updateData(e);
                                    clearErrors();
                                    setFunderName('');
                                }
                            })}
                            name={'ftype' as const} id='CCG' value="1" />

                            <span className="ms-1">{t('LeadSidebar.ccg')}</span>
                        </label>

                        <label className="form--radio-label  mb-2 d-flex align-items-center">
                        <input  type="radio"
                            {...register('ftype', {
                                onChange: (e: any) => {
                                    props.updateData(e);
                                    clearErrors();
                                    setFunderName('');
                                }
                            })}
                            name={'ftype' as const} id='LA' value="2" />

                            <span className="ms-1">{t('LeadSidebar.la')}</span>
                        </label>

                        <label className="form--radio-label  mb-2 d-flex align-items-center">
                        <input  type="radio"
                            {...register('ftype', {
                                onChange: (e: any) => {
                                    props.updateData(e);
                                    clearErrors();
                                    setFunderName('');
                                }
                            })}
                            name={'ftype' as const} id='Charity' value="3" />

                            <span className="ms-1">{t('LeadSidebar.ch')}</span>
                        </label>

                        <label className="form--radio-label mb-2 d-flex align-items-center">
                        <input  type="radio"
                            {...register('ftype', {
                                onChange: (e: any) => {
                                    props.updateData(e);
                                    clearErrors();
                                    setFunderName('');
                                }
                            })}
                            name={'ftype' as const} id='Self' value="4" />

                            <span className="ms-1">{t('LeadSidebar.self')}</span>
                        </label>
                    </div>
                    {getValues('ftype') !== '4' && <>
                    <div className="d-flex mb-2">
                        <select
                            {...register('fFunder', {
                                onChange: (e: any) => {
                                    let fid = props.funderlist?.data?.find((data:any)=>data.key === Number(e.target.value))
                                    updateFileds(e,fid);
                                    clearErrors();
                                }
                            })}
                            name={'fFunder' as const}
                            id="Funder"
                            //@ts-ignore
                            className={`form-control white flex-7 ${errors.fFunder ? 'is-invalid' : ''}`}
                        >
                            <option disabled={true} value="">
                                {t('LeadSidebar.fname')}
                            </option>
                            {props.funderlist &&
                                props.funderlist?.data?.map((funders: any) => {
                                    return (
                                        <option key={funders.key} value={funders.key}>
                                            {funders.value}
                                        </option>
                                    );
                                })}
                        </select>
                    </div>
                    </>}
                    {getValues('ftype') === '4' && <>
                    <div className="d-flex mb-2">
                    <input
                        type="text"
                        disabled
                        {...register('fFunder')}
                        name={'fFunder' as const}
                        placeholder={`${t('LeadSidebar.fname')}`}
                        //@ts-ignore
                        className={`form-control white flex-7 ${errors.fFunder ? 'is-invalid' : ''}`}
                    />
                    </div>
                    </>}
                    <input
                        type="text"
                        {...register('fEmailId', {
                            onChange: (e: any) => {
                                props.updateData(e);
                            }
                        })}
                        name={'fEmailId' as const}
                        placeholder={`${t('VacantRightbar.email')}`}
                        // disabled={getValues('ftype') === '4' ? true : false}
                        // style={{ pointerEvents: getValues('ftype') !== '4' ? 'all' : 'none' }}
                        //@ts-ignore
                        className={`form-control white mb-2 ${errors.fEmailId ? 'is-invalid' : ''}`}
                    />

                    <div className="d-flex justify-content-between">
                        <input
                            type="text"
                            {...register('fContactNo', {
                                onChange: (e: any) => {
                                    props.updateData(e);
                                }
                            })}
                            name={'fContactNo' as const}
                            placeholder={`${t('VacantRightbar.contact')}`}
                            // disabled={getValues('ftype') === '4' ? true : false}
                            // style={{ pointerEvents: getValues('ftype') !== '4' ? 'all' : 'none' }}
                            //@ts-ignore
                            className={`form-control white mb-2 ${errors.fContactNo ? 'is-invalid' : ''}`}
                        />
                    </div>
                    <input
                        type="text"
                        {...register('fAddress1', {
                            onChange: (e: any) => {
                                props.updateData(e);
                            }
                        })}
                        name={'fAddress1' as const}
                        placeholder={`${t('VacantRightbar.add1')}`}
                        // disabled={getValues('ftype') === '4' ? true : false}
                        // style={{ pointerEvents: getValues('ftype') !== '4' ? 'all' : 'none' }}
                        //@ts-ignore
                        className={`form-control white mb-2 ${errors.fAddress1 ? 'is-invalid' : ''}`}
                    />
                    <div className="d-flex justify-content-between">
                        <select
                            {...register('fCity', {
                                onChange: (e: any) => {
                                    props.updateData(e);
                                }
                            })}
                            name={'fCity' as const}
                            id="city"
                            // disabled={getValues('ftype') === '4' ? true : false}
                            // style={{ pointerEvents: getValues('ftype') !== '4' ? 'all' : 'none' }}
                            //@ts-ignore
                            className={`form-control white mb-2 ${errors.fCity ? 'is-invalid' : ''}`}
                        >
                            <option disabled={true} value="">{t('VacantRightbar.county')}</option>
                            {props.citylist &&
                                props.citylist?.data?.map((cities: any) => {
                                    return (
                                        <option key={cities.key} value={cities.key}>
                                            {cities.value}
                                        </option>
                                    );
                                })}
                        </select>
                        <input
                            type="text"
                            {...register('fPoBox', {
                                onChange: (e: any) => {
                                    props.updateData(e);
                                }
                            })}
                            name={'fPoBox' as const}
                            placeholder={`${t('VacantRightbar.postal')}`}
                            // disabled={getValues('ftype') === '4' ? true : false}
                            // style={{ pointerEvents: getValues('ftype') !== '4' ? 'all' : 'none' }}
                            //@ts-ignore
                            className={`form-control white mb-2 ms-2 ${errors.fPoBox ? 'is-invalid' : ''}`}
                        />
                    </div>
                    <input
                        type="text"
                        {...register('fsuid', {
                            onChange: (e: any) => {                                
                                props.updateData(e);
                            }
                        })}
                        name={'fsuid' as const}
                        placeholder={`${t('VacantRightbar.Suid')}`}
                        // disabled={getValues('ftype') === '4' ? true : false}
                        // style={{ pointerEvents: getValues('ftype') !== '4' ? 'all' : 'none' }}
                        //@ts-ignore
                        className={`form-control white mb-2`}
                        maxLength={40}
                    />                
                     <input
                        type="text"
                        {...register('fpo', {
                            onChange: (e: any) => {                                
                                props.updateData(e);
                            }
                        })}
                        name={'fpo' as const}
                        placeholder={`${t('VacantRightbar.po_reference')}`}
                        // disabled={getValues('ftype') === '4' ? true : false}
                        // style={{ pointerEvents: getValues('ftype') !== '4' ? 'all' : 'none' }}
                        //@ts-ignore
                        className={`form-control white mb-2`}
                        maxLength={40}
                    />                

                    { funderNote?.length == 0 && <div className='d-flex mt-3'><img src={note} className='img-fluid me-1 noteiconsize'  onClick={()=>handleNoteClickModal('')}></img><p>{t('VacantRightbar.enter_note')}</p></div>}
                    { window.$utils.convertToPlain(funderNote)?.length >= 30 && <div className='d-flex mt-2'><img src={note} className='img-fluid me-1'></img><p>{window.$utils.convertToPlain(funderNote).slice(0,27)}...</p>  <p className='bold' onClick={()=>handleNoteClickModal(funderNote)}>{t('VacantRightbar.read_more')}</p></div>} 
                    { window.$utils.convertToPlain(funderNote)?.length > 0 && window.$utils.convertToPlain(funderNote)?.length < 30 && (<div className='d-flex mt-2'><img src={note} className='img-fluid me-1' onClick={()=>handleNoteClickModal(funderNote)}></img><p>{window.$utils.convertToPlain(funderNote)}</p></div> )}

                    {getValues('ftype') !== '4' &&
                    <>
                    <label className="mb-2 bold mt-3">{t('VacantRightbar.comm')}</label>
                    <div className="form-check">
                        <input
                            {...register('fCheck', {
                                onChange: (e: any) => {
                                    props.updateData(e);
                                }
                            })}
                            name={'fCheck' as const}
                            className="form-check-input"
                            type="checkbox"
                            value=""
                            id="checkboxsameas"
                        />
                        <label className="form-check-label">{t('VacantRightbar.same')}</label>
                    </div>

                    <input
                        type="text"
                        {...register('cFunder', {
                            onChange: (e: any) => {
                                props.updateCData(e);
                            }
                        })}
                        name={'cFunder' as const}
                        placeholder={`${t('LeadSidebar.cname')}`}
                        //@ts-ignore
                        className={`form-control white mb-2  ${errors.cFunder ? 'is-invalid' : ''}`}
                    />

                    <input
                        type="text"
                        {...register('cEmailId', {
                            onChange: (e: any) => {
                                props.updateCData(e);
                            }
                        })}
                        name={'cEmailId' as const}
                        placeholder={`${t('VacantRightbar.email')}`}
                        //@ts-ignore
                        className={`form-control white mb-2  ${errors.cEmailId ? 'is-invalid' : ''}`}
                    />

                    <div className="d-flex justify-content-between">

                        <input
                            type="text"
                            {...register('cContactNo', {
                                onChange: (e: any) => {
                                    props.updateCData(e);
                                }
                            })}
                            name={'cContactNo' as const}
                            placeholder={`${t('VacantRightbar.contact')}`}
                            //@ts-ignore
                            className={`form-control white mb-2 ${errors.cContactNo ? 'is-invalid' : ''}`}
                        />
                    </div>
                    <input
                        type="text"
                        {...register('cAddress1', {
                            onChange: (e: any) => {
                                props.updateCData(e);
                            }
                        })}
                        name={'cAddress1' as const}
                        placeholder={`${t('VacantRightbar.add1')}`}
                        //@ts-ignore
                        className={`form-control white mb-2  ${errors.cAddress1 ? 'is-invalid' : ''}`}
                    />

                    <div className="d-flex justify-content-between">
                        <select
                            {...register('cCity', {
                                onChange: (e: any) => {
                                    props.updateCData(e);
                                }
                            })}
                            name={'cCity' as const}
                            id="ccity"
                            //@ts-ignore
                            className={`form-control white mb-2  ${errors.cCity ? 'is-invalid' : ''}`}
                        >
                            <option value="">{t('VacantRightbar.county')}</option>
                            {props.citylist &&
                                props.citylist?.data?.map((cities: any) => {
                                    return (
                                        <option key={cities.key} value={cities.key}>
                                            {cities.value}
                                        </option>
                                    );
                                })}
                        </select>
                        <input
                            type="text"
                            {...register('cPoBox', {
                                onChange: (e: any) => {
                                    props.updateCData(e);
                                }
                            })}
                            name={'cPoBox' as const}
                            placeholder={`${t('VacantRightbar.postal')}`}
                            //@ts-ignore
                            className={`form-control white mb-2 ms-2 ${errors.cPoBox ? 'is-invalid' : ''}`}
                        />
                    </div>
                    {Number(props.selfData?.servicetype) !== 1 && getValues('ftype') !== '1' && <>
                    <label className="mb-2 bold mt-3">{t('VacantRightbar.social')}</label>
                    <input
                        type="text"
                        {...register('sFunder', {
                            onChange: (e: any) => {
                                props.updateSData(e);
                            }
                        })}
                        name={'sFunder' as const}
                        placeholder={`${t('VacantRightbar.sname')}`}
                        //@ts-ignore
                        className={`form-control white mb-2  ${errors.sFunder ? 'is-invalid' : ''}`}
                    />

                    <input
                        type="text"
                        {...register('sEmailId', {
                            onChange: (e: any) => {
                                props.updateSData(e);
                            }
                        })}
                        name={'sEmailId' as const}
                        placeholder={`${t('VacantRightbar.email')}`}
                        //@ts-ignore
                        className={`form-control white mb-2  ${errors.sEmailId ? 'is-invalid' : ''}`}
                    />

                    <div className="d-flex justify-content-between">

                        <input
                            type="text"
                            {...register('sContactNo', {
                                onChange: (e: any) => {
                                    props.updateSData(e);
                                }
                            })}
                            name={'sContactNo' as const}
                            placeholder={`${t('VacantRightbar.contact')}`}
                            //@ts-ignore
                            className={`form-control white mb-2 ${errors.sContactNo ? 'is-invalid' : ''}`}
                        />
                    </div>
                    <input
                        type="text"
                        {...register('sAddress1', {
                            onChange: (e: any) => {
                                props.updateSData(e);
                            }
                        })}
                        name={'sAddress1' as const}
                        placeholder={`${t('VacantRightbar.add1')}`}
                        //@ts-ignore
                        className={`form-control white mb-2  ${errors.sAddress1 ? 'is-invalid' : ''}`}
                    />

                    <div className="d-flex justify-content-between">
                        <select
                            {...register('sCity', {
                                onChange: (e: any) => {
                                    props.updateSData(e);
                                }
                            })}
                            name={'sCity' as const}
                            id="ccity"
                            //@ts-ignore
                            className={`form-control white mb-2  ${errors.sCity ? 'is-invalid' : ''}`}
                        >
                            <option value="">{t('VacantRightbar.county')}</option>
                            {props.citylist &&
                                props.citylist.data.map((cities: any) => {
                                    return (
                                        <option key={cities.key} value={cities.key}>
                                            {cities.value}
                                        </option>
                                    );
                                })}
                        </select>
                        <input
                            type="text"
                            {...register('sPoBox', {
                                onChange: (e: any) => {
                                    props.updateSData(e);
                                }
                            })}
                            name={'sPoBox' as const}
                            placeholder={`${t('VacantRightbar.postal')}`}
                            //@ts-ignore
                            className={`form-control white mb-2 ms-2 ${errors.sPoBox ? 'is-invalid' : ''}`}
                        />
                    </div>
                    </>
                    }
                    </>
                    }
                    {/* <div className="d-flex" style={{ pointerEvents: props.condata !== undefined && props.patientname != '' ? 'all' : 'none' }}>
                        <div className="w-100">
                            {fileres !== '' && (
                                <>
                                    <div className="d-flex justify-content-between  mt-2">
                                        <div className="input-readonly" data-tooltip-id="my-tooltip" data-tooltip-content="Contract Document ">
                                            <textarea rows={1} {...register(`funderContract`, {})} name={`funderContract` as const} onClick={(e: any) => openpdf(e)} readOnly />
                                        </div>

                                        <button className="btn-icon red-background" name="btndelete" onClick={(e: any) => removepdf(e)}>
                                            <i className="fa-solid fa-trash"></i>
                                        </button>
                                    </div>
                                </>
                            )}
                            {fileres == '' && (
                                <>
                                    <Controller
                                        control={control}
                                        name="contractPDF"
                                        render={({ field: { value, ...fieldProps }, fieldState: { error } }) => {
                                            return (
                                                <FilePond
                                                    files={file}
                                                    onupdatefiles={setFiles}
                                                    // @ts-ignore
                                                    allowFileSizeValidation={true}
                                                    maxFileSize={10000000}
                                                    allowFileTypeValidation={true}
                                                    acceptedFileTypes={['application/pdf','image/*','application/vnd.ms-excel','application/vnd.ms-powerpoint','application/msword','application/vnd.openxmlformats-officedocument.wordprocessingml.document','application/vnd.openxmlformats-officedocument.spreadsheetml.sheet']}
                                                    labelMaxFileSizeExceeded={'File is too large'}
                                                    fileValidateTypeLabelExpectedTypes={""}
                                                    name={'contractPDF'}
                                                    labelIdle={`${t('VacantRightbar.pdftitle1')}<span class="filepond--label-action"> ${t('VacantRightbar.pdftitle2')}</span>`}
                                                    server={{
                                                        url: `${BASEURLDEV}${API_ENDPOINT.UploadDocument}`,
                                                        process: {
                                                            headers: {
                                                                Authorization: `Bearer ${token}`
                                                            },
                                                            ondata: (formData) => {
                                                                formData.append('patientName', props.patientname);
                                                                formData.append('funderName', props.condata);
                                                                return formData;
                                                            },
                                                            //@ts-ignore
                                                            onload: (res: any) => {
                                                                const fileres = JSON.parse(res);
                                                                let filedata = fileres.data;
                                                                setFileres(fileres.data.filePath);
                                                                setValue('funderContract', filedata.fileName, { shouldValidate: true });
                                                                props.updateFData({ file, filedata });
                                                            }
                                                        },
                                                        remove: (source, load, error) => {
                                                            error('oh my goodness');
                                                            load();
                                                        }
                                                    }}
                                                />
                                            );
                                        }}
                                    />
                                    <p className="small mt-2">
                                        <strong>{t('VacantRightbar.note')}</strong> {t('VacantRightbar.note2')}
                                    </p>
                                </>
                            )}
                        </div>
                    </div> */}

                    <div className="mt-3 text-center">
                        <button disabled={!isValid} onClick={props.addFunder} className="btn btn-sm btn-primary flex-1 me-2" id="save-funder1">
                            Add Funder
                        </button>
                    </div>
                    <Tooltip id="my-tooltip-back" className="my-tooltip tooltip-custom-width" />
                    <RenderModalContainer>
                            {showNoteModal && <NoteContainer  onClose={toggle} title={funderName != null && funderName != '' ? funderName+"'s Notes" : t('VacantRightbar.funder_note')} setFunderNote={funderNote} notebody={updateNote}  isReadonly={false} isEditing={isEditing}/> }
                    </RenderModalContainer>
                    </div>
                </>
            )}
        </>
    );
};

export default ContractView;

import React from 'react';
import { Box, CircularProgress, Typography } from '@mui/material';

const CircularProgressBarView = (props: any) => {
    return (
        <div className="position-relative ontop progress-galleryimage text-center" >
            <Box sx={{ position: 'relative', display: 'inline-flex' }}>
                <CircularProgress variant="determinate" value={props?.progressValue} sx={{color:"#070044"}} />
                <Box
                    sx={{
                        top: 0,
                        left: 0,
                        bottom: 0,
                        right: 0,
                        position: 'absolute',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <Typography variant="caption" component="div" color="textSecondary">
                        {`${Math.round(props?.progressValue)}%`}
                    </Typography>
                </Box>
            </Box>
        </div>
    );
};

export default CircularProgressBarView;

import React from 'react';
import AccreditationView from './AccreditationView';

const AccreditationContainer = (props:any) => {
    return (
        <div>
            <AccreditationView serviceAccreditation={props?.serviceAccreditation} cqcRating={props?.cqcRating} cqcLocationId={props?.cqcLocationId} cqcLatestInspection={props?.cqcLatestInspection} cqcReportPublished={props?.cqcReportPublished} cqcLatestReview={props?.cqcLatestReview}/>
        </div>
    );
};

export default AccreditationContainer;
import React, { ChangeEvent, useEffect, useImperativeHandle, useRef, useState } from 'react';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import up_counter from '../../../assets/images/up-counter.svg';
import down_counter from '../../../assets/images/down-counter.svg';
//@ts-ignore
import AOS from 'aos';
import 'aos/dist/aos.css';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { wardNumbers } from '../../../utils/constant';
import { useDispatch, useSelector } from 'react-redux';
import { setOnboardingWardFacility } from '../../../store/onboarding/onboardingWardFacilityReducer';
import { getOnboardingWardsorRoomCount, submitWardCountorRoomCount } from '../../../service/onboarding-ward-facility-count-service';
import warning from "../../../assets/images/warning.png"
import swal from 'sweetalert';
import { useTranslation } from 'react-i18next';
import { RootState } from '../../../store';
import LoaderContainer from '../../../components/Loader/loaderContainer';
import { OnboardingProgressType } from '../../../utils/model';
import { setOnboardingProgress } from '../../../store/onboarding/onboardingProgressReducer';
import { setOnboardingPageValidity } from '../../../store/onboarding/onboardingPageValidityReducer';

const WardNumberView = React.forwardRef((props, ref) => {
    const [selectWard, setSelectWard] = useState<string>('');
    const [counter, setCounter] = useState<number>(25);
    const [wardCount, setWardCount] = useState<any>(wardNumbers);
    const [isLoading, setIsLoading] = useState(false)
    const [isValid, setIsvalid] = useState(false)
    const [wardNumber, setWardNumber] = useState(0)
    const [isValidCount, setIsValidCount] = useState(false)

    const { wardFacility } = useSelector((state: RootState) => state.onboardingWardFacility);
    const { stageDescription, stageHeading, stageNumber } = useSelector((state: RootState) => state.onboardingProgress);

    const { t } = useTranslation();

    const { serviceId, serviceName } = useSelector((state: RootState) => state.onboardingService);
    const sliderRef = useRef<Slider>(null);

    const dispatch = useDispatch();


    useEffect(() => {
        AOS.init();
    }, []);

    useEffect(()=>{
        dispatch(setOnboardingPageValidity(isValid))
    }, [isValid])

    useEffect((() => {
        const fetchWardFacilityCount = async () => {
            setIsLoading(true)
            const response = await getOnboardingWardsorRoomCount(serviceId)
            if (response.status) {
                setIsLoading(false)
                dispatch(setOnboardingWardFacility(response?.data?.isWardFacility))
                if (response?.data?.isWardFacility === null) {
                    setSelectWard("")
                } else if (response?.data?.isWardFacility === true) {
                    setSelectWard("ward")
                    const newArray = wardCount?.map((item: any) => ({
                        ...item,
                        active: item.ward === response?.data?.wardCount
                    }))
                    setWardCount(newArray)
                    setWardNumber(response?.data?.wardCount)
                } else if (response?.data?.isWardFacility === false && response?.data?.roomCount !== 0) {
                    setSelectWard("room")
                    setCounter(response?.data?.roomCount)
                    setIsValidCount(true)
                }
            } else {
                setIsLoading(false)
            }

        }
        fetchWardFacilityCount()
    }), [])

    useEffect(() => {
        const isValidWard = wardCount?.some((item: any) => item.active === true);
        const isValidRoom = selectWard === "room" && counter > 0;

        setIsvalid(isValidWard || isValidRoom);
    }, [wardCount, counter, selectWard]);

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            if (sliderRef.current) {
                if (selectWard === "ward") {
                    if (wardNumber < 10) {
                        sliderRef.current.slickGoTo(0);
                    } else if (wardNumber > 10) {
                        sliderRef.current.slickGoTo(wardCount.length - 1);
                    }
                }
            }
        }, 100);

        return () => clearTimeout(timeoutId);
    }, [wardNumber, wardCount.length, selectWard]);

    useEffect(() => {
        if (selectWard === '') {
            if (stageNumber === 2) {
                const obj: OnboardingProgressType = { stageDescription, stageNumber, stageHeading: 'Set Up Your Wards & Beds.' };
                dispatch(setOnboardingProgress(obj));
            }
        } else {
            if (stageNumber === 2) {
                const obj: OnboardingProgressType = { stageDescription, stageNumber, stageHeading: wardFacility === null ? 'Set Up Your Wards & Beds.' : wardFacility ? 'Set Up Your Wards & Beds.' : `Then Let's Add Beds.` };
                dispatch(setOnboardingProgress(obj));
            }
        }
    }, [selectWard]);

    const showWarning = (message: string, success?: boolean) => {
        swal({
            title: '',
            text: message,
            icon: success ? 'success' : warning,
            dangerMode: true
        });
    };


    const incrementCounter = () => {
        if (isValidCount) {
            showWarning("It is not possible to increase the bed count at this step. Please proceed to the next step.", false)
            return
        }
        if (counter < 200) {
            setCounter((prevState) => prevState + 1);
            setIsvalid(true)
        } else {
            showWarning("The maximum number of rooms allowed is 200", false)
        }
    };

    const decrementCounter = () => {
        if (isValidCount) {
            showWarning("It is not possible to decrease the bed count at this step. Please proceed to the next step", false)
            return
        }
        if (counter > 1) {
            setCounter((prevState) => prevState - 1);
        }
    };


    const handleChangeCount = (e: ChangeEvent<HTMLInputElement>) => {
        let value = Number(e.target.value);

        if (value <= 0) {
            value = 1;
        } else if (value > 200) {
            showWarning("The maximum number of rooms allowed is 200", false);
            value = counter;
        }

        setCounter(value);
        setIsvalid(value > 0);
    };


    const handleActivateWard = (ward: number) => {
        const updatedArr = wardCount.map((item: any) => ({
            ...item,
            active: item.ward === ward
        }));
        setIsvalid(true);
        setWardCount(updatedArr);
    };




    const handleWardFacility = (name: string) => {
        setSelectWard(name);
        if (name === 'ward') {
            const payload = true;
            setIsvalid(false)
            dispatch(setOnboardingWardFacility(payload));
        } else if (name === 'room') {
            const payload = false;
            dispatch(setOnboardingWardFacility(payload));
            setIsvalid(true)
        }
    };

    const sliderSettings = {
        dots: false,
        infinite: false,
        speed: 800,
        slidesToShow: 10,
        slidesToScroll: 10,
        prevArrow: <button className="slick-prev">Previous</button>,
        nextArrow: <button className="slick-next">Next</button>,
        draggable: false,

    };

    function handleSubmitWardorBedCount() {
        const submitWardorBedCount = async () => {
            const ward_count = wardCount?.find((item: any) => item.active === true)?.ward
            const payload = {
                Service_Id: serviceId,
                IsWardFacility: selectWard === "ward" ? true : false,
                ...(selectWard === "ward" && { WardCount: ward_count }),
                ...(selectWard === "room" && { RoomCount: counter })
            }
            setIsLoading(true)
            const response = await submitWardCountorRoomCount(payload)
            if (response?.status) {
                setIsLoading(false)
                // showWarning(t('OnboardingSetupWard.Submit_Success_Message')!, true)
            } else {
                setIsLoading(false)
                swal(response.message, {
                    icon: 'error',
                });
            }
        }
        submitWardorBedCount()
    }


    useImperativeHandle(ref, () => ({
        isValidated: isValid,
        backActionHandler: () => {
            handleSubmitWardorBedCount()
        },
        nextActionHandler: () => {
            handleSubmitWardorBedCount()
        },
        triggerValidation: async () => {
            if (!isValid) {
                showWarning(t('OnboardingSetupWard.Validation_Warning')!, false);
            }
            return isValid;
        }
    }));

    if (isLoading) {
        return <LoaderContainer />
    }

    return (
        <div className="pb-115">
            <div className="container-fluid p-0">
                <div className="getlocated-section">
                    <div className="profileInfo">
                        <div className="row">
                            <div className={`${selectWard === 'ward' ? 'col-md-5' : 'col-md-6'} profileInfoChild pe-4`}>
                                <div className="sec-frame">
                                    <div className="sec-frame-title">
                                        <p>1. Do you have any wards at your facility ?</p>
                                    </div>
                                    <div className="d-flex align-items-center justify-content-center mt-4 mb-4">
                                        <button className={`button-ward-facility ms-3 ${selectWard === 'ward' ? 'active' : ''}`} disabled={isValidCount} onClick={() => handleWardFacility('ward')}>
                                            Yes
                                        </button>
                                        <button className={`button-ward-facility ms-3 ${selectWard === 'room' ? 'active' : ''}`} disabled={wardNumber > 0} onClick={() => handleWardFacility('room')}>
                                            No
                                        </button>
                                    </div>
                                </div>
                            </div>

                            {selectWard === 'room' && (
                                <div className="col-md-6 profileInfoChild ps-4">
                                    <div className="sec-frame">
                                        <div className="sec-frame-title">
                                            <p>2. Please select the total number of beds</p>
                                        </div>
                                        <div className="counter-container mt-4 mb-4 d-flex flex-column align-items-center">
                                            <div className="button cursor-pointer" onClick={incrementCounter}>
                                                <img src={up_counter} className="img-fluid" alt="up-arrow" width={30} />
                                            </div>

                                            <input type="number" min={1} id="counter" className="counter input-field" disabled={isValidCount} value={counter} onChange={handleChangeCount} />

                                            {
                                                (counter > 1) && (
                                                    <div className="button cursor-pointer" onClick={decrementCounter}>
                                                        <img src={down_counter} className="img-fluid" alt="down-arrow" width={30} />
                                                    </div>
                                                )
                                            }

                                        </div>
                                    </div>
                                </div>
                            )}

                            {selectWard === 'ward' && (
                                <div className="col-md-7 profileInfoChild ps-4">
                                    <div className="sec-frame h-100">
                                        <div className="sec-frame-title">
                                            <p>2. Please select the number of wards.</p>
                                        </div>
                                        <div className="h-100">
                                            <Slider {...sliderSettings} className="wardnumber-slick" ref={sliderRef} >
                                                {wardCount?.map((item: any, index: number) => (
                                                    <ul className="list-unstyled d-flex justify-content-center ob-ward-slider-sec" key={index}>
                                                        <li onClick={() => handleActivateWard(item.ward)}>
                                                            <button className={`pagination-neumorphic ${item.active ? 'active' : ''}${(wardNumber > 0 && wardNumber !== item.ward) ? "disable-btn no-hover" : ""}`} disabled={wardNumber > 0 && wardNumber !== item.ward} >{item?.ward}</button>
                                                        </li>
                                                    </ul>
                                                ))}
                                            </Slider>
                                            <div></div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                        {
                            selectWard === 'ward' && (
                                <>
                                    <div className='setup-wards-rooms-grey mt-5' data-aos="fade-up" data-aos-duration="1500" data-aos-once="true">
                                        <ul className='mb-0 ps-3'>
                                            <li className='list-style'>
                                                <p className='normal mb-2'>This step is crucial to ensure accurate allocation of rooms and beds within your facility. By setting up wards, you will have a clearer overview of your facility's capacity and be better equipped to manage your resources effectively when you decide to activate any of Locate Care digital tools <span className='bolder'>(see plans).</span></p>
                                            </li>
                                            <li className='list-style'>
                                                <p className='normal mb-2'><span className='bolder'>Please select "YES" if you have wards to set up, </span>and then choose the number of wards you wish to configure. This allows us to tailor the setup process to your specific needs.</p>
                                            </li>
                                            <li className='list-style'>
                                                <p className='normal mb-2'>If you <span className='bolder'>don't have any wards, select "NO" </span>and you can proceed with the next steps of adding beds.</p>
                                            </li>
                                        </ul>
                                    </div>
                                    <p className="ps-4 mt-4" data-aos="fade-up" data-aos-duration="1500" data-aos-once="true"><span className='bolder'>Please Remember, </span>setting up wards & rooms properly helps in streamlining operations and improving the quality of care provided to patients. <br></br>If you need any assistance during this process, feel free to click the <span className='bolder'>"Need Assistance?" </span>button.</p>
                                </>
                            )
                        }


                    </div>
                </div>
            </div>
        </div>
    );
});
export default WardNumberView;




'use client';
import React, { useEffect, useImperativeHandle, useRef, useState } from 'react';
import { Tooltip } from 'react-tooltip';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
//@ts-ignore
import { animateScroll as scroll } from 'react-scroll';
//@ts-ignore
import AOS from 'aos';
import 'aos/dist/aos.css';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import warning from "../../../assets/images/warning.png"
import { useForm, Controller, useFieldArray } from 'react-hook-form';
import { addOnboardingWard, deleteOnboardingWard, getOnboardingWards, submitOnboardingWardDetails } from '../../../service/onboarding-name-ward-service';
import { useTranslation } from 'react-i18next';
import swal from 'sweetalert';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store';
import LoaderContainer from '../../../components/Loader/loaderContainer';
import { useLocation } from 'react-router-dom';
import { debounce } from 'lodash';
import { setOnboardingWardFacility } from '../../../store/onboarding/onboardingWardFacilityReducer';
import { setOnboardingPageValidity } from '../../../store/onboarding/onboardingPageValidityReducer';

interface Ward {
    wardName: string;
    bedPrefix: string;
    totalBeds: number;
    ward_Id?: number | null;
}

type WardFieldName = "wardName" | "bedPrefix" | "totalBeds";

const NameWardsView = React.forwardRef((props: {showBottomSection?: (keepPopupVisible?: boolean) => void}, ref) => {

    const [loading, setLoading] = useState(false)
    const [isValid, setIsvalid] = useState(false)

    const { t } = useTranslation();
    const dispatch = useDispatch();

    const sliderSettings = {
        dots: false,
        infinite: false,
        speed: 800,
        slidesToShow: 3,
        slidesToScroll: 1,
        prevArrow: <button className="slick-prev">Previous</button>,
        nextArrow: <button className="slick-next">Next</button>,
        draggable: true
    };

    const [openServicePanel, SetOpenServicePanel] = useState(false);
    const [scrollToSection, setScrollToSection] = useState(false);
    const [scrollToSection1, setScrollToSection1] = useState(false);
    const [wardList, setWardList] = useState<Ward[]>([]);
    const [refresh, setRefresh] = useState(999);
    const [reviewWardList, setReviewWardList] = useState<any>();
    const [term, setTerm] = useState('');
    const [termList, setTermList] = useState<string[]>([]);
    const [isFinalScreen, setIsFinalScreen] = useState(false);
    const [isWardList, setIsWardList] = useState(false)
    const [totalBedsArray, setTotalBedsArray] = useState([])
    const [showDeleteWardIcon, setShowDeleteWardIcon] = useState(true);

    const { serviceId, serviceName } = useSelector((state: RootState) => state.onboardingService);
    const location = useLocation();

    const {
        control,
        handleSubmit,
        formState: { errors },
        setValue,
        trigger,
        getValues
    } = useForm({
        defaultValues: {
            wardList: [] as Ward[]
        }
    });

    const { fields, append, remove } = useFieldArray({
        control,
        name: 'wardList'
    });

    const sliderRef = useRef<Slider>(null);

    const showWarning = (message: string, success?: boolean) => {
        swal({
            title: '',
            text: message,
            icon: success ? 'success' : warning,
            dangerMode: true
        });
    };

    useEffect(() => {
        AOS.init();
    }, []);

    useEffect(()=>{
        dispatch(setOnboardingPageValidity(isValid))
    }, [isValid])

    useEffect(() => {
        if (location.pathname.includes('preview') || location.pathname.includes('publish')) {
            setIsFinalScreen(true);
        } else {
            if(props.showBottomSection){
                setIsFinalScreen(true);
            } else {
                setIsFinalScreen(false);
            }
        }
    }, [location]);

    const handleServicePanel = (e: React.FormEvent) => {
        e.preventDefault();

        if (!isValid) {
            showWarning("Oops, no ward details have been entered!", false);
            SetOpenServicePanel(false);
        } else {
            SetOpenServicePanel(true);
            setScrollToSection(true);
            setReviewWardList([...wardList]);
        }
    };


    React.useEffect(() => {
        let timeout: any;
        if (scrollToSection) {
            timeout = setTimeout(() => {
                const sectionElement = document.getElementById('servicepanel');
                if (sectionElement) {
                    const viewportHeight = window.innerHeight;
                    const sectionBottomOffset = sectionElement.offsetTop + sectionElement.offsetHeight;

                    const screenWidth = window.innerWidth;
                    if (screenWidth <= 1300) {
                        const offset = sectionBottomOffset - viewportHeight + 340;
                        scroll.scrollTo(offset, {
                            smooth: true,
                            duration: 1000
                        });
                    }
                    if (screenWidth > 1300) {
                        const offset = sectionBottomOffset - viewportHeight + 420;
                        scroll.scrollTo(offset, {
                            smooth: true,
                            duration: 1000
                        });
                    }
                }
                setScrollToSection(false);
            }, 0);
        }

        return () => clearTimeout(timeout);
    }, [scrollToSection]);

    const HandleClosePanel = (e: any) => {
        e.preventDefault();
        SetOpenServicePanel(false);
        setScrollToSection1(true);
    };

    React.useEffect(() => {
        let timeout: any;
        if (scrollToSection1) {
            timeout = setTimeout(() => {
                const sectionElement1 = document.getElementById('service-section');
                if (sectionElement1) {
                    const offset1 = sectionElement1.offsetTop + 500;
                    scroll.scrollTo(offset1, {
                        smooth: true
                    });
                }
                setScrollToSection1(false);
            }, 0);
        }

        return () => clearTimeout(timeout);
    }, [scrollToSection1]);

    useEffect(() => {
        var dynamicDiv = document.getElementById('dynamicDiv5');
        dynamicDiv?.classList.remove('closed');
        var dynamicDivHeight = dynamicDiv?.clientHeight + 'px';
        document.documentElement.style.setProperty('--dynamic-div-height', dynamicDivHeight);
        dynamicDiv?.classList.add('closed');
    }, []);

    useEffect(() => {
        if (fields?.length > 0) {
            setWardList([...fields]);
        }

        if (fields?.length === 1) {
            setShowDeleteWardIcon(false);
        } else {
            setShowDeleteWardIcon(fields?.length !== 0);
        }
    }, [fields]);

    useEffect(() => {
        const allFilled = wardList.every(ward =>
            ward.wardName && ward.totalBeds
        );
        if (allFilled) {
            setIsvalid(true)
        } else {
            setIsvalid(false)
        }
    }, [wardList])




    useEffect(() => {
        const fetchOnboardingWardsDetails = async () => {
            setLoading(true)
            setTimeout(async () => {
                const response = await getOnboardingWards(serviceId)
                if (response.status) {
                    setLoading(false)
                    const wardCount = response?.data?.wardCount;
                    if (response?.data?.wardList?.length > 0) {
                        const wardListArr = response?.data?.wardList
                        const totalbedArr = wardListArr?.map((item: any) => item.totalBeds)
                        setValue('wardList', wardListArr);
                        setIsWardList(true)
                        setTotalBedsArray(totalbedArr)
                        // if (response?.data?.wardList?.length === wardCount) {
                        //     console.log("called")
                        //     const wardListArr = response?.data?.wardList
                        //     const totalbedArr = wardListArr?.map((item: any) => item.totalBeds)
                        //     setValue('wardList', wardListArr);
                        //     setIsWardList(true)
                        //     setTotalBedsArray(totalbedArr)
                        // } else {
                        //     console.log("called else")
                        //     const newArr = [...response?.data?.wardList]
                        //     const count = wardCount - Number(newArr.length)
                        //     for (let i = 0; i < count; i++) {
                        //         newArr.push({
                        //             wardName: '',
                        //             bedPrefix: '',
                        //             totalBeds: 1,
                        //             ward_Id: null
                        //         });
                        //     }
                        //     setValue('wardList', newArr);

                        // }
                    } else {
                        const wardArr: Ward[] = [];
                        for (let i = 0; i < wardCount; i++) {
                            wardArr.push({
                                wardName: '',
                                bedPrefix: '',
                                totalBeds: 1,
                                ward_Id: null
                            });
                        }
                        setValue('wardList', wardArr);

                    }
                }
                setLoading(false)

            }, 2000)

        }

        fetchOnboardingWardsDetails()
    }, [])


    const handleInputChange = (index: number, event: any, name: WardFieldName) => {
        let { value, type } = event.target;

        if(name === 'wardName' && value.length > 100){
            showWarning(t("OnboardingNameWard.Ward_Name_Length_Warning")!, false);
            return;
        }
        if(name === 'bedPrefix' && value.length > 100){
            showWarning(t("OnboardingNameWard.Bed_Prefix_Length_Warning")!, false);
            return;
        }

        if (name === "totalBeds") {
            if (!value || Number(value) == 0) {
                setIsvalid(false)
            }
            else {
                setIsvalid(true)
            }

            let newValue = Number(value);
            setValue(`wardList.${index}.totalBeds`, newValue);
            setWardList((prevWards) =>
                prevWards.map((ward, i) => (i === index ? { ...ward, totalBeds: newValue } : ward))
            );
            debounceCheckValue(index, newValue)
            return;
        }

        setValue(`wardList.${index}.${name}`, value);

        setWardList((prevWards) =>
            prevWards.map((ward, i) => (i === index ? { ...ward, [name]: value } : ward))
        );
    };

    const handleBlur = async (fieldName: any) => {
        await trigger(fieldName);
    };

    const getOrdinalSuffix = (index: number) => {
        const remainder = index % 10;
        const remainder100 = index % 100;

        if (remainder === 1 && remainder100 !== 11) return 'st';
        if (remainder === 2 && remainder100 !== 12) return 'nd';
        if (remainder === 3 && remainder100 !== 13) return 'rd';
        return 'th';
    };

    const handleCheckValue = (index: number, newValue: number) => {
        if (isWardList) {
            // const currentValue = getValues(`wardList.${index}.totalBeds`)
            const currentValue = newValue
            const previousValue = totalBedsArray[index]
            const ordinalIndex = index + 1;
            const suffix = getOrdinalSuffix(ordinalIndex);
            if (currentValue > 100) {
                showWarning("The maximum number of beds allowed is 100.");
                setValue(`wardList.${index}.totalBeds`, 100);
                setWardList((prevWards) =>
                    prevWards.map((ward, i) => (i === index ? { ...ward, totalBeds: 100 } : ward))
                );
            }
            if (currentValue < previousValue) {
                swal({
                    title: "",
                    text: `Are you sure you want delete last ${Number(previousValue) - Number(currentValue)} bed/beds at ${ordinalIndex}${suffix} ward ?`,
                    icon: warning,
                    buttons: ["No", "Yes"],
                    dangerMode: true
                }).then(async (confirm: boolean) => {
                    if (!confirm) {
                        setIsvalid(true)
                        setValue(`wardList.${index}.totalBeds`, previousValue);
                        setWardList((prevWards) =>
                            prevWards.map((ward, i) => (i === index ? { ...ward, totalBeds: previousValue } : ward))
                        );
                    }
                })
            }
        }
    }
    const debounceCheckValue = debounce(handleCheckValue, 250);





    const handleDeleteWard = async (field: any, index: number) => {
        swal({
            title: '',
            text: t(`Do you really want to delete ward number ${index + 1}?`)!,
            icon: warning,
            buttons: ['No', 'Yes'],
            dangerMode: true
        }).then(async (confirm: boolean) => {
            if (confirm) {
                let payload
                if (field?.ward_Id === null) {
                    payload = {
                        Service_Id: serviceId,
                        ward_Id: null
                    };
                } else if (field?.ward_Id !== null) {
                    payload = {
                        Service_Id: serviceId,
                        ward_Id: field?.ward_Id
                    };
                }
                if (fields?.length > 1) {
                    setLoading(true)
                    const response = await deleteOnboardingWard(payload)
                    if (response.status) {
                        setLoading(false)
                        remove(index)
                        setRefresh((prevStat) => prevStat + 1);
                        swal({
                            title: '',
                            text: t('OnboardingNameWard.Delete_Ward_Success_Message')!,
                            icon: 'success',
                            dangerMode: true
                        }).then(() => {
                            if (props.showBottomSection) {
                                props.showBottomSection(true);
                            }
                        })
                    }
                    setLoading(false)
                }

            }
        })
    };

    const handleAddWard = async () => {

        if (fields.length === 20) {
            swal({
                title: '',
                text: "The max ward count will be 20 only",
                icon: warning,
                // buttons: ['No', 'Yes'],
                dangerMode: true
            })
            return
        }

        swal({
            title: '',
            text: t('OnboardingNameWard.Add_Ward_Warning')!,
            icon: warning,
            buttons: ['No', 'Yes'],
            dangerMode: true
        }).then(async (confirm: boolean) => {
            if (confirm) {
                const payload = {
                    Service_Id: serviceId
                }
                setLoading(true)
                const response = await addOnboardingWard(payload)
                if (response?.status) {
                    setLoading(false)
                    append({ wardName: '', bedPrefix: '', totalBeds: 1, ward_Id: null });
                    setRefresh((prevStat) => prevStat + 1);
                    setTimeout(() => {
                        sliderRef.current?.slickGoTo(wardList.length);
                    }, 100);
                }
                setLoading(false)
            }
        })


    };


    const handleKeyEnter = (e: any) => {
        if (e.key === 'Enter') {
            if (termList.length < 10) {
                setTermList([...termList, term]);
                setTerm('');
            } else {
                setTerm('');
            }
        }
    };

    const deleteTermListValue = (name: string) => {
        const newArr = [...termList];
        const updatedArr = newArr.filter((value) => value !== name);
        setTermList(updatedArr);
    };

    const setValueToTerm = (e: any, value: string) => {
        e.stopPropagation();
        setTerm(value);
    };

    const handleSubmitData = (isPreview?: boolean) => {
        submitWardsDetails(isPreview)

    }

    const submitWardsDetails = async (isPreview?: boolean) => {
        setIsvalid(true)
        setLoading(true)
        const payload = {
            Service_Id: serviceId,
            WardList: getValues("wardList")
        }
        const response = await submitOnboardingWardDetails(payload)
        if (response.status) {
            if(getValues("wardList").length !== 0){
                if(getValues("wardList").length > 1){
                    dispatch(setOnboardingWardFacility(true))
                }
            }
            setTimeout(() => {
                setLoading(false)
                if (isPreview) {
                    swal({
                        title: '',
                        text: "Wards and associated beds added successfully.",
                        icon: 'success',
                        dangerMode: true
                    }).then(() => {
                        if (props.showBottomSection) {
                            props.showBottomSection();
                        }
                    })
                }
            }, 250);
        } else {
            setLoading(false)
            swal(response.message, {
                icon: 'error',
            });
        }
        setLoading(false)
    }

    useImperativeHandle(ref, () => ({
        isValidated: isValid,
        backActionHandler: (isPreview?: boolean) => {
            handleSubmitData(isPreview)
        },
        nextActionHandler: (isPreview?: boolean) => {
            handleSubmitData(isPreview)
        },
        triggerValidation: async () => {
            if (!isValid) {
                showWarning(t('OnboardingNameWard.Validation_Warning')!, false)
            }
            return isValid
        }
    }));

    if (loading) {
        return <LoaderContainer />
    }

    return (
        <div>
            <div className="pb-4">
                <div className="container-fluid p-0">
                    <div className="getlocated-section">
                        <div className="sec-frame position-relative">
                            <div className="sec-frame-title">
                                <p>Provide Ward Details</p>
                            </div>
                            <div className="addRoomSlider">
                                {
                                    fields?.length < 4 ? (<>
                                        <div className='row'>
                                            {fields.map((field: any, index) => (
                                                <div className="addRoom-item col-lg-4 " key={field.id}>
                                                    <div className="classic-card room-ward no-hover">
                                                        <div>
                                                            <div className='margin-top-15'>
                                                                <div className="neumorphic-fieldset mb-3">
                                                                    <label htmlFor={`wardList.${index}.wardName`}>
                                                                        <span className="label-style">Ward Name*</span>
                                                                    </label>
                                                                    <Controller
                                                                        name={`wardList.${index}.wardName`}
                                                                        control={control}
                                                                        rules={{ required: 'Ward Name is required' }}
                                                                        render={({ field }) => (
                                                                            <input
                                                                                {...field}
                                                                                type="text"
                                                                                id={`wardList.${index}.wardName`}
                                                                                className={`form-control ${errors?.wardList?.[index]?.wardName ? 'is-invalid' : ''}`}
                                                                                onBlur={() => handleBlur(`wardList.${index}.wardName`)}
                                                                                onChange={(event) => {
                                                                                    handleInputChange(index, event, 'wardName');
                                                                                    field.onChange(event);
                                                                                }}
                                                                            />
                                                                        )}
                                                                    />
                                                                </div>
                                                                <div className="neumorphic-fieldset mb-3">
                                                                    <label htmlFor={`wardList.${index}.bedPrefix`}>
                                                                        <span className="label-style">Bed Prefix</span>
                                                                    </label>
                                                                    <Controller
                                                                        name={`wardList.${index}.bedPrefix`}
                                                                        control={control}
                                                                        render={({ field }) => (
                                                                            <input
                                                                                {...field}
                                                                                type="text"
                                                                                id={`wardList.${index}.bedPrefix`}
                                                                                className="form-control"
                                                                                onChange={(event) => {
                                                                                    handleInputChange(index, event, 'bedPrefix');
                                                                                    field.onChange(event);
                                                                                }}
                                                                            />
                                                                        )}
                                                                    />
                                                                </div>
                                                                <div className="neumorphic-fieldset mb-3">
                                                                    <label htmlFor={`wardList.${index}.totalBeds`}>
                                                                        <span className="label-style">Total Beds*</span>
                                                                    </label>
                                                                    <Controller
                                                                        name={`wardList.${index}.totalBeds`}
                                                                        control={control}
                                                                        rules={{ required: 'Total Beds is required' }}
                                                                        render={({ field }) => (
                                                                            <input
                                                                                {...field}
                                                                                type="number"
                                                                                min="1"
                                                                                max="100"
                                                                                id={`wardList.${index}.totalBeds`}
                                                                                onBlur={() => handleBlur(`wardList.${index}.totalBeds`)}
                                                                                className={`form-control ${errors?.wardList?.[index]?.totalBeds ? 'is-invalid' : ''}`}
                                                                                onChange={(event) => {
                                                                                    let value = event.target.value
                                                                                    handleInputChange(index, { ...event, target: { ...event.target, value } }, 'totalBeds');
                                                                                    field.onChange(value);
                                                                                }}
                                                                                onBlurCapture={(e:any) => handleCheckValue(index, e.target.value)}
                                                                            />
                                                                        )}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="card-number-part">
                                                                <div className="neumorphicOrderList orderListBig">{index + 1}</div>
                                                            </div>
                                                            <div className={`delete-ward ${showDeleteWardIcon ? '' : 'hidden'}`}>
                                                                <button
                                                                    className="classic-btn delete-button"
                                                                    data-tooltip-id="my-tooltip1"
                                                                    data-tooltip-content="Delete Ward"
                                                                    data-tooltip-place="top"
                                                                    onClick={() => handleDeleteWard(field, index)}
                                                                    disabled={fields?.length === 1}
                                                                >
                                                                    <span>
                                                                        <i className="fa-solid fa-xmark"></i>
                                                                    </span>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </>) :
                                        <Slider {...sliderSettings} key={refresh} ref={sliderRef}>
                                            {fields.map((field: any, index) => (
                                                <div className="addRoom-item" key={field.id}>
                                                    <div className="classic-card room-ward no-hover">
                                                        <div>
                                                            <div className='margin-top-15'>
                                                                <div className="neumorphic-fieldset mb-3">
                                                                    <label htmlFor={`wardList.${index}.wardName`}>
                                                                        <span className="label-style">Ward Name*</span>
                                                                    </label>
                                                                    <Controller
                                                                        name={`wardList.${index}.wardName`}
                                                                        control={control}
                                                                        rules={{ required: 'Ward Name is required' }}
                                                                        render={({ field }) => (
                                                                            <input
                                                                                {...field}
                                                                                type="text"
                                                                                id={`wardList.${index}.wardName`}
                                                                                className={`form-control ${errors?.wardList?.[index]?.wardName ? 'is-invalid' : ''}`}
                                                                                onBlur={() => handleBlur(`wardList.${index}.wardName`)}
                                                                                onChange={(event) => {
                                                                                    handleInputChange(index, event, 'wardName');
                                                                                    field.onChange(event);
                                                                                }}
                                                                            />
                                                                        )}
                                                                    />
                                                                </div>
                                                                <div className="neumorphic-fieldset mb-3">
                                                                    <label htmlFor={`wardList.${index}.bedPrefix`}>
                                                                        <span className="label-style">Bed Prefix</span>
                                                                    </label>
                                                                    <Controller
                                                                        name={`wardList.${index}.bedPrefix`}
                                                                        control={control}
                                                                        render={({ field }) => (
                                                                            <input
                                                                                {...field}
                                                                                type="text"
                                                                                id={`wardList.${index}.bedPrefix`}
                                                                                className="form-control"
                                                                                onChange={(event) => {
                                                                                    handleInputChange(index, event, 'bedPrefix');
                                                                                    field.onChange(event);
                                                                                }}
                                                                            />
                                                                        )}
                                                                    />
                                                                </div>
                                                                <div className="neumorphic-fieldset mb-3">
                                                                    <label htmlFor={`wardList.${index}.totalBeds`}>
                                                                        <span className="label-style">Total Beds*</span>
                                                                    </label>
                                                                    <Controller
                                                                        name={`wardList.${index}.totalBeds`}
                                                                        control={control}
                                                                        rules={{ required: 'Total Beds is required' }}
                                                                        render={({ field }) => (
                                                                            <input
                                                                                {...field}
                                                                                type="number"
                                                                                min="1"
                                                                                max="100"
                                                                                id={`wardList.${index}.totalBeds`}
                                                                                onBlur={() => handleBlur(`wardList.${index}.totalBeds`)}
                                                                                className={`form-control ${errors?.wardList?.[index]?.totalBeds ? 'is-invalid' : ''}`}
                                                                                onChange={(event) => {
                                                                                    let value = event.target.value
                                                                                    handleInputChange(index, { ...event, target: { ...event.target, value } }, 'totalBeds');
                                                                                    field.onChange(value);
                                                                                }}
                                                                                onBlurCapture={(e: any) => handleCheckValue(index, e.target.value)}
                                                                            />
                                                                        )}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="card-number-part">
                                                                <div className="neumorphicOrderList orderListBig">{index + 1}</div>
                                                            </div>
                                                            <div className={`delete-ward ${showDeleteWardIcon ? '' : 'hidden'}`}>
                                                                <button
                                                                    className="classic-btn delete-button"
                                                                    data-tooltip-id="my-tooltip1"
                                                                    data-tooltip-content="Delete Ward"
                                                                    data-tooltip-place="top"
                                                                    onClick={() => handleDeleteWard(field, index)}
                                                                    disabled={fields?.length === 1}
                                                                >
                                                                    <span>
                                                                        <i className="fa-solid fa-xmark"></i>
                                                                    </span>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </Slider>
                                }

                            </div>
                            <div className="addWardButton nameaddWardButton" data-tooltip-id="my-tooltip" data-tooltip-content="Add Ward" data-tooltip-place="right" onClick={handleAddWard}>
                                <i className="fa-solid fa-plus classic-btn blue-bg " aria-hidden="true"></i>
                            </div>
                            
                        </div>
                        {!isFinalScreen && <div className="setup-wards-rooms-grey mt-5 w-75 mx-auto" data-aos="fade-up" data-aos-duration="1500" data-aos-once="true">
                            <p className="mb-3">For each ward, you can enter the following information:</p>
                            <ul className="mb-0 ps-3">
                                <li>
                                    <p className="normal mb-2">
                                        <span className="bolder">Ward Name: </span>Choose a distinctive name for each ward.
                                    </p>
                                </li>
                                <li>
                                    <p className="normal mb-2">
                                        <span className="bolder">Bed Prefix: </span>Provide a prefix to help identify rooms within the ward. <span className="bolder">Sample</span>
                                    </p>
                                </li>
                                <li>
                                    <p className="normal mb-2">
                                        <span className="bolder">Total Beds: </span>Specify how many beds are in each ward.
                                    </p>
                                </li>
                            </ul>
                        </div>}
                        {!isFinalScreen && <p className="mt-3 text-center" data-aos="fade-up" data-aos-duration="1500" data-aos-once="true">
                            If you <span className="bolder">need any help,</span> click the <span className="bolder">"Need Assistance?"</span> button.
                        </p>}
                    </div>
                </div>
                {!isFinalScreen && fields?.length > 6 && (
                    <div className="amenities-section-btn" data-aos="fade-up" data-aos-duration="1500" data-aos-once="true">
                        <a className={`cursor-pointer services-find-btn ${openServicePanel ? 'faded-btn' : ''}`} onClick={handleServicePanel}>
                            Review <span className="arrow"></span>
                        </a>
                    </div>
                )}
                <Tooltip id="my-tooltip" className="my-tooltip" />
            </div>
            {!isFinalScreen && <div className="panel pb-115 review-margin" id="servicepanel">
                <div className={`service-detail-panel position-relative px-2 ${openServicePanel ? 'opened' : 'closed'}`}>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-lg-12 position-relative">
                                <a href="#servicebtn" className="panelclosebtn" onClick={HandleClosePanel} title="close">
                                    <i className="fa-solid fa-xmark"></i>
                                </a>
                                <div className="search">
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Search Room Wards"
                                        value={term}
                                        onChange={(e) => setTerm(e.target.value)}
                                        onKeyPress={(e) => handleKeyEnter(e)}
                                    />
                                    <a title="search" className="searchicon">
                                        <i className="fa-solid fa-magnifying-glass"></i>
                                    </a>
                                </div>
                                <div className="search-tags">
                                    {termList.map((value, index) => (
                                        <a className="service-tags" key={value + index} onClick={(e) => setValueToTerm(e, value)}>
                                            {value}{' '}
                                            <i
                                                className="fa-solid fa-xmark ms-2"
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    deleteTermListValue(value);
                                                }}
                                            ></i>
                                        </a>
                                    ))}
                                </div>
                                <ul className="list-unstyled  service-detail-panel-main-list justify-content-start">
                                    {wardList
                                        ?.filter((item: any) => {
                                            if (term === '') {
                                                return item;
                                            } else {
                                                const termLowerCase = term?.toLowerCase();
                                                const totalBedsMatch = !isNaN(Number(term)) && item.totalBeds === Number(term);

                                                return item?.wardName?.toLowerCase().includes(termLowerCase) || item?.bedPrefix?.toLowerCase().includes(termLowerCase) || totalBedsMatch;
                                            }
                                        })
                                        .map((item: any, index) => (
                                            <li key={item?.bedPrefix + index}>
                                                <div className="reviewroombox">
                                                    <h3>{item.wardName}</h3>
                                                    <p>Bed Prefix : {item?.bedPrefix ? item?.bedPrefix : ''}</p>
                                                    <p>Total Beds : {item?.totalBeds ? item?.totalBeds : ''}</p>
                                                </div>
                                            </li>
                                        ))}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>}
            <Tooltip id="my-tooltip1" className="my-tooltip" />
        </div>
    );
});

export default NameWardsView;

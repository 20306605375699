import React from 'react';
import OnboardingView from './OnboardingView';

const OnboardingContainer = () => {
    return (
        <div>
                <OnboardingView/>
        </div>
    );
};

export default OnboardingContainer;
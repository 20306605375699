import React, { useState } from 'react';
import ProgressFooterView from './ProgressFooterView';

export interface ProgressFooterPropsType {
    // isValidated: boolean
    // backActionHandler: () => void
    // nextActionHandler: () => void
    // getStageStatus: () => void
    stageRef: React.MutableRefObject<any>
}

const ProgressFooterContainer = (props : ProgressFooterPropsType) => {
    return (
        <div>
            <ProgressFooterView {...props}/>
        </div>
    );
};

export default ProgressFooterContainer;
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import swal from 'sweetalert';
import loader from '../../../assets/logos/loader.svg';
import ClientPortal from '../../../components/ClientPortal';
//@ts-ignore
import AOS from 'aos';
import 'aos/dist/aos.css';
import airobot from '../../../assets/images/airobot.png';

const OnboardingGenerateAnimationLoader = (props: any) => {
    const { showModal,previewMessage } = props;
    const navigate = useNavigate();
    const [message, setMessage] = useState('');
    useEffect(() => {
        AOS.init();
    }, []);

    useEffect(() => {
        if (showModal) {
            document.body.classList.add('modal-open');
        } else {
            document.body.classList.remove('modal-open');
        }
        return () => {
            document.body.classList.remove('modal-open');
        };
    }, [showModal]);

    useEffect(() => {
        setMessage(previewMessage);
    }, []);

    return (
        <ClientPortal selector="myportal" show={showModal}>
            <div className="overlay"></div>
            <div className="modal preview-modal nhs-body loader-modal" style={{ width: 450 }}>
                <div className="d-flex justify-content-center align-items-center" style={{ background:'#fff' , padding: 20, borderRadius: 10, boxShadow: '0 4px 8px rgba(0, 0, 0, 0.3)' }}>
                    <div className="d-flex flex-column justify-content-center align-items-center" style={{ paddingTop: 30, paddingBottom: 30 }}>
                    <div className="robot-container d-flex align-item-center justify-content-center">
                        <div className="robot-animation mt-2">
                            <img src={airobot} className="img-fluid" alt="airboat" width={100} />
                            <div className="mouth"></div>
                        </div>
                    </div>
                        <div
                            data-aos-duration="2000"
                            data-aos-once="true"
                            key={previewMessage}
                            className='text-loading'
                        >
                            {previewMessage}
                        </div>
                    </div>
                </div>

                {/* <div className="d-flex justify-content-center align-items-center" style={{ padding: 20, borderRadius: 10, boxShadow: '0 4px 8px rgba(0, 0, 0, 0.3)' }}>
                    <div className="d-flex flex-column justify-content-center align-items-center" style={{ paddingTop: 30, paddingBottom: 30 }}>
                    <div className="robot-container d-flex align-item-center justify-content-center">
                        <div id="bot" className="speaking neutral">
                            <div id="head">
                            <div id="left-ear">
                                <div id="left-ear-inner"></div>
                            </div>
                            <div id="face">
                                <div id="eyes">
                                <div id="left-eye"></div>
                                <div id="right-eye"></div>
                                </div>
                                <div id="mouth"></div>
                            </div>
                            <div id="right-ear">
                                <div id="right-ear-inner"></div>
                            </div>
                            </div>
                        </div>
                    </div>
                        <div
                            data-aos-duration="2000"
                            data-aos-once="true"
                            key={previewMessage}
                            className='typewriter'
                        >
                            <h3>{previewMessage}</h3>
                        </div>
                    </div>
                </div> */}
            </div>
        </ClientPortal>
    );
};

export default OnboardingGenerateAnimationLoader;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState = {
    isForPublished: false
};

export const onboardingPublishedSlice = createSlice({
    name: 'onboardingIsForPublished',
    initialState,
    reducers: {
        setOnboardingIsPublished: (state, action: PayloadAction<boolean>) => {
            state.isForPublished = action.payload;
        }
    }
});

export const { setOnboardingIsPublished } = onboardingPublishedSlice.actions;

export default onboardingPublishedSlice.reducer;

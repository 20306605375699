// import LoadingButton from "@mui/lab/LoadingButton";
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import React, { forwardRef, useCallback, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../store";

const AutocompleteRenderOptionComponent = forwardRef(
  ({ option, index, showLoadingBelow, isLastItem, hasMore, ...props }: any, ref) => {
    return (
      <>
        <Box ref={ref} {...props}>
          {option.label}
        </Box>
        {isLastItem && showLoadingBelow && hasMore && (
          <>
            <p className="load-more-text ps-3">Load more ...</p>
          </>
        )}
           {isLastItem && !hasMore && (
          <>
          </>
        )}
      </>
    );
  }
);

export const SmartSearchDropdownPatientDetailView = ({
  options,
  loading,
  onFetchMore,
  hasMore,
  ...props
}: any) => {
  const observer = useRef();
  const [value, setValue] = useState<any>(null)
  const { Data } = useSelector((state: RootState) => state.navigation);
  const [qvalue, qsetValue] = useState<any>(undefined)

  const lastOptionElementRef = useCallback(async (node) => {
    //@ts-ignore
    if (observer.current) observer.current.disconnect();
    //@ts-ignore
    observer.current = new IntersectionObserver(async (entries) => {
      if (entries[0].isIntersecting && hasMore) {
          onFetchMore(qvalue);
      }
    });
    //@ts-ignore
    if (node) observer.current.observe(node);
  }, [qvalue,hasMore]);

   useEffect(()=>{
      qsetValue(props.squery)
   },[props.squery])
   useEffect(()=>{
    if(value === null){
      props.getqueryvalue(null);
    }
   },[value])

   useEffect(() => {
    if (props.valFromMap && Data !== '' && Data.leadstatus === 2 && props.nhs) {
      setValue(props.valFromMap);
    }
  }, [props.valFromMap, Data, props.nhs]);

  useEffect(() => {
    if(props.defaultValue !== undefined && options?.length > 0) {
        const updatedPatientTypes = props.patientTypes?.map(({ key, ...rest }:any) => ({
            id: key,  
            ...rest,  
          }));
          console.log("updatedPatientTypes",updatedPatientTypes)
      let selectedValue = updatedPatientTypes?.filter((x: any) => x.id == props.defaultValue)[0];
      props.onChangeSmartSearch(selectedValue)
      setValue(updatedPatientTypes?.filter((x: any) => x.id == props.defaultValue)[0]?.value)
    }
  }, [props.defaultValue,options])
  return (
    <Autocomplete
    id={props.id}
    key={props.id}
    disabled = {props.disabled === true ? true : false}
      //@ts-ignore
      options={options}
      filterOptions={(x) => x}
      loading={loading}
      onBlur={(event: any) =>{   
        let selectedValue = options?.filter((x: any) => x.value === event.target.value)[0];
        if(props?.onBlurSmartSearch != undefined)
         {
            if(selectedValue == undefined || selectedValue == null) {
              props?.onBlurSmartSearch('');
            } else {
              props?.onBlurSmartSearch(selectedValue);
            }
         }
      }}
      className={`${props.errors !== undefined ? 'is-invalid' : ''} ${props.disabled ? 'disabled-auto-complete-dropdown' : ''}`}
      onChange={(event: any, newValue: any, reason: any) => {
        // console.log("event: on change: ", newValue)
        if(reason === 'clear') {
          props.onChangeSmartSearch('')
          setValue(null)
        } else {
          props.onChangeSmartSearch(newValue)
          setValue(newValue?.value)
        }
      }}
      value={value}
      renderOption={(props, option, { index }) => {
        return (
          <AutocompleteRenderOptionComponent
            {...props}
            isLastItem={index === options.length - 1}
            showLoadingBelow={loading}
            option={option}
            hasMore={hasMore}
            ref={index === options.length - 1 ? lastOptionElementRef : null}
          />
        );
      }}
      {...props}
    />
  );
};

export default SmartSearchDropdownPatientDetailView;

import React from 'react';
import AccreditationsView from './AccreditationsView';

const AccreditationsContainer = React.forwardRef((props, ref) => {
    return (
        <div>
            <AccreditationsView {...props} ref={ref} />
        </div>
    );
});

export default AccreditationsContainer;

import ClientPortal from "../../../../../../components/ClientPortal";
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { SendServiceCoordinatorEmails } from "../../../../../../service/service-details-service";
import sweetalert from 'sweetalert';
import WYSIWYGEditor from "../../../../../../components/WYSIWYGEditor/WYSIWYGEditor";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../../store";

const initialValues = {
    name: '',
    subject: '',
    from: '',
    body: ''
};

export const serviceCoordinatorMail = Yup.object().shape({
    name: Yup.string().required('Please enter name*'),
    subject: Yup.string().required('Please enter subject*'),
    from: Yup.string()
        .trim()
        .matches(/^$|^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/, 'Email is invalid')
        .email('Email is Invalid*')
        .required(),
    body: Yup.string().required('Please enter body*')
});

const SendMessageToService = ({ handleModal, showPortal, coordinatorData, firstName, lastName, ServiveName, setShowPortal }: any) => {

    const [initialBody, setInitialBody] = useState<string>('');
    const { StatusLogin, DataLogin } = useSelector((state: RootState) => state.login);
    const { serviceId, serviceName } = useSelector((state: RootState) => state.onboardingService);

    const {
        register,
        formState: { errors, isValid },
        handleSubmit,
        reset,
        watch,
        setValue,
        control,
        trigger
    } = useForm<any>({
        defaultValues: initialValues,
        resolver: yupResolver(serviceCoordinatorMail)
    });

    const serviceProviderEmailSubmit = async (data: any) => {
        const payload = {
            serviceSlug: coordinatorData?.serviceSlug,
            subject: data.subject,
            fromEmail: {
                email: data.from,
                name: data.name
            },
            body: data.body + `<br><p><a href=mailto:${data.from}>Click here to reply</a> ${data.name}.</p>`
        };
        const response = await SendServiceCoordinatorEmails(payload);
        if (response.status === true) {
            setShowPortal(false);
            sweetalert(response.message, {
                icon: 'success'
            });
            reset(initialValues);
        } else {
            sweetAlert(response.message, {
                icon: 'error',
                dangerMode: true
            });
            reset(initialValues);
        }
    };

    useEffect(()=>{
        setValue('name',DataLogin?.userModel?.userName , { shouldValidate: true });
        setValue('from',DataLogin?.userModel?.email , { shouldValidate: true });
        setValue('subject',"");
        setValue('body', "");
    },[showPortal])
    return (

        <ClientPortal selector="myportal" show={showPortal}>
            <div className="overlay" onClick={handleModal}></div>
            <div className="modal">
                <div className="header mb-3">
                    <h4 className="mb-0">
                        Send a message to {firstName} from {coordinatorData?.serviceName}
                    </h4>
                    <a onClick={handleModal} className="removeButton">
                        <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 384 512">
                            <path
                                fill="#ffffff"
                                d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"
                            />
                        </svg>
                    </a>
                </div>
                <div className="body message-popup">
                <form onSubmit={handleSubmit(serviceProviderEmailSubmit)}>
                            <div className='row'>
                                <div className="col-md-6">
                                    <p className="mb-2 label">Name*</p>
                                    <input
                                        type="text"
                                        // placeholder="Enter your Name*"
                                        className={`${errors.name ? 'form-control white mb-3 error-field' : 'form-control white mb-3'}`}
                                        {...register("name", { required: "Name is required" })}
                                        onBlur={() => trigger("name")}
                                        name='name'
                                    />
                                    {/* {errors.name && <p className="error-message">{errors.name?.message as String}</p>} */}
                                </div>
                                <div className="col-md-6">
                                    <p className="mb-2 label">Email Address*</p>
                                    <input type="text"
                                        // placeholder="Enter Your Email Address*"
                                        className={`${errors.from ? 'form-control white mb-3 overview-input error-field' : 'form-control white mb-3 overview-input'}`}
                                        {...register("from", { required: "From email is required*" })}
                                        onBlur={() => trigger("from")}
                                        name="from">
                                    </input>
                                    {/* {errors.from && <p className="error-message">{errors.from?.message as String}</p>} */}
                                </div>

                            </div>

                            <p className="mb-2 label">Subject*</p>
                            <input
                                type="text"
                                // placeholder="Enter email subject*"
                                className={`${errors.subject ? 'form-control white mb-3 error-field' : 'form-control white mb-3'}`}
                                {...register("subject", { required: "Subject is required" })}
                                onBlur={() => trigger("subject")}
                                name='subject'
                            />
                            <p className="mb-2 label">Your Message*</p>
                           
                                <textarea
                                    name='body'
                                    className={`${errors.body ? 'form-control white mb-3 error-field' : 'form-control white mb-3'}`}
                                   // {...register("body", { required: "Body is required" })}
                                    onChange={(e) => setValue('body', e.target.value?.split('<p></p>')?.join('')?.trim(), { shouldValidate: true })} onBlur={() => trigger("body")}
                                />
                            {/* {errors.body && <p className="error-message">{errors.body?.message as String}</p>} */}
                            <div className="w-full text-center"><button disabled={!isValid} type='submit' className="btn primary-btn mt-1 px-5">Send</button></div>
                        </form>
                </div>
            </div>
        </ClientPortal>
    )
}

export default SendMessageToService

import React, { useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';
import Header from './Header';
import SideNav from './SideNav';
import RightSidebar from './RightSidebar';
import { useSelector } from 'react-redux';
import { RootState } from '../store';
import GenericErrorComponent from '../components/GenericErrorComponent';
import NHSHeaderContainer from './NHSHeader/NHSHeaderContainer';
import OnboardingHeaderContainer from './OnboardingHeader/OnboardingHeaderContainer';
import { Roles } from '../utils/constant';
import { ROUTES_DATA } from '../navigation/RoutesConstants';

export interface ILayoutComponentProps {}

const Layout: React.FunctionComponent = () => {
    const { ShowClass, ShowToggle } = useSelector((state: RootState) => state.navigation);
    const { StatusLogin, DataLogin } = useSelector((state: RootState) => state.login);
    const { StatusRoleSelected, DataRoleSelected } = useSelector((state: RootState) => state.roleSelected);
    const location = useLocation();
    return (
        <>
            <ErrorBoundary FallbackComponent={GenericErrorComponent}>
                {DataLogin?.userModel?.userRoleId === Roles.NHS && (
                    <>
                        <NHSHeaderContainer />
                        <Outlet />
                        {location?.pathname === ROUTES_DATA.NHSREFERRAL && <RightSidebar />}
                    </>
                )}

                {DataLogin?.userModel?.userRoleId === Roles.OnBoarding && (
                    <>
                        <div className="nhs-body">
                            <OnboardingHeaderContainer />
                            <Outlet />
                        </div>
                    </>
                )}

                {DataLogin?.userModel?.userRoleId === Roles.SiteUser && (
                    <>
                        <div className="nhs-body">
                            <OnboardingHeaderContainer />
                            <Outlet />
                        </div>
                    </>
                )}

                {DataLogin?.userModel?.userRoleId !== Roles.NHS && DataLogin?.userModel?.userRoleId !== Roles.OnBoarding && DataLogin?.userModel?.userRoleId !== Roles.SiteUser && !location.pathname.includes(ROUTES_DATA.SERVICEDETAIL) && (
                    <>
                        <Header />
                        <SideNav />
                        <div className={`${ShowClass && ShowToggle ? 'main show toggle' : ShowClass && !ShowToggle ? 'main show' : 'main'} `}>
                            <Outlet />
                        </div>
                        <RightSidebar />
                    </>
                )}
                {DataLogin?.userModel?.userRoleId === Roles.ReferralManager && location.pathname.includes(ROUTES_DATA.SERVICEDETAIL) && (
                    <>
                        <NHSHeaderContainer />
                        <Outlet />
                    </>
                )}
                {DataLogin?.userModel?.userRoleId === Roles.ServiceManager && location.pathname.includes(ROUTES_DATA.SERVICEDETAIL) && (
                    <>
                        <NHSHeaderContainer />
                        <Outlet />
                    </>
                )}
            </ErrorBoundary>
        </>
    );
};

export default Layout;

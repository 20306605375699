import React from 'react';
import NameWardsView from './NameWardsView';

const NameWardsContainer = React.forwardRef((props: {showBottomSection?: (keepPopupVisible?: boolean) => void}, ref) => {
    return (
        <div>
            <NameWardsView {...props} ref={ref} />
        </div>
    );
});

export default NameWardsContainer;

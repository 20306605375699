import React, { useEffect, useImperativeHandle, useState } from 'react';
import { Tooltip } from 'react-tooltip';
import { getOnboardingFacilityCategory, saveFacilityDetails, getOnboardingFacilityCategoryById } from '../../../service/onboarding-facilityCategory';
import infoIcon from '../../../assets/images/info-icon.svg';
import selectedIcon from '../../../assets/images/selected-icon.svg';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../store';
import warning from '../../../assets/images/warning.png';
import { useTranslation } from 'react-i18next';
import swal from 'sweetalert';
import { setOnboardingPageValidity } from '../../../store/onboarding/onboardingPageValidityReducer';

interface Facility {
    serviceTypeId: number;
    serviceTypeName: string;
    serviceTypeIconName: string | null;
    serviceTypeIconUrl: string | null;
    serviceTypeDescription: string;
}

interface Category {
    serviceCareLevelId: number;
    serviceCareLevelName: string;
    serviceTypes: Facility[];
}

const FacilitiesRegiCategoryView = React.forwardRef<any>((props, ref) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [facilities, setFacilities] = useState<Facility[]>([]);
    const [categories, setCategories] = useState<Category[]>([]);
    const [selectedCareType, setSelectedCareType] = useState<number | null>(null);
    const [selectedFacilities, setSelectedFacilities] = useState<{ [key: number]: number[] }>({});
    const { serviceId, serviceName } = useSelector((state: RootState) => state.onboardingService);
    const [isValid, setIsValid] = useState(false);

    useEffect(()=>{
        dispatch(setOnboardingPageValidity(isValid))
    }, [isValid])

    useEffect(() => {
        const fetchData = async () => {
            const categoryResponse = await getOnboardingFacilityCategory(serviceId);

            if (categoryResponse && categoryResponse?.data) {
                const fetchedCategories = categoryResponse?.data;

                // Null check for fetchedCategories
                if (fetchedCategories && fetchedCategories?.length > 0) {
                    setCategories(fetchedCategories);
                    setSelectedCareType(fetchedCategories[0]?.serviceCareLevelId);
                }
            }

            if (serviceId) {
                const facilitiesResponse = await getOnboardingFacilityCategoryById(serviceId);
                if (facilitiesResponse?.data) {
                    const serviceCares = facilitiesResponse?.data;
                    let fetchedFacilities = [];
                    if (Array.isArray(serviceCares)) {
                        fetchedFacilities = serviceCares?.flatMap((serviceCare: any) =>
                            serviceCare?.serviceTypes
                                ?.filter((serviceType: any) => serviceType?.isSelected)
                                .map((serviceType: any) => ({
                                    categoryId: serviceCare?.serviceCareLevelId,
                                    facilityId: serviceType?.serviceTypeId,
                                }))
                        );
                    }
                    const formattedSelectedFacilities = fetchedFacilities.reduce(
                        (acc: { [key: number]: number[] }, facility: { categoryId: number, facilityId: number, isSelected: boolean }) => {
                            const { categoryId, facilityId, isSelected } = facility;
                            acc[categoryId] = acc[categoryId] ? [...acc[categoryId], facilityId] : [facilityId];
                            return acc;
                        },
                        {} as { [key: number]: number[] }
                    );
                    setSelectedFacilities(formattedSelectedFacilities);
                }
            }
        };
        fetchData();
    }, [serviceId]);

    const handleCareTypeChange = (careTypeId: number | null) => {
        setSelectedCareType(careTypeId);
    };

    const handleFacilitySelection = (categoryId: number, facilityId: number) => {
        setSelectedFacilities((prevSelectedFacilities) => {
            const selectedForCategory = prevSelectedFacilities[categoryId] || [];

            const isSelected = selectedForCategory.includes(facilityId);
            const updatedSelection = isSelected
                ? selectedForCategory.filter(id => id !== facilityId)
                : [...selectedForCategory, facilityId];

            return {
                ...prevSelectedFacilities,
                [categoryId]: updatedSelection,
            };
        });
    };

    useEffect(() => {
        const totalSelections = Object.values(selectedFacilities).flat().length;
        const validity = totalSelections > 0;
        setIsValid(validity);
    }, [selectedFacilities]);

    const filteredFacilities = selectedCareType
        ? categories?.find(category => category?.serviceCareLevelId === selectedCareType)?.serviceTypes || []
        : [];

    const submitFacilitiesDataToServer = async () => {
        const payload = {
            ServiceCare: Object.entries(selectedFacilities).map(([serviceCareLevelId, serviceTypeIds]) => ({
                serviceCareLevelId: parseInt(serviceCareLevelId, 10),
                serviceTypeIds,
            })),
            Service_Id: serviceId,
        };

        const response = await saveFacilityDetails(payload);
        if (response.status===false)
        {
            showWarning(response?.message)
        }
    };
    const showWarning = (message: string, success?: boolean) => {
        swal({
            title: '',
            text: message,
            icon: success ? 'success' : warning,
            dangerMode: true
        });
    };

    useImperativeHandle(ref, () => ({
        isValidated: isValid,
        backActionHandler: () => {
        },
        nextActionHandler: () => {
            submitFacilitiesDataToServer();
        },
        triggerValidation: async () => {
            if (!isValid) {
                showWarning(t('OnboardingFacilities.Validation_Warning')!);
            }
            return isValid;
        }

    }));

    return (
        <div className="mt-5 pb-115">
            <div className="row m-0">
                <div className="col-xl-2 p-0">
                    <ul className="nav flex-column nav-pills facility-pills" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                        {categories.map((category) => (
                            <li key={category.serviceCareLevelId} className="nav-item">
                                <a
                                    className={`nav-link ${selectedCareType === category.serviceCareLevelId ? 'active' : ''}`}
                                    id={`v-pills-${category.serviceCareLevelId}-tab`}
                                    data-bs-toggle="pill"
                                    href={`#v-pills-${category.serviceCareLevelId}`}
                                    role="tab"
                                    aria-controls={`v-pills-${category.serviceCareLevelId}`}
                                    aria-selected={selectedCareType === category.serviceCareLevelId}
                                    onClick={() => handleCareTypeChange(category.serviceCareLevelId)}
                                >
                                    {category.serviceCareLevelName}
                                </a>
                            </li>
                        ))}
                    </ul>
                </div>
                <div className="col-xl-10 p-0">
                    <div className="tab-content facility-tab-content" id="v-pills-tabContent">
                        <div className="tab-pane fade show active" id="v-pills-adult" role="tabpanel" aria-labelledby="v-pills-adult-tab">
                            <div className="row justify-content-center">
                                <div className="">
                                    <div className="facility-cards-parent p-0 d-flex flex-wrap justify-content-start">
                                        {filteredFacilities.map((facility, index) => (
                                            <div key={index} className="facility-card classic-card selected-facility">
                                                <input
                                                    type="checkbox"
                                                    className="selection-box"
                                                    checked={selectedFacilities[selectedCareType || 0]?.includes(facility.serviceTypeId) || false}
                                                    onChange={() => handleFacilitySelection(selectedCareType || 0, facility.serviceTypeId)}
                                                />
                                                <div className="selectedButton">
                                                    <img src={selectedIcon} alt="selected icon" className="img-fluid" />
                                                </div>
                                                {facility.serviceTypeDescription ? (
                                                    <div className="infoIcon" data-tooltip-id="my-tooltip" data-tooltip-content={facility.serviceTypeDescription} data-tooltip-place="top">
                                                        <img src={infoIcon} alt="info icon" className="img-fluid" />
                                                    </div>
                                                ) : null}
                                                <img
                                                    src={facility.serviceTypeIconUrl || ''}
                                                    alt={facility.serviceTypeName}
                                                    className="img-fluid"
                                                />
                                                <p className="light-blue-text">{facility.serviceTypeName}</p>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <Tooltip id="my-tooltip" className="my-tooltip tooltip-custom-width1" />
        </div>
    );
});

export default FacilitiesRegiCategoryView;
import React, { ChangeEvent, useState, useEffect } from 'react';
import TimePicker from 'rc-time-picker';
import 'rc-time-picker/assets/index.css';
import moment from 'moment-timezone';
import { timeZones } from '../../../utils/constant';
import { WorkingHour, WorkingHoursProps } from './faceProfileTypes';
import { format } from "../../../utils/constant"

const WorkingHours: React.FC<WorkingHoursProps> = ({ workingHoursData, setWorkingHoursData, localTimeZone, setLocalTimeZone, setIsValid }) => {

    const [pickerOpen, setPickerOpen] = useState(false);
    const [pickerOpenIndex, setPickerOpenIndex] = useState<number | null>(null);
    const [openPicker, setOpenPicker] = useState<{ [key: string]: 'start' | 'end' | null }>({});

    useEffect(()=>{
        setIsValid(checkFormValidity(workingHoursData));
    }, [])

    const checkFormValidity = (data: WorkingHour[]) => {
        return data.every(item => {
            if (item.active) {
                return item.startTime && item.endTime;
            }
            return true;
        });
    };

    const handleDayActive = (e: ChangeEvent<HTMLInputElement>, week: string) => {
        const newArr = [...workingHoursData];
        const updatedArr = newArr.map((item: any) => {
            if (item.dayOfWeek === week) {
                if (e.target.checked) {
                    return {
                        ...item,
                        active: true,
                        error: (!item.startTime || !item.endTime) ? 'Both these fields are required' : '',
                    };
                } else {
                    return {
                        ...item,
                        active: false,
                        startTime: null,
                        endTime: null,
                        fromTime: null,
                        toTime: null,
                        error: '',
                    };
                }
            }
            return item;
        });
        
        setWorkingHoursData(updatedArr);
        setIsValid(checkFormValidity(updatedArr));
    };    
    

    const handleSetStartTime = (value: any, index: number) => {
        const newArr = [...workingHoursData];
        if (value) {
            const localTime = moment.tz(value, localTimeZone);
            const utcTime = localTime.utc();
            const newStartTime = value.format(format);
            const newUtcStartTime = value && utcTime.format(format);
            newArr[index].startTime = newStartTime;
            newArr[index].fromTime = newUtcStartTime;
    
            const endTime = newArr[index].endTime;
            if (!endTime) {
                newArr[index].error = 'End time is required';
            } else if (moment(newStartTime, format).isAfter(moment(endTime, format))) {
                newArr[index].error = 'Start time cannot be after end time';
                newArr[index].startTime = null;
                newArr[index].fromTime = null;
            } else {
                newArr[index].error = '';
            }
        } else {
            newArr[index].fromTime = null;
            newArr[index].startTime = null;
            newArr[index].error = 'Both these fields are required';
        }
    
        setWorkingHoursData(newArr);
        setIsValid(checkFormValidity(newArr));
        // setOpenPicker(prev => ({ ...prev, [index]: null }));
    };
    
    const handleSetEndTime = (value: any, index: number) => {
        const newArr = [...workingHoursData];
        if (value) {
            const localTime = moment.tz(value, localTimeZone);
            const newEndTime = value.format(format);
            const utcTime = localTime.utc();
            const newUtcEndTime = value && utcTime.format(format);
            newArr[index].endTime = newEndTime;
            newArr[index].toTime = newUtcEndTime;
    
            const startTime = newArr[index].startTime;
            if (!startTime) {
                newArr[index].error = 'Start time is required';
            } else if (moment(newEndTime, format).isBefore(moment(startTime, format))) {
                newArr[index].error = 'End time cannot be earlier than start time';
                newArr[index].endTime = null;
                newArr[index].toTime = null;
            } else {
                newArr[index].error = '';
            }
        } else {
            newArr[index].endTime = null;
            newArr[index].toTime = null;
            newArr[index].error = 'Both these fields are required';
        }
    
        setWorkingHoursData(newArr);
        setIsValid(checkFormValidity(newArr));
        // setOpenPicker(prev => ({ ...prev, [index]: null }));
    };    

    const handleOpenChange = (index: number, type: 'start' | 'end') => {
        setPickerOpen(true);
        setTimeout(() => {
            setOpenPicker(prev => ({ ...prev, [index]: type }));
        }, 250);
    };

    const addOkButton = () => {
        const timePickerPanel = document.querySelector('.rc-time-picker-panel-inner');
        if (timePickerPanel && !document.querySelector('.rc-time-picker-ok-btn')) {

            const okButton = document.createElement('button');
            okButton.innerText = 'OK';
            okButton.className = 'rc-time-picker-ok-btn';
            okButton.style.margin = '10px auto';
            okButton.style.marginTop = '100px';
            okButton.style.padding = '4px 12px';
            okButton.style.borderRadius = '5px';

            okButton.onclick = () => {
                setOpenPicker({});
            };

            timePickerPanel.appendChild(okButton);
        }
    }

    useEffect(() => {
        setTimeout(() => {
            addOkButton();
        }, 50);

        const handleDocumentClick = (event: any) => {
            const targetElement = event.target;
            const timePickerPanel = document.querySelector('.rc-time-picker-panel-inner');

            if (timePickerPanel && !timePickerPanel.contains(targetElement)) {
                const isAnyPickerOpen = Object.values(openPicker).some((value) => value !== null);
                if (isAnyPickerOpen) {
                    setOpenPicker({});
                    setPickerOpen(false);
                }
            }
        };

        document.addEventListener('click', handleDocumentClick);

        return () => {
            document.removeEventListener('click', handleDocumentClick);
        };
    }, [openPicker]);

    return (
        <div className="col-md-6 d-flex flex-column">
            <div className="sec-frame">
                <div className="sec-frame-title">
                    <p>2. Key Contact’s Working Hours</p>
                </div>
                <div style={{ width: '100%' }}>
                    <select style={{ width: '100%' }}
                        className='custom-select form-control mb-3'
                        value={localTimeZone}
                        onChange={(e: React.ChangeEvent<HTMLSelectElement>) => setLocalTimeZone(e.target.value)}>
                        {timeZones?.map((zone: any) => (
                            <option key={zone.label} value={zone.value}>
                                {zone.label}
                            </option>
                        ))}
                    </select>
                </div>

                {workingHoursData?.map((item: WorkingHour, index) => (
                    <div className="row mb-1" key={index}>
                        <div className="col-xl-6 pe-0 d-flex align-items-center">
                            <div className="d-flex align-items-center">
                                <div className="d-flex justify-content-start align-items-center">
                                    <div className="anim-switch">
                                        <label className="switchts">
                                            <input type="checkbox" onChange={(e) => handleDayActive(e, item.dayOfWeek)} checked={item?.active} />
                                            <div className="sliderts">
                                                <div className="circlets">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" className="checkmark" enable-background="new 0 0 512 512" viewBox="0 0 24 24">
                                                        <path
                                                            fill="currentColor"
                                                            d="M9.707 19.121a.997.997 0 01-1.414 0l-5.646-5.647a1.5 1.5 0 010-2.121l.707-.707a1.5 1.5 0 012.121 0L9 14.171l9.525-9.525a1.5 1.5 0 012.121 0l.707.707a1.5 1.5 0 010 2.121z"
                                                            data-original="#000000"
                                                        ></path>
                                                    </svg>
                                                </div>
                                            </div>
                                        </label>
                                    </div>
                                    <p className="custom-control-label ms-2">{item.active ? item.dayOfWeek : <del className="grey">{item.dayOfWeek}</del>}</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-6 pe-0">
                            <div className="d-flex daily-fields">
                                <div className="me-2">
                                    <TimePicker
                                        showSecond={false}
                                        minuteStep={15}
                                        onChange={(value) => handleSetStartTime(value, index)}
                                        format={format}
                                        value={item.startTime ? moment(item.startTime, format).tz(localTimeZone, true) : undefined}
                                        use12Hours
                                        inputReadOnly
                                        disabled={!item.active}
                                        onOpen={() => handleOpenChange(index, 'start')}
                                        open={openPicker[index] === 'start'}
                                    />
                                </div>
                                <div className="d-flex align-items-center justify-content-center">
                                    <h6 className="mb-0"> - </h6>
                                </div>
                                <div className="ms-2">
                                    <TimePicker
                                        showSecond={false}
                                        minuteStep={15}
                                        value={item.endTime ? moment(item.endTime, format).tz(localTimeZone, true) : undefined}
                                        onChange={(value) => handleSetEndTime(value, index)}
                                        format={format}
                                        use12Hours
                                        inputReadOnly
                                        disabled={!item.fromTime ? true : false}
                                        onOpen={() => handleOpenChange(index, 'end')}
                                        open={openPicker[index] === 'end'}
                                    />
                                </div>
                            </div>
                            {item.error && <p style={{ color: 'red', fontSize: '10px' }}>{item.error}</p>}
                        </div>
                    </div>
                ))}
                <div className="info-text-p mt-3 text-center">
                    <p className="hanzipen-font-bold">This helps win managing expectations for response times and availability.</p>
                </div>
            </div>
        </div>
    );
};

export default WorkingHours;

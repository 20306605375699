"use client";
import React, { useEffect, useRef, useState } from "react";
//@ts-ignore
import { Link } from 'react-scroll';
import { useTranslation } from 'react-i18next';

const OnboardingPreviewTabView = () => {
    const { t } = useTranslation();
    // Sticky Menu Area
    useEffect(() => {
        window.addEventListener('scroll', isSticky);
        return () => {
            window.removeEventListener('scroll', isSticky);
        };

    });

    /* Method that will fix header after a specific scrollable */
    // const isSticky = (e:any) => {
    //     const header = document.querySelector('.stickey-header-section');
    //     const scrollTop = window.scrollY
    //     scrollTop >= 800 ? header!.classList.add('is-sticky') : header!.classList.remove('is-sticky');
    // };
    const isSticky = () => {
        const header = document.querySelector('.stickey-header-section');
        const scrollTop = window.scrollY;
        const screenWidth = window.innerWidth; // Get the current screen width

        // Check if the scroll position is greater than or equal to 800 and the screen width is greater than or equal to a certain defaultValue
        if (scrollTop >= 750 && screenWidth >= 1700) {
            header?.classList.add('is-sticky'); // Use optional chaining to prevent errors if header is null
        }
        else if (scrollTop >= 600 && screenWidth <= 1699) {
            header?.classList.add('is-sticky'); // Use optional chaining to prevent errors if header is null
        } else {
            header?.classList.remove('is-sticky');
        }
    };
    return (
        <div>
            <div className='service-detail-padding stickey-header-section'>
                <div className='container-fluid p-0'>
                    <div className='row'>
                        <div className='col-lg-12'>
                            <ul className='list-unstyled d-flex tab-list header-section'>
                                <li><Link to="overview-section" spy={true} smooth={true} offset={-100} duration={100} activeClass="active" >{t("ServiceDetail.Overview")}</Link></li>
                                <li><Link to="amenities-section" spy={true} smooth={true} offset={-100} duration={100}>{t("ServiceDetail.Amenities")}</Link></li>
                                <li><Link to="service-section" spy={true} smooth={true} offset={-100} duration={100}>{t("ServiceDetail.Services")}</Link></li>
                                <li><Link to="location-section" spy={true} smooth={true} offset={-100} duration={100}>{t("ServiceDetail.Location")}</Link></li>
                                <li><Link to="question-answer-section" spy={true} smooth={true} offset={-100} duration={100}>{t("ServiceDetail.Question_answers")}</Link></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default OnboardingPreviewTabView;
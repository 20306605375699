
import React, { useEffect, useState } from "react";
import AccreditationContainer from "./Accreditation/AccreditationContainer";
import OverviewDetailsContainer from './OverviewDetails/OverviewDetailsContainer';
import { useParams } from "react-router-dom";
import { GetServiceOverviewDetails } from "../../../../../service/service-details-service";


const OverviewView = () => {
    const { serviceSlug } = useParams();
    const [result, SetResult] = useState<any>(null)

    useEffect(() => {
        GetAllServiceList();
    }, [])

    const GetAllServiceList = async () => {
        const result = await GetServiceOverviewDetails(serviceSlug)
        SetResult(result)
    }
    return (
        <div id="overview-section" >
            <div className="service-detail-padding overview-section section-padding">
                <div className="container-fluid p-0">
                    <div className="row">
                        <div className="col-lg-12">
                            <OverviewDetailsContainer serviceCoordinatorDetails={result?.data?.serviceCoordinatorDetails} serviceDescription={result?.data?.serviceDescription} />
                        </div>
                    </div>
                </div>
            </div>
            <div className={`service-detail-padding accreditation-section section-padding ${result?.data?.serviceAccreditation?.length > 1 ? '' : 'hide'}`}>
                <div className="container-fluid p-0">
                    <div className="row">
                        <div className="col-lg-12">
                            <AccreditationContainer serviceAccreditation={result?.data?.serviceAccreditation} cqcRating={result?.data?.cqcRating} cqcLocationId={result?.data?.cqcLocationId} cqcLatestInspection={result?.data?.cqcLatestInspection} cqcReportPublished={result?.data?.cqcReportPublished} cqcLatestReview={result?.data?.cqcLatestReview} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default OverviewView;
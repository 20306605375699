import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { getPatientDraftData, getPatientReservedData, getPlanData } from '../../../../../../service/dashboard-service';
import { removePatientDraftAction } from '../../../../../../store/dashboard/draftPatientData';
import { RootState } from '../../../../../../store';
import { rightNavigationPage } from '../../../../../../utils/constant';
import { setNavigationAction } from '../../../../../../store/dashboard/navigationReducer';
import swal from 'sweetalert';
import warning from '../../../../../../assets/images/warning.png';
import { setTempSplitFundingAction } from '../../../../../../store/splitFunding/tempSplitFundingReducer';

import eventEmitter from '../../../../../../utils/eventEmitter';

const ReservedTabView = (props: any) => {
    const { t } = useTranslation();
    const { StatusLogin, DataLogin } = useSelector((state: RootState) => state.login);
    const { DataServiceDetail } = useSelector((state: RootState) => state.serviceDetail);
    const dispatch = useDispatch();
    let plans: any;
    let funders: any[] = [];
    async function getpatientdata(pid: any) {
        const payload: any = { id: pid };
        await getPatientReservedData(payload);
    }

    async function getplandata(aid: any) {
        const response = await getPlanData(aid);
        if (response.status == true) {
            plans = [];
            for (let j = 0; j < response.data.length; j++) {
                if (response.data[j].planType !== 0) {
                    let obj: any = {};
                    funders = [];
                    obj.corefee = response?.data[j]?.weeklyCharge;
                    obj.admissiondate = response?.data[j]?.startDate?.toString();
                    obj.enddate = response?.data[j]?.endDate !== null ? response?.data[j]?.endDate?.toString() : '';
                    obj.socialfund = response?.data[j]?.socialServiceCharges || 0;
                    obj.healthfund = response?.data[j]?.healthcareChanges || 0;
                    obj.planId = response?.data[j]?.planId;
                    for (let i = 0; i < response?.data[j]?.planDetails?.length; i++) {
                        funders.push({
                            planDetailId: response?.data[j]?.planDetails[i].planDetailId,
                            funderid: response?.data[j]?.planDetails[i].funderId,
                            fundername: response?.data[j]?.planDetails[i].funderName,
                            fundertype:
                                response?.data[j]?.planDetails[i].funderType === 1
                                    ? 'ICB'
                                    : response?.data[j]?.planDetails[i].funderType === 2
                                    ? 'LA'
                                    : response?.data[j]?.planDetails[i].funderType === 3
                                    ? 'Charity'
                                    : 'Self',
                            proportion: response?.data[j]?.planDetails[i].proportion,
                            proportionvalue: response?.data[j]?.planDetails[i].proportionValue,
                            isapplicable: response?.data[j]?.planDetails[i].isApplicable === 1 ? false : true,
                            ispercentage: response?.data[j]?.planDetails[i].amountType === 1 ? false : true
                        });

                        obj.funderlist = funders;
                    }
                    plans = [...plans, obj];
                    dispatch(setTempSplitFundingAction(plans));
                }
            }
        }
    }
    const admitHandler = (pid: any, aid: any, bedId: any) => {
        swal({
            title: '',
            text:
                props.patientData?.bedName != undefined
                    ? `Are you sure you want to update the patient admission information in Bed ${props.patientData.bedName}?`
                    : `Are you sure you want to update the patient admission information?`,
            icon: warning,
            buttons: ['No', 'Yes'],
            dangerMode: true
        }).then(async (confirm: any) => {
            if (confirm) {
                dispatch(removePatientDraftAction());
                getpatientdata(pid).then((res: any) => {
                    const data = {
                        isDraftAdd: 'reserved',
                        bedId: bedId
                    };
                    let obj: any = {
                        ShowClass: true,
                        FormName: rightNavigationPage.Vacant,
                        Data: data,
                        ShowToggle: false
                    };
                    console.log('obj: ', obj);
                    dispatch(setNavigationAction(obj));
                });
                eventEmitter.emit('onAdmitReservePatient');
                getplandata(aid);
            }
        });
    };

    return (
        <div className="accordion accordion-flush" id="user-service-ac">
            <div className="accordion-item">
                <h2 className="accordion-header" id="admission-details">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#admission-detail" aria-expanded="false" aria-controls="admission-detail">
                        {t('ReservedRightbar.Reservation_Details')}
                    </button>
                </h2>
                <div id="admission-detail" className="accordion-collapse collapse show" aria-labelledby="admission-details" data-bs-parent="#user-service-ac">
                    <div className="accordion-body">
                        <div>
                            {props.patientData !== '' && props.patientData?.reservationDetails !== null &&
                                props.patientData.reservationDetails?.estStartDate !== null &&
                                props.patientData.reservationDetails?.estStartDate !== undefined &&
                                props.patientData.reservationDetails?.estStartDate !== '' && (
                                    <p>
                                        {t('ReservedRightbar.Est_Date_of_Admission')}:{' '}
                                        <span className="bold">{window.$utils.dateFormatDDMMYYYY(props.patientData.reservationDetails?.estStartDate)}</span>
                                    </p>
                                )}
                            {props.patientData !== '' &&
                                props.patientData.reservationDetails?.lor !== undefined &&
                                props.patientData.reservationDetails?.lor !== '' &&
                                props.patientData.reservationDetails?.lor !== null && (
                                    <p className="mt-1">
                                        {t('ReservedRightbar.Length_of_Reserve')}: <span className="bold">{`${props.patientData.reservationDetails?.lor} Days`}</span>
                                    </p>
                                )}
                            <div className="d-flex justify-content-between align-items-center mt-1">
                                {props.patientData !== '' &&
                                    props.patientData.reservationDetails?.estDischargeDate !== undefined &&
                                    props.patientData.reservationDetails?.estDischargeDate !== '' &&
                                    props.patientData.reservationDetails?.estDischargeDate !== null && (
                                        <p>
                                            {t('ReservedRightbar.Est_Discharge_Date')}:{' '}
                                            <span className="bold">{window.$utils.dateFormatDDMMYYYY(props.patientData.reservationDetails?.estDischargeDate)}</span>
                                        </p>
                                    )}

                                {/* <a href="#" className="btn-icon gray-background" data-bs-toggle="tooltip" data-bs-placement="top" title="View">
                                    <i className="fa fa-pencil" aria-hidden="true"></i>
                                </a> */}
                            </div>
                            {props.patientData !== '' &&
                                props.patientData.reservationDetails?.patientType !== undefined &&
                                props.patientData.reservationDetails?.patientType !== '' &&
                                props.patientData.reservationDetails?.patientType !== null && (
                                    <p className="mt-1">
                                        {t('ReservedRightbar.Patient_type')}: <span className="bold">{props.patientData.reservationDetails?.patientType}</span>
                                    </p>
                                )}
                            {props.patientData !== '' &&
                                props.patientData.reservationDetails?.reservedBy !== undefined &&
                                props.patientData.reservationDetails?.reservedBy !== '' &&
                                props.patientData.reservationDetails?.reservedBy !== null && (
                                    <p className="mt-1">
                                        {t('ReservedRightbar.Reserved_by')}: <span className="bold">{props.patientData.reservationDetails?.reservedBy}</span>
                                    </p>
                                )}
                        </div>
                    </div>
                </div>
            </div>
            <div className="accordion-item">
                <h2 className="accordion-header" id="service-user-details">
                    <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#service-user-detail"
                        aria-expanded="false"
                        aria-controls="service-user-detail"
                    >
                        {t('ReservedRightbar.Service_User_Detail')}
                    </button>
                </h2>
                <div id="service-user-detail" className="accordion-collapse collapse" aria-labelledby="service-user-detail" data-bs-parent="#user-service-ac">
                    <div className="accordion-body">
                        <div>
                            {props.patientData !== '' &&
                                props.patientData.serviceUserDetails?.nhsId !== undefined &&
                                props.patientData.serviceUserDetails?.nhsId !== '' &&
                                props.patientData.serviceUserDetails?.nhsId !== null && (
                                    <p>
                                        {t('ReservedRightbar.NHS_ID')}: <span className="bold">{props.patientData.serviceUserDetails?.nhsId}</span>
                                    </p>
                                )}
                            {props.patientData !== '' &&
                                props.patientData.serviceUserDetails?.localId !== undefined &&
                                props.patientData.serviceUserDetails?.localId !== '' &&
                                props.patientData.serviceUserDetails?.localId !== null && (
                                    <p className="mt-1">
                                        {t('ReservedRightbar.Client_ID')}: <span className="bold">{props.patientData.serviceUserDetails?.localId}</span>
                                    </p>
                                )}
                            {props.patientData !== '' &&
                                props.patientData.serviceUserDetails?.dateOfBirth !== undefined &&
                                props.patientData.serviceUserDetails?.dateOfBirth !== '' &&
                                props.patientData.serviceUserDetails?.dateOfBirth !== null && (
                                    <p className="mt-1">
                                        {t('ReservedRightbar.Date_of_Birth')}: <span className="bold">{window.$utils.dateFormatDDMMYYYY(props.patientData.serviceUserDetails?.dateOfBirth)}</span>
                                    </p>
                                )}
                            {/* {props.patientData !== '' && ((props.patientData.serviceUserDetails.address1 !== undefined && props.patientData.serviceUserDetails.address1 !== '') ||
                                (props.patientData.serviceUserDetails.address2 !== undefined && props.patientData.serviceUserDetails.address2 !== '') ||
                                (props.patientData.serviceUserDetails.city !== undefined && props.patientData.serviceUserDetails.city !== '')) && (
                                <p className="mt-1">
                                    {t('ReservedRightbar.Address')}:{' '}
                                    <span className="bold">
                                        {props.patientData.serviceUserDetails.address1 || ''} {`, ${props.patientData.serviceUserDetails.address2}` || ''}{' '}
                                        {`, ${props.patientData.serviceUserDetails.city}.` || '.'}
                                    </span>
                                </p>
                            )} */}
                            {props.patientData !== '' &&
                                props.patientData.serviceUserDetails?.patientAddress !== undefined &&
                                props.patientData.serviceUserDetails?.patientAddress !== '' &&
                                props.patientData.serviceUserDetails?.patientAddress !== null && (
                                    <p className="mt-1">
                                        {t('ReservedRightbar.Address')}: <span className="bold">{props.patientData?.serviceUserDetails?.patientAddress || ''}</span>
                                    </p>
                                )}
                            {props.patientData !== '' &&
                                props.patientData.serviceUserDetails?.contactNo !== undefined &&
                                props.patientData.serviceUserDetails?.contactNo !== '' &&
                                props.patientData.serviceUserDetails?.contactNo !== null && (
                                    <p className="mt-1">
                                        {t('ReservedRightbar.Contact_Info')}: <span className="bold">{props.patientData.serviceUserDetails?.contactNo}</span>
                                    </p>
                                )}
                            {props.patientData !== '' &&
                                props.patientData.serviceUserDetails?.email !== undefined &&
                                props.patientData.serviceUserDetails?.email !== '' &&
                                props.patientData.serviceUserDetails?.email !== null && (
                                    <p className="mt-1">
                                        {t('ReservedRightbar.Email')}: <span className="bold">{props.patientData.serviceUserDetails?.email}</span>
                                    </p>
                                )}
                        </div>
                    </div>
                </div>
            </div>
            {DataServiceDetail?.iqviaServiceEnabled === false && (
                <div className="mt-2 text-center  right_content_btn">
                    <button
                        onClick={() => admitHandler(props.patientData.serviceUserDetails?.patientId, props.patientData?.admissionId, props.patientData?.bedId)}
                        className="btn btn-primary flex-1 me-2"
                        id="save-funder1"
                    >
                        {' '}
                        {t('ReservedRightbar.Admit_btn')}
                    </button>
                </div>
            )}
        </div>
    );
};

export default ReservedTabView;

'use client';
import React, { useEffect, useImperativeHandle, useRef, useState } from 'react';
import airobot from '../../../assets/images/airobot.png';
// import ProgressFooterView from '../ProgressFooter/ProgressFooterView';
//@ts-ignore
import AOS from 'aos';
import 'aos/dist/aos.css';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { generateOpenAiDescription, getOnboardingDescribeFacility, submitGenerateOpenAiDescription } from '../../../service/onboarding-describeyourfacility-service';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store';
import LoaderContainer from '../../../components/Loader/loaderContainer';
import swal from 'sweetalert';
import { OpenAienums, wordLimit } from '../../../utils/constant';
import { DescribeFacilityType, OpenAiResponse } from '../../../utils/model';
import warning from "../../../assets/images/warning.png"
import OnboardingGenerateAnimationLoader from '../ServiceOnboardingPreview/OnboardingGenerateAnimationLoder';
import { t } from 'i18next';
import { setOnboardingPageValidity } from '../../../store/onboarding/onboardingPageValidityReducer';

const DescribeYourFacilityView = React.forwardRef((props, ref) => {
    var settings = {
        dots: false,
        infinite: false,
        speed: 800,
        slidesToShow: 1,
        slidesToScroll: 1
    };

    const dispatch = useDispatch();

    const sliderRef = useRef<Slider>(null);
    const headingInputRefs = useRef<Array<HTMLTextAreaElement | null>>([]);

    const [descriptionData, setDescriptionData] = useState<any>([]);
    const [iseLoading, setIsLoading] = useState(false)
    // const [clickCount, setClickCount] = useState(0)
    const [isValid, setIsValid] = useState(false)
    const [isMounted, setIsMounted] = useState(true);
    const [validDescription, setValidDescription] = useState(false)
    const [isManualGenerate, setIsManualGenerate] = useState(false);

    const { serviceId, serviceName } = useSelector((state: RootState) => state.onboardingService);

    useEffect(()=>{
        dispatch(setOnboardingPageValidity(isValid && validDescription))
    }, [isValid, validDescription])

    useEffect(() => {
        const fetchFacilityData = async () => {
            setIsLoading(true)
            const response = await getOnboardingDescribeFacility(serviceId!);
            if (response.status) {
                setIsLoading(false)
                const descriptions = response?.data?.openairesponse;
                if (descriptions?.length > 0) {
                    const updatedArr = descriptions.map((item: any) => ({
                        ...item,
                        wordCount: calculateWordCount(item.generatedResponse)
                    }));
                    setDescriptionData(updatedArr);
                } else {
                    setDescriptionData(descriptions);
                }
                // setClickCount(response?.data?.clickCount)
            }
            setIsLoading(false)
        };
        fetchFacilityData();
    }, []);

    useEffect(() => {
        AOS.init();
    }, []);

    useEffect(() => {
        setIsMounted(true);
        if (descriptionData?.length > 0) {
            const finalAiValidation = descriptionData?.map(({ wordCount, ...rest }: any) => rest)
                .some((item: any) => item.generatedResponse)
            const finalDesAiValidation = descriptionData?.map(({ wordCount, ...rest }: any) => rest)
                .some((item: any) => item.isSelected === true)
            if (isMounted) {
                setIsValid(finalAiValidation);
                setValidDescription(finalDesAiValidation)
            }
        }

        return () => {
            setIsMounted(false);
        };

    }, [descriptionData, isMounted])


    const showWarning = (message: string, success?: boolean) => {
        swal({
            title: '',
            text: message,
            icon: success ? 'success' : warning,
            dangerMode: true
        });
    };


    function calculateWordCount(text: string) {
        return text?.trim().split(/\s+/).length;
    }

    const startAnimation = () => {
        const element = document.getElementById('startRobotSpeaking');
        if (element) {
            element.classList.add('startAnim');
        }
    };

    const handleChangeDescription = (event: any, descriptionId: number) => {
        const newDescription = event.target.value;
        const wordCount = calculateWordCount(newDescription);

        if (wordCount <= wordLimit) {
            const updatedDescriptions = descriptionData.map((item: any) => {
                if (item.airesponseId === descriptionId) {
                    return { ...item, generatedResponse: newDescription, wordCount };
                }
                return item;
            });
            setDescriptionData(updatedDescriptions);
        }
        //  else {
        //     const truncatedDescription = newDescription.split(/\s+/).slice(0, 150).join(' ');
        //     const truncatedWordCount = calculateWordCount(truncatedDescription);

        //     const updatedDescriptions = descriptionData.map((item: any) => {
        //         if (item.Description_Id === descriptionId) {
        //             return { ...item, Description: truncatedDescription, wordCount: truncatedWordCount };
        //         }
        //         return item;
        //     });
        //     setDescriptionData(updatedDescriptions);
        // }
    };

    const handleActiveDescription = (id: number) => {
        const updatedData = descriptionData.map((item: any) => ({
            ...item,
            isSelected: item.airesponseId === id
        }));
        setDescriptionData(updatedData);
    };

    const handleGenerateOpenAi = async () => {
        const payload: DescribeFacilityType = {
            serviceId: serviceId!,
            pageId: OpenAienums.FacilityOverview
        }
        //setIsLoading(true)
        setIsManualGenerate(true)
        const response = await generateOpenAiDescription(payload)
        if (response?.status) {
            //setIsLoading(false)
            setIsManualGenerate(false)
            const descriptionArr = response?.data
            const updatedArr = descriptionArr?.map((item: any) => ({
                ...item,
                wordCount: calculateWordCount(item?.generatedResponse)
            }))
            setDescriptionData(updatedArr)
            setTimeout(() => {
                sliderRef.current?.slickGoTo(updatedArr.length);
                setTimeout(() => {
                    focusHeadingAutocomplete(updatedArr.length - 1)
                }, 500);
            }, 100);
            // setClickCount((prev) => prev + 1)
        } else {
            //setIsLoading(false)
            setIsManualGenerate(false)
            swal({
                title: '',
                text: response.message,
                icon: "error",
                dangerMode: true
            })
        }

    }

    const handleSubmitOpenAiDescriptions = () => {
        const submitOpenAiDescriptions = async () => {
            const finalAiResponse = descriptionData?.map(({ wordCount, ...rest }: any) => rest)
            const payload = {
                Service_Id: serviceId!,
                openairesponse: finalAiResponse
            }
            setIsLoading(true)
            const response = await submitGenerateOpenAiDescription(payload)
            if (response.status) {
                setIsLoading(false)
            } else {
                setIsLoading(false)
                swal({
                    title: '',
                    text: response.message,
                    icon: "error",
                    dangerMode: true
                })
            }

        }
        submitOpenAiDescriptions()
    }


    useImperativeHandle(ref, () => ({
        isValidated: isValid && validDescription,

        backActionHandler: handleSubmitOpenAiDescriptions,

        nextActionHandler: handleSubmitOpenAiDescriptions,

        triggerValidation: async () => {
            if (!isValid) {
                showWarning("Please generate at least one description.", false);
                return false;
            }

            if (!validDescription) {
                showWarning("Please select at least one description.", false);
                return false;
            }

            return true;
        }
    }));

    const focusHeadingAutocomplete = (index: number) => {
        if (headingInputRefs && headingInputRefs?.current[index]) {
            headingInputRefs.current[index]!.focus();
            headingInputRefs.current[index]!.setSelectionRange(headingInputRefs.current[index]!.value.length, headingInputRefs.current[index]!.value.length);
            headingInputRefs.current[index]!.scrollTop = headingInputRefs.current[index]!.scrollHeight;
        }
    };


    if (isManualGenerate) {
        return <OnboardingGenerateAnimationLoader showModal={isManualGenerate} previewMessage={t('GenerateHeadings.Facility_Description')} />
    }
    if (iseLoading) {
        return <LoaderContainer />
    }
    return (
        <div className='mt-4 pb-115'>
            <div className="row">
                <div className="col-md-10">
                    <div className="sec-frame">
                        <div className="sec-frame-title">
                            <p>Add Overview</p>
                        </div>

                        <div className="gradient-bg describe-facility-content description-slider position-relative">
                            {descriptionData?.length > 0 ? (
                                <Slider {...settings} ref={sliderRef}>
                                    {descriptionData?.map((item: any, index: number) => (
                                        <div className="position-relative" key={item?.airesponseId}>
                                            <div className="description-item ">
                                                <textarea
                                                    ref={(ref) => (headingInputRefs.current[index] = ref)}
                                                    className="text-justify desc-textarea form-control mb-4"
                                                    name=""
                                                    id=""
                                                    value={item?.generatedResponse}
                                                    onChange={(event) => handleChangeDescription(event, item?.airesponseId)}
                                                />
                                                <div className="d-flex justify-content-between">
                                                    <div className="me-2">
                                                        <div className="desc-count-btn">{item.wordCount}/{t('Words.facility')}</div>
                                                    </div>
                                                    <div className="position-relative use-this-btn">
                                                        <input
                                                            className=""
                                                            type="radio"
                                                            checked={item.isSelected}
                                                            name="description"
                                                            id=""
                                                            onChange={() => handleActiveDescription(item?.airesponseId)}
                                                        />
                                                        <button className={`btn primary-btn ${item.isSelected ? "useThisBtn" : ""} `}>
                                                            <span>Use this</span>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </Slider>
                            ) : (
                                <div className="">
                                    <p className="text-justify mb-3">Click on Generate to generate the description</p>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                <div className="col-md-2 ">
                    <div className="ms-3 robot-container d-flex align-item-center justify-content-center">
                        <div className="robot-animation mt-2">
                            <img src={airobot} className="img-fluid" alt="airboat" />
                            {/* <div className="mouth"></div> */}
                            {/* <div className="mouth">
                                    <div className="mouth-container">
                                        <div className="mouth-container-line"></div>
                                    </div>
                                </div> */}
                        </div>
                    </div>
                    <div className='ms-3'>
                        <button className="toggle-button variation3" onClick={handleGenerateOpenAi} >
                            <span className="toggle-indicator"></span>
                            <span className="label">Generate</span>
                        </button>
                    </div>
                </div>
            </div>
            <div className="row mt-3" data-aos="fade-up" data-aos-duration="1600" data-aos-once="true">
                <div className="col-md-10">
                    <p className="text-center">
                        <span className="bolder">Once you are satisfied</span> with the description, click <span className="bolder">“Next”</span> to proceed, or{' '}
                        <span className="bolder">“{(isValid && validDescription) ? "Save & Continue Later" : "Exit & Continue Later"}”</span> to save your progress and continue later.
                    </p>
                </div>
            </div>
        </div>

    );
});

export default DescribeYourFacilityView;

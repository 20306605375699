import React, { useState } from 'react';
import { ROUTES_DATA } from '../../../../../../navigation/RoutesConstants';

const ServicesDetailsView = (props: any) => {

  const [openBox, SetOpenBox] = useState(false)

  const handleBox = (event: any) => {
    event.preventDefault();
    SetOpenBox((prevState) => !prevState);
  }

  const handleListItemClick = (id: any) => {
    const url = `${ROUTES_DATA.PROVIDERSERVICEDETAIL}/${id}`;
    window.open(url, '_blank');
  };
  return (

    <div className=''>

      {openBox &&
        <div className='servicesmore-box'>
          <div className='servicemore-data'>
            <h5 className='bolder mb-3'>{props?.serviceDetails?.serviceDetails?.serviceSectionName}</h5>
            <ul>
              {
                props?.serviceDetails?.serviceDetails && props?.serviceDetails?.serviceDetails?.providerServicesSectionDetailMasters &&
                props?.serviceDetails?.serviceDetails?.providerServicesSectionDetailMasters.length > 0 &&
                props?.serviceDetails?.serviceDetails?.providerServicesSectionDetailMasters.map((psd: any, index: any) => {
                  // return (
                  //   <li key={index} className='lead-text mb-1' onClick={() => handleListItemClick(psd?.servicesSectionDetailId)}><i className="fa fa-caret-right me-2 servicelist-righticon" aria-hidden="true"></i><span className='clickable'>{psd?.name}</span></li>
                  // )
                  return (
                    <li key={index} className='lead-text mb-1'><i className="fa fa-caret-right me-2 servicelist-righticon" aria-hidden="true"></i><span className='clickable'>{psd?.name}</span></li>
                  )
                })
              }
            </ul>
          </div>
          {/* <div className="d-flex justify-content-end">
          <a href="#" onClick={handleBox} aria-labelledby="Click Text" className="white-click-btn black-click-btn">
            <i className="fa-solid fa-close"></i>
          </a>
        </div> */}
        </div>

      }
      <div className={`white-btn-box ${openBox ? "open" : ""} `}>
        <a href="#" onClick={handleBox} aria-labelledby="Click Text" className={`white-click-btn ${openBox ? "rotate" : ""}`}>
          <i className="fa-solid fa-plus"></i>
        </a>
      </div>
    </div>


  );
};

export default ServicesDetailsView;

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import UserDetailContainer from '../../ReferralManagementPage/ReferralManager/ReferralSidebar/CreateLead/UserDetail/UserDetailContainer';
import PenPictureContainer from '../../ReferralManagementPage/ReferralManager/ReferralSidebar/CreateLead/PenPicture/PenPictureContainer';
import ContactDetailsContainer from '../../ReferralManagementPage/ReferralManager/ReferralSidebar/CreateLead/ContactDetails/ContactDetailsContainer';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store';
import warning from '../../../assets/images/warning.png';
import swal from 'sweetalert';
import { getCityData, getPatientTypeData } from '../../../service/dashboard-service';
import { addLead, getServiceList } from '../../../service/leads';
import { rightNavigationPage } from '../../../utils/constant';
import { setNavigationAction } from '../../../store/dashboard/navigationReducer';
import eventEmitter from '../../../utils/eventEmitter';
import ContractContainer from '../../ReferralManagementPage/ReferralManager/ReferralSidebar/CreateLead/ContractDetail/contractContainer';


const LeadDetailsView = (props: { isheader?: boolean, referralId?: number }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const { Data } = useSelector((state: RootState) => state.navigation);
    const { DataLeadInfo } = useSelector((state: RootState) => state.leadInformation);

    const [savedisable, setsavedisable] = useState(false);
    const [userdata, setuserdata] = useState<any>();
    const [userdataerror, setuserdataerror] = useState<any>();
    const [pendata, setpendata] = useState<any>();
    const [pendataerror, setpendataerror] = useState<any>();
    const [contractdata, setcontractdata] = useState<any>();
    const [contactdata, setcontactdata] = useState<any>();
    const [deletedFunderList, setdeletedfunderlist] = useState<any>();
    const [contactdataerror, setcontactdataerror] = useState<any>();
    const [contractdataerror, setcontractdataerror] = useState<any>();
    const [funderlisterror, setfunderlisterror] = useState<any>();
    const [pooldisable, setpooldisable] = useState<boolean>(false);

    useEffect(() => {
        if (userdataerror && pendataerror && contactdataerror) {
            setsavedisable(true);
        } else {
            setsavedisable(false);
        }
    }, [userdataerror, pendataerror, contactdataerror]);

    useEffect(() => {
        async function getDropdowns() {
            await getPatientTypeData();
        }
        getDropdowns();
    }, []);

    useEffect(() => {
        async function getDropdowns() {
            await getCityData();
            await getServiceList();
        }
        getDropdowns();
    }, []);

    useEffect(() => {
        if (userdataerror && pendataerror && contactdataerror) {
            setsavedisable(true);
            setpooldisable(true);
        } else {
            setsavedisable(false);
            setpooldisable(false);
        }
    }, [userdataerror, pendataerror, contactdataerror]);

    const getcontractdata = (condata: any) => {
        setcontractdata(condata);
    };

    const getcontactdata = (contactdata: any) => {
        setcontactdata(contactdata);
    };
    const getuserdataerror = (userdataerror: any) => {
        setuserdataerror(userdataerror);
    };

    const getpendataerror = (pendataerror: any) => {
        setpendataerror(pendataerror);
    };

    const getcontractdataerror = (conerror: any) => {
        setcontractdataerror(conerror);
    };
    const getfundelisterror = (funerror: any) => {
        setfunderlisterror(funerror);
    };

    const getcontactdataerror = (contactdataerror: any) => {
        setcontactdataerror(contactdataerror);
    };

    const getuserdata = (userdata: any) => {
        setuserdata(userdata);
    };

    const getpendata = (pendata: any) => {
        setpendata(pendata);
    };

    const alertHandler = (text: any, operation: any) => {
        swal({
            title: '',
            text: text,
            icon: warning,
            buttons: ['No', 'Yes'],
            dangerMode: true
        }).then(async (confirm: any) => {
            if (confirm) {
                if (operation === 'add' || operation === 'update') {
                    addlead();
                } else {
                    poollead();
                }
            }
        });
    };

    const savealert = () => {
        alertHandler(`Are you sure you want to save the lead?`, 'add');
    };

    const updatealert = () => {
        alertHandler(`Are you sure you want to update the lead ${props.referralId}?`, 'update');
    };

    const addlead = async () => {
        let contactobj = {
            referralSourceId: Number(contactdata?.referralSourceId) || 0,
            referralSourceDescription: contactdata?.referralSourceDescription,
        };
        if (contractdata?.ListData?.length > 0) {
            var fundarr = [];
            for (let i = 0; i < contractdata?.ListData.length; i++) {
                let funderdata = {
                    funderId: contractdata?.ListData[i]?.funderData?.ftype === '4' ? null : Number(contractdata?.ListData[i]?.funderData?.fFunder),
                    funderEmail: contractdata?.ListData[i]?.funderData?.fEmailId || '',
                    funderPhoneNo: contractdata?.ListData[i]?.funderData?.fContactNo || '',
                    funderAddressLine1: contractdata?.ListData[i]?.funderData?.fAddress1 || '',
                    // "funderCountyId": Number(contractdata?.ListData[i]?.funderData?.fCity),
                    funderCountyId:
                        contractdata?.ListData[i]?.funderData?.fCity === '' || contractdata?.ListData[i]?.funderData?.fCity === undefined || Number(contractdata?.ListData[i]?.funderData?.fCity) === 0
                            ? null
                            : Number(contractdata?.ListData[i]?.funderData?.fCity),
                    funderName: contractdata?.ListData[i]?.funderData?.ftype === '4' ? contractdata?.ListData[i]?.funderData?.fFunderVal : '',
                    isSelfFunded: contractdata?.ListData[i]?.funderData?.ftype === '4' ? 1 : 0,
                    funderPostalCode: contractdata?.ListData[i]?.funderData?.fPoBox || '',
                    commissionerName: contractdata?.ListData[i]?.commisionerData?.cFunder || '',
                    commissionerEmail: contractdata?.ListData[i]?.commisionerData?.cEmailId || '',
                    commissionerPhoneNo: contractdata?.ListData[i]?.commisionerData?.cContactNo || '',
                    commissionerAddressLine1: contractdata?.ListData[i]?.commisionerData?.cAddress1 || '',
                    // "commissionerCountyId": Number(contractdata?.ListData[i]?.commisionerData?.cCity || ''),
                    commissionerCountyId:
                        contractdata?.ListData[i]?.commisionerData?.cCity === '' ||
                            contractdata?.ListData[i]?.commisionerData?.cCity === undefined ||
                            Number(contractdata?.ListData[i]?.commisionerData?.cCity) === 0
                            ? null
                            : Number(contractdata?.ListData[i]?.commisionerData?.cCity),
                    commissionerPostalCode: contractdata?.ListData[i]?.commisionerData?.cPoBox || '',
                    socialWorkerName: contractdata.ListData[i].commisionerData.sFunder,
                    socialWorkerEmail: contractdata.ListData[i].commisionerData.sEmailId,
                    socialWorkerPhoneNo: contractdata.ListData[i].commisionerData.sContactNo,
                    socialWorkerAddressLine1: contractdata.ListData[i].commisionerData.sAddress1,
                    socialWorkerCountyId: contractdata.ListData[i].commisionerData.sCity !== '' ? Number(contractdata.ListData[i].commisionerData.sCity) : null,
                    socialWorkerCountryId: null,
                    socialWorkerPostalCode: contractdata.ListData[i].commisionerData.sPoBox,
                    suid: contractdata.ListData[i]?.funderData?.fsuid,
                    note: contractdata.ListData[i]?.funderData?.fnote,
                };

                fundarr.push(funderdata);
            }
        }

        const addpayload = {
            patientDetail: {
                leadId: DataLeadInfo === undefined ? 0 : DataLeadInfo?.data?.patientDetail?.leadId,
                patientName: userdata?.name || '',
                patientTypeId: Number(userdata?.patienttype) || 0,
                serviceTypeId: Number(userdata?.servicetype) || 0,
                dateOfBirth: userdata?.dob ? window.$utils.dateFormatDDMMYYYY(window.$utils.dateFormat(userdata.dob)) : null,
                leadDate: userdata?.leaddate ? window.$utils.dateFormatDDMMYYYY(window.$utils.dateFormat(userdata.leaddate)) : '',
                gender: userdata?.gender !== 0 ? Number(userdata?.gender) : null,
                nhsId: userdata?.NhsId || '',
                localId: userdata?.LocalId || '',
                contactNo: userdata?.ContactNo || '',
                email: userdata?.EmailId || '',
                countyId: userdata?.City === undefined || Number(userdata?.City) === 0 ? null : Number(userdata?.City),
                pobox: userdata?.PoBox,
                addressLine1: userdata?.Address1,
                addressLine2: userdata?.Address2,
                penPictureDescription: 'string',
                submitToPool: false,
                organizationId: 1,
                isfromPatientInquiry: true
            },
            penPictureDetailModel: {
                description: pendata?.desc?.desc,
                listPenPictureList: pendata?.attachments?.files ? pendata?.attachments?.files?.map((item: any) => ({
                    leadPenPicture_Id: item.leadPenPicture_Id,
                    documentPath: item?.filepath,
                    documentName: item?.filename
                })) : []
            },
            leadFunderDetailsList: contractdata?.ListData?.length === 0 ? [] : fundarr,
            referralContactDetailModel: contactobj,
        };

        const res = await addLead(addpayload);

        if (res.status === true) {
            swal(res.message, {
                icon: 'success'
            });
            eventEmitter.emit('eventOpenLeadContainer', false);
            let obj: any = {
                ShowClass: false,
                FormName: '',
                Data: '',
                ShowToggle: false
            };
            dispatch(setNavigationAction(obj));
        } else {
            swal(res.message, {
                icon: 'error'
            });
        }
    }

    const poolalert = () => {
        if (props.referralId != undefined) {
            alertHandler(`Are you sure you want to pool the lead ${props.referralId}?`, 'pool');
        }
        else {
            alertHandler(`Are you sure you want to pool the lead?`, 'pool');
        }
    };

    const poollead = async () => {
        let contactobj = {
            referralSourceId: Number(contactdata?.referralSourceId) || 0,
            referralSourceDescription: contactdata?.referralSourceDescription,
        };
        if (contractdata?.ListData?.length > 0) {
            var fundarr = [];
            for (let i = 0; i < contractdata?.ListData.length; i++) {
                let funderdata = {
                    funderId: contractdata?.ListData[i]?.funderData?.ftype === '4' ? null : Number(contractdata?.ListData[i]?.funderData?.fFunder),
                    funderEmail: contractdata?.ListData[i]?.funderData?.fEmailId || '',
                    funderPhoneNo: contractdata?.ListData[i]?.funderData?.fContactNo || '',
                    funderAddressLine1: contractdata?.ListData[i]?.funderData?.fAddress1 || '',
                    funderCountyId:
                        contractdata?.ListData[i]?.funderData?.fCity === '' || contractdata?.ListData[i]?.funderData?.fCity === undefined || Number(contractdata?.ListData[i]?.funderData?.fCity) === 0
                            ? null
                            : Number(contractdata?.ListData[i]?.funderData?.fCity),
                    funderName: contractdata?.ListData[i]?.funderData?.ftype === '4' ? contractdata?.ListData[i]?.funderData?.fFunderVal : '',
                    isSelfFunded: contractdata?.ListData[i]?.funderData?.ftype === '4' ? 1 : 0,
                    funderPostalCode: contractdata?.ListData[i]?.funderData?.fPoBox || '',
                    commissionerName: contractdata?.ListData[i]?.commisionerData?.cFunder || '',
                    commissionerEmail: contractdata?.ListData[i]?.commisionerData?.cEmailId || '',
                    commissionerPhoneNo: contractdata?.ListData[i]?.commisionerData?.cContactNo || '',
                    commissionerAddressLine1: contractdata?.ListData[i]?.commisionerData?.cAddress1 || '',
                    commissionerCountyId:
                        contractdata?.ListData[i]?.commisionerData?.cCity === '' ||
                            contractdata?.ListData[i]?.commisionerData?.cCity === undefined ||
                            Number(contractdata?.ListData[i]?.commisionerData?.cCity) === 0
                            ? null
                            : Number(contractdata?.ListData[i]?.commisionerData?.cCity),
                    commissionerPostalCode: contractdata?.ListData[i]?.commisionerData?.cPoBox || '',
                    socialWorkerName: contractdata.ListData[i].commisionerData.sFunder,
                    socialWorkerEmail: contractdata.ListData[i].commisionerData.sEmailId,
                    socialWorkerPhoneNo: contractdata.ListData[i].commisionerData.sContactNo,
                    socialWorkerAddressLine1: contractdata.ListData[i].commisionerData.sAddress1,
                    socialWorkerCountyId: contractdata.ListData[i].commisionerData.sCity !== '' ? Number(contractdata.ListData[i].commisionerData.sCity) : null,
                    socialWorkerCountryId: null,
                    socialWorkerPostalCode: contractdata.ListData[i].commisionerData.sPoBox,
                    suid: contractdata.ListData[i]?.funderData?.fsuid,
                    note: contractdata.ListData[i]?.funderData?.fnote,
                };

                fundarr.push(funderdata);
            }
        }

        const addpayload = {
            patientDetail: {
                leadId: DataLeadInfo === undefined ? 0 : DataLeadInfo?.data?.patientDetail?.leadId,
                patientName: userdata?.name || '',
                patientTypeId: Number(userdata?.patienttype) || 0,
                serviceTypeId: Number(userdata?.servicetype) || 0,
                dateOfBirth: userdata?.dob ? window.$utils.dateFormatDDMMYYYY(window.$utils.dateFormat(userdata.dob)) : null,
                leadDate: userdata?.leaddate ? window.$utils.dateFormatDDMMYYYY(window.$utils.dateFormat(userdata.leaddate)) : '',
                gender: userdata?.gender !== 0 ? Number(userdata?.gender) : null,
                nhsId: userdata?.NhsId || '',
                localId: userdata?.LocalId || '',
                contactNo: userdata?.ContactNo || '',
                email: userdata?.EmailId || '',
                countyId: userdata?.City === undefined || Number(userdata?.City) === 0 ? null : Number(userdata?.City),
                pobox: userdata?.PoBox,
                addressLine1: userdata?.Address1,
                addressLine2: userdata?.Address2,
                penPictureDescription: 'string',
                submitToPool: true,
                organizationId: 1,
                isfromPatientInquiry: true
            },
            penPictureDetailModel: {
                description: pendata?.desc?.desc,
                listPenPictureList: pendata?.attachments?.files ? pendata?.attachments?.files?.map((item: any) => ({
                    leadPenPicture_Id: item.leadPenPicture_Id,
                    documentPath: item?.filepath,
                    documentName: item?.filename
                })) : []
            },
            leadFunderDetailsList: contractdata?.ListData?.length === 0 ? [] : fundarr,
            referralContactDetailModel: contactobj,
        };

        const res = await addLead(addpayload);
        if (res.status === true) {
            swal(res.message, {
                icon: 'success'
            });
            // eventEmitter.emit('onAddNewLeadRM');
            let obj: any = {
                ShowClass: false,
                FormName: '',
                Data: '',
                ShowToggle: false
            };
            dispatch(setNavigationAction(obj));
            eventEmitter.emit('eventOpenLeadContainer', false);
            eventEmitter.emit('eventOpenNHSContainerStateChanger', false);
            // eventEmitter.emit("onClickRefreshData", 2,2)
        } else {
            swal(res.message, {
                icon: 'error'
            });
        }
    }

    const deletefunderlist = (deleteData: any) => {
        setdeletedfunderlist(deleteData);
    }

    // Code block to reset the scroll to the last opened accordion item.
    useEffect(() => {
        const accordion = document.getElementById('accordionLead');    
        const handleShown = (e: any) => {
            const openAccordion = e.target as HTMLElement;
            const headingId = openAccordion.getAttribute('aria-labelledby');
            const headingElement = document.getElementById(headingId!);
    
            if (headingElement) {
                headingElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
                const parentContainer = headingElement.closest(props.referralId ? '#myTabContent': ".nhs-sidebar");
                if (parentContainer) {
                    parentContainer.scrollBy({
                        top: props.referralId ? -25 : -60,
                        behavior: 'smooth'
                    });
                }
            }
        };    
        accordion?.addEventListener('shown.bs.collapse', handleShown);
    
        return () => {
            accordion?.removeEventListener('shown.bs.collapse', handleShown);
        };
    }, [props.referralId]);  

    return (
        <div className='nhs-accordion mt-3'>
            <div className='referral-height'>
                <div className="accordion" id="accordionLead">
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="headingOne">
                            <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                {t('ReferralLead.User_Detail')}
                            </button>
                        </h2>
                        <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionLead">
                            <div className="accordion-body" style={{ pointerEvents: Data?.fleadstatus === 1 ? 'all' : 'none' }}>
                                <UserDetailContainer getuserdata={getuserdata} getuserdataerror={getuserdataerror} />
                            </div>
                        </div>
                    </div>
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="headingTwo">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                {t('ReferralLead.Pen_Picture')}
                            </button>
                        </h2>
                        <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionLead">
                            <div className="accordion-body">
                                <PenPictureContainer getpendataerror={getpendataerror} getpendata={getpendata} userdata={userdata} isenable={Data?.fleadstatus} />
                            </div>
                        </div>
                    </div>
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="headingThree">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                {t('ReferralLead.Funder_Details')}
                            </button>
                        </h2>
                        <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionLead">
                            <div className="accordion-body">
                                <ContractContainer
                                    contractdata={getcontractdata}
                                    getcontracterror={getcontractdataerror}
                                    getfundelisterror={getfundelisterror}
                                    selfData={userdata}
                                    deletefunderlist={deletefunderlist}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="headingFour">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                Contact Details
                            </button>
                        </h2>
                        <div id="collapseFour" className="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionLead">
                            <div className="accordion-body">
                                <ContactDetailsContainer getcontactdata={getcontactdata} getcontactdataerror={getcontactdataerror} isenable={Data?.fleadstatus} />
                            </div>
                        </div>
                    </div>
                </div>

                <div 
                    className={`d-flex justify-content-between  right_content_btn ${Data?.fleadstatus === 1 ? 'disable-none' : 'disable-all'} `}
                    style={{ pointerEvents: Data?.fleadstatus === 1 ? 'all' : 'none' }}
                >
                    {DataLeadInfo?.data?.patientDetail === undefined && (
                        <button className="btn btn-secondary flex-1 me-2" disabled={!savedisable} onClick={savealert}>
                            {t('ReferralLead.Save_btn')}
                        </button>
                    )}
                    {DataLeadInfo?.data?.patientDetail !== undefined && (
                        <button disabled={!savedisable} onClick={updatealert} className="btn btn-secondary flex-1 me-2">
                            {t('ReferralLead.Update_btn')}
                        </button>
                    )}
                    <button disabled={!pooldisable} onClick={poolalert} className="btn btn-primary flex-1 me-2">
                        {t('ReferralLead.Pool_btn')} <i className="fa-solid fa-arrow-right ms-1"></i>
                    </button>
                </div>
            </div>
        </div>
    );
};

export default LeadDetailsView;
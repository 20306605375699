import React, { useEffect, useState } from 'react';
import ModalWrapperContainer from '../ModalWrapper/indexContainer';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Tooltip } from 'react-tooltip';
import { useTranslation } from 'react-i18next';
import { updateDocuSign } from '../../service/contract-service';
import RenderModalContainer from '../RenderModal/indexContainer';
import PreviewContractContainer from '../PreviewContractModal/PreviewContractContainer';
import { coreFeesType } from '../../../src/utils/constant';
interface ModalProps {
    onClose: () => any;
    title: string;
    content: any;
    footer: any;
    className: any;
    plantype:any;
    count:any;
    plandata:any;
    totalCount:any;
}

const PlanHistoryView = (props: ModalProps) => {
    const { t } = useTranslation();
    
    const [plandetials,setplandetails] = useState<any>();
    const [cindex,setcindex] = useState<any>(0);
    const [showModal, setShowModal] = useState(false);
    const [previewdata, setpreviewdata] = useState<any>();
    const [additionaldisplay,setadditionaldisplay] = useState<any>(true);
    const setplandata = (data:any,index:any) =>{
        setplandetails(undefined);
        setcindex(index);
    }
    

    useEffect(()=>{
      setplandetails(props.plandata?.planData[cindex]);
      if(props.plandata?.planData[0]?.isInclusionHours1Included === 0 || props.plandata?.planData[0]?.isInclusionHours2Included === 0 || props.plandata?.planData[0]?.isInclusionHours3Included === 0 || props.plandata?.planData[0]?.isInclusionHours4Included === 0){
        setadditionaldisplay(true);
      }
      else{
        setadditionaldisplay(false);
      }
    },[cindex,props.plandata])
    

    const toggleModalPreview = () => {
        setShowModal(false);
        setpreviewdata(undefined);
    };

    async function handleClickModal(digitalContractId:any) {
        
            const docures = await updateDocuSign(digitalContractId);
              if(docures.status){
                setShowModal(true);
                setpreviewdata(docures.data);
              }            
    }
    
    return (
        <div>
            <ModalWrapperContainer
                title={`${props.title} - ${props.plandata?.planData?.length === 1 ? props.plandata?.planData[0]?.planName:''}`}
                onClose={props.onClose}
                className="modal modal-small"
                content={
                    <div>
                        {props.plandata?.planData?.length > 0 && props.plandata?.planData?.length === 1 && <>
                        <div>
                            <div className="d-flex">
                                {props.plandata?.planData[0]?.weeklyCharge != null && <div className="d-flex me-4">
                                    <label className="me-1">{t('RightSidebarContraclList.funder_Total_Fee')}:</label>
                                    <span className="bolder">£ {props.plandata?.planData[0].weeklyCharge}</span>
                                </div>}
                                {props.plandata?.planData[0]?.dailyCoreFee != null && props.plandata?.planData[0]?.dailyCoreFee > 0 && <div className="d-flex me-4">
                                    <label className="me-1">{t('RightSidebarContraclList.funder_Core_Fees')}:</label>
                                    <span className="bolder">£ {props.plandata?.planData[0]?.dailyCoreFeeType === coreFeesType.Weekly ? props.plandata?.planData[0]?.dailyCoreFee : (props.plandata?.planData[0]?.dailyCoreFee * 7).toFixed(2)}</span>
                                </div>
                                }
                                <div className="d-flex me-4">
                                    <label className="me-1">{t('SplitFundingPopup.Start_Date')}:</label>
                                    <span className="bolder">{window.$utils.dateFormatDDMMYYYY(props.plandata?.planData[0].startDate)}</span>
                                </div>
                                <div className="d-flex">
                                    <label className="me-1">{t('SplitFundingPopup.End_Date')}:</label>
                                    <span className="bolder">{props.plandata?.planData[0].endDate ? window.$utils.dateFormatDDMMYYYY(props.plandata?.planData[0].endDate) : ''}</span>
                                </div>

                                
                            </div>
                            <div className="d-flex mt-3">
                            {props.plandata?.planData[0]?.continenceCareFee != 0 && props.plandata?.planData[0]?.isContinenceFeeIncluded === 0 && <div className="d-flex me-4">
                                    <label className="me-1">{t('RightSidebarContraclList.funder_Continence_Fee')}:</label>
                                    <span className="bolder">£ {props.plandata?.planData[0]?.continenceCareFeeType === coreFeesType.Weekly ? props.plandata?.planData[0]?.continenceCareFee : (props.plandata?.planData[0]?.continenceCareFee * 7).toFixed(2)}</span>
                                </div>}
                                {props.plandata?.planData[0]?.fncFee != 0 && props.plandata?.planData[0]?.isFncFeeIncluded === 0 && <div className="d-flex me-4">
                                    <label className="me-1">{t('RightSidebarContraclList.funder_FNC_Fee')}:</label>
                                    <span className="bolder">£ {props.plandata?.planData[0]?.fncFeeType === coreFeesType.Weekly ? props.plandata?.planData[0]?.fncFee : (props.plandata?.planData[0]?.fncFee * 7).toFixed(2)}</span>
                                </div>}

                                {props.plandata?.planData[0]?.totalAdditionalServiceFee != 0 && additionaldisplay && <div className="d-flex">
                                    <label className="me-1">{t('RightSidebarContraclList.Additionalfee')}:</label>
                                    <span className="bolder">£ {props.plandata?.planData[0]?.totalAdditionalServiceFee}</span>
                                </div>}
                                
                            </div>

                            <table className="table prev-plan-table mt-4 mb-0">
                                <tbody>
                                <tr>
                                    <th>{t('SplitFundingPopup.Funder_Type')}</th>
                                    <th style={{ width: '38%' }}>{t('SplitFundingPopup.Funder_Name')}</th>
                                    <th style={{ width: '17%' }}>{t('SplitFundingPopup.Proportion_Value')}</th>
                                    <th style={{ width: '8%' }}>{t('SplitFundingPopup.IPA')}</th>
                                    <th>{t('SplitFundingPopup.IsApplicable')}</th>
                                    <th>{t('SplitFundingPopup.Autopay')}</th>
                                </tr>
                                {props.plandata?.planData[0]?.planDetails.length > 0 && props.plandata?.planData[0]?.planDetails.map((plans: any, i: number)=>{                                    
                                    return (
                                        <tr>
                                        <td>{plans.funderType === 1 ? "ICB" :plans.funderType === 2 ? "LA" : plans.funderType === 3 ? "Charity" : "Self"}</td>
                                        <td>{plans.funderName}</td>
                                        <td>£ {plans.proportionValue}</td>
                                    <td>{ plans.digitalContractId > 0 && <a href="#" className='btn-icon btn-secondary' data-tooltip-id="my-tooltip-view" data-tooltip-content="View" onClick={(e)=>handleClickModal(plans.digitalContractId)}><i className="fa-solid fa-eye"></i></a>}</td>
                                    <td>{plans.isApplicable === 1 ? `${t('SplitFundingPopup.Yes')}`:`${t('SplitFundingPopup.No')}`}</td>
                                    <td>{plans.isAutoPay === 1 ? `${t('SplitFundingPopup.Yes')}`:`${t('SplitFundingPopup.No')}`}</td>
                                    </tr>
                                    )
                                })}
                                {previewdata !== undefined && <RenderModalContainer>{showModal && <PreviewContractContainer onClose={toggleModalPreview} previewdata={previewdata} />}</RenderModalContainer>}
                                </tbody>
                            </table>
                        </div>
                        </>}
                        {props.plandata?.planData?.length > 0 && props.plandata?.planData?.length > 1 && <>
                        <div>
                        <ul className="nav nav-tabs mb-0 tab-list" id="myTab" role="tablist">
                            {props.plandata?.planData.map((data:any,i:any)=>{
                                return(
                                    <li className="nav-item" role="presentation">
                                    <button
                                        className={`nav-link ${cindex === i ? 'active' : ''}`}
                                        id={i}
                                        key={i}
                                        data-bs-toggle="tab"
                                        data-bs-target={`#plan${i}`}
                                        type="button"
                                        role="tab"
                                        aria-controls={`#plan${i}`}
                                        aria-selected="true"
                                        onClick={(e)=>setplandata(data,i)}
                                    >
                                        <p className="bolder mb-1">{data.planName}</p>
                                        <p>
                                        {t('SplitFundingPopup.Start_Date')}  : <span>{data.startDate ? window.$utils.dateFormatDDMMYYYY(data.startDate) : ''}</span>
                                        </p>
                                        <p>
                                        {t('SplitFundingPopup.End_Date')}  : <span>{data.endDate ? window.$utils.dateFormatDDMMYYYY(data.endDate) : ''}</span>
                                        </p>
                                    </button>
                                </li>
                                )
                            })}
                           
                        </ul>
                        {plandetials !== undefined && <>
                            <div className="tab-content tab-custom-height tab-list-content" id="myTabContent">
                            <div className="tab-pane fade show active" id="plan0" role="tabpanel" aria-labelledby="tab-plan1">
                            
                                <div className="d-flex">
                                    <div className="d-flex me-4">
                                        <label className="me-1">{t('SplitFundingPopup.Weekly_charge')}:</label>
                                        <span className="bolder">£ {plandetials?.dailyCoreFeeType === coreFeesType.Weekly ? plandetials?.dailyCoreFee : (plandetials?.dailyCoreFee * 7).toFixed(2)}</span>
                                    </div>
                                    
                                </div>

                                <table className="table prev-plan-table mt-3 mb-0">
                                    <tbody>
                                    <tr>
                                        <th >{t('SplitFundingPopup.Funder_Type')}</th>
                                        <th style={{ width: '38%' }}>{t('SplitFundingPopup.Funder_Name')}</th>
                                        <th style={{ width: '17%' }}>{t('SplitFundingPopup.Proportion_Value')}</th>
                                        <th  style={{ width: '8%' }}>{t('SplitFundingPopup.IPA')}</th>
                                        <th>{t('SplitFundingPopup.IsApplicable')}</th>
                                        <th>{t('SplitFundingPopup.Autopay')}</th>
                                    </tr>
                                    {plandetials?.planDetails?.length > 0 && plandetials?.planDetails?.map((plans: any, i: number)=>{
                                    return (
                                        <tr>
                                        <td>{plans.funderType === 1 ? "ICB" :plans.funderType === 2 ? "LA" : plans.funderType === 3 ? "Charity" : "Self"}</td>
                                        <td>{plans.funderName}</td>
                                        <td>£ {plans.proportionValue}</td>
                                        <td><a href="#" className='btn-icon btn-secondary' data-tooltip-id="my-tooltip-view" data-tooltip-content="View"><i className="fa-solid fa-eye"></i></a></td>
                                        <td>{plans.isApplicable === 1 ? `${t('SplitFundingPopup.Yes')}`:`${t('SplitFundingPopup.No')}`}</td>
                                    <td>{plans.isAutoPay === 1 ? `${t('SplitFundingPopup.Yes')}`:`${t('SplitFundingPopup.No')}`}</td>
                                    </tr>
                                    )
                                })}
                                    </tbody>
                                </table>
                            </div>
                            
                        </div>
                        </>}
                                
                        {props.totalCount > 4 && <>
                        <p className='purple-text mt-3'>{t('SplitFundingPopup.Note')}</p>
                        </>}

                        <Tooltip className="my-tooltip tooltip-custom-width1" id="my-tooltip-view" />
                    </div>
                    </>}
                    </div>
                }
            />
        </div>
    );
};

export default PlanHistoryView;

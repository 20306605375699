import { Tooltip } from 'react-tooltip';
// import AccreditationsContainer from '../Accreditations/AccreditationsContainer';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';
import DescribeYourFacilityContainer from '../DescribeYourFacility/DescribeYourFacilityContainer';
import FaceProfileContainer from '../FaceProfile/FaceProfileContainer';
import FacilitiesRegiCategoryContainer from '../FacilitiesRegiCategory/FacilitiesRegiCategoryContainer';
import GalleryContainer from '../Gallery/GalleryContainer';
import OnboardLocationContainer from '../OnboardLocation/OnboardLocationContainer';
import QuestionanswersContainer from '../Question-answers/QuestionanswersContainer';
import SetupWardContainer from '../SetupWard/SetupWardContainer';
import WardNumberContainer from '../WardNumber/WardNumberContainer';
import ProgressFooterContainer from './ProgressFooter/ProgressFooterContainer';
import ProgressHeaderContainer from './ProgressHeader/ProgressHeaderContainer';
// import { ONBOARDING_STAGES } from '../../../utils/constant';
import AccreditationsContainer from '../Accreditations/AccreditationsContainer';
import NameWardsContainer from '../NameWards/NameWardsContainer';
import ServiceOfferView from '../ServiceOffer/ServiceOfferView';

const OnboardingParentView = () => {
    const { stageDescription, stageHeading, stageNumber } = useSelector((state: RootState) => state.onboardingProgress);
    const { wardFacility } = useSelector((state: RootState) => state.onboardingWardFacility);

    const stageRef = useRef<any>();

    const getStageLayout = () => {
        switch (stageNumber) {
            case 1:
                return <FacilitiesRegiCategoryContainer ref={stageRef} />;
            case 2:
                return <WardNumberContainer ref={stageRef} />;
            case 3:
                return <NameWardsContainer ref={stageRef} />
            case 4:
                return <SetupWardContainer ref={stageRef} />;
            case 5:
                return <OnboardLocationContainer ref={stageRef} />;
            case 6:
                return <DescribeYourFacilityContainer ref={stageRef} />;
            case 7:
                return <FaceProfileContainer ref={stageRef} />;
            case 8:
                return <ServiceOfferView ref={stageRef} />;
            case 9:
                return <AccreditationsContainer ref={stageRef} />;
            case 10:
                return <GalleryContainer ref={stageRef} />;
            case 11:
                return <QuestionanswersContainer ref={stageRef} />;
            default:
                return <>Please go to a valid stage</>;
        }
    };


    return (
        <div className="getlocated-body onboarding-bg-gray service-detail-padding">
            <div className="container-fluid p-0">
                <div className="getlocated-section smallPadding">
                    <ProgressHeaderContainer />

                    {getStageLayout()}

                    {/* <ProgressFooterContainer isValidated={isValidated} backActionHandler={backActionHandler} nextActionHandler={nextActionHandler} getStageStatus={getStageStatus}/> */}
                    <ProgressFooterContainer stageRef={stageRef} />
                    <Tooltip id="my-tooltip" className="my-tooltip" />
                </div>
            </div>
        </div>
    );
};

export default OnboardingParentView;

import React from 'react';
import FaceProfileView from './FaceProfileView';

const FaceProfileContainer = React.forwardRef((props, ref) => {
    return (
        <div>
            <FaceProfileView {...props} ref={ref}/>
        </div>
    );
});

export default FaceProfileContainer;
import React, { useState, useCallback, useEffect } from 'react';
import wifiIcon from '../../../assets/images/wifi.svg';
import TvIcon from '../../../assets/images/tv.svg';
import kitchenIcon from '../../../assets/images/Kitchenette.svg';
import washerIcon from '../../../assets/images/washing-machine.svg';
import freeParkingIcon from '../../../assets/images/car.svg';
import paidParkingIcon from '../../../assets/images/parking.svg';
import { Tooltip } from 'react-tooltip';
import { useLocation } from 'react-router-dom';
import swal from 'sweetalert';
import warning from '../../../assets/images/warning.png';
import { useTranslation } from 'react-i18next';

// Define types
interface Amenity {
    amenityId: number;
    amenityName: string;
    amenityIcon: string;
}

interface AmenityCategory {
    amenityCategoryId: number;
    amenityCategoryName: string;
    amenityCategoryIcon: string;
    amenities: Amenity[];
}

interface WardCardProps {
    bedId: number;
    bedName: string;
    amenities: Amenity[];
    handleModal: (bedId: number, wardId: number) => void;
    handleDelete: (bedId: number) => void;
    handleBedNameChange: (bedId: number, newName: string) => void;
    handleUpdateAmenities: (bedId: number, wardId: number, amenities: Amenity[], isDeletingAmenity?: boolean, deletedAmenityName?: string) => void;
    wardId: string;
    categories: AmenityCategory[];
    showWarning: (message: string, success?: boolean) => void;
    showBedDeleteIcon: boolean;
    bedPrefix: string;
    wardName: string;
    index: number
}

const WardCard: React.FC<WardCardProps> = React.memo(({ bedId, bedName, amenities, handleModal, handleDelete, handleBedNameChange, handleUpdateAmenities, wardId, categories, showWarning, showBedDeleteIcon, bedPrefix, wardName, index }) => {
    const { t } = useTranslation();
    const location = useLocation();

    const [isEditing, setIsEditing] = useState(false);
    const [editedBedName, setEditedBedName] = useState(bedName);
    const [isFinalScreen, setIsFinalScreen] = useState(false);

    useEffect(() => {
        setEditedBedName(bedName);
    }, [bedName]);

    useEffect(() => {
        if (location.pathname.includes('preview') || location.pathname.includes('publish')) {
            setIsFinalScreen(true);
        } else {
            setIsFinalScreen(false);
        }
    }, [location]);

    const icons: { [key: string]: string } = {
        wifi: wifiIcon,
        tv: TvIcon,
        kitchen: kitchenIcon,
        washer: washerIcon,
        freeParking: freeParkingIcon,
        paidParking: paidParkingIcon
    };

    const restrictedCategories = ['Outdoors', 'Activities', 'Leisure & Well-being'];

    const handleEditClick = useCallback(() => {
        setIsEditing(true);
    }, []);

    const handleInputChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.value.length <= 100) {
            setEditedBedName(e.target.value);
        } else {
            showWarning(t("OnboardingSetupWard.Bed_Prefix_Length_Warning")!)
        }
    }, []);

    const handleInputBlur = useCallback(() => {
        setIsEditing(false);
        if(bedName.length <= 100){
            // if (editedBedName !== bedName) {
            //     handleBedNameChange(bedId, editedBedName);
            // }
            if (editedBedName !== '') {
                handleBedNameChange(bedId, editedBedName);
            } else {
                handleBedNameChange(bedId, getBedNameWithPrefix());
                setEditedBedName(getBedNameWithPrefix())
            }
        } else {
            showWarning(t("OnboardingSetupWard.Bed_Prefix_Length_Warning")!)
        }
    }, [editedBedName, bedId, bedName, handleBedNameChange]);

    const handleKeyDown = useCallback(
        (e: React.KeyboardEvent<HTMLInputElement>) => {
            if (e.key === 'Enter') {
                handleInputBlur();
            }
        },
        [handleInputBlur]
    );

    const truncateText = (text: string, length: number) => {
        return text.length > length ? text.substring(0, length) + '...' : text;
    };

    const truncatedBedName = truncateText(editedBedName, 10);

    const handleAmenityClick = (amenity: Amenity) => {
        swal({
            title: '',
            text: `Do you want to remove ${amenity?.amenityName} from ${bedName}?`,
            icon: warning,
            buttons: ['No', 'Yes'],
            dangerMode: true
        }).then(async (confirm: any) => {
            if (confirm) {
                handleConfirmRemove(amenity);
            }
        });
    };

    const handleConfirmRemove = (amenity: Amenity) => {
        if(amenities.length === 1) {
            showWarning("One bed requires at least one amenity.")
            return;
        }
        if (amenity) {
            const updatedAmenities = amenities.filter((a) => a.amenityId !== amenity.amenityId);
            handleUpdateAmenities(bedId, parseInt(wardId), updatedAmenities, true, amenity.amenityName);
        }
    };

    const filteredAmenities = amenities.filter((amenity) => {
        const category = categories.find((category) => category.amenities.some((a) => a.amenityId === amenity.amenityId && !restrictedCategories.includes(category.amenityCategoryName)));
        return category !== undefined;
    });

    const getShortBedName = useCallback((wardName: string) => {
        const initials = wardName
            .split(' ')
            .map((word) => word.charAt(0))
            .join('');
        return initials;
    }, []);

    const getBedNameWithPrefix = () => {
        if (bedPrefix) {
            return `${bedPrefix}-${index + 1}`;
        } else {
            return `${getShortBedName(wardName)}-${index + 1}`;
        }
    };

    return (
        <div className="setup-card-item">
            <div className="ward-card classic-card no-hover">
                <h5 className="ward-card-h5 d-flex align-items-center">
                    {isEditing ? (
                        <input
                            type="text"
                            value={editedBedName}
                            onChange={handleInputChange}
                            onBlur={handleInputBlur}
                            onKeyDown={handleKeyDown}
                            className="form-control form-control-sm w-50 textinput-edit"
                            autoFocus
                        />
                    ) : (
                        <span data-tooltip-id="bed-name-tooltip" data-tooltip-content={editedBedName} data-tooltip-place="top">
                            {truncatedBedName}
                        </span>
                    )}
                    <span className="editBedName ms-2 cursor-pointer" onClick={handleEditClick} data-tooltip-id="edit-bed-tooltip" data-tooltip-content="Edit Bed">
                        <i className="fa-regular fa-pen-to-square"></i>
                    </span>
                </h5>
                <div className="ward-allocations">
                    <div className="add-amenities-button mb-3">
                        <div onClick={() => handleModal(bedId, parseInt(wardId))}>
                            <button className="classic-btn add-amenities-btn">
                                <span>{isFinalScreen ? 'Edit Amenities' : 'Add Amenities'}</span>
                            </button>
                        </div>
                    </div>
                    <div className="ward-selection">
                        <ul>
                            {filteredAmenities.map((amenity, index) => (
                                <li key={index} className="hasItem cursor-pointer">
                                    <div
                                        className="selected-amenity"
                                        data-tooltip-id="my-tooltip"
                                        data-tooltip-content={amenity.amenityName}
                                        data-tooltip-place="top"
                                        onClick={() => handleAmenityClick(amenity)}
                                    >
                                        <div className="selected-amenity-icon">
                                            <img src={amenity.amenityIcon || wifiIcon} alt='' className="img-fluid" />
                                        </div>
                                    </div>
                                </li>
                            ))}
                            {filteredAmenities.length < 16 ?
                            Array.from({ length: 16 - filteredAmenities.length }, (_, i) => (
                                <li key={`empty-${i}`}></li>
                            )) 
                            : Array.from({
                                length: Math.ceil(filteredAmenities.length / 4) * 4 - filteredAmenities.length
                            }, (_, i) => (
                                <li key={`empty-${i}`}></li>
                            ))}
                        </ul>
                    </div>
                    <div className={`delete-ward ${showBedDeleteIcon ? '': 'hidden'}`}>
                        <button
                            className="classic-btn delete-button"
                            data-tooltip-id="my-tooltip"
                            data-tooltip-content="Delete Bed"
                            data-tooltip-place="top"
                            onClick={() => handleDelete(bedId)}
                        >
                            <span>
                                <i className="fa-solid fa-xmark"></i>
                            </span>
                        </button>
                    </div>
                </div>
            </div>
            <Tooltip id="bed-name-tooltip" />
            <Tooltip id="edit-bed-tooltip" />
        </div>
    );
});

export default WardCard;

import React from 'react';
import GalleryView from './GalleryView';

const GalleryContainer = React.forwardRef((props, ref) => {
    return (
        <div>
            <GalleryView {...props} ref={ref} />
        </div>
    );
});

export default GalleryContainer;

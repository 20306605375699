"use client";
import React, { useEffect, useImperativeHandle, useRef, useState } from "react";
import { getOnboardingQuestionAndAnswers, saveOnboardingQuestionAndAnswers } from "../../../service/onboarding-service";
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store";
import { setOnboardingPageValidity } from "../../../store/onboarding/onboardingPageValidityReducer";
// import ProgressFooterView from '../ProgressFooter/ProgressFooterView';

const QuestionanswersView = React.forwardRef((props, ref) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const {serviceId, serviceName} = useSelector((state: RootState) => state.onboardingService);
    const [questions, setQuestions] = useState<any>([]);
    const [openAccordian, setAccordian] = useState<number | null>(null);
    const [isValid,setValid] = useState<boolean>(false)

    useImperativeHandle(ref, () => ({
        isValidated: isValid,
        backActionHandler: () => {
            saveData();
        },
        nextActionHandler: () => {            
            saveData();  
        },
        triggerValidation: async () => {
           
        }
    }));

    useEffect(()=>{
        dispatch(setOnboardingPageValidity(isValid))
    }, [isValid])

    useEffect(() => {   
        getData();
    }, []);

    const getData = async () => {
        const response = await getOnboardingQuestionAndAnswers(serviceId!);
        if (response.status === true) {
            setQuestions(response?.data?.map((item: any) => ({
                questionText: item?.questionText,
                answerText: item?.answerText !== null ? item?.answerText : "",
                isChecked: item?.isChecked,
                questionId : item?.questionId,
                answerId :item?.answerId
            })));
            const hasChecked = response?.data?.some((item: any) => item?.isChecked === true);
            setValid(hasChecked);
        }
    };

    const handleCheckboxChange = (index: any) => {
        const updatedQuestions = [...questions];
        if (updatedQuestions[index].answerText.trim() !== "") {
            updatedQuestions[index].isChecked = !updatedQuestions[index].isChecked;
            setQuestions(updatedQuestions);
            setValid(true)
        } else {
           setValid(false)
        }
    };

    const handleTextareaChange = (index: any, event: any) => {
        const updatedQuestions = [...questions];
        updatedQuestions[index].answerText = event.target.value;
        if (event.target.value.trim() === "") {
            updatedQuestions[index].isChecked = false;
            setValid(false)
        }
        setQuestions(updatedQuestions);
    };
    const toggleAccordion = (index: number) => {
        setAccordian(openAccordian === index ? null : index);
    };

    const saveData = async ()=>{
        const payload = {
            serviceId: serviceId!,
            answers: questions
          };
          if(isValid){
              await saveOnboardingQuestionAndAnswers(payload);
              getData();
          }
    }
    return (
        <div>
             <div className="question-answer-accordion pb-115">
                        <div className="accordion qa-accordion" id="accordionExample">
                            {questions && questions?.length > 0 && questions?.map((item: any, index: any) => {
                                return (
                                    <div key={index} className="accordion-item d-flex">
                                        <div className="me-3 selection-box neumorphic-checkbox">
                                            <input
                                                type="checkbox"
                                                className="question-select form-check-input"
                                                checked={item.isChecked}
                                                onChange={() => handleCheckboxChange(index)}
                                            />
                                        </div>
                                        <div className="w-100">
                                            <h2 className="accordion-header" id="headingOne">
                                                <button 
                                                    type="button" 
                                                    data-bs-toggle="collapse" 
                                                    data-bs-target="#collapseOne"
                                                    className={`accordion-button ${openAccordian === index ? '' : 'collapsed'}`}
                                                    onClick={() => toggleAccordion(index)}
                                                    aria-expanded={openAccordian === index}
                                                    aria-controls={`collapse-${index}`}
                                                >
                                                    {item?.questionText}
                                                </button>
                                            </h2>
                                            <div
                                                id={`collapse-${index}`}
                                                className={`accordion-collapse collapse ${openAccordian === index ? 'show' : ''}`}
                                                aria-labelledby={`heading-${index}`}
                                            >
                                                <div className="accordion-body">
                                                    <textarea
                                                        className="form-control answer-textarea"
                                                        value={item.answerText}
                                                        onChange={(event) => handleTextareaChange(index, event)}
                                                        placeholder={`${t('OnboardingQuestionAndAnswer.Answer_Textarea_Placeholder')}`}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
            {/* <ProgressFooterView /> */}
        </div >
    );
});

export default QuestionanswersView;
import swal from 'sweetalert';
import React, { ChangeEvent, DragEvent, useCallback, useEffect, useMemo, useRef, useState } from "react";
// import OwlCarousel from "react-owl-carousel";
// import "owl.carousel/dist/assets/owl.carousel.css";
// import "owl.carousel/dist/assets/owl.theme.default.css";
import userlogo1 from "../../../assets/images/userlogo-1.png";
import userlogo2 from "../../../assets/images/userlogo-2.jpg";
import userlogo3 from "../../../assets/images/userlogo-3.jpg";
import userlogo4 from "../../../assets/images/userlogo-4.jpg";
import uploadLogo from "../../../assets/images/upload.svg";
// import ProgressFooterView from '../ProgressFooter/ProgressFooterView';
import ClientPortal from "../../../components/ClientPortal";
import { BEConfigureInterface, BEServiceLogoInterface, ConfigureUserNameInterface, FEConfigureInterface, FEServiceLogoInterface, OrganizationListResponse, OrganizationServiceListPayload, OrganizationServiceListResponse, ServicesInterface, WelcomeViewProps } from "./types";
import { GetOrganizationServiceList, NewUserAssigningService, UploadServiceLogo, getConfigureUserNameEmail, getSiteUsername } from "../../../service/onboarding-service";
import { ApiResponse } from "../../../utils/model";
import { MAX_FILE_SIZE_IN_MB, ProfileStatus, VerificationStatus } from "../../../utils/constant";
import { debounce, first } from "lodash";
import { Controller, useForm } from "react-hook-form";
import { CropperRef, Cropper, FixedCropper, ImageRestriction } from 'react-advanced-cropper';
import * as Yup from 'yup';
import 'react-advanced-cropper/dist/style.css';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch, useSelector } from 'react-redux';
import { setOnboardingProgress } from '../../../store/onboarding/onboardingProgressReducer';
import { useNavigate } from 'react-router';
import { ROUTES_DATA } from '../../../navigation/RoutesConstants';
import { setOnboardingService } from '../../../store/onboarding/onboardingServiceReducer';
import imageCompression from 'browser-image-compression';
import { RootState } from '../../../store';
import { setOnboardingIsPublished } from '../../../store/onboarding/onboardingPublishedReducer';
import { setOnboardingWardFacility } from '../../../store/onboarding/onboardingWardFacilityReducer';
import useComponentVisible from '../../../hooks/useComponentVisible';
import { useTranslation } from 'react-i18next';
import Autocomplete from '@mui/material/Autocomplete';
import { TextField } from '@mui/material';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';

const configureInitialValue: FEConfigureInterface = {
    UserName: "",
    Email: "",
    Password: "",
    ConfirmPassword: ""
}

const WelcomeView = ({ organizationList }: WelcomeViewProps) => {

    const settings = {
        dots: false,
        infinite: false,
        speed: 800,
        slidesToShow: 3,
        slidesToScroll: 2,
        arrows: true,
        adaptiveHeight: true,
    };

    const { t } = useTranslation();
    const { onboardingStages } = useSelector((state: RootState) => state.onboardingProgressStages);

    const [hasMore, setHasMore] = useState<boolean>(true);
    const [selectedTabPanel, setSelectedTabPanel] = useState<number>(1);
    const [showPassPolicy, setShowPassPolicy] = useState<boolean>(false);
    const [selectedService, setSelectedService] = useState<ServicesInterface | null>(null);
    // const [orgServiceList, setOrgServiceList] = useState<OrganizationServiceListResponse | null>(null);
    const [orgServiceArr, setOrgServiceArr] = useState<ServicesInterface[]>([]);
    const [orgServicePayload, setOrgServicePayload] = useState<OrganizationServiceListPayload>({
        orgId: "",
        searchValue: "",
        serviceLogo: "",
        page: 1,
        pageSize: 10,
    });

    const [showPortal, setShowPortal] = useState(false);
    const [matchedOrg, setMatchedOrg] = useState<number[]>([]);
    const onboardingServices = useSelector((state: RootState) => state.onboardingService);
    const [isDragging, setIsDragging] = useState(false);
    const [isConfigureUserLoading, setIsConfigureUserLoading] = useState(false);
    const [nonSearchData, setNonSearchData] = useState<any>([]);
    const croppedImg = useRef<string | File>("");

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const configureUserValidation = Yup.object().shape({
        UserName: Yup.string().required(),
        Email: Yup.string().trim()
            .matches(/^$|^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/, "Email is Invalid")
            .email('Email is Invalid')
            .required(),
        // Password: Yup.string().required('Please Enter your password.')
        //     .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/, 'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character'),
        // ConfirmPassword: Yup.string().required('Please Enter your confirm password.')
        //     .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/, 'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character')
        //     .oneOf([Yup.ref("Password")], "Passwords do not match"),

        Password: Yup.string().when('Email', {
            is: (email: string) => (siteUsernameList?.find(option => option.email === email) ? true : false),
            then: (schema: any) => schema.notRequired(),
            otherwise: (schema: any) =>
                schema
                    .required('Please Enter your password.')
                    .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/, { message: 'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character', excludeEmptyString: true})
        }),
        ConfirmPassword: Yup.string().when('Email', {
            is: (email: string) => (siteUsernameList?.find(option => option.email === email) ? true : false),
            then: (schema: any) => schema.notRequired(),
            otherwise: (schema: any) =>
                schema
                    .required('Please Enter your confirm password.')
                    .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/, { message: 'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character', excludeEmptyString: true})
                    .oneOf([Yup.ref("Password")], "Passwords do not match")
        }),
    });

    const { register, control, handleSubmit, setValue, getValues, watch: configureWatch, formState: { errors, isDirty, isValid }, reset: resetConfigureUser } = useForm<FEConfigureInterface>({
        defaultValues: configureInitialValue,
        resolver: yupResolver(configureUserValidation),
        mode: "all",
    });

    const { handleSubmit: logoSubmit, watch, setValue: setLogo, getValues: getLogoValue, formState: { isDirty: isImageDirty } } = useForm<FEServiceLogoInterface>({
        defaultValues: { logo: "" },
        mode: "all"
    });

    const values = watch();

    const scrollContainerRef = useRef<HTMLDivElement | null>(null);
    const logoInputRef = useRef<HTMLInputElement>(null);
    const handleModal = async (event: any, service?: ServicesInterface) => {
        event.preventDefault();
        if (service) {
            setSelectedService(service);

            const res: ApiResponse<ConfigureUserNameInterface> = await getConfigureUserNameEmail(service.serviceId);
            if (res.data) {
                setValue("Email", res.data.email);
                setValue("UserName", res.data.userName);
                setValue("Password", "");
                setValue("ConfirmPassword", "");
                delayedQueryForUsername(res.data.userName);
            }
        } else {
            resetConfigureUser(getValues())
            setValue("Email", "");
            setValue("UserName", "");
            setValue("Password", "");
            setValue("ConfirmPassword", "");
        }
        setShowPortal((prevState) => !prevState);
    };

    const handlePolicyModal = (e: any) => {
        e.preventDefault();
        setShowPassPolicy((prev) => !prev);
    }

    useEffect(() => {
        if (showPortal) {
            document.body.classList.add("modal-open");
        } else {
            document.body.classList.remove("modal-open");
        }
        return () => {
            document.body.classList.remove("modal-open");
        };
    }, [showPortal]);

    const [showSecondPortal, setShowSecondPortal] = useState(false);

    const handleSecondModal = async (event: any, service?: ServicesInterface) => {
        event.preventDefault();

        if (service) {
            setSelectedService(service);
            if (service.logopath) {
                setLogo("logo", service.logopath);
            } else {
                setLogo("logo", "");
            }


        } else {
            setLogo("logo", "");
        }

        setShowSecondPortal((prevState) => !prevState);

    };

    useEffect(() => {
        if (showSecondPortal) {
            document.body.classList.add("modal-open");
        } else {
            document.body.classList.remove("modal-open");
        }
        return () => {
            document.body.classList.remove("modal-open");
        };
    }, [showSecondPortal]);

    // const carouselRef = useRef<OwlCarousel>(null);

    const [loading, setLoading] = useState(true);
    
    const fetchOrganizationServiceList = async (payload: OrganizationServiceListPayload, reset = false) => {
        setLoading(true);      
        const res: ApiResponse<OrganizationServiceListResponse> = await GetOrganizationServiceList(payload);
        
        if (res.status) {
            setOrgServiceArr((prev) => {
                if (payload.searchValue && payload.page === 1) {
                    return res.data.services; 
                }
                if (payload.searchValue && payload.page > 1) {
                    return [...prev, ...res.data.services]; 
                }
                if (!payload.searchValue && payload.page > 1) {
                    return reset ? [...res.data.services] : [...prev, ...res.data.services];
                }
                if (payload.searchValue && payload.page === 1) {
                    return reset ? [...res.data.services] : [...prev, ...res.data.services];
                }
                return [...res.data.services]; 
            });
            setMatchedOrg(res.data.matchingOrganizations); 
            const totalLoaded = ((payload.page - 1) * orgServicePayload.pageSize) + res.data.services.length; 
            const remainingItems = res.totalCount - totalLoaded; 
            setHasMore(remainingItems > 0);
        }
        setLoading(false); 
    };
    
    const handleScroll = () => {
        const container = scrollContainerRef.current;
        if (!container || !hasMore || loading) return; 
    
        const { scrollTop, scrollHeight, clientHeight } = container;
        if ((scrollTop + clientHeight >= scrollHeight - 20) && hasMore) {
            fetchOrganizationServiceList({ ...orgServicePayload, page: orgServicePayload.page + 1});
            setOrgServicePayload((prev) => ({ ...prev, page: prev.page + 1 }));
        }
    };

    useEffect(() => {
        const payload: OrganizationServiceListPayload = {
            ...orgServicePayload,
            orgId: String(selectedTabPanel),
        };

        setOrgServicePayload(payload);
        fetchOrganizationServiceList(payload);
    }, []);

    const handleTabClick = useCallback(async (org: OrganizationListResponse) => {
        setSelectedTabPanel(org.organizationId);

        const payload: OrganizationServiceListPayload = {
            ...orgServicePayload,
            orgId: String(org.organizationId),
            page: 1,
            pageSize: 10
        };

        setOrgServiceArr([]);
        setOrgServicePayload(payload);
        fetchOrganizationServiceList(payload);
    }, [orgServicePayload]);

    const delayedQuery = useCallback(debounce((payload: OrganizationServiceListPayload) => {
        fetchOrganizationServiceList(payload)
    }, 500), []);

   
    const handleServiceSearch = (e: ChangeEvent<HTMLInputElement>) => {
        const searchValue = e.target.value;
        const payload: OrganizationServiceListPayload = {
            ...orgServicePayload,
            searchValue,
            page: 1, 
        };
    
        setOrgServicePayload(payload);
    
        if (searchValue.length === 0) {
            setOrgServiceArr([]);
            fetchOrganizationServiceList({ ...orgServicePayload, searchValue: "", page: 1 }, true); 
            return;
        }
    
        if (searchValue.length < 3) return;
        delayedQuery(payload);
    };

    // Function to convert base64 string to File object
    const base64ToFile = (base64String: string, filename: string) => {
        // Split the base64 string into data type and actual base64 data
        const [header, data] = base64String.split(',');
        const mimeType = header?.match(/:(.*?);/)?.[1]; // Extract MIME type from the header

        // Decode base64 data to binary
        const binaryString = atob(data);
        const len = binaryString.length;
        const bytes = new Uint8Array(len);

        // Convert binary string to array of bytes
        for (let i = 0; i < len; i++) {
            bytes[i] = binaryString.charCodeAt(i);
        }

        // Create a Blob from the bytes
        const blob = new Blob([bytes], { type: mimeType });

        // Convert Blob to File
        return new File([blob], filename, { type: mimeType });
    };

    const handleConfigureSubmit = useCallback(async (data: FEConfigureInterface) => {
        const payload: BEConfigureInterface = {
            Email: data.Email,
            Password: data.Password,
            UserName: data.UserName,
            OrgId: Number(orgServicePayload.orgId),
            ServiceId: selectedService ? selectedService?.serviceId : -1
        }
        const res: ApiResponse<null> = await NewUserAssigningService(payload);
        if (res.status) {
            swal(res.message, {
                icon: 'success',
            }).then(async (confirm: any) => {
                if (confirm) {
                    setShowPortal(false);
                    fetchOrganizationServiceList(orgServicePayload);
                }
            });
        } else {
            swal(res.message, {
                icon: 'error',
            })
        }
    }, [selectedService, orgServicePayload]);

    const getImageUrl = useCallback((img: string | File | FileList): string => {
        if (typeof img === "string") {
            return img;
        } else if (img instanceof File) {
            return URL.createObjectURL(img);
        } else if (img instanceof FileList && img.length > 0) {
            return URL.createObjectURL(img[0]);
        }
        return "";
    }, [values.logo]);



    const getImageFiletype = (img: string | File | FileList) => {
        if (typeof img === "string") {
            return true;
        } else {
            return false
        }
    }


    const handleServiceLogoSubmit = async () => {
        const formData = new FormData();
        const file = base64ToFile(croppedImg.current as string, "logo");

        const options = {
            maxSizeMB: 5,
            useWebWorker: true
        };
        const compressedBlob = await imageCompression(file, options);
        const compressedFile = new File([compressedBlob], file.name, { type: file.type });

        formData.append("serviceLogo", compressedFile);
        formData.append("serviceId", String(selectedService?.serviceId))

        const res: ApiResponse<null> = await UploadServiceLogo(formData);
        if (res.status) {
            swal(res.message, {
                icon: 'success',
            }).then(async (confirm: any) => {
                if (confirm) {
                    setShowSecondPortal((prevState) => !prevState);
                    fetchOrganizationServiceList(orgServicePayload);
                }
            });
        } else {
            swal(res.message, {
                icon: 'error',
            });
        }
    };

    const handleImageCropChange = (cropper: CropperRef) => {
        const lCroppedImg = cropper?.getCanvas()?.toDataURL();
        if (lCroppedImg) {
            croppedImg.current = lCroppedImg;
        }
    };

    const { DataLogin } = useSelector((state: RootState) => state.login);

    const name = useMemo(() => {
        const [firstName] = DataLogin?.userModel?.userName?.split(" ");
        return firstName;
    }, []);

    const onSetupClick = (service: ServicesInterface) => {
        const { serviceId, serviceName, stepId, siteUserName, completion, isPublished, isWardFacility, slugName } = service;
        dispatch(setOnboardingService({ serviceId, serviceName, userName: siteUserName ?? name, slugName }))
        dispatch(setOnboardingWardFacility(isWardFacility))
        const currentStep = onboardingStages.find((value: any) => value.stageNumber === stepId);
        dispatch(setOnboardingIsPublished(false));
        if (completion === 100) {
            if (isPublished === 1) {
                navigate(ROUTES_DATA.ONBOARDINGPREVIEW);
                dispatch(setOnboardingIsPublished(true));
                return
            }

            navigate(ROUTES_DATA.ONBOARDINGPREVIEW);
            return;
        }
        if (currentStep) {
            dispatch(setOnboardingIsPublished(false));
            if (stepId === 1 && completion === 0) {
                dispatch(setOnboardingProgress({ stageDescription: undefined, stageHeading: undefined, stageNumber: undefined }));
                dispatch(setOnboardingService({ serviceId, serviceName, userName: siteUserName ?? name, slugName }))
                navigate(ROUTES_DATA.ONBOARDING);
                return
            }
            dispatch(setOnboardingProgress(currentStep));
            dispatch(setOnboardingService({ ...onboardingServices, serviceId, serviceName, userName: siteUserName ?? name, slugName }))
            navigate(ROUTES_DATA.ONBOARDING);
        }
    };

    const [siteUsernameList, setSiteUserNameList] = useState<ConfigureUserNameInterface[]>([]);
    const { ref } = useComponentVisible(true);

    const apiCallForUsernameSearch = async (username: string) => {
        setIsConfigureUserLoading(true);
        const res: ApiResponse<ConfigureUserNameInterface[]> = await getSiteUsername(username);
        if (res.data?.length > 0) {
            setSiteUserNameList(res.data);
            setIsConfigureUserLoading(false);
        } else {
            setIsConfigureUserLoading(false);
        }
    }

    const handleDrop = (event: DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        const file = event.dataTransfer.files[0];

        if (file) {
            setLogo("logo", file, { shouldDirty: true });

            const reader = new FileReader();
            reader.onloadend = function () {
                const base64String = reader?.result;
                croppedImg.current = base64String as string;
            };
            reader.readAsDataURL(file);
        }

        setIsDragging(false);
    };

    const handleDragOver = (event: DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        setIsDragging(false);
    };


    const delayedQueryForUsername = useCallback(debounce((val: string) => apiCallForUsernameSearch(val), 500), []);

    const handleUploadClick = () => {
        if (logoInputRef.current) {
            logoInputRef.current.click();
        }
    };

    useEffect(() => {
        generateDynamicStyles()

        return () => {
            const styleElement = document.getElementById('dynamic-tab-styles');
            if (styleElement) {
                styleElement.remove();
            }
        };
    }, [organizationList])

    const generateDynamicStyles = () => {

        const style = document.createElement('style');
        style.id = 'dynamic-tab-styles';
        let dynamicStyles = '';

        const activeTabIndex = organizationList?.findIndex(tab => tab.organizationId === selectedTabPanel);
        const initialTranslateValue = activeTabIndex !== -1 ? activeTabIndex * 211 : 0;

        organizationList?.forEach((_, index) => {
            const translateValue = index * 211;
            dynamicStyles += `
                .group-tabs.glinderActive${index + 1} .slick-track:before {
                    transform: translateX(${translateValue}px);
                    transition: all ease 0.7s;
                }
            `;
        });

        dynamicStyles += `
            .group-tabs .slick-track:before {
                transform: translateX(${initialTranslateValue}px);
                transition: all ease 0.7s;
            }
        `;

        style.innerHTML = dynamicStyles;
        document.head.appendChild(style);
    };

    const handleTabSliding = (tabId: number) => {
        const tabIndex = organizationList?.findIndex((item) => (item.organizationId === tabId));
        const glider = document.querySelector('.group-tabs');

        if (tabIndex !== -1) {
            glider?.classList.remove(...Array.from({ length: organizationList?.length }, (_, i) => `glinderActive${i + 1}`));
            glider?.classList.add(`glinderActive${tabIndex + 1}`);
        }
    }

    useEffect(() => {
        if (organizationList) {
            handleTabSliding(selectedTabPanel)
        }
    }, [selectedTabPanel])

    return (
        <div className="getlocated-body onboarding-bg-gray service-detail-padding pb-115">
            <div className="container-fluid p-0">
                <div className="getlocated-section">
                    <div className="section-title-parent">
                        <h5 className="section-title"><span className="gradient-text">Welcome {name}!</span></h5>
                    </div>

                    <div className='steps-headline'>
                        <div className='steps-description text-left'>
                            <ol>
                                <li>This dashboard provides an overview of your care group and individual care home sites.</li>
                                <li>Here, you can easily manage and configure each site's profile, track their completion status, and assign site users to ensure everything runs smoothly.</li>
                                <li>Let’s take a closer look at your sites and start setting up or edit their profiles to highlight the excellent care services you offer.</li>
                            </ol>
                        </div>
                    </div>

                    <div className="smartSearch mb-4">
                        <input type="text" value={orgServicePayload?.searchValue} onChange={handleServiceSearch} className="form-control smartSearchField" placeholder="Search for the service" autoFocus />
                        <i className="fa fa-magnifying-glass"></i>
                    </div>

                    <div className="welcome-container gallery-tab mt-4">
                        <ul
                            className="nav-pills group-tabs welcome-slider mb-2"
                            id="group-pills-tab"
                            role="tablist"
                        >
                            {/* <OwlCarousel
                                ref={carouselRef}
                                className="owl-theme position-relative all-owl owl-carousel"
                                dots={false}
                                // margin={10}
                                key={organizationList?.length + Date.now()}
                                mouseDrag={false}
                                touchDrag={false}
                                nav={true}
                                smartSpeed={800}
                                items={3}
                            >
                                {organizationList?.length > 0 ? organizationList?.map((org, i) => (
                                    <li className="nav-item" key={org.organizationId} role="presentation">
                                        <button
                                            className={`nav-link ${org.organizationId === selectedTabPanel ? "active" : ""}`}
                                            id={`pills-group-${org.organizationId}`}
                                            data-bs-toggle="pill"
                                            data-bs-target={`#pills-group-${org.organizationId}-nav`}
                                            type="button"
                                            role="tab"
                                            aria-controls={`#pills-group-${org.organizationId}-nav`}
                                            aria-selected={org.organizationId === selectedTabPanel ? "true" : "false"}
                                            onClick={() => handleTabClick(org)}
                                            style={matchedOrg?.includes(org.organizationId) ? { color: "red", fontWeight: "bold" } : {}}
                                        >
                                            <span>{org.organizationName}</span>
                                        </button>
                                    </li>
                                )) : null}
                            </OwlCarousel> */}
                            <Slider {...settings}>
                                {organizationList?.length > 0 ? organizationList?.map((org, i) => (
                                    <div className="nav-item" key={org.organizationId} role="presentation">
                                        <button
                                            className={`nav-link ${org.organizationId === selectedTabPanel ? "active" : ""}`}
                                            id={`pills-group-${org.organizationId}`}
                                            data-bs-toggle="pill"
                                            data-bs-target={`#pills-group-${org.organizationId}-nav`}
                                            type="button"
                                            role="tab"
                                            aria-controls={`#pills-group-${org.organizationId}-nav`}
                                            aria-selected={org.organizationId === selectedTabPanel ? "true" : "false"}
                                            onClick={() => handleTabClick(org)}
                                            style={matchedOrg?.includes(org.organizationId) ? { color: "red", fontWeight: "bold" } : {}}
                                        >
                                            <span>{org.organizationName}</span>
                                        </button>
                                    </div>
                                )) : null}
                            </Slider>
                            {/* <span className="glider"></span> */}
                        </ul>
                        <div
                            className="tab-content bordered-tab-content"
                            id="pills-tabContent"
                        >
                            <div
                                className="tab-pane fade show active"
                                id={`#pills-group-${selectedTabPanel}-nav serviceList`}
                                role="tabpanel"
                                aria-labelledby={`pills-group-${selectedTabPanel}`}
                                tabIndex={0}
                                ref={scrollContainerRef}
                                style={{height: "525px", overflowY: "auto" }}
                                onScroll={handleScroll}
                            >
                                <div className="tab-table">
                                    <table className="table  table-responsive">
                                        <thead className="thead-light welcome-table-header-fixed">
                                            <tr>
                                                <th>Service Provider Name</th>
                                                <th>CQC Location ID</th>
                                                <th>Verification</th>
                                                <th>Profile Status</th>
                                                <th>Completion</th>
                                                <th>Site User</th>
                                                <th>Logo</th>
                                                <th>Set Up</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {orgServiceArr && orgServiceArr?.length > 0 ? orgServiceArr?.map((service, index) => (
                                                <tr key={index}>
                                                    <td>
                                                        <span className="table-row-number">{index + 1}</span>
                                                        <span className="ms-2 font-bold"><a href="#">{service.serviceName}</a></span>
                                                    </td>
                                                    <td>{service.cqcLocationId ? service.cqcLocationId : "-"}</td>
                                                    <td>
                                                        <span className={service.verificationStatus === VerificationStatus.COMPLETED ? 'text-success' : service.verificationStatus === VerificationStatus.IN_REVIEW ? 'text-primary' : 'text-warning'}>
                                                            {service.verificationStatus}
                                                        </span>
                                                    </td>
                                                    <td>{service.profileStatus}</td>
                                                    <td>{service.completion ? service.completion + "%" : "-"}</td>
                                                    <td className="text-underline">
                                                        <a
                                                            href="#"
                                                            className={!service.isConfigured ? 'light-blue-text' : ''}
                                                            onClick={(e) => handleModal(e, service)}
                                                            style={service.verificationStatus !== VerificationStatus.COMPLETED ? { pointerEvents: "none" } : {}}
                                                        >
                                                            {!service.isConfigured ? "Configure" : service.siteUserName}
                                                        </a>
                                                    </td>
                                                    <td className="text-center">
                                                        {service.logopath ? (
                                                            <div className="position-relative upload-file-parent">
                                                                <img src={service.logopath} alt="Logo" width="50" className='img-fluid upload-icon' style={service.verificationStatus !== VerificationStatus.COMPLETED ? { pointerEvents: "none" } : {}} onClick={(e) => handleSecondModal(e, service)} />
                                                                {/* <input type="file" className="upload-field" /> */}
                                                            </div>
                                                        ) : (
                                                            <div style={service.verificationStatus !== VerificationStatus.COMPLETED ? { pointerEvents: "none" } : {}} onClick={(e) => service.verificationStatus === VerificationStatus.COMPLETED && handleSecondModal(e, service)} className='cursor-pointer'>
                                                                <i className="fa-solid fa-cloud-arrow-up fa-xl"></i>
                                                            </div>
                                                        )}
                                                    </td>
                                                    <td>
                                                        <button
                                                            className={'btn btn-primary'}
                                                            onClick={() => onSetupClick(service)}
                                                            disabled={service.verificationStatus !== VerificationStatus.COMPLETED ? true : false}
                                                        >
                                                            {service.completion === 100 ? t('Service_Completion_BtnText.View_Edit') : t('Service_Completion_BtnText.Setup')}
                                                        </button>
                                                    </td>
                                                </tr>
                                            )) : (
                                                <p style={{ position: "relative", left: 50 }}>No data found.</p>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <ProgressFooterView /> */}

            <ClientPortal selector="myportal" show={showPortal}>
                <div className="overlay" onClick={handleModal}></div>
                <div className="modal userInfoModal">
                    <div className="header mb-4">
                        <h4 className="mb-0"></h4>

                        <a onClick={handleModal} className="removeButton">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                height="1em"
                                viewBox="0 0 384 512"
                            >
                                <path
                                    fill="#ffffff"
                                    d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"
                                />
                            </svg>
                        </a>
                    </div>
                    <div className="body ">
                        <p className="mb-3 text-center hanzipen-font-bold">Please fill in the below details to configure your site user.</p>
                        <form className="popup-box" onSubmit={handleSubmit(handleConfigureSubmit)}>
                            <div className="row">
                                <div className="mb-3" ref={ref}>
                                    <Controller
                                        name="UserName"
                                        control={control}
                                        render={({ field: { onChange, value, ref } }) => (
                                            <Autocomplete
                                                id="Username"
                                                loading={isConfigureUserLoading}
                                                options={siteUsernameList}
                                                size='small'
                                                value={siteUsernameList?.find(option => option.userName === value) ? siteUsernameList?.find(option => option.userName === value) : { email: getValues("Email"), userName: getValues("UserName") }}
                                                onChange={(_event, newValue) => {
                                                    if (newValue) {
                                                        setValue("UserName", newValue?.userName);
                                                        setValue("Email", newValue?.email, {shouldValidate: true});
                                                        delayedQueryForUsername(newValue?.userName);
                                                    } else {
                                                        setValue("UserName", "");
                                                        setValue("Email", "");
                                                    }
                                                    setValue("Password", "");
                                                    setValue("ConfirmPassword", "");
                                                }}
                                                getOptionLabel={(option) => option.userName || ''}
                                                renderOption={(props, option) => (
                                                    <li {...props}>
                                                      {`${option.userName}`}&nbsp;&nbsp;-&nbsp;&nbsp;{`${option.email}`}
                                                    </li>
                                                )}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        placeholder="User name"
                                                        value={configureWatch("UserName")}
                                                        onChange={(e) => {
                                                            const searchValue = e.target.value;
                                                            setValue("UserName", searchValue);
                                                            delayedQueryForUsername(searchValue);
                                                        }}
                                                        sx={{
                                                            background: "#ffffff",
                                                            paddingTop: "0",
                                                            height: '38px',
                                                            '& .MuiInputBase-root': {
                                                                height: '100%',
                                                            },
                                                        }}
                                                        InputLabelProps={{ shrink: false }}
                                                        inputRef={ref}
                                                    />
                                                )}
                                            />
                                        )}
                                    />
                                    {errors.UserName ? (
                                        <p style={{ fontSize: "12px", color: "red", marginTop: "3px", marginLeft: "5px" }}>{errors.UserName.message}</p>
                                    ) : null}
                                </div>
                                <div className="mb-3">
                                    <input {...register("Email")} className="form-control" placeholder="Email Address*" type="text" />
                                    {errors.Email ? (
                                        <p style={{ fontSize: "12px", color: "red", marginTop: "3px", marginLeft: "5px" }}>{errors.Email.message}</p>
                                    ) : null}
                                </div>
                                <div className="mb-3">
                                    <div style={{ display: "flex", alignItems: "center", justifyItems: "center" }}>
                                        <input {...register("Password")} 
                                            className="form-control" 
                                            placeholder={(siteUsernameList?.find(option => option.email === getValues("Email")) ? true : false) ? "Password": "Password*"} 
                                            type="password" 
                                            disabled={(siteUsernameList?.find(option => option.email === getValues("Email")) ? true : false)} />
                                        <span className="ms-2 cursor-pointer" onClick={handlePolicyModal}>
                                            <i className="fa-solid fa-circle-info"></i>
                                        </span>
                                    </div>
                                    {!(siteUsernameList?.find(option => option.email === getValues("Email")) ? true : false) && errors.Password ? (
                                        <p style={{ fontSize: "12px", color: "red", marginTop: "3px", marginLeft: "5px" }}>{errors.Password.message}</p>
                                    ) : null}
                                </div>
                                <div className="mb-3">
                                    <div style={{ display: "flex", alignItems: "center", justifyItems: "center" }}>
                                        <input {...register("ConfirmPassword")} 
                                            className="form-control" 
                                            placeholder={(siteUsernameList?.find(option => option.email === getValues("Email")) ? true : false) ? "Confirm Password": "Confirm Password*"} 
                                            type="password" 
                                            disabled={(siteUsernameList?.find(option => option.email === getValues("Email")) ? true : false)} />
                                    </div>
                                    {!(siteUsernameList?.find(option => option.email === getValues("Email")) ? true : false) && errors.ConfirmPassword ? (
                                        <p style={{ fontSize: "12px", color: "red", marginTop: "3px", marginLeft: "5px" }}>{errors.ConfirmPassword.message}</p>
                                    ) : null}
                                </div>
                            </div>
                            <div className="row mt-2">
                                <div className="d-flex justify-content-center">
                                    <button type="submit" disabled={!isValid} className="btn primary-btn classic-btn">Configure</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </ClientPortal>

            <ClientPortal selector="myportal" show={showPassPolicy} >
                <div className="overlay" onClick={handlePolicyModal}></div>
                <div className="modal userInfoModal" style={{ width: "300px" }}>
                    <div className="header mb-4">
                        <h4 className="mb-0"></h4>
                        <a onClick={handlePolicyModal} className="removeButton">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                height="1em"
                                viewBox="0 0 384 512"
                            >
                                <path
                                    fill="#ffffff"
                                    d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"
                                />
                            </svg>
                        </a>
                    </div>
                    <div className="body">
                        <p className="bold mb-2"> {t('Change_Password.Password_contain')}</p>
                        <ul className="ps-3">
                            <li>{t('Change_Password.capital')}</li>
                            <li>{t('Change_Password.small_letter')}</li>
                            <li>{t('Change_Password.special_character')}</li>
                            <li>{t('Change_Password.number')}</li>
                            <li>{t('Change_Password.characters')}</li>
                        </ul>
                    </div>
                </div>
            </ClientPortal>

            <ClientPortal selector="myportal" show={showSecondPortal}>
                <div className="overlay" onClick={handleSecondModal}></div>
                <div className="modal userInfoModal">
                    <div className="header">
                        <h4 className="mb-0"></h4>
                        <a onClick={handleSecondModal} className="removeButton">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                height="1em"
                                viewBox="0 0 384 512"
                            >
                                <path
                                    fill="#ffffff"
                                    d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"
                                />
                            </svg>
                        </a>
                    </div>
                    <div className="body">
                        <p className="mb-3 text-center hanzipen-font-bold">Please upload your logo image here</p>
                        <form onSubmit={logoSubmit(handleServiceLogoSubmit)} className="popup-box">
                            <div className="row">
                                <div className="col-md-12 ">
                                    <div className="img-upload-box mb-4 d-flex align-items-center justify-content-center"
                                        style={{
                                            border: "2px solid #ced4da"
                                        }}
                                        onDrop={handleDrop}
                                        onDragStart={() => setIsDragging(true)}
                                        onDragOver={handleDragOver}>
                                        <div className="text-center upload-logo-here">
                                            {values.logo && showSecondPortal ? (
                                                <div style={{ height: "200px", width: "300px", marginBottom: "20px" }}>
                                                    {!getImageFiletype(values.logo) ? <FixedCropper
                                                        src={getImageUrl(values.logo)}
                                                        stencilSize={{ width: 300, height: 300 }}
                                                        stencilProps={{
                                                            handlers: false,
                                                            lines: false,
                                                            movable: false,
                                                            resizable: false
                                                        }}
                                                        onChange={handleImageCropChange}
                                                        imageRestriction={ImageRestriction.stencil}
                                                        className={'cropper'}
                                                    /> : <img style={{ height: "200px", width: "300px", marginBottom: "20px" }} src={values.logo.toString()}></img>}
                                                </div>
                                            ) : (
                                                <>
                                                    <i className="fa-regular fa-image mb-3" onClick={handleUploadClick}></i>
                                                    <p className="mb-4">You can upload or drag & drop an image that is 300x300 pixels</p>
                                                </>
                                            )}
                                            <div className="position-relative upload-btn-field">
                                                <button className="btn classic-btn small-btn upload-btn">Upload from your computer</button>
                                                <input
                                                    type="file"
                                                    // {...registerLogo("logo")}
                                                    ref={logoInputRef}
                                                    className="upload-file-input"
                                                    onChange={(e) => {
                                                        if (e.target.files && e.target.files?.length > 0) {
                                                            // setImgModalVal(e.target.files?.[0]);
                                                            setLogo("logo", e.target.files?.[0], { shouldDirty: true });

                                                            const reader = new FileReader();
                                                            reader.onloadend = function () {
                                                                const base64String = reader?.result;
                                                                croppedImg.current = base64String as string;
                                                            };
                                                            reader.readAsDataURL(e.target.files?.[0]);
                                                        }
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="d-flex justify-content-center">
                                    <button className="btn primary-btn classic-btn" disabled={!isImageDirty}>Save</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </ClientPortal>
        </div >
    );
};

export default WelcomeView;

'use client';
import React, { useEffect, useImperativeHandle, useState } from 'react';
//@ts-ignore
import AOS from 'aos';
import 'aos/dist/aos.css';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import AccreditationsDetailView from './AccreditationsDetail/AccreditationsDetailView';
import { getOnboardingAccreditationData, SubmitAccreditationMapping } from '../../../service/onboarding-accreditation-service';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import swal from 'sweetalert';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store';
import LoaderContainer from '../../../components/Loader/loaderContainer';
import { setOnboardingPageValidity } from '../../../store/onboarding/onboardingPageValidityReducer';

const AccreditationsView = React.forwardRef((props, ref) => {
    var settings = {

        infinite: false,
        speed: 800,
        slidesToShow: 4,
        slidesToScroll: 4,
        Drag: false,
        afterChange: handleSlideChange,
    };
    const [accreditations, setAccreditations] = useState<any>([]);
    const [cqcRating, setCQCRating] = useState('');
    const [refresh, setRefresh] = useState(999);
    const [isLoading, setIsloading] = useState(false)
    const [currentSlide, setCurrentSlide] = useState(0);
    const sliderRef = React.useRef(null);
    const dispatch = useDispatch();

    const { serviceId, serviceName } = useSelector((state: RootState) => state.onboardingService);



    useEffect(() => {
        AOS.init();
    }, []);

    useEffect(() => {
        const GetAccreditationData = async () => {
            setIsloading(true)
            const response = await getOnboardingAccreditationData(serviceId);
            if (response?.status) {
                setIsloading(false)
                setAccreditations(response?.data?.accreditations);
                setCQCRating(response?.data?.cqcRating);
            } else {
                setIsloading(false)
            }
        };
        GetAccreditationData();
    }, []);

    function handleSlideChange(index: number) {
        setCurrentSlide(index);
    };

    const handleSubmitAccreditationsMapping = () => {
        const submitAccreditationMapping = async () => {
            const accreditationIds = accreditations
                .filter((accreditation: any) => accreditation.isSelected === true)
                .map((accreditation: any) => accreditation.accreditationId);
            const payload = {
                Service_Id: serviceId,
                AccreditationsIds: accreditationIds
            }
            setIsloading(true)
            const response = await SubmitAccreditationMapping(payload)
            if (response.status) {
                setIsloading(false)
            } else {
                setIsloading(false)
                swal({
                    title: '',
                    text: response.message,
                    icon: "error",
                    dangerMode: true
                })
            }
        }
        submitAccreditationMapping()
    }

    useImperativeHandle(ref, () => ({
        isValidated: true,
        backActionHandler: () => {
            handleSubmitAccreditationsMapping()
        },
        nextActionHandler: () => {
            handleSubmitAccreditationsMapping()
        },
        triggerValidation: async () => {
            // await trigger();
        }
    }));

    useEffect(()=>{
        dispatch(setOnboardingPageValidity(true))
    }, [])

    if (isLoading) {
        return <LoaderContainer />
    }

    return (
        <div className='pb-115'>
            <div className="cards-section accreditation-slider">
                {accreditations?.length > 0 && (
                    <Slider {...settings} ref={sliderRef}>
                        {accreditations.map((item: any, index: number) => (
                            <div className="accred-cards" key={item?.accreditationId}>
                                <div className="classic-card no-hover accreditationsbox">
                                    <h6>{item?.title}</h6>
                                    <p className="accreditations-text text-small">{item?.description}</p>
                                    <div className="acc-img d-flex align-items-center justify-content-center">
                                        <img src={item?.imagePath} className="img-fluid" alt="map image" />
                                    </div>
                                    <AccreditationsDetailView accreditations={accreditations} setAccreditations={setAccreditations} item={item} setIsloading={setIsloading} currentSlide={currentSlide} sliderRef={sliderRef} index={index} cqcRating={cqcRating}/>
                                </div>
                            </div>
                        ))}
                    </Slider>
                )}

            </div>

            <div className="row d-flex align-items-center justify-content-center" data-aos="fade-up" data-aos-duration="1600" data-aos-once="true">
                <div className="col-md-7">
                    <div className="setup-wards-rooms-grey mt-3">
                        <ul className="mb-0 ps-3">
                            <li>
                                <p className="normal mb-2">For each accreditation, enter the necessary dates & upload the certificates.</p>
                            </li>
                            <li>
                                <p className="normal">Ensure file size does not exceed <span className="bolder">10MB</span></p>
                            </li>
                        </ul>
                    </div>
                    <p className="mt-4 text-center">
                        If you <span className="bolder">need any help</span>, click the{' '}
                        <a>
                            <span className="bolder">"Need Assistance?"</span>
                        </a>{' '}
                        button.
                    </p>
                </div>
            </div>
        </div>
    );
});

export default AccreditationsView;

import React, { ChangeEvent, useEffect, useImperativeHandle, useRef, useState } from 'react';
import cqc from '../../../../assets/images/CQC.png';
import swal from 'sweetalert';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import LocationMap from '../../../../assets/images/location-map1.png';
import LocationIcon from '../../../../assets/images/location-direction-icon.svg';
import nearbyIcon from '../../../../assets/images/provider-pin.svg';
import LocationImage from '../../../../assets/images/owl-image1.png';
import TrianglAarrow from '../../../../assets/images/triangle.svg';
import { useTranslation } from 'react-i18next';
import google_maps_logo from '../../../../assets/images/Google_Maps_Logo.png';
import park_garden from '../../../../assets/images/Park-Garden.svg';
import supermarket from '../../../../assets/images/Supermarket.svg';
import laundry from '../../../../assets/images/Laundry.svg';
import salon_barber from '../../../../assets/images/Salon-Barber.svg';
import restaurant from '../../../../assets/images/Restaurant.svg';
import bus from '../../../../assets/images/bus.svg';
import railway from '../../../../assets/images/railway.svg';
import doctor from '../../../../assets/images/doctor.svg';
import pharmacy from '../../../../assets/images/pharmacy.svg';
import hospital from '../../../../assets/images/hospital.svg';
import ClientPortal from '../../../../components/ClientPortal';
import locationImg from '../../../../assets/images/onboarding-location.png';
import EditPencil from '../../../../assets/images/edit.svg';
import warning from '../../../../assets/images/warning.png';
import { getOnboardingPreviewLocationData } from '../../../../service/onboarding-service-preview';
import OnboardingLocationMapComponent from '../../../../components/GoogleMapComponent/OnboardingLocationMapComponent';
import hotel from '../../../../assets/images/hotel.svg';
import airport from '../../../../assets/images/airport.svg';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store';
import { ModulesAccessRights } from '../../../../utils/constant';
import { getCounties, getCountries, saveLocationAndProximity, updateServiceProximity } from '../../../../service/onboarding-location';
import { Autocomplete, TextField } from '@mui/material';


interface OverlayProps {
    proximities: Array<any>;
    showOverlay: boolean;
    onClose: (e: any) => void;
    nearbyLocationClick: (e: any) => void;
  }

const Overlay: React.FC<OverlayProps> = ({ proximities, showOverlay, onClose, nearbyLocationClick }) => {        
    return (
    <div className={`side-pane ${showOverlay ? 'visible' : ''}`}>
        <div className="overlay-content">
            <div className="overlay-header">
                <p>Nearby Location</p>
                <div onClick={onClose}>
                    <i className="fa fa-times-circle" aria-hidden="true"></i>
                </div>
            </div>
            <div className="location-overlay-body">
                {proximities?.length > 0 ? (
                    proximities.map((proximity: any, index: number) => (
                    <div key={index} className="proximity-item">
                        {proximity.type === "park" && (
                        <div className="mb-3">
                            <div className="row">
                            <div className="col-2 d-flex justify-content-end align-items-center">
                                <img src={park_garden} className="img-fluid" alt="Park" width={25} />
                            </div>
                            <div className="col-10">
                                <div className="nearby-link" onClick={() => nearbyLocationClick(proximity)} >
                                    {proximity.name}
                                </div>
                                <div>
                                    (Park - {proximity.distance} KM)
                                </div>
                            </div>
                            </div>
                        </div>
                        )}
                        {proximity.type === "laundry" && (
                        <div className="mb-3">
                            <div className="row">
                            <div className="col-2 d-flex justify-content-end align-items-center">
                                <img src={laundry} className="img-fluid" alt="laundry" width={25} />
                            </div>
                            <div className="col-10">
                                <div className="nearby-link" onClick={() => nearbyLocationClick(proximity)} >
                                    {proximity.name}
                                </div>
                                <div>
                                    (Laundry - {proximity.distance} KM)
                                </div>
                            </div>
                            </div>
                        </div>
                        )}
                        {proximity.type === "restaurant" && (
                        <div className="mb-3">
                            <div className="row">
                            <div className="col-2 d-flex justify-content-end align-items-center">
                                <img src={restaurant} className="img-fluid" alt="restaurant" width={25} />
                            </div>
                            <div className="col-10">
                                <div className="nearby-link" onClick={() => nearbyLocationClick(proximity)} >
                                    {proximity.name}
                                </div>
                                <div>
                                    (Restaurant - {proximity.distance} KM)
                                </div>
                            </div>
                            </div>
                        </div>
                        )}
                        {proximity.type === "train_station" && (
                        <div className="mb-3">
                            <div className="row">
                            <div className="col-2 d-flex justify-content-end align-items-center">
                                <img src={railway} className="img-fluid" alt="train_station" width={25} />
                            </div>
                            <div className="col-10">
                                <div className="nearby-link" onClick={() => nearbyLocationClick(proximity)} >
                                    {proximity.name}
                                </div>
                                <div>
                                    (Railway - {proximity.distance} KM)
                                </div>
                            </div>
                            </div>
                        </div>
                        )}
                        {proximity.type === "pharmacy" && (
                        <div className="mb-3">
                            <div className="row">
                            <div className="col-2 d-flex justify-content-end align-items-center">
                                <img src={pharmacy} className="img-fluid" alt="pharmacy" width={25} />
                            </div>
                            <div className="col-10">
                                <div className="nearby-link" onClick={() => nearbyLocationClick(proximity)} >
                                    {proximity.name}
                                </div>
                                <div>
                                    (Pharmacy - {proximity.distance} KM)
                                </div>
                            </div>
                            </div>
                        </div>
                        )}
                        {proximity.type === "bus_station" && (
                        <div className="mb-3">
                        <div className="row">
                            <div className="col-2 d-flex justify-content-end align-items-center">
                            <img src={bus} className="img-fluid" alt="bus_station" width={25} />
                            </div>
                            <div className="col-10">
                                <div className="nearby-link" onClick={() => nearbyLocationClick(proximity)} >
                                    {proximity.name}
                                </div>
                                <div>
                                    (Bus-station - {proximity.distance} KM)
                                </div>
                            </div>
                        </div>
                        </div>
                        )}
                        {proximity.type === "supermarket" && (
                        <div className="mb-3">
                            <div className="row">
                            <div className="col-2 d-flex justify-content-end align-items-center">
                                <img src={supermarket} className="img-fluid" alt="supermarket" width={25} />
                            </div>
                            <div className="col-10">
                                <div className="nearby-link" onClick={() => nearbyLocationClick(proximity)} >
                                    {proximity.name}
                                </div>
                                <div>
                                    (Supermarket - {proximity.distance} KM)
                                </div>
                            </div>
                            </div>
                        </div>
                        )}
                        {proximity.type === "hair_care" && (
                        <div className="mb-3">
                            <div className="row">
                            <div className="col-2 d-flex justify-content-end align-items-center">
                                <img src={salon_barber} className="img-fluid" alt="hair_care" width={25} />
                            </div>
                            <div className="col-10">
                                <div className="nearby-link" onClick={() => nearbyLocationClick(proximity)} >
                                    {proximity.name}
                                </div>
                                <div>
                                    (Salon - {proximity.distance} KM)
                                </div>
                            </div>
                            </div>
                        </div>
                        )}
                        {proximity.type === "doctor" && (
                        <div className="mb-3">
                            <div className="row">
                            <div className="col-2 d-flex justify-content-end align-items-center">
                                <img src={doctor} className="img-fluid" alt="doctor" width={25} />
                            </div>
                            <div className="col-10">
                                <div className="nearby-link" onClick={() => nearbyLocationClick(proximity)} >
                                    {proximity.name}
                                </div>
                                <div>
                                    (Doctor - {proximity.distance} KM)
                                </div>
                            </div>
                            </div>
                        </div>
                        )}
                        {proximity.type === "hospital" && (
                        <div className="mb-3">
                            <div className="row">
                            <div className="col-2 d-flex justify-content-end align-items-center">
                                <img src={hospital} className="img-fluid" alt="hospital" width={25} />
                            </div>
                            <div className="col-10">
                                <div className="nearby-link" onClick={() => nearbyLocationClick(proximity)} >
                                    {proximity.name}
                                </div>
                                <div>
                                    (Hospital - {proximity.distance} KM)
                                </div>
                            </div>
                            </div>
                        </div>
                        )}
                        {proximity.type === "airport" && (
                        <div className="mb-3">
                            <div className="row">
                            <div className="col-2 d-flex justify-content-end align-items-center">
                                <img src={airport} className="img-fluid" alt="airport" width={25} />
                            </div>
                            <div className="col-10">
                                <div className="nearby-link" onClick={() => nearbyLocationClick(proximity)} >
                                    {proximity.name}
                                </div>
                                <div>
                                    (Airport - {proximity.distance} KM)
                                </div>
                            </div>
                            </div>
                        </div>
                        )}
                        {proximity.type === "lodging" && (
                        <div className="mb-2">
                            <div className="row">
                            <div className="col-2 d-flex justify-content-end align-items-center">
                                <img src={hotel} className="img-fluid" alt="lodging" width={25} />
                            </div>
                            <div className="col-10">
                                <div className="nearby-link" onClick={() => nearbyLocationClick(proximity)} >
                                    {proximity.name}
                                </div>
                                <div>
                                    (Lodging - {proximity.distance} KM)
                                </div>
                            </div>
                        </div>
                    </div>
                    )}
                </div>
                ))
                ) : (
                    <p>No nearby locations found.</p>
                )}
            </div>
        </div>
    </div>
    )};

const OnboardingPreviewLocationView = React.forwardRef((props, ref) => {
    const { t } = useTranslation();
    const mapRef = useRef<any>();
    const { isForPublished } = useSelector((state: RootState) => state.onboardingIsForPublished);
    const { StatusLogin, DataLogin } = useSelector((state: RootState) => state.login);

    const [showEditPage, setShowEditPage] = useState<any>(DataLogin?.userModel?.modules?.find((item:any)=>(item.moduleName === 'OnBoarding')))

    const [showOverlay, setShowOverlay] = useState<boolean>(false);

    const validationSchema = Yup.object().shape({
        building: Yup.string().notRequired(),
        street: Yup.string().required('Street is required'),
        city: Yup.string().required('City is required'),
        county: Yup.number().required('County is required'),
        country: Yup.number().required('Region/Country is required'),
        postal_code: Yup.string().required('Postal code is required').matches(/^$|^[A-Z]{1,2}[0-9R][0-9A-Z]? [0-9][A-Z]{2}$/),
        landmark: Yup.string().notRequired(),
        websiteUrl: Yup.string().notRequired().matches(/^$|^(https?:\/\/)?(www\.)?[A-Za-z0-9-]+(\.[A-Za-z0-9-]+)+(:[0-9]{1,5})?(\/[^\s]*)?(\?[^\s]*)?$/),
        park: Yup.number().required('Park is required'),
        laundry: Yup.number().required('Laundry is required'),
        restaurant: Yup.number().required('Restaurant is required'),
        train_station: Yup.number().required('Train station is required'),
        pharmacy: Yup.number().required('Pharmacy is required'),
        supermarket: Yup.number().required('Supermarket is required'),
        hair_care: Yup.number().required('Hair care is required'),
        bus_station: Yup.number().required('Bus station is required'),
        doctor: Yup.number().required('Doctor is required'),
        hospital: Yup.number().required('Hospital is required'),
        airport: Yup.number().required('Airport is required'),
        lodging: Yup.number().required('Lodging is required')
    });
    const { register, control, handleSubmit, watch, getValues, reset, formState, setValue, trigger } = useForm({ mode: 'all', resolver: yupResolver(validationSchema) });
    const { errors, isValid } = formState;
    const watchAllFields = watch();
    const values = getValues();
    const { serviceId, serviceName } = useSelector((state: RootState) => state.onboardingService);

    const [data, setData] = useState<any>(undefined);
    const [countyDropDownData, setCountyDropDownData] = useState<{ key: number; value: string }[]>([]);
    const [countryDropDownData, setCountryDropDownData] = useState<{ id: number; value: string; label: string }[]>([]);
    const [selectedCounty, setSelectedCounty] = useState<{ key: number; value: string } | null>(null);
    const [selectedCountry, setSelectedCountry] = useState<{ id: number; value: string; label: string } | null>(null);
    const [showPortal, setShowPortal] = useState(false);
    const [isNPChecked, setIsNPChecked] = useState(false);
    const [location, setLocation] = useState<{ lat: number; lng: number } | undefined>(undefined);
    const [viewHeight, setViewHeight] = useState(window.innerHeight);
    const [refresh, setRefresh] = useState(1);
    const { DataUserLocation } = useSelector((state: RootState) => state.userLocation);

    const handleCountyChange = (event: any, newValue: { key: number; value: string } | null) => {
        setSelectedCounty(newValue);
        setValue('county', newValue?.key, { shouldValidate: true });
    };

    const handleCountryChange = (event: any, newValue: { id: number; value: string; label: string } | null) => {
        setSelectedCountry(newValue);
        setValue('country', newValue?.id, { shouldValidate: true });
    };
    const handleNPCheckboxChange = (event: ChangeEvent<HTMLInputElement>) => {
        setIsNPChecked(event.target.checked);
    };
    const [serviceProximitesCoords,setServiceProximitesCoords] = useState<any>(null);

    const setPlaceDistance = () => {
        if (mapRef.current) {
            setValue('park', (mapRef.current.distances[0].distance.value / 1000).toFixed(2), { shouldValidate: true });
            setValue('laundry', (mapRef.current.distances[1].distance.value / 1000).toFixed(2), { shouldValidate: true });
            setValue('restaurant', (mapRef.current.distances[2].distance.value / 1000).toFixed(2), { shouldValidate: true });
            setValue('train_station', (mapRef.current.distances[3].distance.value / 1000).toFixed(2), { shouldValidate: true });
            setValue('pharmacy', (mapRef.current.distances[4].distance.value / 1000).toFixed(2), { shouldValidate: true });
            setValue('supermarket', (mapRef.current.distances[5].distance.value / 1000).toFixed(2), { shouldValidate: true });
            setValue('hair_care', (mapRef.current.distances[6].distance.value / 1000).toFixed(2), { shouldValidate: true });
            setValue('bus_station', (mapRef.current.distances[7].distance.value / 1000).toFixed(2), { shouldValidate: true });
            setValue('doctor', (mapRef.current.distances[8].distance.value / 1000).toFixed(2), { shouldValidate: true });
            setValue('hospital', (mapRef.current.distances[9].distance.value / 1000).toFixed(2), { shouldValidate: true });
            setValue('airport', (mapRef.current.distances[10].distance.value / 1000).toFixed(2), { shouldValidate: true });
            setValue('lodging', (mapRef.current.distances[11].distance.value / 1000).toFixed(2), { shouldValidate: true });
            saveProximityData();
        }
    };
    const getPlaceDistance = (type: string) => {
        switch (type) {
            case 'park':
                return values.park;
            case 'laundry':
                return values.laundry;
            case 'restaurant':
                return values.restaurant;
            case 'train_station':
                return values.train_station;
            case 'pharmacy':
                return values.pharmacy;
            case 'supermarket':
                return values.supermarket;
            case 'hair_care':
                return values.hair_care;
            case 'bus_station':
                return values.bus_station;
            case 'doctor':
                return values.doctor;
            case 'hospital':
                return values.hospital;
            case 'airport':
                return values.airport;
            case 'lodging':
                return values.lodging;
        }
    };

    const saveData = async () => {
        if (!isValid) {
            return;
        }
        let places: any[] = [];
        if (isNPChecked) {
            if (mapRef.current) {
                places = mapRef.current.places.map((item: any, index: number) => {
                    const lat = item.geometry?.location.lat();
                    const lng = item.geometry?.location.lng();
                    return {
                        type: item.type,
                        distance: parseFloat(getPlaceDistance(item.type)),
                        location: {
                            lat,
                            lng
                        },
                        name: item.name,
                        place_id: item.place_id,
                        vicinity: item.vicinity
                    };
                });
            }
        } else {
            places = [
                { type: 'park', distance: parseFloat(getPlaceDistance('park')) },
                { type: 'laundry', distance: parseFloat(getPlaceDistance('laundry')) },
                { type: 'restaurant', distance: parseFloat(getPlaceDistance('restaurant')) },
                { type: 'train_station', distance: parseFloat(getPlaceDistance('train_station')) },
                { type: 'pharmacy', distance: parseFloat(getPlaceDistance('pharmacy')) },
                { type: 'supermarket', distance: parseFloat(getPlaceDistance('supermarket')) },
                { type: 'hair_care', distance: parseFloat(getPlaceDistance('hair_care')) },
                { type: 'bus_station', distance: parseFloat(getPlaceDistance('bus_station')) },
                { type: 'doctor', distance: parseFloat(getPlaceDistance('doctor')) },
                { type: 'hospital', distance: parseFloat(getPlaceDistance('hospital')) },
                { type: 'airport', distance: parseFloat(getPlaceDistance('airport')) },
                { type: 'lodging', distance: parseFloat(getPlaceDistance('lodging')) }
            ];
        }

        const bodyData = {
            building: values.building,
            street: values.street,
            city: values.city,
            county: values.county,
            country: values.country,
            postal_code: values.postal_code,
            landmark: values.landmark,
            websiteUrl: values.websiteUrl,
            location: mapRef.current ? mapRef.current.markerPosition : location,
            proximity: places
        };

        const res = await saveLocationAndProximity(serviceId!, bodyData);
        if (res.status === true) {
            swal('Changes to the location information saved successfully', {
                icon: 'success'
            }).then(async (confirm: any) => {
                setShowPortal(false);
            });
        } else {
            swal(res.message, {
                icon: 'error'
            }).then(async (confirm: any) => {});
        }
    };

    const saveProximityData = async () => {
        if (mapRef.current) {
            let places: any[] = [];
            places = mapRef.current.places.map((item: any, index: number) => {
                const lat = item.geometry?.location.lat();
                const lng = item.geometry?.location.lng();
                return {
                    type: item.type,
                    distance: parseFloat((mapRef.current.distances[index].distance.value / 1000).toFixed(2)),
                    location: {
                        lat,
                        lng
                    },
                    name: item.name,
                    place_id: item.place_id,
                    vicinity: item.vicinity
                };
            });

            await updateServiceProximity(serviceId!, places).then((res) => {
                if (res.status !== true) {
                    swal(res.message, {
                        icon: 'error'
                    }).then(() => {});
                }
            });
        }
    };

    useEffect(() => {
        if (isNPChecked && mapRef.current) {
            if (!data.usedMapsAPI) {
                // swal({
                //     title: '',
                //     text: t('OnboardingLocation.Nearby_Places_Warning')!,
                //     icon: warning,
                //     buttons: ['No', 'Yes'],
                //     dangerMode: true
                // }).then(async (confirm: any) => {
                //     if (confirm) {
                //         mapRef.current.fetchNearbyPlacesWithDistance(mapRef.current.markerPosition);
                //         setTimeout(() => {
                //             setPlaceDistance();
                //         }, 2000);
                //     } else {
                //         setIsNPChecked(false);
                //     }
                // });
                mapRef.current.fetchNearbyPlacesWithDistance(mapRef.current.markerPosition);
                setTimeout(() => {
                    setPlaceDistance();
                }, 2000);
            }
        }
    }, [isNPChecked]);

    useEffect(() => {
        const getData = async () => {
            try {
                const countyRes = await getCounties();
                if (countyRes) {
                    setCountyDropDownData(countyRes?.data);
                }

                const countryRes = await getCountries();
                if (countryRes) {
                    setCountryDropDownData(countryRes?.data?.result);
                }

                const response = await getOnboardingPreviewLocationData(serviceId!);
                if (response.status === true) {
                    setData(response.data);
                }
            } catch (error) {
                console.error('Error getting data: ', error);
            }
        };
        getData();
        const handleResize = () => {
            setViewHeight(window.innerHeight);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        setTimeout(() => {
            if (data && data.proximity && data.proximity.length > 0)
                setServiceProximitesCoords(data.proximity.map((item: any) => {
                    return {
                        type: item.type,
                        distance: item.distance,
                        name: item.name,
                        place_id: item.place_id,
                        vicinity: item.vicinity,
                        location: item.location,
                        geometry: {
                            location: item.location.lat && item.location.lng
                                ? new window.google.maps.LatLng(item.location.lat, item.location.lng)
                                : undefined
                        }
                    };
                }));
        },2000)
    },[data])

    useEffect(() => {
        if (data) {
            setValue('building', data.building, { shouldValidate: true });
            setValue('street', data.street, { shouldValidate: true });
            setValue('city', data.city, { shouldValidate: true });
            setValue('county', data.county, { shouldValidate: true });
            setValue('country', data.country, { shouldValidate: true });
            setValue('postal_code', data.postalCode, { shouldValidate: true });
            setValue('landmark', data.landmark, { shouldValidate: true });
            setValue('websiteUrl', data.websiteUrl, { shouldValidate: true });
            setValue('park', data.proximity[0]?.distance, { shouldValidate: data.proximity[0] ? true : false });
            setValue('laundry', data.proximity[1]?.distance, { shouldValidate: data.proximity[1] ? true : false });
            setValue('restaurant', data.proximity[2]?.distance, { shouldValidate: data.proximity[2] ? true : false });
            setValue('train_station', data.proximity[3]?.distance, { shouldValidate: data.proximity[3] ? true : false });
            setValue('pharmacy', data.proximity[4]?.distance, { shouldValidate: data.proximity[4] ? true : false });
            setValue('supermarket', data.proximity[5]?.distance, { shouldValidate: data.proximity[5] ? true : false });
            setValue('hair_care', data.proximity[6]?.distance, { shouldValidate: data.proximity[6] ? true : false });
            setValue('bus_station', data.proximity[7]?.distance, { shouldValidate: data.proximity[7] ? true : false });
            setValue('doctor', data.proximity[8]?.distance, { shouldValidate: data.proximity[8] ? true : false });
            setValue('hospital', data.proximity[9]?.distance, { shouldValidate: data.proximity[9] ? true : false });
            setValue('airport', data.proximity[10]?.distance, { shouldValidate: data.proximity[10] ? true : false });
            setValue('lodging', data.proximity[11]?.distance, { shouldValidate: data.proximity[11] ? true : false });
            const fetchedCounty = countyDropDownData.find((option) => option.key === data.county);
            const fetchedCountry = countryDropDownData.find((option) => option.id === data.country);
            if (fetchedCounty) setSelectedCounty(fetchedCounty);
            if (fetchedCountry) setSelectedCountry(fetchedCountry);
            setLocation(data.location);
            setIsNPChecked(data.usedMapsAPI);
            setTimeout(() => {
                if (data.usedMapsAPI && mapRef.current) {
                    mapRef.current.setPlaces(
                        data.proximity.map((item: any) => {
                            return {
                                type: item.type,
                                distance: item.distance,
                                name: item.name,
                                place_id: item.place_id,
                                vicinity: item.vicinity,
                                geometry: { location: item.location ? new window.google.maps.LatLng(item.location.lat, item.location.lng) : undefined }
                            };
                        })
                    );
                }

                if (!data.usedMapsAPI) {
                    setIsNPChecked(true);
                }
            }, 1000);
        }
    }, [data]);

    useEffect(() => {
        if (showPortal) {
            document.body.classList.add('modal-open');
        } else {
            document.body.classList.remove('modal-open');
        }
        setRefresh((prev) => prev + 1);
        setTimeout(() => {
            if (data?.usedMapsAPI && mapRef.current) {
                mapRef.current.setPlaces(
                    data.proximity.map((item: any) => {
                        return {
                            type: item.type,
                            distance: item.distance,
                            name: item.name,
                            place_id: item.place_id,
                            vicinity: item.vicinity,
                            geometry: { location: item.location ? new window.google.maps.LatLng(item.location.lat, item.location.lng) : undefined }
                        };
                    })
                );
            }
        }, 1000);
        return () => {
            document.body.classList.remove('modal-open');
        };
    }, [showPortal]);

    const handleShowPortal = (event: any) => {
        event.preventDefault();
        setShowPortal((prevState) => !prevState);
    };

    const handleDirectionClick = () => {
        let origin = undefined;
        if (DataUserLocation && DataUserLocation?.location_obj[0]?.geometry?.location?.lat) {
            origin = {
                lat: DataUserLocation?.location_obj[0]?.geometry?.location?.lat!,
                lng: DataUserLocation?.location_obj[0]?.geometry?.location?.lng!
            };
        }
        let destination = undefined;
        if (data?.location?.lat!) {
            destination = {
                lat: data?.location?.lat!,
                lng: data?.location?.lng!
            };
        }

        let url = process.env.REACT_APP_MAPS_DIRECTIONS_URL;

        if (origin) {
            const originStr = `${origin.lat},${origin.lng}`;
            url += `&origin=${originStr}`;
        }

        if (destination) {
            const destinationStr = `${destination.lat},${destination.lng}`;
            url += `&destination=${destinationStr}`;
        }
        window.open(url, '_blank');
    };

    const handleNearbyLocationClick = (locationObj: any) => {
        let origin = undefined;
        if (locationObj?.location?.lat && locationObj?.location.lng) {
            origin = {
                lat: locationObj?.location?.lat!,
                lng: locationObj?.location?.lng!
            };
        }

        let destination = undefined;
        if (data?.location?.lat!) {
            destination = {
                lat: data?.location?.lat!,
                lng: data?.location?.lng!
            };
        }

        let url = process.env.REACT_APP_MAPS_DIRECTIONS_URL;

        if (origin) {
            const originStr = `${origin.lat},${origin.lng}`;
            url += `&origin=${originStr}`;
        }

        if (destination) {
            const destinationStr = `${destination.lat},${destination.lng}`;
            url += `&destination=${destinationStr}`;
        }
        window.open(url, '_blank');
    };

    const submitalert = () => {
        swal({
            title: '',
            text: `Do you want to save the changes done to the location information?`,
            icon: warning,
            buttons: ['No', 'Yes'],
            dangerMode: true
        }).then(async (confirm: any) => {
            if (confirm) {
                saveData();
            } else {
            }
        });
    };

    const handleOverlayToggle = () => {
        setShowOverlay(prevState => !prevState);
    };
      
    return (
        <div>
            <div className="section-padding" id="location-section">
                <div>
                    <h4 className="text-center section-title mb-3 bolder" data-aos="fade-up" data-aos-duration="1500" data-aos-once="true">
                        {t('Location.Location_Title')}
                    </h4>
                    <div className="d-flex align-items-start justify-content-center preview-small-title">
                        <p className="section-sub-title text-center mb-0 bolder" data-aos="fade-up" data-aos-duration="1500" data-aos-once="true">
                            {t('Location.Find_Location')}
                        </p>
                        {isForPublished ? (
                            showEditPage?.userAccess?.some((a: any) => a.accessCode === ModulesAccessRights.Onboarding.LocationEditAccess.accessCode) ? (
                                <div className="edit-icon-preview ms-2 cursor-pointer" onClick={handleShowPortal} data-aos="fade-up" data-aos-duration="1500" data-aos-once="true">
                                    <img src={EditPencil} className="img-fluid" alt="edit" width={20} />
                                </div>
                            ) : (
                                <></>
                            )
                        ) : (
                            <div className="edit-icon-preview ms-2 cursor-pointer" onClick={handleShowPortal} data-aos="fade-up" data-aos-duration="1500" data-aos-once="true">
                                <img src={EditPencil} className="img-fluid" alt="edit" width={20} />
                            </div>
                        )}
                    </div>
                    <div className="position-relative location-section">
                        <div className="onboardMap mt-0" key={refresh}>
                            {location && !showPortal && (
                                <OnboardingLocationMapComponent
                                    ref={mapRef}
                                    location={location}
                                    containerStyle={{
                                        width: `100%`,
                                        height: `400px`,
                                        // height: viewHeight - 150 + 'px'
                                        position: `relative`,
                                        overflow: `hidden`
                                    }}
                                    nearbyMarkers={serviceProximitesCoords}
                                    isPinDraggable={false}
                                />
                            )}
                            
                        </div>
                        <Overlay 
                            proximities={serviceProximitesCoords}
                            showOverlay={showOverlay}
                            onClose={handleOverlayToggle}
                            nearbyLocationClick={handleNearbyLocationClick}
                        />
                        {/* <img src={LocationMap} alt="Location Map" className="img-fluid location-map-img" />
                        <div className="location-map-box-pin" data-aos="fade-down" data-aos-duration="1500" data-aos-once="true">
                            <div>
                                <div className="location-image-box">
                                    <img
                                        src={LocationImage}
                                        alt="Location Image"
                                        className="img-fluid "
                                    />
                                </div>
                                <div className="location-details">
                                    <div className="d-flex">
                                        <p className="bolder lead-text map-box-title">
                                            Castle in Coventry
                                        </p>
                                        <p className="ms-auto bold map-box-subtitle">
                                            <i className="fa-solid fa-heart"></i> 4.5 (153)
                                        </p>
                                    </div>
                                    <p className="darkgray-text location-text">2 Mount Rd,Poole BH14 00w </p>
                                    <div className="cqc-rating d-flex mt-1">
                                        <img
                                            src={cqc}
                                            className="img-fluid me-2"
                                            alt="cqc"
                                            width={70}
                                        />
                                        <p className="bold d-flex align-items-center green-text ms-auto">
                                            Good <span className="dot-round ms-1"></span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <img
                                src={TrianglAarrow}
                                alt="triangle arrow"
                                className="box-botton-arrow"
                            />
                        </div> */}
                        <a onClick={handleDirectionClick} className="btn btn-blue btn-direction">
                            <img src={LocationIcon} alt="Location Direction" width={17} className="me-1" />
                            {t('Location.Directions')}
                        </a>
                        <a className="btn btn-blue btn-nearby" onClick={handleOverlayToggle} >
                            <img
                            src={nearbyIcon}
                            alt="Location Direction"
                            width={25}
                            className="me-1"
                            />
                            {t("Location.Nearby")}
                        </a>
                        
                    </div>
                </div>
            </div>
            <ClientPortal selector="myportal" show={showPortal}>
                <div className="overlay" onClick={handleShowPortal}></div>
                <div className="modal modal-collage-image p-0 nhs-body">
                    <div className="header">
                        <a onClick={handleShowPortal} className="d-flex align-items-center">
                            <i className="fa-solid fa-angle-left back-collage-btn"></i>
                            <h4 className="bolder mb-0 ms-2">Edit Your Location</h4>
                        </a>
                    </div>
                    <div className="body service-detail-padding preview-service-fieldset-text float-button-sec-margin">
                        <div className="container-fluid p-0">
                            <div className="cards-section onboard-location float-button-sec-margin">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="sec-frame mb-4">
                                            <div className="sec-frame-title">
                                                <p>1. Provide Facility Address</p>
                                            </div>
                                            <div className="form-container">
                                                <div className="neumorphic-fieldset mb-2">
                                                    <label htmlFor="floor">
                                                        <span className="label-style">Unit, Floor or Building (If applicable)</span>
                                                    </label>
                                                    <input
                                                        type="text"
                                                        {...register('building', {
                                                            // onChange: (e: any) => {
                                                            //     props.updateData(e);
                                                            // }
                                                        })}
                                                        name={'building' as const}
                                                        className={`form-control ${errors.building ? 'is-invalid' : ''}`}
                                                    />
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-8">
                                                        <div className="neumorphic-fieldset mb-2">
                                                            <label htmlFor="street">
                                                                <span className="label-style">Street Address *</span>
                                                            </label>
                                                            <input
                                                                type="text"
                                                                {...register('street', {
                                                                    // onChange: (e: any) => {
                                                                    //     props.updateData(e);
                                                                    // }
                                                                })}
                                                                name={'street' as const}
                                                                className={`form-control ${errors.street ? 'is-invalid' : ''}`}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-8">
                                                        <div className="row">
                                                            <div className="col-md-6">
                                                                <div className="neumorphic-fieldset mb-2">
                                                                    <label htmlFor="city">
                                                                        <span className="label-style">Town/ City *</span>
                                                                    </label>
                                                                    <input
                                                                        type="text"
                                                                        className={`form-control ${errors.city ? 'is-invalid' : ''}`}
                                                                        {...register('city', {
                                                                            // onChange: (e: any) => {
                                                                            //     props.updateData(e);
                                                                            // }
                                                                        })}
                                                                        name={'city' as const}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <div className="neumorphic-fieldset mb-2">
                                                                    <label htmlFor="county">
                                                                        <span className="label-style">County *</span>
                                                                    </label>
                                                                    <Autocomplete
                                                                        disablePortal
                                                                        options={countyDropDownData}
                                                                        getOptionLabel={(option) => option.value}
                                                                        value={selectedCounty}
                                                                        isOptionEqualToValue={(option, value) => option.key === value.key}
                                                                        onChange={handleCountyChange}
                                                                        renderInput={(params) => (
                                                                            <TextField
                                                                                {...params}
                                                                                InputProps={{
                                                                                    ...params.InputProps,
                                                                                    classes: {
                                                                                        root: errors.county ? 'onboarding-auto-complete-input-layout-error' : 'onboarding-auto-complete-input-layout',
                                                                                        input: `onboarding-auto-complete-input`
                                                                                    }
                                                                                }}
                                                                                variant="outlined"
                                                                                size="small"
                                                                            />
                                                                        )}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-8">
                                                        <div className="neumorphic-fieldset mb-2">
                                                            <label htmlFor="region">
                                                                <span className="label-style">Region/ Country *</span>
                                                            </label>
                                                            <Autocomplete
                                                                disablePortal
                                                                options={countryDropDownData}
                                                                getOptionLabel={(option) => option.value}
                                                                onChange={handleCountryChange}
                                                                value={selectedCountry}
                                                                isOptionEqualToValue={(option, value) => option.id === value.id}
                                                                renderInput={(params) => (
                                                                    <TextField
                                                                        {...params}
                                                                        InputProps={{
                                                                            ...params.InputProps,
                                                                            classes: {
                                                                                root: errors.country ? 'onboarding-auto-complete-input-layout-error' : 'onboarding-auto-complete-input-layout',
                                                                                input: 'onboarding-auto-complete-input'
                                                                            }
                                                                        }}
                                                                        variant="outlined"
                                                                        size="small"
                                                                    />
                                                                )}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <div className="neumorphic-fieldset mb-2">
                                                            <label htmlFor="postal">
                                                                <span className="label-style">Postal Code *</span>
                                                            </label>
                                                            <input
                                                                type="text"
                                                                className={`form-control ${errors.postal_code ? 'is-invalid' : ''}`}
                                                                {...register('postal_code', {
                                                                    // onChange: (e: any) => {
                                                                    //     props.updateData(e);
                                                                    // }
                                                                })}
                                                                name={'postal_code' as const}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="neumorphic-fieldset mb-2">
                                                    <label htmlFor="landmark">
                                                        <span className="label-style">Landmark (If applicable)</span>
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className={`form-control ${errors.landmark ? 'is-invalid' : ''}`}
                                                        {...register('landmark', {
                                                            // onChange: (e: any) => {
                                                            //     props.updateData(e);
                                                            // }
                                                        })}
                                                        name={'landmark' as const}
                                                    />
                                                </div>
                                                <div className="neumorphic-fieldset mb-2">
                                                    <label htmlFor="websiteUrl">
                                                        <span className="label-style">Website URL</span>
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className={`form-control ${errors.websiteUrl ? 'is-invalid' : ''}`}
                                                        {...register('websiteUrl', {
                                                            // onChange: (e: any) => {
                                                            //     props.updateData(e);
                                                            // }
                                                        })}
                                                        name={'websiteUrl' as const}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="sec-frame">
                                            <div className="sec-frame-title">
                                                <p>2. Specify Proximity</p>
                                            </div>
                                            <div className="">
                                                <div className="d-flex justify-content-end mb-3">
                                                    <div className="d-flex align-items-center">
                                                        <div className="me-2">
                                                            <img src={google_maps_logo} className="img-fluid" alt="map image" width={100} />
                                                        </div>
                                                        <div className="anim-switch">
                                                            <label className="switchts">
                                                                <input type="checkbox" disabled={isNPChecked} checked={isNPChecked} onChange={handleNPCheckboxChange} />

                                                                <div className="sliderts">
                                                                    <div className="circlets">
                                                                        <svg
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                            width="10"
                                                                            height="10"
                                                                            className="checkmark"
                                                                            enable-background="new 0 0 512 512"
                                                                            viewBox="0 0 24 24"
                                                                        >
                                                                            <path
                                                                                fill="currentColor"
                                                                                d="M9.707 19.121a.997.997 0 01-1.414 0l-5.646-5.647a1.5 1.5 0 010-2.121l.707-.707a1.5 1.5 0 012.121 0L9 14.171l9.525-9.525a1.5 1.5 0 012.121 0l.707.707a1.5 1.5 0 010 2.121z"
                                                                                data-original="#000000"
                                                                            ></path>
                                                                        </svg>
                                                                    </div>
                                                                </div>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row nearbyPlaces">
                                                    <div className="col-md-4 d-flex align-items-center">
                                                        <img src={park_garden} className="img-fluid" alt="map image" width={25} />
                                                        <div className="neumorphic-fieldset mb-2 ms-3">
                                                            <label htmlFor="name">
                                                                <span className="label-style">Park/Garden</span>
                                                            </label>
                                                            <input
                                                                type="number"
                                                                className={`form-control fieldset-fixed-padding-right ${errors.park ? 'is-invalid' : ''}`}
                                                                disabled={isNPChecked}
                                                                {...register('park', {
                                                                    // onChange: (e: any) => {
                                                                    //     props.updateData(e);
                                                                    // }
                                                                })}
                                                                name={'park' as const}
                                                            />
                                                            <span className="fieldset-fixed-text">Km</span>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4 d-flex align-items-center">
                                                        <img src={supermarket} className="img-fluid" alt="map image" width={25} />
                                                        <div className="neumorphic-fieldset mb-2 ms-3">
                                                            <label htmlFor="name">
                                                                <span className="label-style">Supermarket</span>
                                                            </label>
                                                            <input
                                                                type="number"
                                                                className={`form-control fieldset-fixed-padding-right ${errors.supermarket ? 'is-invalid' : ''}`}
                                                                disabled={isNPChecked}
                                                                {...register('supermarket', {
                                                                    // onChange: (e: any) => {
                                                                    //     props.updateData(e);
                                                                    // }
                                                                })}
                                                                name={'supermarket' as const}
                                                            />
                                                            <span className="fieldset-fixed-text">Km</span>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4 d-flex align-items-center">
                                                        <img src={laundry} className="img-fluid" alt="map image" width={25} />
                                                        <div className="neumorphic-fieldset mb-2 ms-3">
                                                            <label htmlFor="name">
                                                                <span className="label-style">Laundry</span>
                                                            </label>
                                                            <input
                                                                type="number"
                                                                className={`form-control fieldset-fixed-padding-right ${errors.laundry ? 'is-invalid' : ''}`}
                                                                disabled={isNPChecked}
                                                                {...register('laundry', {
                                                                    // onChange: (e: any) => {
                                                                    //     props.updateData(e);
                                                                    // }
                                                                })}
                                                                name={'laundry' as const}
                                                            />
                                                            <span className="fieldset-fixed-text">Km</span>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4 d-flex align-items-center">
                                                        <img src={salon_barber} className="img-fluid" alt="map image" width={25} />
                                                        <div className="neumorphic-fieldset mb-2 ms-3">
                                                            <label htmlFor="name">
                                                                <span className="label-style">Salon/Barber</span>
                                                            </label>
                                                            <input
                                                                type="number"
                                                                className={`form-control fieldset-fixed-padding-right ${errors.hair_care ? 'is-invalid' : ''}`}
                                                                disabled={isNPChecked}
                                                                {...register('hair_care', {
                                                                    // onChange: (e: any) => {
                                                                    //     props.updateData(e);
                                                                    // }
                                                                })}
                                                                name={'hair_care' as const}
                                                            />
                                                            <span className="fieldset-fixed-text">Km</span>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4 d-flex align-items-center">
                                                        <img src={restaurant} className="img-fluid" alt="map image" width={25} />
                                                        <div className="neumorphic-fieldset mb-2 ms-3">
                                                            <label htmlFor="name">
                                                                <span className="label-style">Restaurant</span>
                                                            </label>
                                                            <input
                                                                type="number"
                                                                className={`form-control fieldset-fixed-padding-right ${errors.restaurant ? 'is-invalid' : ''}`}
                                                                disabled={isNPChecked}
                                                                {...register('restaurant', {
                                                                    // onChange: (e: any) => {
                                                                    //     props.updateData(e);
                                                                    // }
                                                                })}
                                                                name={'restaurant' as const}
                                                            />
                                                            <span className="fieldset-fixed-text">Km</span>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4 d-flex align-items-center">
                                                        <img src={hotel} className="img-fluid" alt="map image" width={25} />
                                                        <div className="neumorphic-fieldset mb-2 ms-3">
                                                            <label htmlFor="name">
                                                                <span className="label-style">Hotel</span>
                                                            </label>
                                                            <input
                                                                type="number"
                                                                className={`form-control fieldset-fixed-padding-right ${errors.lodging ? 'is-invalid' : ''}`}
                                                                disabled={isNPChecked}
                                                                {...register('lodging', {
                                                                    // onChange: (e: any) => {
                                                                    //     props.updateData(e);
                                                                    // }
                                                                })}
                                                                name={'lodging' as const}
                                                            />
                                                            <span className="fieldset-fixed-text">Km</span>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4 d-flex align-items-center">
                                                        <img src={airport} className="img-fluid" alt="map image" width={25} />
                                                        <div className="neumorphic-fieldset mb-2 ms-3">
                                                            <label htmlFor="name">
                                                                <span className="label-style">Airport</span>
                                                            </label>
                                                            <input
                                                                type="number"
                                                                className={`form-control fieldset-fixed-padding-right ${errors.airport ? 'is-invalid' : ''}`}
                                                                disabled={isNPChecked}
                                                                {...register('airport', {
                                                                    // onChange: (e: any) => {
                                                                    //     props.updateData(e);
                                                                    // }
                                                                })}
                                                                name={'airport' as const}
                                                            />
                                                            <span className="fieldset-fixed-text">Km</span>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4 d-flex align-items-center">
                                                        <img src={bus} className="img-fluid" alt="map image" width={25} />
                                                        <div className="neumorphic-fieldset mb-2 ms-3">
                                                            <label htmlFor="name">
                                                                <span className="label-style">Bus Stop</span>
                                                            </label>
                                                            <input
                                                                type="number"
                                                                className={`form-control fieldset-fixed-padding-right ${errors.bus_station ? 'is-invalid' : ''}`}
                                                                disabled={isNPChecked}
                                                                {...register('bus_station', {
                                                                    // onChange: (e: any) => {
                                                                    //     props.updateData(e);
                                                                    // }
                                                                })}
                                                                name={'bus_station' as const}
                                                            />
                                                            <span className="fieldset-fixed-text">Km</span>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4 d-flex align-items-center">
                                                        <img src={railway} className="img-fluid" alt="map image" width={25} />
                                                        <div className="neumorphic-fieldset mb-2 ms-3">
                                                            <label htmlFor="name">
                                                                <span className="label-style">Railway Stn.</span>
                                                            </label>
                                                            <input
                                                                type="number"
                                                                className={`form-control fieldset-fixed-padding-right ${errors.train_station ? 'is-invalid' : ''}`}
                                                                disabled={isNPChecked}
                                                                {...register('train_station', {
                                                                    // onChange: (e: any) => {
                                                                    //     props.updateData(e);
                                                                    // }
                                                                })}
                                                                name={'train_station' as const}
                                                            />
                                                            <span className="fieldset-fixed-text">Km</span>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4 d-flex align-items-center">
                                                        <img src={doctor} className="img-fluid" alt="map image" width={25} />
                                                        <div className="neumorphic-fieldset mb-2 ms-3">
                                                            <label htmlFor="name">
                                                                <span className="label-style">GP</span>
                                                            </label>
                                                            <input
                                                                type="number"
                                                                className={`form-control fieldset-fixed-padding-right ${errors.doctor ? 'is-invalid' : ''}`}
                                                                disabled={isNPChecked}
                                                                {...register('doctor', {
                                                                    // onChange: (e: any) => {
                                                                    //     props.updateData(e);
                                                                    // }
                                                                })}
                                                                name={'doctor' as const}
                                                            />
                                                            <span className="fieldset-fixed-text">Km</span>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4 d-flex align-items-center">
                                                        <img src={pharmacy} className="img-fluid" alt="map image" width={25} />
                                                        <div className="neumorphic-fieldset mb-2 ms-3">
                                                            <label htmlFor="name">
                                                                <span className="label-style">Pharmacy</span>
                                                            </label>
                                                            <input
                                                                type="number"
                                                                className={`form-control fieldset-fixed-padding-right ${errors.pharmacy ? 'is-invalid' : ''}`}
                                                                disabled={isNPChecked}
                                                                {...register('pharmacy', {
                                                                    // onChange: (e: any) => {
                                                                    //     props.updateData(e);
                                                                    // }
                                                                })}
                                                                name={'pharmacy' as const}
                                                            />
                                                            <span className="fieldset-fixed-text">Km</span>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4 d-flex align-items-center">
                                                        <img src={hospital} className="img-fluid" alt="map image" width={25} />
                                                        <div className="neumorphic-fieldset mb-2 ms-3">
                                                            <label htmlFor="name">
                                                                <span className="label-style">Hospital</span>
                                                            </label>
                                                            <input
                                                                type="number"
                                                                className={`form-control fieldset-fixed-padding-right ${errors.hospital ? 'is-invalid' : ''}`}
                                                                disabled={isNPChecked}
                                                                {...register('hospital', {
                                                                    // onChange: (e: any) => {
                                                                    //     props.updateData(e);
                                                                    // }
                                                                })}
                                                                name={'hospital' as const}
                                                            />
                                                            <span className="fieldset-fixed-text">Km</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="sec-frame h-100">
                                            <div className="sec-frame-title">
                                                <p>3. Pinpoint Exact Location</p>
                                            </div>

                                            <div className="onboardMap p-1 mt-0" key={refresh}>
                                                {location && (
                                                    <OnboardingLocationMapComponent
                                                        ref={mapRef}
                                                        location={location}
                                                        isPinDraggable={true}
                                                        containerStyle={{
                                                            width: `100%`,
                                                            height: `100%`,
                                                            borderRadius: '10px'
                                                        }}
                                                    />
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer--sticky p-2">
                        <div className="d-flex justify-content-center">
                            <button type="submit" disabled={!isValid} className="btn primary-btn mt-1 px-5" onClick={submitalert}>
                                Save
                            </button>
                        </div>
                    </div>
                </div>
            </ClientPortal>
        </div>
    );
});

export default OnboardingPreviewLocationView;

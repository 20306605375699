import { AxiosError, AxiosResponse } from 'axios';
import { DataResponseModel } from '../utils/model';
import { API_ENDPOINT, BASEURLDEV } from './ApiConstants';
import instance from './axiosinstance';

export const getOnboardingFacilityCategory = (id:any) => {
    return instance.get(`${BASEURLDEV}${API_ENDPOINT.GetOnboardingFacilityData}?serviceId=${id}`)
    .then((response: AxiosResponse) => {
        const resultData: DataResponseModel = response.data;
        return resultData;
    })
    .catch((error: AxiosError) => {
        if (error.code === 'ERR_NETWORK') {
            return {
                status: false,
                data: [],
                message: error.message,
                totalCount: 0
            } as DataResponseModel;
        } else {
            return {
                status: false,
                data: [],
                message: 'Something went wrong!',
                totalCount: 0
            } as DataResponseModel;
        }
    });
};

export const getOnboardingFacilityCategoryById = (serviceId: number) => {
    return instance.get(`${BASEURLDEV}${API_ENDPOINT.GetOnboardingFacilityData}?serviceId=${serviceId}`)
    .then((response: AxiosResponse) => {
        const resultData: DataResponseModel = response.data;
        return resultData;
    })
    .catch((error: AxiosError) => {
        if (error.code === 'ERR_NETWORK') {
            return {
                status: false,
                data: [],
                message: error.message,
                totalCount: 0
            } as DataResponseModel;
        } else {
            return {
                status: false,
                data: [],
                message: 'Something went wrong!',
                totalCount: 0
            } as DataResponseModel;
        }
    });
};


export const saveFacilityDetails = (payload: any) => {
    return instance
        .post(`${BASEURLDEV}${API_ENDPOINT.SaveServiceFacilityDetails}`, payload)
        .then((response: AxiosResponse) => {
            const resultData: DataResponseModel = response.data;
            return resultData;
        })
        .catch((error: AxiosError) => {
            if (error.code === 'ERR_NETWORK') {
                return {
                    status: false,
                    data: [],
                    message: error.message,
                    totalCount: 0
                } as DataResponseModel;
            } else {
                return {
                    status: false,
                    data: [],
                    message: 'Something went wrong!',
                    totalCount: 0
                } as DataResponseModel;
            }
        });
};

export const getCategoriesById = (id:any) => {
    return instance.get(`${BASEURLDEV}${API_ENDPOINT.GetOnboardingServiceCategoriesById}?serviceId=${id}`)
    .then((response: AxiosResponse) => {
        const resultData: DataResponseModel = response.data;
        return resultData;
    })
    .catch((error: AxiosError) => {
        if (error.code === 'ERR_NETWORK') {
            return {
                status: false,
                data: [],
                message: error.message,
                totalCount: 0
            } as DataResponseModel;
        } else {
            return {
                status: false,
                data: [],
                message: 'Something went wrong!',
                totalCount: 0
            } as DataResponseModel;
        }
    });
};
import { yupResolver } from '@hookform/resolvers/yup';
import { Autocomplete, IconButton, TextField } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import React, { ChangeEvent, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import swal from 'sweetalert';
import * as Yup from 'yup';
import google_maps_logo from '../../../assets/images/Google_Maps_Logo.png';
import laundry from '../../../assets/images/Laundry.svg';
import park_garden from '../../../assets/images/Park-Garden.svg';
import restaurant from '../../../assets/images/Restaurant.svg';
import salon_barber from '../../../assets/images/Salon-Barber.svg';
import supermarket from '../../../assets/images/Supermarket.svg';
import airport from '../../../assets/images/airport.png';
import bus from '../../../assets/images/bus.svg';
import doctor from '../../../assets/images/doctor.svg';
import hospital from '../../../assets/images/hospital.svg';
import hotel from '../../../assets/images/hotel.png';
import pharmacy from '../../../assets/images/pharmacy.svg';
import railway from '../../../assets/images/railway.svg';
import warning from '../../../assets/images/warning.png';
import OnboardingLocationMapComponent from '../../../components/GoogleMapComponent/OnboardingLocationMapComponent';
import { getCounties, getCountries, getOnboardingLocationData, saveLocationAndProximity, updateServiceProximity } from '../../../service/onboarding-location';
import { RootState } from '../../../store';
import { setOnboardingPageValidity } from '../../../store/onboarding/onboardingPageValidityReducer';

const OnboardLocationView = React.forwardRef((props, ref) => {
    const { t } = useTranslation();
    const mapRef = useRef<any>();

    const { serviceId, serviceName } = useSelector((state: RootState) => state.onboardingService);
    const dispatch = useDispatch();
    useImperativeHandle(ref, () => ({
        isValidated: isValid,
        backActionHandler: () => {
            saveData();
        },
        nextActionHandler: () => {
            saveData();
        },
        triggerValidation: async () => {
            await trigger();
            if (!isValid) {
                showWarning(t('OnboardingLocation.Validation_Warning')!);
            }
            return isValid;
        }
    }));
    const validationSchema = Yup.object().shape({
        building: Yup.string().notRequired(),
        street: Yup.string().required('Street is required'),
        city: Yup.string().required('City is required'),
        county: Yup.number().required('County is required'),
        country: Yup.number().required('Region/Country is required'),
        postal_code: Yup.string().required('Postal code is required').matches(/^$|^[A-Z]{1,2}[0-9R][0-9A-Z]? [0-9][A-Z]{2}$/),
        landmark: Yup.string().notRequired(),
        websiteUrl: Yup.string().notRequired().matches(/^$|^(https?:\/\/)?(www\.)?[A-Za-z0-9-]+(\.[A-Za-z0-9-]+)+(:[0-9]{1,5})?(\/[^\s]*)?(\?[^\s]*)?$/),
        park: Yup.number().required('Park is required'),
        laundry: Yup.number().required('Laundry is required'),
        restaurant: Yup.number().required('Restaurant is required'),
        train_station: Yup.number().required('Train station is required'),
        pharmacy: Yup.number().required('Pharmacy is required'),
        supermarket: Yup.number().required('Supermarket is required'),
        hair_care: Yup.number().required('Hair care is required'),
        bus_station: Yup.number().required('Bus station is required'),
        doctor: Yup.number().required('Doctor is required'),
        hospital: Yup.number().required('Hospital is required'),
        airport: Yup.number().required('Airport is required'),
        lodging: Yup.number().required('Lodging is required')
    });

    const { register, control, handleSubmit, watch, getValues, reset, formState, setValue, trigger } = useForm({ mode: 'all', resolver: yupResolver(validationSchema) });
    const { errors, isValid } = formState;
    const watchAllFields = watch();
    const values = getValues();

    const [data, setData] = useState<any>(undefined);
    const [countyDropDownData, setCountyDropDownData] = useState<{ key: number; value: string }[]>([]);
    const [countryDropDownData, setCountryDropDownData] = useState<{ id: number; value: string; label: string }[]>([]);
    const [selectedCounty, setSelectedCounty] = useState<{ key: number; value: string } | null>(null);
    const [selectedCountry, setSelectedCountry] = useState<{ id: number; value: string; label: string } | null>(null);

    // NP - Nearby Places
    const [isNPChecked, setIsNPChecked] = useState(false);
    const [location, setLocation] = useState<{ lat: number; lng: number } | undefined>(undefined);

    const showWarning = (message: string, success?: boolean) => {
        swal({
            title: '',
            text: message,
            icon: success ? 'success' : warning,
            dangerMode: true
        });
    };

    const handleCountyChange = (event: any, newValue: { key: number; value: string } | null) => {
        setSelectedCounty(newValue);
        setValue('county', newValue?.key, { shouldValidate: true });
    };

    const handleCountryChange = (event: any, newValue: { id: number; value: string; label: string } | null) => {
        setSelectedCountry(newValue);
        setValue('country', newValue?.id, { shouldValidate: true });
    };

    const handleNPCheckboxChange = (event: ChangeEvent<HTMLInputElement>) => {
        setIsNPChecked(event.target.checked);
    };

    const setPlaceDistance = () => {
        if (mapRef.current) {
            setValue('park', (mapRef.current.distances[0]?.distance?.value / 1000).toFixed(2), { shouldValidate: true });
            setValue('laundry', (mapRef.current.distances[1]?.distance?.value / 1000).toFixed(2), { shouldValidate: true });
            setValue('restaurant', (mapRef.current.distances[2]?.distance?.value / 1000).toFixed(2), { shouldValidate: true });
            setValue('train_station', (mapRef.current.distances[3]?.distance?.value / 1000).toFixed(2), { shouldValidate: true });
            setValue('pharmacy', (mapRef.current.distances[4]?.distance?.value / 1000).toFixed(2), { shouldValidate: true });
            setValue('supermarket', (mapRef.current.distances[5]?.distance?.value / 1000).toFixed(2), { shouldValidate: true });
            setValue('hair_care', (mapRef.current.distances[6]?.distance?.value / 1000).toFixed(2), { shouldValidate: true });
            setValue('bus_station', (mapRef.current.distances[7]?.distance?.value / 1000).toFixed(2), { shouldValidate: true });
            setValue('doctor', (mapRef.current.distances[8]?.distance?.value / 1000).toFixed(2), { shouldValidate: true });
            setValue('hospital', (mapRef.current.distances[9]?.distance?.value / 1000).toFixed(2), { shouldValidate: true });
            setValue('airport', (mapRef.current.distances[10]?.distance?.value / 1000).toFixed(2), { shouldValidate: true });
            setValue('lodging', (mapRef.current.distances[11]?.distance?.value / 1000).toFixed(2), { shouldValidate: true });
            saveProximityData();
        }
    };

    const getPlaceDistance = (type: string) => {
        switch (type) {
            case 'park':
                return values.park;
            case 'laundry':
                return values.laundry;
            case 'restaurant':
                return values.restaurant;
            case 'train_station':
                return values.train_station;
            case 'pharmacy':
                return values.pharmacy;
            case 'supermarket':
                return values.supermarket;
            case 'hair_care':
                return values.hair_care;
            case 'bus_station':
                return values.bus_station;
            case 'doctor':
                return values.doctor;
            case 'hospital':
                return values.hospital;
            case 'airport':
                return values.airport;
            case 'lodging':
                return values.lodging;
        }
    };

    const saveData = async () => {
        if (!isValid) {
            return;
        }
        let places: any[] = [];
        if (isNPChecked) {
            if (mapRef.current) {
                places = mapRef.current.places.map((item: any, index: number) => {
                    const lat = item.geometry?.location.lat();
                    const lng = item.geometry?.location.lng();
                    return {
                        type: item.type,
                        distance: parseFloat(getPlaceDistance(item.type)),
                        location: {
                            lat,
                            lng
                        },
                        name: item.name,
                        place_id: item.place_id,
                        vicinity: item.vicinity
                    };
                });
            }
        } else {
            places = [
                { type: 'park', distance: parseFloat(getPlaceDistance('park')) },
                { type: 'laundry', distance: parseFloat(getPlaceDistance('laundry')) },
                { type: 'restaurant', distance: parseFloat(getPlaceDistance('restaurant')) },
                { type: 'train_station', distance: parseFloat(getPlaceDistance('train_station')) },
                { type: 'pharmacy', distance: parseFloat(getPlaceDistance('pharmacy')) },
                { type: 'supermarket', distance: parseFloat(getPlaceDistance('supermarket')) },
                { type: 'hair_care', distance: parseFloat(getPlaceDistance('hair_care')) },
                { type: 'bus_station', distance: parseFloat(getPlaceDistance('bus_station')) },
                { type: 'doctor', distance: parseFloat(getPlaceDistance('doctor')) },
                { type: 'hospital', distance: parseFloat(getPlaceDistance('hospital')) },
                { type: 'airport', distance: parseFloat(getPlaceDistance('airport')) },
                { type: 'lodging', distance: parseFloat(getPlaceDistance('lodging')) }
            ];
        }

        const bodyData = {
            building: values.building,
            street: values.street,
            city: values.city,
            county: values.county,
            country: values.country,
            postal_code: values.postal_code,
            landmark: values.landmark,
            websiteUrl: values.websiteUrl,
            location: mapRef.current ? mapRef.current.markerPosition : location,
            proximity: places
        };

        await saveLocationAndProximity(serviceId!, bodyData).then((res) => {
            if (res.status !== true) {
                showWarning(t('OnboardingLocation.Save_Error_Message')!);
            }
        });
    };

    const saveProximityData = async () => {
        if (mapRef.current) {
            let places: any[] = [];
            places = mapRef.current.places.map((item: any, index: number) => {
                const lat = item.geometry?.location.lat();
                const lng = item.geometry?.location.lng();
                return {
                    type: item.type,
                    distance: parseFloat((mapRef.current.distances[index].distance.value / 1000).toFixed(2)),
                    location: {
                        lat,
                        lng
                    },
                    name: item.name,
                    place_id: item.place_id,
                    vicinity: item.vicinity
                };
            });

            await updateServiceProximity(serviceId!, places).then((res) => {
                if (res.status !== true) {
                    showWarning(t('OnboardingLocation.Save_Error_Message')!);
                }
            });
        }
    };

    useEffect(() => {
        if (isNPChecked && mapRef.current) {
            if (!data.usedMapsAPI) {
                // swal({
                //     title: '',
                //     text: t('OnboardingLocation.Nearby_Places_Warning')!,
                //     icon: warning,
                //     buttons: ['No', 'Yes'],
                //     dangerMode: true
                // }).then(async (confirm: any) => {
                //     if (confirm) {
                //         mapRef.current.fetchNearbyPlacesWithDistance(mapRef.current.markerPosition);
                //         setTimeout(() => {
                //             setPlaceDistance();
                //         }, 2000);
                //     } else {
                //         setIsNPChecked(false);
                //     }
                // });
                mapRef.current.fetchNearbyPlacesWithDistance(mapRef.current.markerPosition);
                setTimeout(() => {
                    setPlaceDistance();
                }, 2000);
            }
        }
    }, [isNPChecked]);

    useEffect(() => {
        const getData = async () => {
            try {
                const countyRes = await getCounties();
                if (countyRes) {
                    setCountyDropDownData(countyRes?.data);
                }

                const countryRes = await getCountries();
                if (countryRes) {
                    setCountryDropDownData(countryRes?.data?.result);
                }

                const response = await getOnboardingLocationData(serviceId!);
                if (response.status === true) {
                    setData(response.data);
                }
            } catch (error) {
                console.error('Error getting data: ', error);
            }
        };
        getData();
    }, []);

    useEffect(() => {
        if (data) {
            setValue('building', data.building, { shouldValidate: true });
            setValue('street', data.street, { shouldValidate: true });
            setValue('city', data.city, { shouldValidate: true });
            setValue('county', data.county, { shouldValidate: true });
            setValue('country', data.country, { shouldValidate: true });
            setValue('postal_code', data.postalCode, { shouldValidate: true });
            setValue('landmark', data.landmark, { shouldValidate: true });
            setValue('websiteUrl', data.websiteUrl, { shouldValidate: true });
            setValue('park', data.proximity[0]?.distance, { shouldValidate: data.proximity[0] ? true : false });
            setValue('laundry', data.proximity[1]?.distance, { shouldValidate: data.proximity[1] ? true : false });
            setValue('restaurant', data.proximity[2]?.distance, { shouldValidate: data.proximity[2] ? true : false });
            setValue('train_station', data.proximity[3]?.distance, { shouldValidate: data.proximity[3] ? true : false });
            setValue('pharmacy', data.proximity[4]?.distance, { shouldValidate: data.proximity[4] ? true : false });
            setValue('supermarket', data.proximity[5]?.distance, { shouldValidate: data.proximity[5] ? true : false });
            setValue('hair_care', data.proximity[6]?.distance, { shouldValidate: data.proximity[6] ? true : false });
            setValue('bus_station', data.proximity[7]?.distance, { shouldValidate: data.proximity[7] ? true : false });
            setValue('doctor', data.proximity[8]?.distance, { shouldValidate: data.proximity[8] ? true : false });
            setValue('hospital', data.proximity[9]?.distance, { shouldValidate: data.proximity[9] ? true : false });
            setValue('airport', data.proximity[10]?.distance, { shouldValidate: data.proximity[10] ? true : false });
            setValue('lodging', data.proximity[11]?.distance, { shouldValidate: data.proximity[11] ? true : false });
            const fetchedCounty = countyDropDownData.find((option) => option.key === data.county);
            const fetchedCountry = countryDropDownData.find((option) => option.id === data.country);
            if (fetchedCounty) setSelectedCounty(fetchedCounty);
            if (fetchedCountry) setSelectedCountry(fetchedCountry);
            setLocation(data.location);
            setIsNPChecked(data.usedMapsAPI);
            setTimeout(() => {
                if (data.usedMapsAPI && mapRef.current) {
                    mapRef.current.setPlaces(
                        data.proximity.map((item: any) => {
                            return {
                                type: item.type,
                                distance: item.distance,
                                name: item.name,
                                place_id: item.place_id,
                                vicinity: item.vicinity,
                                geometry: { location: item.location ? new window.google.maps.LatLng(item.location.lat, item.location.lng) : undefined }
                            };
                        })
                    );
                }

                if (!data.usedMapsAPI) {
                    setIsNPChecked(true);
                }
            }, 1000);
        }
    }, [data]);

    useEffect(()=>{
        dispatch(setOnboardingPageValidity(isValid))
    }, [isValid])

    return (
        <div className="cards-section onboard-location pb-115">
            <div className="row">
                <div className="col-md-6">
                    <div className="sec-frame mb-3">
                        <div className="sec-frame-title">
                            <p>1. Provide Facility Address</p>
                        </div>
                        <div className="form-container">
                            <div className="neumorphic-fieldset mb-2">
                                <label htmlFor="floor">
                                    <span className="label-style">Unit, Floor or Building (If applicable)</span>
                                </label>
                                <input
                                    type="text"
                                    {...register('building', {
                                        // onChange: (e: any) => {
                                        //     props.updateData(e);
                                        // }
                                    })}
                                    name={'building' as const}
                                    className={`form-control ${errors.building ? 'is-invalid' : ''}`}
                                />
                            </div>
                            <div className="row">
                                <div className="col-md-8">
                                    <div className="neumorphic-fieldset mb-2">
                                        <label htmlFor="street">
                                            <span className="label-style">Street Address *</span>
                                        </label>
                                        <input
                                            type="text"
                                            {...register('street', {
                                                // onChange: (e: any) => {
                                                //     props.updateData(e);
                                                // }
                                            })}
                                            name={'street' as const}
                                            className={`form-control ${errors.street ? 'is-invalid' : ''}`}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-8">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="neumorphic-fieldset mb-2">
                                                <label htmlFor="city">
                                                    <span className="label-style">Town/ City *</span>
                                                </label>
                                                <input
                                                    type="text"
                                                    className={`form-control ${errors.city ? 'is-invalid' : ''}`}
                                                    {...register('city', {
                                                        // onChange: (e: any) => {
                                                        //     props.updateData(e);
                                                        // }
                                                    })}
                                                    name={'city' as const}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="neumorphic-fieldset mb-2">
                                                <label htmlFor="county">
                                                    <span className="label-style">County *</span>
                                                </label>
                                                <Autocomplete
                                                    disablePortal
                                                    options={countyDropDownData}
                                                    getOptionLabel={(option) => option.value}
                                                    value={selectedCounty}
                                                    isOptionEqualToValue={(option, value) => option.key === value.key}
                                                    onChange={handleCountyChange}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            InputProps={{
                                                                ...params.InputProps,
                                                                classes: {
                                                                    root: errors.county ? 'onboarding-auto-complete-input-layout-error' : 'onboarding-auto-complete-input-layout',
                                                                    input: `onboarding-auto-complete-input`
                                                                }
                                                            }}
                                                            variant="outlined"
                                                            size="small"
                                                        />
                                                    )}
                                                />
                                                {/* <input
                                                    type="text"
                                                    className={`form-control ${errors.county ? 'is-invalid' : ''}`}
                                                    {...register('county', {
                                                        // onChange: (e: any) => {
                                                        //     props.updateData(e);
                                                        // }
                                                    })}
                                                    name={'county' as const}
                                                /> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-8">
                                    <div className="neumorphic-fieldset mb-2">
                                        <label htmlFor="country">
                                            <span className="label-style">Region/ Country *</span>
                                        </label>
                                        <Autocomplete
                                            disablePortal
                                            options={countryDropDownData}
                                            getOptionLabel={(option) => option.value}
                                            onChange={handleCountryChange}
                                            value={selectedCountry}
                                            isOptionEqualToValue={(option, value) => option.id === value.id}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    InputProps={{
                                                        ...params.InputProps,
                                                        classes: {
                                                            root: errors.country ? 'onboarding-auto-complete-input-layout-error' : 'onboarding-auto-complete-input-layout',
                                                            input: 'onboarding-auto-complete-input'
                                                        }
                                                    }}
                                                    variant="outlined"
                                                    size="small"
                                                />
                                            )}
                                        />
                                        {/* <input
                                            type="text"
                                            className={`form-control ${errors.country ? 'is-invalid' : ''}`}
                                            {...register('country', {
                                                // onChange: (e: any) => {
                                                //     props.updateData(e);
                                                // }
                                            })}
                                            name={'country' as const}
                                        /> */}
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="neumorphic-fieldset mb-2">
                                        <label htmlFor="postal">
                                            <span className="label-style">Postal Code *</span>
                                        </label>
                                        <input
                                            type="text"
                                            className={`form-control ${errors.postal_code ? 'is-invalid' : ''}`}
                                            {...register('postal_code', {
                                                // onChange: (e: any) => {
                                                //     props.updateData(e);
                                                // }
                                            })}
                                            name={'postal_code' as const}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="neumorphic-fieldset mb-2">
                                <label htmlFor="landmark">
                                    <span className="label-style">Landmark (If applicable)</span>
                                </label>
                                <input
                                    type="text"
                                    className={`form-control ${errors.landmark ? 'is-invalid' : ''}`}
                                    {...register('landmark', {
                                        // onChange: (e: any) => {
                                        //     props.updateData(e);
                                        // }
                                    })}
                                    name={'landmark' as const}
                                />
                            </div>
                            <div className="neumorphic-fieldset mb-2">
                                <label htmlFor="websiteUrl">
                                    <span className="label-style">Website URL</span>
                                </label>
                                <input
                                    type="text"
                                    className={`form-control ${errors.websiteUrl ? 'is-invalid' : ''}`}
                                    {...register('websiteUrl', {
                                        // onChange: (e: any) => {
                                        //     props.updateData(e);
                                        // }
                                    })}
                                    name={'websiteUrl' as const}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="sec-frame">
                        <div className="sec-frame-title">
                            <p>2. Specify Proximity</p>
                        </div>
                        <div className="">
                            <div className="d-flex justify-content-end mb-3">
                                <div className="d-flex align-items-center">
                                    <div className="me-2">
                                        <img src={google_maps_logo} className="img-fluid" alt="map image" width={100} />
                                    </div>
                                    <div className="anim-switch">
                                        <label className="switchts">
                                            <input type="checkbox" disabled={isNPChecked} checked={isNPChecked} onChange={handleNPCheckboxChange} />
                                            <div className="sliderts">
                                                <div className="circlets">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" className="checkmark" enable-background="new 0 0 512 512" viewBox="0 0 24 24">
                                                        <path
                                                            fill="currentColor"
                                                            d="M9.707 19.121a.997.997 0 01-1.414 0l-5.646-5.647a1.5 1.5 0 010-2.121l.707-.707a1.5 1.5 0 012.121 0L9 14.171l9.525-9.525a1.5 1.5 0 012.121 0l.707.707a1.5 1.5 0 010 2.121z"
                                                            data-original="#000000"
                                                        ></path>
                                                    </svg>
                                                </div>
                                            </div>
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div className="row nearbyPlaces">
                                <div className="col-xl-4 d-flex align-items-center">
                                    <img src={park_garden} className="img-fluid" alt="map image" width={25} />
                                    <div className="neumorphic-fieldset mb-2 ms-3">
                                        <label htmlFor="park">
                                            <span className="label-style">Park/Garden</span>
                                        </label>
                                        <input
                                            type="number"
                                            className={`form-control fieldset-fixed-padding-right ${errors.park ? 'is-invalid' : ''}`}
                                            disabled={isNPChecked}
                                            {...register('park', {
                                                // onChange: (e: any) => {
                                                //     props.updateData(e);
                                                // }
                                            })}
                                            name={'park' as const}
                                        />
                                        <span className="fieldset-fixed-text">Km</span>
                                    </div>
                                </div>
                                <div className="col-xl-4 d-flex align-items-center">
                                    <img src={supermarket} className="img-fluid" alt="map image" width={25} />
                                    <div className="neumorphic-fieldset mb-2 ms-3">
                                        <label htmlFor="supermarket">
                                            <span className="label-style">Supermarket</span>
                                        </label>
                                        <input
                                            type="number"
                                            className={`form-control fieldset-fixed-padding-right ${errors.supermarket ? 'is-invalid' : ''}`}
                                            disabled={isNPChecked}
                                            {...register('supermarket', {
                                                // onChange: (e: any) => {
                                                //     props.updateData(e);
                                                // }
                                            })}
                                            name={'supermarket' as const}
                                        />
                                        <span className="fieldset-fixed-text">Km</span>
                                    </div>
                                </div>
                                <div className="col-xl-4 d-flex align-items-center">
                                    <img src={laundry} className="img-fluid" alt="map image" width={25} />
                                    <div className="neumorphic-fieldset mb-2 ms-3">
                                        <label htmlFor="laundry">
                                            <span className="label-style">Laundry</span>
                                        </label>
                                        <input
                                            type="number"
                                            className={`form-control fieldset-fixed-padding-right ${errors.laundry ? 'is-invalid' : ''}`}
                                            disabled={isNPChecked}
                                            {...register('laundry', {
                                                // onChange: (e: any) => {
                                                //     props.updateData(e);
                                                // }
                                            })}
                                            name={'laundry' as const}
                                        />
                                        <span className="fieldset-fixed-text">Km</span>
                                    </div>
                                </div>
                                <div className="col-xl-4 d-flex align-items-center">
                                    <img src={salon_barber} className="img-fluid" alt="map image" width={25} />
                                    <div className="neumorphic-fieldset mb-2 ms-3">
                                        <label htmlFor="hair_care">
                                            <span className="label-style">Salon/Barber</span>
                                        </label>
                                        <input
                                            type="number"
                                            className={`form-control fieldset-fixed-padding-right ${errors.hair_care ? 'is-invalid' : ''}`}
                                            disabled={isNPChecked}
                                            {...register('hair_care', {
                                                // onChange: (e: any) => {
                                                //     props.updateData(e);
                                                // }
                                            })}
                                            name={'hair_care' as const}
                                        />
                                        <span className="fieldset-fixed-text">Km</span>
                                    </div>
                                </div>
                                <div className="col-xl-4 d-flex align-items-center">
                                    <img src={restaurant} className="img-fluid" alt="map image" width={25} />
                                    <div className="neumorphic-fieldset mb-2 ms-3">
                                        <label htmlFor="restaurant">
                                            <span className="label-style">Restaurant</span>
                                        </label>
                                        <input
                                            type="number"
                                            className={`form-control fieldset-fixed-padding-right ${errors.restaurant ? 'is-invalid' : ''}`}
                                            disabled={isNPChecked}
                                            {...register('restaurant', {
                                                // onChange: (e: any) => {
                                                //     props.updateData(e);
                                                // }
                                            })}
                                            name={'restaurant' as const}
                                        />
                                        <span className="fieldset-fixed-text">Km</span>
                                    </div>
                                </div>

                                <div className="col-xl-4 d-flex align-items-center">
                                    <img src={hotel} className="img-fluid" alt="map image" width={25} />
                                    <div className="neumorphic-fieldset mb-2 ms-3">
                                        <label htmlFor="lodging">
                                            <span className="label-style">Hotel</span>
                                        </label>
                                        <input
                                            type="number"
                                            className={`form-control fieldset-fixed-padding-right ${errors.lodging ? 'is-invalid' : ''}`}
                                            disabled={isNPChecked}
                                            {...register('lodging', {
                                                // onChange: (e: any) => {
                                                //     props.updateData(e);
                                                // }
                                            })}
                                            name={'lodging' as const}
                                        />
                                        <span className="fieldset-fixed-text">Km</span>
                                    </div>
                                </div>
                                <div className="col-xl-4 d-flex align-items-center">
                                    <img src={airport} className="img-fluid" alt="map image" width={25} />
                                    <div className="neumorphic-fieldset mb-2 ms-3">
                                        <label htmlFor="airport">
                                            <span className="label-style">Airport</span>
                                        </label>
                                        <input
                                            type="number"
                                            className={`form-control fieldset-fixed-padding-right ${errors.airport ? 'is-invalid' : ''}`}
                                            disabled={isNPChecked}
                                            {...register('airport', {
                                                // onChange: (e: any) => {
                                                //     props.updateData(e);
                                                // }
                                            })}
                                            name={'airport' as const}
                                        />
                                        <span className="fieldset-fixed-text">Km</span>
                                    </div>
                                </div>
                                <div className="col-xl-4 d-flex align-items-center">
                                    <img src={bus} className="img-fluid" alt="map image" width={25} />
                                    <div className="neumorphic-fieldset mb-2 ms-3">
                                        <label htmlFor="bus_station">
                                            <span className="label-style">Bus Stop</span>
                                        </label>
                                        <input
                                            type="number"
                                            className={`form-control fieldset-fixed-padding-right ${errors.bus_station ? 'is-invalid' : ''}`}
                                            disabled={isNPChecked}
                                            {...register('bus_station', {
                                                // onChange: (e: any) => {
                                                //     props.updateData(e);
                                                // }
                                            })}
                                            name={'bus_station' as const}
                                        />
                                        <span className="fieldset-fixed-text">Km</span>
                                    </div>
                                </div>
                                <div className="col-xl-4 d-flex align-items-center">
                                    <img src={railway} className="img-fluid" alt="map image" width={25} />
                                    <div className="neumorphic-fieldset mb-2 ms-3">
                                        <label htmlFor="train_station">
                                            <span className="label-style">Railway Stn.</span>
                                        </label>
                                        <input
                                            type="number"
                                            className={`form-control fieldset-fixed-padding-right ${errors.train_station ? 'is-invalid' : ''}`}
                                            disabled={isNPChecked}
                                            {...register('train_station', {
                                                // onChange: (e: any) => {
                                                //     props.updateData(e);
                                                // }
                                            })}
                                            name={'train_station' as const}
                                        />
                                        <span className="fieldset-fixed-text">Km</span>
                                    </div>
                                </div>
                                <div className="col-xl-4 d-flex align-items-center">
                                    <img src={doctor} className="img-fluid" alt="map image" width={25} />
                                    <div className="neumorphic-fieldset mb-2 ms-3">
                                        <label htmlFor="doctor">
                                            <span className="label-style">GP</span>
                                        </label>
                                        <input
                                            type="number"
                                            className={`form-control fieldset-fixed-padding-right ${errors.doctor ? 'is-invalid' : ''}`}
                                            disabled={isNPChecked}
                                            {...register('doctor', {
                                                // onChange: (e: any) => {
                                                //     props.updateData(e);
                                                // }
                                            })}
                                            name={'doctor' as const}
                                        />
                                        <span className="fieldset-fixed-text">Km</span>
                                    </div>
                                </div>
                                <div className="col-xl-4 d-flex align-items-center">
                                    <img src={pharmacy} className="img-fluid" alt="map image" width={25} />
                                    <div className="neumorphic-fieldset mb-2 ms-3">
                                        <label htmlFor="pharmacy">
                                            <span className="label-style">Pharmacy</span>
                                        </label>
                                        <input
                                            type="number"
                                            className={`form-control fieldset-fixed-padding-right ${errors.pharmacy ? 'is-invalid' : ''}`}
                                            disabled={isNPChecked}
                                            {...register('pharmacy', {
                                                // onChange: (e: any) => {
                                                //     props.updateData(e);
                                                // }
                                            })}
                                            name={'pharmacy' as const}
                                        />
                                        <span className="fieldset-fixed-text">Km</span>
                                    </div>
                                </div>
                                <div className="col-xl-4 d-flex align-items-center">
                                    <img src={hospital} className="img-fluid" alt="map image" width={25} />
                                    <div className="neumorphic-fieldset mb-2 ms-3">
                                        <label htmlFor="hospital">
                                            <span className="label-style">Hospital</span>
                                        </label>
                                        <input
                                            type="number"
                                            className={`form-control fieldset-fixed-padding-right ${errors.hospital ? 'is-invalid' : ''}`}
                                            disabled={isNPChecked}
                                            {...register('hospital', {
                                                // onChange: (e: any) => {
                                                //     props.updateData(e);
                                                // }
                                            })}
                                            name={'hospital' as const}
                                        />
                                        <span className="fieldset-fixed-text">Km</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="sec-frame h-100">
                        <div className="sec-frame-title">
                            <p>3. Pinpoint Exact Location</p>
                        </div>

                        <div className="onboardMap p-1 mt-0">
                            {location && (
                                <OnboardingLocationMapComponent
                                    ref={mapRef}
                                    location={location}
                                    containerStyle={{
                                        width: `100%`,
                                        height: `100%`,
                                        borderRadius: '10px'
                                    }}
                                    isPinDraggable={true}
                                />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
});

export default OnboardLocationView;

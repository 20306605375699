import React, { useCallback, useEffect, useState } from 'react';
import WelcomeView from './WelcomeView';
import { GetOrganizationlistService } from '../../../service/onboarding-service';
import { OrganizationListResponse } from './types';
import { ApiResponse } from '../../../utils/model';

const WelcomeContainer = () => {
    const [organizationList, setOrganizationList] = useState<OrganizationListResponse[]>([]);

    const ImageRef = React.useRef<HTMLDivElement | null>(null);

    const fetchOrganizationList = useCallback(async () => {
        const res: ApiResponse<OrganizationListResponse[]> = await GetOrganizationlistService();
        if (res.status) {
            setOrganizationList(res.data);
        }
    }, []);

    useEffect(() => {
        fetchOrganizationList();
    }, []);

    return (
        <div>
            <WelcomeView organizationList={organizationList} />
        </div>
    );
};

export default WelcomeContainer;

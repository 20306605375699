import { AxiosError, AxiosResponse } from "axios";
import { API_ENDPOINT, BASEURLDEV } from "./ApiConstants";
import instance from "./axiosinstance";
import { DataResponseModel } from '../utils/model';
import { AssistQuery } from '../pages/Onboarding/OnboardingParent/ProgressHeader/types';
import { BEConfigureInterface, BEServiceLogoInterface, OrganizationServiceListPayload } from "../pages/Onboarding/Welcome/types";
import eventEmitter from "../utils/eventEmitter";

export const GetOrganizationlistService = async () => {
    return instance
        .get(`${BASEURLDEV}${API_ENDPOINT.GetOrganizationlist}`)
        .then((response: AxiosResponse) => {
            const resultData: any = response.data
            return resultData;
        })
        .catch((error: AxiosError) => {
            if (error?.code === 'ERR_BAD_REQUEST' && error?.response?.status === 401) {
                window.$utils.signOutUser()
            }
            if (error.code === 'ERR_NETWORK') {
                const errorResponse = {
                    status: false,
                    data: [],
                    message: error.message,
                    totalCount: 0
                };
                const errorData: any = errorResponse;
                return errorData;
            } else {
                const errorData: any = error.response;
                return errorData;
            }
        });
};

export const GetOrganizationServiceList = async (payload: OrganizationServiceListPayload) => {
    eventEmitter.emit('eventSetLoader', true)
    return instance
        .post(`${BASEURLDEV}${API_ENDPOINT.GetOrganizationServiceList}`, payload)
        .then((response: AxiosResponse) => {
            eventEmitter.emit('eventSetLoader', false)
            const resultData: any = response.data
            return resultData;
        })
        .catch((error: AxiosError) => {
            eventEmitter.emit('eventSetLoader', false)
            if (error?.code === 'ERR_BAD_REQUEST' && error?.response?.status === 401) {
                window.$utils.signOutUser()
            }
            if (error.code === 'ERR_NETWORK') {
                const errorResponse = {
                    status: false,
                    data: [],
                    message: error.message,
                    totalCount: 0
                };
                const errorData: any = errorResponse;
                return errorData;
            } else {
                const errorData: any = error.response;
                return errorData;
            }
        });
};

export const NewUserAssigningService = async (payload: BEConfigureInterface) => {
    eventEmitter.emit('eventSetLoader', true)
    return instance
        .post(`${BASEURLDEV}${API_ENDPOINT.ConfigueSiteUser}`, payload)
        .then((response: AxiosResponse) => {
            const resultData: any = response.data
            eventEmitter.emit('eventSetLoader', false)
            return resultData;
        })
        .catch((error: AxiosError) => {
            eventEmitter.emit('eventSetLoader', false)
            if (error.code === 'ERR_NETWORK') {
                const errorResponse = {
                    status: false,
                    data: [],
                    message: error.message,
                    totalCount: 0
                };
                const errorData: any = errorResponse;
                return errorData;
            } else {
                const errorData: any = error.response;
                return errorData;
            }
        });
};

export const UploadServiceLogo = async (formData: FormData) => {
    eventEmitter.emit('eventSetLoader', true)
    return instance
        .post(`${BASEURLDEV}${API_ENDPOINT.UploadLogo}`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
        .then((response: AxiosResponse) => {
            const resultData: any = response.data
            eventEmitter.emit('eventSetLoader', false)
            return resultData;
        })
        .catch((error: AxiosError) => {
            eventEmitter.emit('eventSetLoader', false)
            if (error.code === 'ERR_NETWORK') {
                const errorResponse = {
                    status: false,
                    data: [],
                    message: error.message,
                    totalCount: 0
                };
                const errorData: any = errorResponse;
                return errorData;
            } else {
                const errorData: any = error.response;
                return errorData;
            }
        });
};


export const submitAssisstanceQuery = (payload: AssistQuery) => {
    return (
        instance
            .post(`${BASEURLDEV}${API_ENDPOINT.SubmitAssistanceQueryApi}`, payload)
            .then((response: AxiosResponse) => {
                const resultData: any = response.data as DataResponseModel;
                return resultData;
            })
            .catch((error: AxiosError) => {
                if (error.code === 'ERR_NETWORK') {
                    const errorResponse = {
                        status: false,
                        data: [],
                        message: error.message,
                        totalCount: 0
                    };
                    const errorData: any = errorResponse as DataResponseModel;
                    return errorData;
                } else {
                    const errorResponse = {
                        status: false,
                        data: [],
                        message: 'Something went wrong!',
                        totalCount: 0
                    };
                    const errorData: any = error.response ? (error.response.data as DataResponseModel) : (errorResponse as DataResponseModel);
                    return errorData;
                }
            })
    );
};

export const getOnboardingLocationData = (id: number) => {
    return (
        instance
            .get(`${BASEURLDEV}${API_ENDPOINT.GetOnboardingLocationData}${id}`)
            .then((response: AxiosResponse) => {
                const resultData: any = response.data as DataResponseModel;
                return resultData;
            })
            .catch((error: AxiosError) => {
                if (error.code === 'ERR_NETWORK') {
                    const errorResponse = {
                        status: false,
                        data: [],
                        message: error.message,
                        totalCount: 0
                    };
                    const errorData: any = errorResponse as DataResponseModel;
                    return errorData;
                } else {
                    const errorResponse = {
                        status: false,
                        data: [],
                        message: 'Something went wrong!',
                        totalCount: 0
                    };
                    const errorData: any = error.response ? (error.response.data as DataResponseModel) : (errorResponse as DataResponseModel);
                    return errorData;
                }
            })
    );
};

export const getSiteUserNameAndEmail = (id: number) => {
    return (
        instance
            .get(`${BASEURLDEV}${API_ENDPOINT.GetSiteUserNameAndEmail}${id}`)
            .then((response: AxiosResponse) => {
                const resultData: any = response.data as DataResponseModel;
                return resultData;
            })
            .catch((error: AxiosError) => {
                if (error.code === 'ERR_NETWORK') {
                    const errorResponse = {
                        status: false,
                        data: [],
                        message: error.message,
                        totalCount: 0
                    };
                    const errorData: any = errorResponse as DataResponseModel;
                    return errorData;
                } else {
                    const errorResponse = {
                        status: false,
                        data: [],
                        message: 'Something went wrong!',
                        totalCount: 0
                    };
                    const errorData: any = error.response ? (error.response.data as DataResponseModel) : (errorResponse as DataResponseModel);
                    return errorData;
                }
            })
    );
};

export const getOnboardingQuestionAndAnswers = (id: number) => {
    return (
        instance
            .get(`${BASEURLDEV}${API_ENDPOINT.GetOnboardingQuestionAndAnswers}?serviceId=${id}`)
            .then((response: AxiosResponse) => {
                const resultData: any = response.data as DataResponseModel;
                return resultData;
            })
            .catch((error: AxiosError) => {
                if (error.code === 'ERR_NETWORK') {
                    const errorResponse = {
                        status: false,
                        data: [],
                        message: error.message,
                        totalCount: 0
                    };
                    const errorData: any = errorResponse as DataResponseModel;
                    return errorData;
                } else {
                    const errorResponse = {
                        status: false,
                        data: [],
                        message: 'Something went wrong!',
                        totalCount: 0
                    };
                    const errorData: any = error.response ? (error.response.data as DataResponseModel) : (errorResponse as DataResponseModel);
                    return errorData;
                }
            })
    );
};

export const saveOnboardingQuestionAndAnswers = (payload: any) => {
    return (
        instance
            .post(`${BASEURLDEV}${API_ENDPOINT.SaveOnboardingQuestionAndAnswers}`, payload)
            .then((response: AxiosResponse) => {
                const resultData: any = response.data as DataResponseModel;
                return resultData;
            })
            .catch((error: AxiosError) => {
                if (error.code === 'ERR_NETWORK') {
                    const errorResponse = {
                        status: false,
                        data: [],
                        message: error.message,
                        totalCount: 0
                    };
                    const errorData: any = errorResponse as DataResponseModel;
                    return errorData;
                } else {
                    const errorResponse = {
                        status: false,
                        data: [],
                        message: 'Something went wrong!',
                        totalCount: 0
                    };
                    const errorData: any = error.response ? (error.response.data as DataResponseModel) : (errorResponse as DataResponseModel);
                    return errorData;
                }
            })
    );
};

export const getSiteUsername = (username: string) => {
    return (
        instance
            .get(`${BASEURLDEV}${API_ENDPOINT.GetSiteUserNameList}${username}`)
            .then((response: AxiosResponse) => {
                const resultData: any = response.data as DataResponseModel;
                return resultData;
            })
            .catch((error: AxiosError) => {
                if (error.code === 'ERR_NETWORK') {
                    const errorResponse = {
                        status: false,
                        data: [],
                        message: error.message,
                        totalCount: 0
                    };
                    const errorData: any = errorResponse as DataResponseModel;
                    return errorData;
                } else {
                    const errorResponse = {
                        status: false,
                        data: [],
                        message: 'Something went wrong!',
                        totalCount: 0
                    };
                    const errorData: any = error.response ? (error.response.data as DataResponseModel) : (errorResponse as DataResponseModel);
                    return errorData;
                }
            })
    );
};

export const getConfigureUserNameEmail = (id: number) => {
    return (
        instance
            .get(`${BASEURLDEV}${API_ENDPOINT.GetConfigureUsernameEmail}${id}`)
            .then((response: AxiosResponse) => {
                const resultData: any = response.data as DataResponseModel;
                return resultData;
            })
            .catch((error: AxiosError) => {
                if (error.code === 'ERR_NETWORK') {
                    const errorResponse = {
                        status: false,
                        data: [],
                        message: error.message,
                        totalCount: 0
                    };
                    const errorData: any = errorResponse as DataResponseModel;
                    return errorData;
                } else {
                    const errorResponse = {
                        status: false,
                        data: [],
                        message: 'Something went wrong!',
                        totalCount: 0
                    };
                    const errorData: any = error.response ? (error.response.data as DataResponseModel) : (errorResponse as DataResponseModel);
                    return errorData;
                }
            })
    );
};

import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import swal from 'sweetalert';
import loader from '../../../assets/logos/loader.svg';
import ClientPortal from '../../../components/ClientPortal';
import { ROUTES_DATA } from '../../../navigation/RoutesConstants';
//@ts-ignore
import AOS from 'aos';
import 'aos/dist/aos.css';

const ServiceOnboardingPreviewPreviewLoader = (props: any) => {
    const { showModal } = props;
    const navigate = useNavigate();
    const [message, setMessage] = useState('');
    useEffect(() => {
        AOS.init();
    }, []);

    useEffect(() => {
        if (showModal) {
            document.body.classList.add('modal-open');
        } else {
            document.body.classList.remove('modal-open');
        }
        return () => {
            document.body.classList.remove('modal-open');
        };
    }, [showModal]);

    useEffect(() => {
        setMessage('Your profile is being set up');
        setTimeout(() => {
            setMessage('Setting up wards and bed board');
            setTimeout(() => {
                setMessage('Setting up location information');
                setTimeout(() => {
                    setMessage('Contact information being set up');
                    setTimeout(() => {
                        setMessage('Setting up services');
                        setTimeout(() => {
                            setTimeout(() => {
                                navigate(ROUTES_DATA.ONBOARDINGDASHBOARD);
                                swal({
                                    title: '',
                                    text: 'Profile Setup Completed Successfully',
                                    icon: 'success',
                                    dangerMode: true
                                });
                            }, 2000);
                        }, 3000);
                    }, 3000);
                }, 3000);
            }, 3000);
        }, 3000);
    }, []);

    return (
        <ClientPortal selector="myportal" show={showModal}>
            <div className="overlay"></div>
            <div className="modal preview-modal nhs-body" style={{ width: 450 }}>
                <div className="d-flex justify-content-center align-items-center" style={{ padding: 20, borderRadius: 10, boxShadow: '0 4px 8px rgba(0, 0, 0, 0.3)' }}>
                    <div className="d-flex flex-column justify-content-center align-items-center" style={{ paddingTop: 30, paddingBottom: 30 }}>
                        <img src={loader} alt="loader" className="img-fluid" style={{ height: 100, width: 100, borderRadius: 10 }} />
                        <div
                            style={{ textAlign: 'center', fontSize: 22, paddingTop: 20, textTransform: 'capitalize', fontWeight: 'bold' }}
                            data-aos="fade-In"
                            data-aos-duration="2000"
                            data-aos-once="true"
                            key={message}
                        >
                            {message}
                        </div>
                    </div>
                </div>
            </div>
        </ClientPortal>
    );
};

export default ServiceOnboardingPreviewPreviewLoader;

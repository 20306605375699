import React from "react";
import { useTranslation } from 'react-i18next';
import { coreFeesType } from "../../../../../../../utils/constant";

const ApplicableRatesView = (props: any) => {
    const { t } = useTranslation();
    return (
        <div>
            <div className="accordion-item">
                <h2 className="accordion-header" id="flush-headingTwo">
                    <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#flush-collapse-applicable-rates"
                        aria-expanded="false"
                        aria-controls="flush-collapse-applicablerates"
                    >
                        {t('RightSidebarContraclList.funder_Applicable_Rates')}
                    </button>
                </h2>
                <div
                    id="flush-collapse-applicable-rates"
                    className="accordion-collapse collapse"
                    aria-labelledby="flush-collapse-applicable-rates"
                    data-bs-parent="#admissiondetail1"
                >
                    <div className="accordion-body pb-0">
                            {props.dataApplicableRates?.totalFees != null && <p className="mb-1 total-fee">
                                <label className="me-1">{t('RightSidebarContraclList.funder_Total_Fee')}:</label> <span className="bold"><span className="me-1">£</span>{props.dataApplicableRates.totalFees || 0} / {t('RightSidebarContraclList.funder_Weekly')}</span>
                            </p>}
                            <p className="mb-1 other-fee">
                                <label className="me-1">{t('RightSidebarContraclList.funder_Core_Fees')}:</label> <span className="bold"><span className="me-1">£</span>{ props.dataApplicableRates.coreFeesType === coreFeesType.Weekly ?  `${props.dataApplicableRates.dailyCoreFee || 0} / ${t('RightSidebarContraclList.funder_Weekly')}` : `${props.dataApplicableRates.dailyCoreFee || 0} / ${t('RightSidebarContraclList.funder_Daily')}` } </span>
                            </p>
                            {(props.dataApplicableRates?.continenceCareFee != null && props.dataApplicableRates?.continenceCareFee != 0) && <p className="mb-1 other-fee">
                                
                                <label className="me-1">{t('RightSidebarContraclList.funder_Continence_Fee')}:</label> {props.dataApplicableRates?.isContinenceCareFeeIncluded === 1 &&
                                 <span>*</span>
                                } <span className="bold"><span className="me-1">£</span>{ props.dataApplicableRates?.continenceCareFeeType === coreFeesType.Weekly ?  `${props.dataApplicableRates.continenceCareFee || 0} / ${t('RightSidebarContraclList.funder_Weekly')}` : `${props.dataApplicableRates.continenceCareFee || 0} / ${t('RightSidebarContraclList.funder_Daily')}` } </span>
                            </p>}
                            {(props.dataApplicableRates?.fncFee != null && props.dataApplicableRates?.fncFee != 0) && <p className="mb-1 other-fee">
                                <label className="me-1">{t('RightSidebarContraclList.funder_FNC_Fee')}:</label>   {props.dataApplicableRates?.isFncFeeIncluded === 1 &&
                                 <span>*</span>
                                } <span className="bold"><span className="me-1">£</span>{ props.dataApplicableRates?.fncFeeType === coreFeesType.Weekly ?  `${props.dataApplicableRates.fncFee || 0} / ${t('RightSidebarContraclList.funder_Weekly')}` : `${props.dataApplicableRates.fncFee || 0} / ${t('RightSidebarContraclList.funder_Daily')}` } </span>
                            </p>}
                            {(props.dataApplicableRates?.totalAdditionalServiceFee != null && props.dataApplicableRates?.totalAdditionalServiceFee != 0) && <p className="mb-1 other-fee">
                                <label className="me-1">{t('RightSidebarContraclList.Additionalfee')}:</label> <span className="bold"><span className="me-1">£</span>{`${props.dataApplicableRates.totalAdditionalServiceFee || 0} / ${t('RightSidebarContraclList.funder_Weekly')}`} </span>
                            </p>}
                            
                            {/* <p className="mb-1">
                                <label>{t('RightSidebarContraclList.Health_Funding')}:</label> <span className="bold"><span className="me-1">£</span>{props.dataApplicableRates.healthFunding} </span>
                            </p>
                            <p className="mb-1">
                                <label>{t('RightSidebarContraclList.Social_Funding')}:</label> <span className="bold"><span className="me-1">£</span>{props.dataApplicableRates.socialFunding} </span>
                            </p> */}
                            {((props.dataApplicableRates.hourlyFee1 !== undefined && props.dataApplicableRates.hourlyFee1 !== '' && props.dataApplicableRates.hourlyFee1 !== null) || (props.dataApplicableRates.hourlyFee2 !== undefined && props.dataApplicableRates.hourlyFee2 !== '' && props.dataApplicableRates.hourlyFee2 !== null) || (props.dataApplicableRates.hourlyFee3 !== undefined && props.dataApplicableRates.hourlyFee3 !== '' && props.dataApplicableRates.hourlyFee3 !== null) || (props.dataApplicableRates.hourlyFee4 !== undefined && props.dataApplicableRates.hourlyFee4 !== '' && props.dataApplicableRates.hourlyFee4 !== null)) &&
                            <>
 <p className="mt-3 mb-2">
                                <span className="bold">{t('RightSidebarContraclList.funder_Additional_Service_Fee')}</span>
                            </p>
                        <table width="100%" className="applicable-rate-table">
                            <tr>
                                <th style={{"width":"28%"}}>Service</th>
                                <th>Rates</th>
                                <th>Inclusion</th>
                            </tr>
                            {props.dataApplicableRates.hourlyFee1 !== undefined && props.dataApplicableRates.hourlyFee1 !== '' && props.dataApplicableRates.hourlyFee1 !== null && (
                            <tr>
                                <td>
                                    <label>
                                        {t('RightSidebarContraclList.funder_1_hourly')} 
                                        {props.dataApplicableRates?.isInclusionHours1Included === 1 &&
                                 <span>*</span>
                                        }
                                        </label>
                                </td>
                                <td>
                                    <span className=" me-1"><span className="me-1">£</span>{props.dataApplicableRates.hourlyFee1 || 0}</span>
                                    {t('RightSidebarContraclList.per_hour')}
                                </td>
                                <td>
                                    <span className="me-1">{props.dataApplicableRates.inclusionHours1 || 0}</span>
                                    {<>
                                    {props.dataApplicableRates.inclusionHoursType1 === 1 && (`${t('RightSidebarContraclList.per_Day')}`
                                    )}
                                    {props.dataApplicableRates.inclusionHoursType1 === 2 && (`${t('RightSidebarContraclList.per_Week')}`
                                    )}
                                    </>}
                                </td>
                            </tr>
                            )}
                            {props.dataApplicableRates.hourlyFee2 !== undefined && props.dataApplicableRates.hourlyFee2 !== '' && props.dataApplicableRates.hourlyFee2 !== null && (
                            <tr>
                                <td>
                                <label>
                               
                                    {t('RightSidebarContraclList.funder_2_hourly')}
                                    {props.dataApplicableRates?.isInclusionHours2Included === 1 &&
                                 <span>*</span>
                                }
                                    </label> {' '}
                                </td>
                                <td>
                                    <span className=" me-1" ><span className="me-1">£</span>{props.dataApplicableRates.hourlyFee2 || 0}</span>
                                    {t('RightSidebarContraclList.per_hour')}
                                </td>
                                <td>
                                <span className="me-1">{props.dataApplicableRates.inclusionHours2 || 0}</span>
                                { <>
                                {props.dataApplicableRates.inclusionHoursType2 === 1 && (`${t('RightSidebarContraclList.per_Day')}`
                                    )}
                                    {props.dataApplicableRates.inclusionHoursType2 === 2 && (`${t('RightSidebarContraclList.per_Week')}`
                                    )}
                                    </>}
                                </td>
                            </tr>
                            )}
                            {props.dataApplicableRates.hourlyFee3 !== undefined && props.dataApplicableRates.hourlyFee3 !== '' && props.dataApplicableRates.hourlyFee3 !== null && (
                            <tr>
                                <td>
                                <label>
                              
                                    {t('RightSidebarContraclList.funder_3_hourly')}
                                    {props.dataApplicableRates?.isInclusionHours3Included === 1 &&
                                 <span>*</span>
                                }
                                    </label> {' '}
                                </td>
                                <td>
                                    <span className=" me-1"><span className="me-1">£</span>{props.dataApplicableRates.hourlyFee3 || 0}</span>
                                    {t('RightSidebarContraclList.per_hour')}
                                </td>
                                <td>
                                <span className="me-1">{props.dataApplicableRates.inclusionHours3 || 0}</span>
                                { <>
                                {props.dataApplicableRates.inclusionHoursType3 === 1 && (`${t('RightSidebarContraclList.per_Day')}`
                                    )}
                                    {props.dataApplicableRates.inclusionHoursType3 === 2 && (`${t('RightSidebarContraclList.per_Week')}`
                                    )}
                                    </>}
                                </td>
                            </tr>
                            )}
                            {props.dataApplicableRates.hourlyFee4 !== undefined && props.dataApplicableRates.hourlyFee4 !== '' && props.dataApplicableRates.hourlyFee4 !== null && (
                                <tr>
                                    <td><label>
                                 
                                        {t('RightSidebarContraclList.funder_4_hourly')}
                                        {props.dataApplicableRates?.isInclusionHours4Included === 1 &&
                                 <span>*</span>
                                }
                                        </label> {' '}</td>
                                    <td>
                                        <span className=" me-1">
                                        <span className="me-1">£</span>{props.dataApplicableRates.hourlyFee4 || 0}
                                        </span>
                                        {t('RightSidebarContraclList.per_hour')}
                                    </td>
                                    <td>
                                    <span className="me-1">{props.dataApplicableRates.inclusionHours4 || 0}</span>
                                    { <>
                                    {props.dataApplicableRates.inclusionHoursType4 === 1 && (`${t('RightSidebarContraclList.per_Day')}`
                                    )}
                                    {props.dataApplicableRates.inclusionHoursType4 === 2 && (`${t('RightSidebarContraclList.per_Week')}`
                                    )}
                                    </>}
                                    </td>
                                </tr>
                            )}
                           
                        </table>
                            </>
                            }
                           
                        <p className='includednote bolder mt-10'>* Indicates amount included in Core Fee</p>
                    </div>
                </div>
                
            </div>
        </div>
    )
}

export default ApplicableRatesView;
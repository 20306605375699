import React, { useEffect, useState } from "react";
import LocationMap from "../../../../../assets/images/location-map.png";
import LocationIcon from "../../../../../assets/images/location-direction-icon.svg";
import LocationImage from "../../../../../assets/images/owl-image1.png";
import TrianglAarrow from "../../../../../assets/images/triangle.svg";
import cqc from "../../../../../assets/images/CQC.png";
//@ts-ignore
import AOS from "aos";
import "aos/dist/aos.css";
import GoogleMapBaseComponent from "../../../../../components/GoogleMapBaseComponent/GoogleMapBaseComponent";
import { GetServiceLocationDetails } from "../../../../../service/service-details-service";
import { useParams } from "react-router-dom";
import { RootState } from "../../../../../store";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import park_garden from '../../../../../assets/images/Park-Garden.svg';
import supermarket from '../../../../../assets/images/Supermarket.svg';
import laundry from '../../../../../assets/images/Laundry.svg';
import salon_barber from '../../../../../assets/images/Salon-Barber.svg';
import restaurant from '../../../../../assets/images/Restaurant.svg';
import bus from '../../../../../assets/images/bus.svg';
import railway from '../../../../../assets/images/railway.svg';
import doctor from '../../../../../assets/images/doctor.svg';
import pharmacy from '../../../../../assets/images/pharmacy.svg';
import hospital from '../../../../../assets/images/hospital.svg';
import hotel from '../../../../../assets/images/hotel.svg';
import airport from '../../../../../assets/images/airport.svg';
import nearbyIcon from '../../../../../assets/images/provider-pin.svg';


interface OverlayProps {
  proximities: Array<any>;
  showOverlay: boolean;
  onClose: (e: any) => void;
  nearbyLocationClick: (e: any) => void;
}

const Overlay: React.FC<OverlayProps> = ({ proximities, showOverlay, onClose, nearbyLocationClick }) => {        
  return (
  <div className={`side-pane ${showOverlay ? 'visible' : ''}`}>
      <div className="overlay-content">
          <div className="overlay-header">
              <p>Nearby Location</p>
              <div onClick={onClose}>
                  <i className="fa fa-times-circle" aria-hidden="true"></i>
              </div>
          </div>
          <div className="location-overlay-body">
              {proximities?.length > 0 ? (
                  proximities.map((proximity: any, index: number) => (
                  <div key={index} className="proximity-item">
                      {proximity.type === "park" && (
                      <div className="mb-3">
                          <div className="row">
                          <div className="col-2 d-flex justify-content-end align-items-center">
                              <img src={park_garden} className="img-fluid" alt="Park" width={25} />
                          </div>
                          <div className="col-10">
                              <div className="nearby-link" onClick={() => nearbyLocationClick(proximity)} >
                                  {proximity.name}
                              </div>
                              <div>
                                  (Park - {proximity.distance} KM)
                              </div>
                          </div>
                          </div>
                      </div>
                      )}
                      {proximity.type === "laundry" && (
                      <div className="mb-3">
                          <div className="row">
                          <div className="col-2 d-flex justify-content-end align-items-center">
                              <img src={laundry} className="img-fluid" alt="laundry" width={25} />
                          </div>
                          <div className="col-10">
                              <div className="nearby-link" onClick={() => nearbyLocationClick(proximity)} >
                                  {proximity.name}
                              </div>
                              <div>
                                  (Laundry - {proximity.distance} KM)
                              </div>
                          </div>
                          </div>
                      </div>
                      )}
                      {proximity.type === "restaurant" && (
                      <div className="mb-3">
                          <div className="row">
                          <div className="col-2 d-flex justify-content-end align-items-center">
                              <img src={restaurant} className="img-fluid" alt="restaurant" width={25} />
                          </div>
                          <div className="col-10">
                              <div className="nearby-link" onClick={() => nearbyLocationClick(proximity)} >
                                  {proximity.name}
                              </div>
                              <div>
                                  (Restaurant - {proximity.distance} KM)
                              </div>
                          </div>
                          </div>
                      </div>
                      )}
                      {proximity.type === "train_station" && (
                      <div className="mb-3">
                          <div className="row">
                          <div className="col-2 d-flex justify-content-end align-items-center">
                              <img src={railway} className="img-fluid" alt="train_station" width={25} />
                          </div>
                          <div className="col-10">
                              <div className="nearby-link" onClick={() => nearbyLocationClick(proximity)} >
                                  {proximity.name}
                              </div>
                              <div>
                                  (Railway - {proximity.distance} KM)
                              </div>
                          </div>
                          </div>
                      </div>
                      )}
                      {proximity.type === "pharmacy" && (
                      <div className="mb-3">
                          <div className="row">
                          <div className="col-2 d-flex justify-content-end align-items-center">
                              <img src={pharmacy} className="img-fluid" alt="pharmacy" width={25} />
                          </div>
                          <div className="col-10">
                              <div className="nearby-link" onClick={() => nearbyLocationClick(proximity)} >
                                  {proximity.name}
                              </div>
                              <div>
                                  (Pharmacy - {proximity.distance} KM)
                              </div>
                          </div>
                          </div>
                      </div>
                      )}
                      {proximity.type === "bus_station" && (
                      <div className="mb-3">
                      <div className="row">
                          <div className="col-2 d-flex justify-content-end align-items-center">
                          <img src={bus} className="img-fluid" alt="bus_station" width={25} />
                          </div>
                          <div className="col-10">
                              <div className="nearby-link" onClick={() => nearbyLocationClick(proximity)} >
                                  {proximity.name}
                              </div>
                              <div>
                                  (Bus-station - {proximity.distance} KM)
                              </div>
                          </div>
                      </div>
                      </div>
                      )}
                      {proximity.type === "supermarket" && (
                      <div className="mb-3">
                          <div className="row">
                          <div className="col-2 d-flex justify-content-end align-items-center">
                              <img src={supermarket} className="img-fluid" alt="supermarket" width={25} />
                          </div>
                          <div className="col-10">
                              <div className="nearby-link" onClick={() => nearbyLocationClick(proximity)} >
                                  {proximity.name}
                              </div>
                              <div>
                                  (Supermarket - {proximity.distance} KM)
                              </div>
                          </div>
                          </div>
                      </div>
                      )}
                      {proximity.type === "hair_care" && (
                      <div className="mb-3">
                          <div className="row">
                          <div className="col-2 d-flex justify-content-end align-items-center">
                              <img src={salon_barber} className="img-fluid" alt="hair_care" width={25} />
                          </div>
                          <div className="col-10">
                              <div className="nearby-link" onClick={() => nearbyLocationClick(proximity)} >
                                  {proximity.name}
                              </div>
                              <div>
                                  (Salon - {proximity.distance} KM)
                              </div>
                          </div>
                          </div>
                      </div>
                      )}
                      {proximity.type === "doctor" && (
                      <div className="mb-3">
                          <div className="row">
                          <div className="col-2 d-flex justify-content-end align-items-center">
                              <img src={doctor} className="img-fluid" alt="doctor" width={25} />
                          </div>
                          <div className="col-10">
                              <div className="nearby-link" onClick={() => nearbyLocationClick(proximity)} >
                                  {proximity.name}
                              </div>
                              <div>
                                  (Doctor - {proximity.distance} KM)
                              </div>
                          </div>
                          </div>
                      </div>
                      )}
                      {proximity.type === "hospital" && (
                      <div className="mb-3">
                          <div className="row">
                          <div className="col-2 d-flex justify-content-end align-items-center">
                              <img src={hospital} className="img-fluid" alt="hospital" width={25} />
                          </div>
                          <div className="col-10">
                              <div className="nearby-link" onClick={() => nearbyLocationClick(proximity)} >
                                  {proximity.name}
                              </div>
                              <div>
                                  (Hospital - {proximity.distance} KM)
                              </div>
                          </div>
                          </div>
                      </div>
                      )}
                      {proximity.type === "airport" && (
                      <div className="mb-3">
                          <div className="row">
                          <div className="col-2 d-flex justify-content-end align-items-center">
                              <img src={airport} className="img-fluid" alt="airport" width={25} />
                          </div>
                          <div className="col-10">
                              <div className="nearby-link" onClick={() => nearbyLocationClick(proximity)} >
                                  {proximity.name}
                              </div>
                              <div>
                                  (Airport - {proximity.distance} KM)
                              </div>
                          </div>
                          </div>
                      </div>
                      )}
                      {proximity.type === "lodging" && (
                      <div className="mb-2">
                          <div className="row">
                          <div className="col-2 d-flex justify-content-end align-items-center">
                              <img src={hotel} className="img-fluid" alt="lodging" width={25} />
                          </div>
                          <div className="col-10">
                              <div className="nearby-link" onClick={() => nearbyLocationClick(proximity)} >
                                  {proximity.name}
                              </div>
                              <div>
                                  (Lodging - {proximity.distance} KM)
                              </div>
                          </div>
                      </div>
                  </div>
                  )}
              </div>
              ))
              ) : (
                  <p>No nearby locations found.</p>
              )}
          </div>
      </div>
  </div>
  )};


const LocationView = () => {
  const [containerStyle, setContainerStyle] = useState<any>({
    width: "100%",
    height: "400px",
  });
  const [centerPoint, setCenterPoint] = useState<any>(null);
  const [showOutOfBorder, setShowOutOfBourder] = useState<boolean>(false);
  const [zoom, setZoom] = useState<any>(11);
  const [locationDetails, SetLocationDetails] = useState<any>(null)
  const [serviceListsData, setServiceListsData] = useState([
    {
      serviceId: null,
      serviceName: null,
      latitude: 0,
      longitude: 0,
    }
  ]);
  const [dataCityWiseLatLngList, setDataCityWiseLatLngList] = useState<any>(null);
  const [directions, setDirections] = useState<any>(null);

  const [showOverlay, setShowOverlay] = useState<boolean>(false);
  const [serviceProxmitiesCoords, SetServiceProximitiesCoords] = useState<any>(null);
  const [serviceProxmities, SetServiceProximities] = useState<any>(null);

  const { StatusUserLocation, DataUserLocation } = useSelector(
    (state: RootState) => state.userLocation
  );

  const { t } = useTranslation();
  const params = useParams();

  useEffect(() => {
    GetServiceLocationList()
  }, [])

  useEffect(() => {
    AOS.init();
  }, []);

  useEffect(() => {
    if (locationDetails) {
      if (locationDetails && locationDetails.serviceLatitude && locationDetails.serviceLongitude) {
        let centerpoint = { lat: Number(locationDetails?.serviceLatitude), lng: Number(locationDetails?.serviceLongitude) };
        setCenterPoint(centerpoint);
      }     
      // let centerpoint = {
      //   lat: DataUserLocation?.user_co0rd?.latitude,
      //   lng: DataUserLocation?.user_co0rd?.longitude,
      // };
      setServiceListsData([
        {
          serviceId: locationDetails?.serviceId,
          serviceName: locationDetails?.serviceName,
          latitude: Number(locationDetails?.serviceLatitude),
          longitude: Number(locationDetails?.serviceLongitude),
        }
      ]);
      const convertedLatLngList = locationDetails?.cityLatLongList?.map((point: any) => ({
        lat: Number(point.latitude),
        lng: Number(point.longitude)
      }));
      setDataCityWiseLatLngList(convertedLatLngList);
      const proximities = locationDetails?.proximities.map((item: any) => {
        return {
            // proximityId: item.serviceProximityId,
            type: item.type,
            distance: item.distance,
            name: item.name,
            place_id: item.place_id,
            vicinity: item.vicinity,
            geometry: {
              location: item.latitude && item.longitude
                  ? new window.google.maps.LatLng(item.latitude, item.longitude)
                  : undefined
          }
        };
    })
      SetServiceProximitiesCoords(proximities);
    }


  }, [locationDetails]);
  useEffect(() => {

  }, [])


  const GetServiceLocationList = async () => {
    const result = await GetServiceLocationDetails(params?.serviceSlug)
    SetLocationDetails(result?.data)
  }
  const handleDirectionClick = () => {
    const directionsService = new google.maps.DirectionsService();
    //  const origin = new google.maps.LatLng(Number(DataUserLocation?.user_coords?.latitude), Number(DataUserLocation?.user_coords?.longitude));
    let origin = undefined
    if(DataUserLocation?.user_coords?.latitude){
      origin = {
        lat: DataUserLocation?.user_coords?.latitude!,
        lng: DataUserLocation?.user_coords?.longitude!,
      };
    }
    // const destination = new google.maps.LatLng(Number(locationDetails?.serviceLatitude), Number(locationDetails?.serviceLongitude));
    let destination = undefined
    if(locationDetails?.serviceLatitude!){
      destination = {
        lat: locationDetails?.serviceLatitude!,
        lng: locationDetails?.serviceLongitude!,
      };
    }
    
    // directionsService.route(
    //   {
    //     origin: origin,
    //     destination: destination,
    //     travelMode: google.maps.TravelMode.DRIVING,
    //   },
    //   (result, status) => {
    //     if (status === google.maps.DirectionsStatus.OK) {
    //       setDirections(result);
    //     } else {
    //       console.error(`Directions request failed due to ${status}`);
    //     }
    //   }
    // );

    let url = process.env.REACT_APP_MAPS_DIRECTIONS_URL;

    if (origin) {
      const originStr = `${origin.lat},${origin.lng}`;
      url += `&origin=${originStr}`;
    }

    if (destination) {
      const destinationStr = `${destination.lat},${destination.lng}`;
      url += `&destination=${destinationStr}`;
    }

    window.open(url, '_blank');
  };

  const handleOverlayToggle = (e: any) => {
    setShowOverlay((prevState) => !prevState);
  };

  const handleNearbyLocationClick = (locationObj: any) => {
    const lat = locationObj?.geometry?.location.lat();
    const lng = locationObj?.geometry?.location.lng();
    let origin = undefined;
    if (lat && lng) {
      origin = { lat, lng };
    }

    let destination = undefined;
    if (locationDetails?.serviceLatitude && locationDetails?.serviceLongitude) {
      destination = {
        lat: locationDetails.serviceLatitude,
        lng: locationDetails.serviceLongitude
      };
    }

    let url = process.env.REACT_APP_MAPS_DIRECTIONS_URL;

    if (origin) {
      const originStr = `${origin.lat},${origin.lng}`;
      url += `&origin=${originStr}`;
    }

    if (destination) {
      const destinationStr = `${destination.lat},${destination.lng}`;
      url += `&destination=${destinationStr}`;
    }
    window.open(url, '_blank');
  };

  return (
    <div className="section-padding" id="location-section">
      <div data-aos="fade-up" data-aos-duration="1000" data-aos-once="true">
        <h4 className="text-center section-title mb-3 bolder">{t("Location.Location_Title")}</h4>
        <p className="section-sub-title text-center mb-4 bolder">
          {t("Location.Find_Location")}
        </p>
        <div className="position-relative overlay-container">
          <GoogleMapBaseComponent
            containerStyle={containerStyle}
            centerPoint={centerPoint}
            zoom={zoom}
            markerPoint={serviceListsData}
            borderPoint={dataCityWiseLatLngList}
            showOutOfBorder={false}
            directions={directions}
            nearbyMarkers={serviceProxmitiesCoords}
            Infobox={<div className="location-map-box-pin">
              <div className="d-flex ">
                <div className="location-image-box">
                  <img
                    src={locationDetails?.serviceLogoImage}
                    alt="Location Image"
                    className="img-fluid"
                    width={74}
                  />
                </div>
                <div className="w-100">
                  <div className="d-flex">
                    <p className="bolder lead-text map-box-title">
                      {locationDetails?.serviceName}
                    </p>
                    <p className="ms-auto bold map-box-subtitle">
                      <i className="fa-solid fa-star yellow-star"></i> 4.5 (153)
                    </p>
                  </div>
                  <p className="darkgray-text">{locationDetails?.address}</p>
                  <div className="cqc-rating d-flex mt-1">
                    <img
                      src={cqc}
                      className="img-fluid me-2"
                      alt="cqc"
                      width={70}
                    />
                    <p className="bold d-flex align-items-center green-text ms-auto">
                      {locationDetails?.cqcRating}
                      <span className={`${locationDetails && locationDetails?.cqcRating ?
                        (
                          locationDetails?.cqcRating.toLowerCase() === 'requires improvement' ? 'dot-round-orange ms-1' :
                            (locationDetails?.cqcRating.toLowerCase() === 'inadequate' ? 'dot-round-red' :
                              (locationDetails?.cqcRating.toLowerCase() === 'good' ? 'dot-round' : 'dot-round'))
                        )
                        : ''
                        } ms-1`}></span>
                    </p>
                  </div>
                </div>
              </div>
              <img
                src={TrianglAarrow}
                alt="triangle arrow"
                className="box-botton-arrow"
              />
            </div>}
          />
          <a className="btn btn-blue btn-direction" onClick={handleDirectionClick} >
            <img
              src={LocationIcon}
              alt="Location Direction"
              width={17}
              className="me-1"
            />
            {t("Location.Directions")}
          </a>

          <a className="btn btn-blue btn-nearby" onClick={handleOverlayToggle} >
            <img
              src={nearbyIcon}
              alt="Location Direction"
              width={17}
              className="me-1"
            />
            {t("Location.Nearby")}
          </a>
          <Overlay
              proximities={serviceProxmitiesCoords}
              showOverlay={showOverlay}
              onClose={handleOverlayToggle}
              nearbyLocationClick={handleNearbyLocationClick}
          /> 
        </div>
      </div>
    </div>
  );
};

export default LocationView;
